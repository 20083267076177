import React from 'react'
import { IIconProps } from '../Types/global'

export const EnquiriesSvg: React.FC<IIconProps> = ({className, fill}) => {
    fill = fill !== undefined ? fill : '#000'
    return (
        <svg width="15" height="12" viewBox="0 0 15 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.4667 6.4V5.06667H10.15V3.71667H11.4667V2.4H12.8167V3.71667H14.15V5.06667H12.8167V6.4H11.4667ZM5.93333 5.83333C5.16667 5.83333 4.51667 5.57222 3.98333 5.05C3.46111 4.52778 3.2 3.88333 3.2 3.11667C3.2 2.36111 3.46111 1.72222 3.98333 1.2C4.51667 0.677777 5.16667 0.416666 5.93333 0.416666C6.68889 0.416666 7.32778 0.677777 7.85 1.2C8.38333 1.72222 8.65 2.36111 8.65 3.11667C8.65 3.88333 8.38333 4.52778 7.85 5.05C7.32778 5.57222 6.68889 5.83333 5.93333 5.83333ZM0.8 11.2833V9.4C0.8 9.05556 0.883333 8.73889 1.05 8.45C1.22778 8.16111 1.46667 7.93333 1.76667 7.76667C2.4 7.41111 3.06667 7.13333 3.76667 6.93333C4.46667 6.73333 5.18889 6.63333 5.93333 6.63333C6.67778 6.63333 7.4 6.73333 8.1 6.93333C8.81111 7.12222 9.47222 7.4 10.0833 7.76667C10.3833 7.93333 10.6167 8.16111 10.7833 8.45C10.9611 8.72778 11.05 9.04444 11.05 9.4V11.2833H0.8ZM2.43333 9.65H9.41667V9.45C9.41667 9.38333 9.4 9.32778 9.36667 9.28333C9.33333 9.22778 9.28889 9.18333 9.23333 9.15C8.74444 8.87222 8.22222 8.65556 7.66667 8.5C7.11111 8.34444 6.53333 8.26667 5.93333 8.26667C5.34444 8.26667 4.76111 8.34444 4.18333 8.5C3.61667 8.65556 3.09444 8.87222 2.61667 9.15C2.57222 9.18333 2.52778 9.22778 2.48333 9.28333C2.45 9.32778 2.43333 9.38333 2.43333 9.45V9.65ZM5.93333 4.2C6.23333 4.2 6.48889 4.09444 6.7 3.88333C6.91111 3.67222 7.01667 3.41667 7.01667 3.11667C7.01667 2.81667 6.90556 2.56667 6.68333 2.36667C6.47222 2.15555 6.22222 2.05 5.93333 2.05C5.63333 2.05 5.37222 2.15555 5.15 2.36667C4.93889 2.57778 4.83333 2.82778 4.83333 3.11667C4.83333 3.41667 4.93889 3.67222 5.15 3.88333C5.37222 4.09444 5.63333 4.2 5.93333 4.2Z" fill={fill}/>
        </svg>
    )
}
