import classNames from 'classnames';
import React, { useEffect, useState } from 'react'
import { Button, Dropdown } from 'react-bootstrap';
import './index.css';
import Loadertemplate from '../Loadertemplate';
import { IOptions } from '../../../Types/global';

interface IDropdownProps{
    placeholder?: string,
    dropdownMenu: IOptions[] | string[],
    defaultValue?: string,
    dropdownWrapClass?: string,
    disabled?: boolean,
    id?: string,
    onSelect?: {
        (value: string): void
    },
    showLoader?: boolean,
    name?: string,
    onDropdownChange?:(name:string,value:string)=>void
}
const Dropdowntemplate: React.FC <IDropdownProps> = ({placeholder, dropdownMenu, defaultValue, dropdownWrapClass, disabled, id, onSelect, showLoader, name, onDropdownChange}) => {
    const [selectedOption, setselectedOption] = useState<string | null>('');
    const onSelectDropdown = (eventKey:string|null, event: React.SyntheticEvent<unknown>) => {
        event.preventDefault();
        setselectedOption(eventKey);
        if(onSelect && eventKey != null)
            onSelect(eventKey);
        if(name && onDropdownChange && eventKey)
            onDropdownChange(name, eventKey)
    }
    useEffect(()=>{
        if(defaultValue && defaultValue !== ''){
            setselectedOption(defaultValue);
        }else if(placeholder){
            setselectedOption(placeholder);
        }
    }, [defaultValue])
    return (
        <Dropdown id={id} className={classNames('defaultWrapClass', dropdownWrapClass && dropdownWrapClass, {'dropdownDisabled': disabled})} onSelect={onSelectDropdown} >
            <Dropdown.Toggle disabled={disabled} className={classNames('defaultDropdownToggle')} variant='none'>{selectedOption}</Dropdown.Toggle>
            <Dropdown.Menu className={classNames('defaultDropdownMenu')}>
                {
                    showLoader ?
                        <Loadertemplate></Loadertemplate>
                    :
                        (dropdownMenu && dropdownMenu.length > 0) ?
                            dropdownMenu.map((item,index)=> (
                                    (typeof item === 'string')?
                                        <Dropdown.Item as='button' className={classNames('defaultDropdownItem')} active={selectedOption === item} eventKey={item} key={index}>{item}</Dropdown.Item>
                                    :
                                        <Dropdown.Item as='button' className={classNames('defaultDropdownItem')} active={selectedOption === item.value} eventKey={item.value} key={index}>{item.value}</Dropdown.Item>
                            ))
                        :
                            <div className='empty-dropdown-box'>
                                No Record Found
                            </div>
                }
            </Dropdown.Menu>
        </Dropdown>
    )
}

export default Dropdowntemplate;