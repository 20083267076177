import styles from "./styles.module.css";

type NoteMessageProsps = {
  message: string;
};

const NoteMessage: React.FC<NoteMessageProsps> = ({ message }) => {
  return (
    <div className={styles["container"]}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className={styles["icon"]}
        viewBox="0 0 25 25"
        fill="none"
      >
        <path
          d="M13.25 14.5H11.25V9.5H13.25M13.25 18.5H11.25V16.5H13.25M1.25 21.5H23.25L12.25 2.5L1.25 21.5Z"
          fill="#8E5E00"
        />
      </svg>
      <p className={styles["text"]}>{message}</p>
    </div>
  );
};

export default NoteMessage;
