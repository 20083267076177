import React from 'react'
import EmptyRecordSvg from '../../../icons/EmptyRecordSvg'

const i18nTranslated = {
    'emptyMsg': 'No Records found.' 
}
const EmptyTemp:React.FC = () => {
    const EmptyRecord: React.CSSProperties = {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
        height: '100%'
    }
    return (
        <div style={EmptyRecord}>
            <div style={{width: '200px'}}>
                <EmptyRecordSvg/>
                <p className='no-data-found'>{i18nTranslated.emptyMsg}</p>
            </div>
        </div>
    )
}

export default EmptyTemp