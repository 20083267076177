import React from 'react'
import { IProspectTemplateProp } from '../../../Types/global';
import classNames from 'classnames';
import './index.css';

const Prospecttemplate: React.FC<IProspectTemplateProp> = ({color_code, children}) => {
    return (
        <div className='prospect-wrap'>
            <span className={classNames(`edd-type ${color_code}`)}></span>
            {children}
        </div>
    )
}
export default Prospecttemplate;
