import toast from 'react-hot-toast';
import { useCallback } from 'react';

interface FailureToastProps {
  message: string;
  id: string;
}

const FailureToast: React.FC<FailureToastProps> = ({ message, id }) => {
  const dismissToast = useCallback(() => {
    toast.remove(id);
  }, [id]);

  return (
    <div className="toast failure">
      <div className="toast-icon-block">
        <div className="toast-icon"></div>
      </div>
      <p>{message}</p>
      <div className="toast-close-icon" onClick={dismissToast}>
        ×
      </div>
    </div>
  );
};

export default FailureToast;
