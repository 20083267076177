import React from 'react';
import { IIconProps } from '../Types/global';

const CallblackSvg: React.FC<IIconProps> = ({className}) => {
    return(
        <svg className={`${className}__callblack`} width='20px' viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M19.95 21C17.8667 21 15.8083 20.55 13.775 19.65C11.7417 18.7333 9.89167 17.4417 8.225 15.775C6.55833 14.1083 5.26667 12.2583 4.35 10.225C3.45 8.19167 3 6.13333 3 4.05C3 3.75 3.1 3.5 3.3 3.3C3.5 3.1 3.75 3 4.05 3H8.1C8.33333 3 8.54167 3.08333 8.725 3.25C8.90833 3.4 9.01667 3.58333 9.05 3.8L9.7 7.3C9.73333 7.56667 9.725 7.79167 9.675 7.975C9.625 8.15833 9.53333 8.31667 9.4 8.45L6.975 10.9C7.30833 11.5167 7.7 12.1167 8.15 12.7C8.61667 13.2667 9.125 13.8167 9.675 14.35C10.1917 14.8667 10.7333 15.35 11.3 15.8C11.8667 16.2333 12.4667 16.6333 13.1 17L15.45 14.65C15.6 14.5 15.7917 14.3917 16.025 14.325C16.275 14.2417 16.5167 14.2167 16.75 14.25L20.2 14.95C20.4333 15.0167 20.625 15.1417 20.775 15.325C20.925 15.4917 21 15.6833 21 15.9V19.95C21 20.25 20.9 20.5 20.7 20.7C20.5 20.9 20.25 21 19.95 21ZM6.025 9L7.675 7.35L7.25 5H5.025C5.10833 5.68333 5.225 6.35833 5.375 7.025C5.525 7.69167 5.74167 8.35 6.025 9ZM14.975 17.95C15.625 18.2333 16.2833 18.4583 16.95 18.625C17.6333 18.7917 18.3167 18.9 19 18.95V16.75L16.65 16.275L14.975 17.95Z" fill="white"/>
        </svg>
    )
}

export default CallblackSvg;
