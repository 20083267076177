import { useState } from "react";

import useEffectAfterMount from "./useEffectAfterMount";
import GeographyService from "../services/GeographyServices";

import { IVillage } from "../../Types/global";
import { UseAnchorVillagesHook } from "../../Types/hooks";

export default function useAnchorVillages({
  page,
  perPage,
  onFailure,
  additionalFields
}: UseAnchorVillagesHook.Parameters): UseAnchorVillagesHook.Result {
  const [data, setData] = useState<IVillage[]>([]);
  const [error, setError] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffectAfterMount(() => {
    setIsLoading(true);
    GeographyService.getPagedAnchorVillages({
      page,
      perPage,
      additionalFields
    })
      .then((result) => {
        setData(
          result.data.sort(
            (a, b) =>
              (b.stats?.total_jcp_visits_year_till_date || 0) -
              (a.stats?.total_jcp_visits_year_till_date || 0)
          )
        );
      })
      .catch((err) => {
        setError(err);
        if (onFailure) {
          onFailure.apply(null, [err]);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [page, perPage, onFailure, additionalFields]);

  return {
    data,
    error,
    isLoading
  };
}
