import styles from "./styles.module.css";

import classNames from "classnames";
import React, { useCallback } from "react";

import View from "../../enums/View";
import Button from "../../../shared/components/Button";
import ViewLayout from "../view-layout";
import AnchorVillage from "./components/anchor-village";
import EmptyRecords from "../../../shared/components/empty-records";
import Loadertemplate from "../../../Components/templates/Loadertemplate";
import useAnchorVillages from "../../../shared/hooks/useAnchorVillages";
import MostVisitedVillage from "./components/most-visited-village";
import useCurrentLocation from "../../../shared/hooks/useCurrentLocation";
import ServerConnectorUtil from "../../../shared/utils/ServerConnectorUtil";
import VillageAdditionalFields from "../../../shared/enums/VillageAdditionalFields";
import { useCreateJcPlanningContext } from "../../hooks/useCreateJcPlanningContext";

import { Model } from "../../../Types/global";
import { useFormContext } from "../../../shared/hooks/useFormContext";
import { CreateJcPlanningForm } from "../../types";

const additionalFields = [
  VillageAdditionalFields.STATS_TOTAL_JCP_VISITS_YEAR_TILL_DATE
];

const SelectAnchorVillageView: React.FC = () => {
  const { values } = useFormContext<CreateJcPlanningForm>();
  const { goToView, cancelOperation } = useCreateJcPlanningContext();

  const { data, isLoading } = useAnchorVillages({
    page: 1,
    perPage: 1000,
    additionalFields,
    onFailure: ServerConnectorUtil.handleServerError
  });

  const {
    latitude: currentLocationLatitude,
    longitude: currentLocationLongitude
  } = useCurrentLocation();

  const nextOperation = useCallback(() => {
    goToView(View.FORM_VIEW);
  }, [goToView]);

  return (
    <ViewLayout>
      <ViewLayout.Body>
        {isLoading ? (
          <div className="loader-wrapper">
            <Loadertemplate />
          </div>
        ) : data.length === 0 ? (
          <EmptyRecords message="No results found for anchor villages" />
        ) : (
          <>
            <div
              className={classNames(
                styles["list"],
                styles["anchor-village-list"]
              )}
            >
              {data.map((item) => (
                <AnchorVillage
                  key={item.id}
                  village={item as Model.Village}
                  currentLocationLatitude={currentLocationLatitude}
                  currentLocationLongitude={currentLocationLongitude}
                />
              ))}
            </div>
            <div
              className={classNames(
                styles["list"],
                styles["most-visited-village-list"]
              )}
            >
              <h6 className={styles["title"]}>Most Visited Village</h6>
              {data
                .map((item) => (
                  <MostVisitedVillage
                    key={item.id}
                    village={item as Model.Village}
                  />
                ))}
            </div>
          </>
        )}
      </ViewLayout.Body>
      <ViewLayout.Footer>
        <Button label="Cancel" variant="secondary" onClick={cancelOperation} />
        <Button
          label="Next"
          variant="primary"
          onClick={nextOperation}
          disabled={isLoading || values.anchorVillage === null}
        />
      </ViewLayout.Footer>
    </ViewLayout>
  );
};

export default SelectAnchorVillageView;
