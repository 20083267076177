import React from 'react'
import { IGridTemplateProp } from '../../../Types/global';
import './index.css';
import classNames from 'classnames';

const Gridtemplate : React.FC<IGridTemplateProp> = ({ gridHead, gridContent, gridHeaderClassName, gridContentClassName, gridWrapperClassName}) => {
    return(
        <div className={classNames('grid', gridWrapperClassName && gridWrapperClassName)}>
            <div className={classNames('grid-title', gridHeaderClassName && gridHeaderClassName)}>
                {gridHead}
            </div>
            <div className={classNames('grid-content', gridContentClassName && gridContentClassName)}>
                {gridContent}
            </div>
        </div>
    )
}
export default Gridtemplate;
