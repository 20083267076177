import React, { useCallback, useEffect, useState } from 'react'
import { Tab, Tabs } from 'react-bootstrap'
import { ITabsData } from '../../../Types/global';
import './index.css';
import { useLocation } from 'react-router-dom';
import classNames from 'classnames';

interface ITabsProps {
  tabs: ITabsData[],
  onSelect: {(k: string | null) :  void},
  children: React.ReactNode
  className?:string
}

const Tabtemplate: React.FC<ITabsProps> = ({children ,tabs, onSelect,className}) => {
  const location = useLocation();
  let { pathname } = location;
  let currentUrl = pathname.split('/');
  let currentTab = currentUrl[currentUrl.length-1];
  const [key, setKey] = useState<string>(currentTab);
  const onTabSelect = useCallback((k: string | null) => {
    k && setKey(k);
    onSelect(k);
  }, [])
  return (
    <div className='tabs-wrap'>
      <Tabs
        id="controlled-tab-example"
        activeKey={key}
        onSelect={onTabSelect}
        className={classNames("mb-3",className)}
      >
        {
          tabs.map((item,index)=> (
            <Tab eventKey={item.key} title={item.title} key={index}>
              { 
                  (item.key === key) &&
                  children
              }
            </Tab>
          ))
        }
      </Tabs>
    </div>
  )
}
export default Tabtemplate;
