import React from 'react'
import style from './index.module.css';
import Buttontemplate from '../Buttontemplate';
const Signout: React.FC = () => {
    const onSignout = () => {
        let {origin} = window.location;
        let catalystAuth = (window as any).catalyst.auth;
        catalystAuth.signOut(`${origin}/#/login`);
    }
    return (
        <div className={style['wrapper']}>
            <Buttontemplate className={style['signout_btn']} type='primary' buttonWidth='auto' callback={onSignout}>
                Signout
            </Buttontemplate>
        </div>
    )
}

export default Signout