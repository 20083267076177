import { useCallback, useState } from 'react';
import { UseModalParameters } from '../../Types/hooks';


export default function useModal({
  onModalOpen,
  onModalClose
}: UseModalParameters.Parameters): UseModalParameters.ReturnType {
  const [open, setOpen] = useState<boolean>(false);

  const openModal = useCallback(() => {
    setOpen(true);
    if (onModalOpen) {
      onModalOpen();
    }
  }, [onModalOpen]);

  const closeModal = useCallback(() => {
    setOpen(false);

    if (onModalClose) {
      onModalClose();
    }
  }, [onModalClose]);

  return {
    open,
    openModal,
    closeModal
  };
}
