export default class api_request{
    public static readonly VILLAGES = "/api/v1/geography/villages";
    public static readonly TUDB_DATA = "/api/v1/tractor-user-databases";
    public static readonly PROSPECT_DATA = "/api/v1/prospects";
    public static readonly TASK_COUNT = "/api/v1/tasks/count";
    public static readonly PROSPECT_COUNT = "/api/v1/prospects/count";
    public static readonly TASK_DATA = "/api/v1/tasks";
    public static readonly PLANNED_VISIT = "/api/v1/jc-plannings";
    public static readonly USER_MYSTATS = "/api/v1/user/my-stats";
    public static readonly PROSPECT_STAT = "/api/v1/prospects/stats";
    public static readonly TUDB_STAT = "/api/v1/tractor-user-databases/stats";
    public static readonly PROSPECT_DETAILS = "/api/v1/prospect/";
    public static readonly TUDB_DETAILS = "/api/v1/tractor-user-database/";
    public static readonly TASK_DETAILS = "/api/v1/follow-up-task/";
    public static readonly PROSPECT_META = "/api/v1/prospect/meta";
    public static readonly TUDB_META = "/api/v1/tractor-user-database/meta";
}