import React, { useCallback, useState } from 'react';
import { IOptions } from '../../../Types/global';
import RecordCollection from '../../../shared/components/RecordCollection';
import { UsePagedVillagesHook } from '../../../Types/hooks';
import usePagedVillages from '../../../shared/hooks/usePagedVillages';
import useScroll from '../../../shared/hooks/useScroll';

const i18nTranslated = {
  typeLabel: 'Type',
  talukaLabel: 'Taluka',
  districtLabel: 'District',
  stateLabel: 'State',
  tivLabel: 'TIV',
  brygLabel: 'BRYG',
  latitudeLabel: 'Latitude',
  longitudeLabel: 'Longitude'
};

const allDropDownOptions = [
  { value: 'name', label: 'Name' },
  { value: 'state', label: 'State' },
  { value: 'district', label: 'District' },
  { value: 'taluk', label: 'Taluk' }
];

const VillageMaster: React.FC = () => {
  const [value, setValue] = useState('');
  const [searchKey, setSearchKey] = useState<string>('name');
  const [searchLabel, setSearchLabel] = useState<string>(
    allDropDownOptions[0].label.toLowerCase()
  );

  const {
    data,
    isLoading,
    setFilters,
    updateFilterKey,
    isLoadingMore,
    loadMoreRecords
  } = usePagedVillages({
    page: 1,
    perPage: 200,
    filters: {}
  });

  const onScrollEventListener = useScroll(loadMoreRecords);

  const onNameChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      setValue(value);
      updateFilterKey(searchKey as keyof UsePagedVillagesHook.Filters, value);
    },
    [searchKey, updateFilterKey]
  );

  const onDropDownChange = useCallback((value: IOptions) => {
    setSearchKey(value.value);
    setValue('');
    setSearchLabel(value.label.toLowerCase());
    setFilters({});
  }, [setFilters]);
  return (
    <RecordCollection>
      <RecordCollection.SearchBox
        value={value}
        onChange={onNameChange}
        placeholder={'Search by ' + searchLabel}
        initialDropDownValue={allDropDownOptions[0]}
        dropDownOptions={allDropDownOptions}
        onDropDownChange={onDropDownChange}
      />
      <RecordCollection.List onScroll={onScrollEventListener}>
        {isLoading ? (
          <RecordCollection.ListLoader />
        ) : data.length === 0 ? (
          <RecordCollection.ListEmpty />
        ) : (
          data.map((item) => (
            <RecordCollection.ListItemWrapper key={item.id}>
              <RecordCollection.ListItem>
                <div className='dF'>
                  <RecordCollection.ListItemColorCode color_code={item.color_code ? item.color_code : undefined}/>
                  <RecordCollection.ListItemTitle title={item.name}/>
                </div>
                <RecordCollection.ListItemKeyValue
                  label={i18nTranslated.typeLabel}
                  value={item.type || '--'}
                />
                <RecordCollection.ListItemKeyValue
                  label={i18nTranslated.talukaLabel}
                  value={item.taluka || '--'}
                />
                <RecordCollection.ListItemKeyValue
                  label={i18nTranslated.districtLabel}
                  value={item.district || '--'}
                />
                <RecordCollection.ListItemKeyValue
                  label={i18nTranslated.stateLabel}
                  value={item.state}
                />
                <RecordCollection.ListItemKeyValue
                  label={i18nTranslated.tivLabel}
                  value={item.tiv || '--'}
                />
                <RecordCollection.ListItemKeyValue
                  label={i18nTranslated.brygLabel}
                  value={item.color_code || '--'}
                />
                <RecordCollection.ListItemKeyValue
                  label={i18nTranslated.latitudeLabel}
                  value={item.latitude}
                />
                <RecordCollection.ListItemKeyValue
                  label={i18nTranslated.longitudeLabel}
                  value={item.longitude}
                />
              </RecordCollection.ListItem>
            </RecordCollection.ListItemWrapper>
          ))
        )}
        {(!isLoading && isLoadingMore) && <RecordCollection.LoadMoreLoader />}
      </RecordCollection.List>
    </RecordCollection>
  );
};

export default VillageMaster;
