import React, { useEffect } from 'react';
import './index.css';
import { TafeLogoSvg } from '../../icons/TafeLogoSvg';
const Login: React.FC = () => {
  useEffect(() => {
    const config = {
      always_render_login:true,
      signin_providers_only: true,
      css_url: '/app/css/embedded_signin_providers_only.css'
    };
    const catalyst = (window as any).catalyst;
    catalyst.auth.signIn('tafe-login', config);
  }, []);
  return (
    <div className="login-container">
      <div className="layout-container">
        <div style={{marginBottom: '10px'}}>
            <TafeLogoSvg/>
        </div>
        <div className="iframe-block visible">
          <h3 style={{ fontSize: '16px', margin: 0 }}>
            Log in to your TAFE account
          </h3>
          <div id='tafe-login'></div>
        </div>
      </div>
    </div>
  );
};
export default Login;
