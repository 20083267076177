import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from 'react';
import Form from 'react-bootstrap/Form';
import SearchDropdowntemplate from '../SearchDropdowntemplate';
import { ILocation, IOptions, IVillage, IVillageOption } from '../../../Types/global';
import getGeography from '../../Hooks/getGeography';
import classNames from 'classnames';
import ServerConnectorUtil from '../../../shared/utils/ServerConnectorUtil';
import GeographyService from '../../../shared/services/GeographyServices';

const GeographyTemp = forwardRef((_, ref) => {
    const [selectedTaluka, setselectedTaluka] = useState<IOptions | null>(null);
    const [selectedVillage, setSelectedVillage] = useState<IOptions | null>(null);
    const [selectedVillageData, setSelectedVillageData] = useState<IVillage|null>(null);

    const [talukasArray, settalukasArray] = useState<IOptions[]>([]);
    const [villagesArray, setvillagesArray] = useState<IOptions[]>([]);
    const [villageIdArray, setvillageIdArray] = useState<IVillageOption[]>([]);
    const [villagesOriginalArray, setVillagesOriginalArray] = useState<IVillage[]>([]);

    const [villageDropDownOptions,setVillageDropDownOptions] = useState<IOptions[]>([]); 

    const [villageDisabled, setvillageDisabled] = useState<boolean>(true);

    const [errors, seterrors] = useState({
        state: '',
        district: '',
        taluka: '',
        village: ''
    })
    useEffect(() => {
        getTalukaData();
    }, [])
    const getTalukaData = useCallback(async() => {
        try{
            let talukas = await getGeography.getTaluka();
            settalukasArray(talukas);
        }catch(error){
            if (error instanceof Error) {
                ServerConnectorUtil.handleServerError(error);
            }
        }
    }, [])
    const onTalukaSelect = useCallback(async(option: IOptions) => {
        // try{
        //     let villagesObjArr = getGeography.getVillage(option.value);
        //     debugger
        //     let villageOptions = villagesObjArr.map((item:IVillageOption)=> {
        //         let village = {
        //             label: item.name,
        //             value: item.name
        //         }
        //         return village;
        //     })
        //     setselectedTaluka(option)
        //     setvillageIdArray(villagesObjArr);
        //     setvillagesArray(villageOptions);
        //     setVillagesOriginalArray(villagesObjArr);
        //     setvillageDisabled(false);
        // }catch(error){
        //     if (error instanceof Error) {
        //         ServerConnectorUtil.handleServerError(error);
        //     }
        // }

        setselectedTaluka(option)
        setSelectedVillage(null);
        setSelectedVillageData(null);
        setvillageDisabled(true);
        GeographyService.getPagedVillages({
            page:1,
            perPage:1000,
            filters:{
                taluk:option.value
            }
        }).then(pagedResut=>{
            setVillageDropDownOptions(pagedResut.data.map((item)=>({
                label:item.name,
                value:item.id
            })))
            setVillagesOriginalArray(pagedResut.data)
            setvillageDisabled(false);
        })
    }, []);
    const onVillageSelect = useCallback((option: IOptions) => {
        const villageData = villagesOriginalArray.find((item) => item.id === option.value);
        if (villageData) {
            setSelectedVillage(option);
            setSelectedVillageData(villageData);
        }
    }, [villagesOriginalArray]);
    const getSelectedLocation = useCallback((): ILocation => {
        let taluka = selectedTaluka && selectedTaluka.value;
        let village = selectedVillage && selectedVillage.label;
        return{
            taluka: taluka,
            village: village
        }
    }, [selectedTaluka, selectedVillage, villageIdArray, villagesArray])
    const setErrorMsg = useCallback((key: string, error:string) => {
        seterrors((prev)=> ({...prev, [key]: error}))
    },[])
    const removeError = useCallback((key: string)=> {
        seterrors((prev)=> ({...prev, [key]: ''}));
    }, []);
    useImperativeHandle(ref, () => ({
        getSelectedLocation,
        setErrorMsg
    }))
    return (
        <>
            <Form.Group className='custom-form-group' >
                <Form.Label className='custom-form-label'>Taluka<span className='required-star'>*</span></Form.Label>
                <Form.Control required as='div' size='lg' className='custom-form-div' onClick={()=> removeError('taluka')}>
                    <SearchDropdowntemplate
                        options={talukasArray}
                        isSearchable={true}
                        placeholder='Add Taluka'
                        onSelect={onTalukaSelect}
                        defaultValue={selectedTaluka}
                    ></SearchDropdowntemplate>
                    <div className={classNames('empty-msg', {'visible': (errors.taluka !== '')})}>{errors.taluka}</div>
                </Form.Control>
            </Form.Group>
            <Form.Group className='custom-form-group'>
                <Form.Label className='custom-form-label'>Village<span className='required-star'>*</span></Form.Label>
                <Form.Control required as='div' size='lg' className='custom-form-div' onClick={()=> removeError('village')}>
                    <SearchDropdowntemplate
                        options={villageDropDownOptions}
                        isSearchable={true}
                        placeholder='Add Village'
                        isDisabled={villageDisabled}
                        defaultValue={selectedVillage}
                        onSelect={onVillageSelect}
                    ></SearchDropdowntemplate>
                    <div className={classNames('empty-msg', {'visible': (errors.village !== '')})}>{errors.village}</div>
                </Form.Control>
            </Form.Group>
            <Form.Group className='custom-form-group'>
                <Form.Label className='custom-form-label'>TAFE BRYG</Form.Label>
                <Form.Control disabled={true} size='lg' className='custom-form-input' name='name' value={selectedVillageData?.color_code || ''}></Form.Control>
            </Form.Group>
            <Form.Group className='custom-form-group'>
                <Form.Label className='custom-form-label'>TAFE TIV</Form.Label>
                <Form.Control disabled={true} size='lg' className='custom-form-input' name='name' value={selectedVillageData?.tiv || ''}></Form.Control>
            </Form.Group>
        </>
    )
})

export default GeographyTemp;