import styles from "./styles.module.css";

import classNames from "classnames";
import { Range, getTrackBackground } from "react-range";

type RangeSliderProps = {
  min: number;
  max: number;
  step: number;
  values: Array<number>;
  className?: string;
  onChange: (values: Array<number>) => void;
};
const RangeSlider: React.FC<RangeSliderProps> = ({
  min,
  max,
  step,
  values,
  onChange,
  className
}) => {
  return (
    <Range
      step={step}
      min={min}
      max={max}
      values={values}
      onChange={onChange}
      renderTrack={({ props, children }) => (
        <div
          {...props}
          className={classNames(styles["track"], className)}
          style={{
            background: getTrackBackground({
              values,
              min,
              max,
              colors: ["#b51213", "rgba(95, 96, 98, 0.2)"]
            })
          }}
        >
          {children}
        </div>
      )}
      //   renderMark={({ props }) => (
      //     <div {...props} key={props.key} style={{
      //         height:"10px",
      //         backgroundColor:"red",
      //     }} />
      //   )}
      renderThumb={({ props }) => (
        <div {...props} key={props.key} className={styles["thumb"]} />
      )}
    />
  );
};

export default RangeSlider;
