export type ListItemKeyValuePair = {
  label: string;
  value: string | number;
};
export type ListItemKeyValuePairsProps = {
  items: ListItemKeyValuePair[];
};

const ListItemKeyValuePairs: React.FC<ListItemKeyValuePairsProps> = ({
  items
}) => {
  return (
    <div className="record-key-value-container">
      {items.map((item, index) => (
        <div className="prospects-stats" key={index}>
          <div className="hps-stats">
            <span>
              <span className="key"></span>{item.label}:<b className="value">{item.value?.toString() || '--'}</b>
              {index !== items.length - 1 && ' | '}
            </span>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ListItemKeyValuePairs;
