import React from 'react'
import { DoubleTickSvg } from '../../../../icons/DoubleTickSvg';
import Buttontemplate from '../../Buttontemplate';
import { JCPSvg } from '../../../../icons/JCPSvg';
import { EnquiriesSvg } from '../../../../icons/EnquiriesSvg';
import { useNavigate } from 'react-router-dom';

const i18nTranslated = {
    tudbCreated: "TUDB created",
    tudbSuccessDesc: "You have successfully created a TUDB",
    backToTUDB: "Back to TUDB",
}
const TudbSuccess: React.FC = () => {
    const navigate = useNavigate();
    return (
        <>
            <DoubleTickSvg/>
            <h1 style={{marginTop: '30px'}}>{i18nTranslated.tudbCreated}</h1>
            <p>{i18nTranslated.tudbSuccessDesc}</p>
            <Buttontemplate type='success' buttonWidth='100%' callback={()=> navigate('/base/enquiries/tractoruser')}>
                <EnquiriesSvg fill='#257851'/>
                {i18nTranslated.backToTUDB}
            </Buttontemplate>
        </>
    )
}

export default TudbSuccess;