import classNames from "classnames";
import styles from "./styles.module.css";

type ButtonProps = {
  label: string;
  size?: "small";
  className?: string;
  disabled?: boolean;
  variant: "primary" | "secondary";
  isLoading?: boolean;
  fullWidth?: boolean;
  onClick?: () => void;
};

const Button: React.FC<ButtonProps> = ({
  label,
  size,
  variant,
  onClick,
  disabled,
  isLoading,
  fullWidth,
  className
}) => {
  return (
    <button
      className={classNames(
        className,
        styles["button"],
        styles[variant],
        size && styles[size],
        isLoading && styles["loading"],
        fullWidth && styles["full-width"]
      )}
      onClick={onClick}
      disabled={disabled || isLoading}
    >
      <div className={classNames(styles['label'],isLoading && "vH")}>{label}</div>
      <div className={classNames("pA", !isLoading && "vH")}>
        <div className={styles["dot-flashing"]}></div>
      </div>
    </button>
  );
};

export default Button;
