enum ProspectStage {
    DELIVERY = 'Delivery',
    SALES_LOST = 'Sales Lost',
    SALES_DROPPED = 'Sales Dropped',
    F1 = 'F1 - Customer interested to buy tractor',
    F2 = 'F2 - Customer considering our tractor',
    F3 = 'F3 - Deal Closed',
    F4 = 'F4 - Booking amount received',
    F5 = 'F5 - Margin Money received / Old Tractor',
    R6 = 'R6 - Case referred to Financier',
    R7 = 'R7 - FI Done',
    R8 = 'R8 - Post FI Documents submitted',
    R9 = 'R9 - Final Documents submitted',
    R10 = 'R10 - Retail Complete',
}

export default ProspectStage;
