import { useRef, useState } from "react";
import ZohoMapService from "../../services/ZohoMapService";
import useEffectAfterMount from "../../hooks/useEffectAfterMount";

type Marker = {
  id: string;
  isActive: boolean;
  isRemoved: boolean;
  latitude: string | number;
  longitude: string | number;
};

type MapsProps = {
  className?: string;
  markers: Array<Marker>;
  shapeOptions?: {
    radius: number;
    latitude: string | number;
    longitude: string | number;
  };
};

const MarkerIcon = `<svg
  xmlns="http://www.w3.org/2000/svg"
  fill="none"
  viewBox="0 0 24 24"
  stroke-width="1.5"
  stroke="#808080"
>
  <path
    stroke-linecap="round"
    stroke-linejoin="round"
    d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
  />
  <path
    stroke-linecap="round"
    stroke-linejoin="round"
    d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z"
  />
</svg>`;

const ActiveMarkerIcon = `<svg
  xmlns="http://www.w3.org/2000/svg"
  fill="none"
  viewBox="0 0 24 24"
  stroke-width="1.5"
  stroke="#ad000e"
>
  <path
    stroke-linecap="round"
    stroke-linejoin="round"
    d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
  />
  <path
    stroke-linecap="round"
    stroke-linejoin="round"
    d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z"
  />
</svg>`;

const markerIconUrl = URL.createObjectURL(
  new Blob([MarkerIcon], { type: "image/svg+xml" })
);

const activeMarkerIconUrl = URL.createObjectURL(
  new Blob([ActiveMarkerIcon], { type: "image/svg+xml" })
);
const ZohoMaps: React.FC<MapsProps> = ({
  markers,
  className,
  shapeOptions
}) => {
  const zohoMap = useRef<any>();
  const mapLoadedIntervalId = useRef<number | undefined>(undefined);
  const domLoadedIntervalId = useRef<number | undefined>(undefined);
  const placedMarkers = useRef<Array<string>>([]);

  const [isMapLoaded, setIsMapLoaded] = useState<boolean>(false);

  useEffectAfterMount(() => {
    const zMapsSdk = ZohoMapService.getZohoMapSdk();

    function onMapInit() {
      const { ZMap } = window as any;
      const map = new ZMap("zmaps-container", zMapsSdk);
      map.showZoomController(false);
      map.allowZoom(false);
      map.showCurrentLocationIcon(false);
      map.loadMap();
      zohoMap.current = map;
    }

    domLoadedIntervalId.current = window.setInterval(() => {
      if (document.getElementById("zmaps-container")) {
        zMapsSdk.init(onMapInit);
        if (domLoadedIntervalId.current) {
          window.clearInterval(domLoadedIntervalId.current);
        }
      }
    }, 200);

    return () => {
      if (domLoadedIntervalId.current) {
        window.clearInterval(domLoadedIntervalId.current);
      }
    };
  }, []);

  useEffectAfterMount(() => {
    mapLoadedIntervalId.current = window.setInterval(() => {
      if (zohoMap.current && zohoMap.current.isLoaded()) {
        setIsMapLoaded(true);

        if (mapLoadedIntervalId.current) {
          window.clearInterval(mapLoadedIntervalId.current);
        }
      }
    }, 500);

    return () => {
      if (mapLoadedIntervalId.current) {
        window.clearInterval(mapLoadedIntervalId.current);
      }
    };
  }, []);

  useEffectAfterMount(() => {
    if (isMapLoaded) {
      const { ZMapsShape, ZMapsShapeType } = window as any;
      const map = zohoMap.current;
      if (map) {
        if (shapeOptions) {
          const zMapsShape = new ZMapsShape("shapeId", ZMapsShapeType.Circle);
          zMapsShape.setCenter(
            parseFloat(shapeOptions.latitude.toString()),
            parseFloat(shapeOptions.longitude.toString())
          );
          zMapsShape.setRadius(shapeOptions.radius);
          zMapsShape.setFill(true);
          zMapsShape.setStrokeColor("black");
          zMapsShape.setFillColor("rgba(66, 153, 245,0.3)");
          zMapsShape.setStrokeWidth(2);
          zMapsShape.setShapeOpacity(1);
          map.addShape(zMapsShape);
          map.fitToShapes(zMapsShape, 1);
        }
      }
    }
  }, [isMapLoaded, shapeOptions]);

  useEffectAfterMount(() => {
    if (isMapLoaded) {
      const { ZMarker } = window as any;
      const map = zohoMap.current;

      if (map) {
        for (const marker of markers) {
          const zMarker = new ZMarker(
            marker.id,
            parseFloat(marker.latitude.toString()),
            parseFloat(marker.longitude.toString())
          );

          zMarker.width = 40;
          zMarker.height = 40;

          if (marker.isActive) {
            zMarker.setIcon(activeMarkerIconUrl);
          } else {
            zMarker.setIcon(markerIconUrl);
          }
          if (marker.isRemoved) {
            if (placedMarkers.current.includes(marker.id)) {
              map.removeMarker(marker.id);
              placedMarkers.current = placedMarkers.current.filter(
                (obj) => obj !== marker.id
              );
            }
          } else if (placedMarkers.current.includes(marker.id)) {
            map.updateMarker(zMarker);
          } else {
            map.addMarker(zMarker);
            placedMarkers.current.push(marker.id);
          }
        }
      }
    }
  }, [isMapLoaded, markers]);

  return <div className={className} id="zmaps-container" />;
};

export default ZohoMaps;
