import React from 'react'

const BlackCrossSvg:React.FC = () => {
  return (
    <svg width="11" height="12" viewBox="0 0 11 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10.75 1.8075L9.6925 0.75L5.5 4.9425L1.3075 0.75L0.25 1.8075L4.4425 6L0.25 10.1925L1.3075 11.25L5.5 7.0575L9.6925 11.25L10.75 10.1925L6.5575 6L10.75 1.8075Z" fill="black"/>
    </svg>
  )
}

export default BlackCrossSvg