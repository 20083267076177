import React, { useCallback, useState } from 'react';
import CallContactTemp from '../Components/templates/CallContactTemp';
import WhatsappContactTemp from '../Components/templates/WhatsappContactTemp';
import RecordCollection from '../shared/components/RecordCollection';
import usePagedContacts from '../shared/hooks/usePagedContacts';
import ServerConnectorUtil from '../shared/utils/ServerConnectorUtil';
import { IOptions } from '../Types/global';
import { UsePagedContactsHook } from '../Types/hooks';
import ContactAdditionalFields from '../shared/enums/ContactAdditionalFields';
import useScroll from '../shared/hooks/useScroll';
import './index.css';
const i18nTranslated = {
  hotProspect: 'HPs',
  prospects: 'Prospects',
  deliveries: 'Deliveries'
};

const allDropDownOptions = [
  { value: 'name', label: 'Name' },
  { value: 'mobileNumber', label: 'Mobile Number' }
];

const MPCTab: React.FC = () => {
  const [value, setValue] = useState('');
  const [searchKey, setSearchKey] = useState<string>('name');
  const [searchLabel, setSearchLabel] = useState<string>(
    allDropDownOptions[0].label.toLowerCase()
  );
  const [initialFilters] = useState({
    type: 'MPC'
  });

  const [additionalFields] = useState<Array<string>>(
    Object.values(ContactAdditionalFields)
  );

  const {
    data,
    isLoading,
    setFilters,
    updateFilterKey,
    isLoadingMore,
    loadMoreRecords
  } = usePagedContacts({
    page: 1,
    perPage: 200,
    filters: initialFilters,
    additionalFields,
    onFailure: ServerConnectorUtil.handleServerError
  });

  const onScrollEventListener = useScroll(loadMoreRecords);

  const onNameChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      setValue(value);
      updateFilterKey(searchKey as keyof UsePagedContactsHook.Filters, value);
    },
    [searchKey, updateFilterKey]
  );

  const onDropDownChange = useCallback((value: IOptions) => {
    setSearchKey(value.value);
    setValue('');
    setSearchLabel(value.label.toLowerCase());
    setFilters(initialFilters);
  }, [initialFilters, setFilters]);
  return (
    <>
      <RecordCollection>
        <RecordCollection.SearchBox
          value={value}
          onChange={onNameChange}
          placeholder={'Search by ' + searchLabel}
          initialDropDownValue={allDropDownOptions[0]}
          dropDownOptions={allDropDownOptions}
          onDropDownChange={onDropDownChange}
        />
        <RecordCollection.List onScroll={onScrollEventListener}>
          {isLoading ? (
            <RecordCollection.ListLoader />
          ) : data.length === 0 ? (
            <RecordCollection.ListEmpty />
          ) : (
            data.map((item) => (
              <RecordCollection.ListItemWrapper key={item.id}>
                <RecordCollection.ListItem>
                  <div className='fCenter'>
                    <RecordCollection.ListItemTitle title={item.name} />
                    <RecordCollection.ListItem>
                      <div className='fCenter title_position'>
                        <div className='grey-dot'></div>
                        <div>{item.influencer_type ? item.influencer_type : '--'}</div>
                      </div>
                    </RecordCollection.ListItem>
                  </div>
                  
                  <RecordCollection.ListItemKeyValue
                    label="Village"
                    value={item.village || '--'}
                  />

                  <RecordCollection.ListItemKeyValuePairs
                    items={[
                      {
                        label: i18nTranslated.hotProspect,
                        value: item.stats?.total_hot_prospects || 0
                      },
                      {
                        label: i18nTranslated.prospects,
                        value: item.stats?.total_prospects || 0
                      },
                      {
                        label: i18nTranslated.deliveries,
                        value: item.stats?.total_deliveries || 0
                      }
                    ]}
                  />
                  <RecordCollection.ListItemKeyValue
                    label="Mobile Number"
                    value={`+91 ${item.mobile_number}`}
                  />
                  <RecordCollection.ListAction>
                    <CallContactTemp
                      className="prospect-contact"
                      phoneNumber={item.mobile_number}
                    />
                    <WhatsappContactTemp phoneNumber={item.mobile_number} />
                  </RecordCollection.ListAction>
                </RecordCollection.ListItem>
              </RecordCollection.ListItemWrapper>
            ))
          )}
         {(!isLoading && isLoadingMore) && <RecordCollection.LoadMoreLoader />}
        </RecordCollection.List>
      </RecordCollection>
    </>
  );
};

export default MPCTab;
