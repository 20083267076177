import React, { useEffect, useState } from 'react'
import { ITudbFormData, ITudbUserData, Model } from '../../Types/global';
import RequestHandler from '../handlers/RequestHandler';
import TractorUserDatabaseService from '../services/TractorUserDatabaseService';
import api_request from '../constants/api_request';
import RequestMethod from '../enums/RequestMethod';
import { useLocation, useParams } from 'react-router-dom';

function useTUDBId() {
    const {id} = useParams();
    const [isLoading, setisLoading] = useState(true);
    const [tudb, settudb] = useState<ITudbFormData>({
        name: null,
        mobile_number: null,
        type: null,
        source: null,
        village: null,
        taluka: null,
        tractor: {
            brand: null,
            model: null,
            hp_segment: null,
            purchased_year: null
        },
        no_of_tractors_owned: null,
        is_satisfied_with_his_tractor: 'No',
        reason_of_dissatisfaction: null,
        total_no_of_tractors_in_the_village: null
    });
    let {pathname} = useLocation();
    const editPage = pathname.includes('edit');
    useEffect(()=> {
        let abortController = new AbortController();
        TractorUserDatabaseService.getTudbDetails({
            url: `${api_request.TUDB_DETAILS}${id}`,
            method: RequestMethod.GET,
            signal: abortController.signal
        }).then(response => {
            settudb(response.data);
            setisLoading(false);
        })
    }, [])
    return {
        isLoading,
        tudb,
        id,
        editPage,
        settudb
    };
}

export default useTUDBId