import styles from "./styles.module.css";

import classNames from "classnames";
import { useCallback, useState } from "react";

import View from "../../enums/View";
import Button from "../../../shared/components/Button";
import ZohoMaps from "../../../shared/components/ZohoMaps";
import ViewLayout from "../view-layout";
import EmptyRecords from "../../../shared/components/empty-records";
import DistanceFilter from "./components/distance-filter";
import Loadertemplate from "../../../Components/templates/Loadertemplate";
import NonAnchorVillage from "./components/non-anchor-village";
import { useFormContext } from "../../../shared/hooks/useFormContext";
import ServerConnectorUtil from "../../../shared/utils/ServerConnectorUtil";
import useNonAnchorVillages from "../../../shared/hooks/useNonAnchorVillages";
import { useCreateJcPlanningContext } from "../../hooks/useCreateJcPlanningContext";
import VillageAdditionalFields from "../../../shared/enums/VillageAdditionalFields";

import { Model } from "../../../Types/global";
import { CreateJcPlanningForm } from "../../types";

const additionalFields = [
  VillageAdditionalFields.STATS_TOTAL_JCP_VISITS_YEAR_TILL_DATE
];

const SelectNonAnchorVillagesView: React.FC = () => {
  const { values, setValue } = useFormContext<CreateJcPlanningForm>();
  const { goToView, cancelOperation } = useCreateJcPlanningContext();

  const [filteredVillages, setFilteredVillages] = useState<
    Array<Model.Village>
  >([]);

  const [selectedVillages, setSelectedVillages] = useState<
    Array<Model.Village>
  >(() => values.nonAnchorVillages || []);

  const [anchorVillage] = useState(
    () => values.anchorVillage as unknown as Model.Village
  );

  const [shapeOptions] = useState(() => {
    return {
      radius:20000,
      latitude: anchorVillage.latitude,
      longitude: anchorVillage.longitude
    };
  });

  const { data: originalVillages, isLoading } = useNonAnchorVillages({
    latitude: anchorVillage.latitude,
    longitude: anchorVillage.longitude,
    additionalFields,
    onSuccess: setFilteredVillages,
    onFailure: ServerConnectorUtil.handleServerError
  });

  const addVillage = useCallback((village: Model.Village) => {
    setSelectedVillages((prev) => prev.concat(village));
  }, []);

  const removeVillage = useCallback((village: Model.Village) => {
    setSelectedVillages((prev) => prev.filter((obj) => obj.id !== village.id));
  }, []);

  const nextOperation = useCallback(() => {
    setValue("nonAnchorVillages", selectedVillages);
    goToView(View.FORM_VIEW);
  }, [goToView, selectedVillages, setValue]);

  const filterVillages = useCallback(
    (distance: number, skipSelectedVillagesReset: boolean) => {
      if (!skipSelectedVillagesReset) {
        setSelectedVillages([]);
      }

      setFilteredVillages(
        (originalVillages as Array<Model.Village>).filter(
          (obj) => obj.distance && obj.distance <= distance
        )
      );
    },
    [originalVillages]
  );

  return (
    <ViewLayout>
      <ViewLayout.Body>
        {isLoading ? (
          <div className="loader-wrapper">
            <Loadertemplate />
          </div>
        ) : originalVillages.length === 0 ? (
          <EmptyRecords message="No results found for non anchor villages" />
        ) : (
          <>
            <div className={styles["slider-container"]}>
              <div className={styles["form-info-container"]}>
                <p className={styles["text"]}>Select upto 3 options</p>
                <p
                  className={classNames(
                    styles["text"],
                    styles["selected-text"]
                  )}
                >
                  {selectedVillages.length} Selected
                </p>
              </div>
              <DistanceFilter filterVillages={filterVillages} />
            </div>
            <div className={styles["list-container"]}>
              {filteredVillages.length ? (
                filteredVillages
                  .map((item) => (
                    <NonAnchorVillage
                      key={item.id}
                      village={item as Model.Village}
                      disabled={selectedVillages.length === 3}
                      checked={selectedVillages.some(
                        (obj) => obj.id === item.id
                      )}
                      addVillage={addVillage}
                      removeVillage={removeVillage}
                    />
                  ))
              ) : (
                <EmptyRecords
                  size="sm"
                  message="No results found for non anchor villages"
                />
              )}
            </div>
            <div className={styles["maps-container"]}>
              <ZohoMaps
                className={styles["maps"]}
                shapeOptions={shapeOptions}
                markers={originalVillages.map((item) => ({
                  id: item.id,
                  isRemoved: !filteredVillages.some(
                    (obj) => obj.id === item.id
                  ),
                  isActive: selectedVillages.some((obj) => obj.id === item.id),
                  latitude: item.latitude,
                  longitude: item.longitude
                }))}
              />
            </div>
          </>
        )}
      </ViewLayout.Body>
      <ViewLayout.Footer>
        <Button label="Cancel" variant="secondary" onClick={cancelOperation} />
        <Button
          label="Next"
          variant="primary"
          onClick={nextOperation}
          disabled={isLoading || selectedVillages.length === 0}
        />
      </ViewLayout.Footer>
    </ViewLayout>
  );
};

export default SelectNonAnchorVillagesView;
