import React from 'react'

const SilverMedalSvg: React.FC = () => {
    return (
        <svg width="34" height="32" viewBox="0 0 34 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_129_21993)">
                <path d="M21.7002 0H33.7002L16.7002 25H4.7002L21.7002 0Z" fill="#646464"/>
                <path d="M0.200195 0H12.2002L27.7002 25H15.7002L0.200195 0Z" fill="#B4B4B4"/>
                <path d="M15.2627 5.26292C15.8428 4.92799 16.5576 4.92799 17.1377 5.26292L26.7621 10.8196C27.3422 11.1545 27.6996 11.7735 27.6996 12.4434V23.5566C27.6996 24.2265 27.3422 24.8455 26.7621 25.1804L17.1377 30.737C16.5576 31.072 15.8428 31.072 15.2627 30.737L5.63834 25.1804C5.05821 24.8455 4.70084 24.2265 4.70084 23.5566V12.4434C4.70084 11.7735 5.05821 11.1545 5.63834 10.8196L15.2627 5.26292Z" fill="#B4B4B4" stroke="#646464" stroke-width="1.25"/>
                <path d="M15.9132 22.7241L12.216 23.9131C12.0146 23.9778 11.8085 23.828 11.8078 23.6165L11.7961 19.7329C11.7955 19.5368 11.7334 19.3459 11.6187 19.187L9.34545 16.0381C9.22165 15.8666 9.3004 15.6242 9.50135 15.5583L13.1913 14.347C13.3776 14.2858 13.54 14.1678 13.6557 14.0096L15.9479 10.8746C16.0728 10.7038 16.3276 10.7038 16.4525 10.8746L18.7447 14.0096C18.8604 14.1678 19.0228 14.2858 19.2091 14.347L22.899 15.5583C23.1 15.6242 23.1787 15.8666 23.0549 16.0381L20.7817 19.187C20.667 19.3459 20.6049 19.5368 20.6043 19.7329L20.5926 23.6165C20.5919 23.828 20.3858 23.9778 20.1844 23.9131L16.4872 22.7241C16.3006 22.6641 16.0998 22.6641 15.9132 22.7241Z" fill="#646464"/>
                <path d="M15.9132 22.7241L16.0089 23.0216M15.9132 22.7241L12.216 23.9131C12.0146 23.9778 11.8085 23.828 11.8078 23.6165L11.7961 19.7329C11.7955 19.5368 11.7334 19.3459 11.6187 19.187L9.34545 16.0381C9.22165 15.8666 9.3004 15.6242 9.50135 15.5583L13.1913 14.347C13.3776 14.2858 13.54 14.1678 13.6557 14.0096L15.9479 10.8746C16.0728 10.7038 16.3276 10.7038 16.4525 10.8746L18.7447 14.0096C18.8604 14.1678 19.0228 14.2858 19.2091 14.347L22.899 15.5583C23.1 15.6242 23.1787 15.8666 23.0549 16.0381L20.7817 19.187C20.667 19.3459 20.6049 19.5368 20.6043 19.7329L20.5926 23.6165C20.5919 23.828 20.3858 23.9778 20.1844 23.9131L16.4872 22.7241C16.3006 22.6641 16.0998 22.6641 15.9132 22.7241Z" stroke="#DBD6D6" stroke-width="0.625"/>
                <path d="M26.4051 11.1756C26.5984 11.0607 26.8191 11 27.044 11H27.0749C27.7652 11 28.3249 11.5596 28.3249 12.25V24.2783C28.3249 24.7249 28.0866 25.1376 27.6999 25.3608L16.8255 31.6392C16.4388 31.8624 15.9623 31.8624 15.5755 31.6392L5.7421 25.9618C4.95867 25.5095 4.90024 24.4006 5.6318 23.8684L8.20044 22L26.4051 11.1756Z" fill="white" fill-opacity="0.1"/>
            </g>
            <defs>
                <clipPath id="clip0_129_21993">
                    <rect width="34" height="32" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    )
}

export default SilverMedalSvg;