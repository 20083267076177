import React, { useCallback, useContext, useEffect, useState } from "react";
import "./index.css";
import Profiletemplate from "../../templates/Profiletemplate";
import tractorModelImg from "../../../images/tafe-model-tractor.jpg";
import Buttontemplate from "../../templates/Buttontemplate";
import { UserContext } from "../../HOC/UserContext";
import { IUserContext } from "../../../Types/global";
import ListBoxtemplate from "../../templates/ListBoxtemplate";
import { Link, useNavigate } from "react-router-dom";
import Followups from "../Followups";
import OverdueHps from "../OverdueHps";
import { ArrowSvg } from "../../../icons/ArrowSvg";
import PlannedVisit from "../PlannedVisit";
import { ExitSvg } from "../../../icons/ExitSvg";
import dateHooks from "../../Hooks/dateHooks";
import useHome from "../../Hooks/useHome";
import AppUrlConstants from "../../../shared/constants/AppUrlConstants";
import AppQueryParameters from "../../../shared/enums/AppQueryParameters";
import GoldMedalSvg from "../../../icons/Medals/GoldMedalSvg";
import SilverMedalSvg from "../../../icons/Medals/SilverMedalSvg";
import PlatinumMedalSvg from "../../../icons/Medals/PlatinumMedalSvg";
import dummyUser from "../../../images/dummy-user.png";
import ProfilePath from "../../../shared/enums/ProfilePath";
import usePagedProspects from "../../../shared/hooks/usePagedProspects";
import ProspectStage from "../../../shared/enums/ProspectStage";
import DateTimeProcessor from "../../../shared/processors/DateTimeProcessor";

const i18nTranslated = {
  welcomeLabel: "Welcome",
  goldLabel: "Gold",
  silverLabel: "Silver",
  platinumLabel: "Platinum",
  exploreMFModel: "Explore our MF models",
  add_enquiry: "Add Enquiry",
  followup_header: "Follow up",
  overdueHeader: "Overdue HP",
  plannedVisitHeader: "Planned Visits and Activation"
};
const DspHome: React.FC = () => {
  const currentUser = useContext(UserContext) as IUserContext;
  const [name, setname] = useState<string>("");
  const [skillLevel, setSkillLevel] = useState<string | null>(null);
  const navigate = useNavigate();
  const [currentDate] = useState(dateHooks.formatDate("", "LLLL"));
  const { followupsCount, plannedVisitCount, setplannedVisitCount } = useHome();

  

  useEffect(() => {
    const user = (currentUser as IUserContext).user;
    if (user) {
      if (user.first_name !== null && user.last_name !== null) {
        setname(`${user.first_name} ${user.last_name}`);
      } else if (user.first_name !== null && user.last_name === null) {
        setname(user.first_name);
      } else {
        setname(user.last_name);
      }
      if (user.skill_level) {
        setSkillLevel(user.skill_level);
      }
    }
  }, [currentUser]);
  const renderSkillLevel = useCallback(() => {
    if (skillLevel === "gold") {
      return (
        <>
          <GoldMedalSvg />
          <span className={`patch-type ${skillLevel}`}>
            {i18nTranslated.goldLabel}
          </span>
        </>
      );
    } else if (skillLevel === "silver") {
      return (
        <>
          <SilverMedalSvg />
          <span className={`patch-type ${skillLevel}`}>
            {i18nTranslated.silverLabel}
          </span>
        </>
      );
    } else if(skillLevel === 'platinum') {
      return (
        <>
          <PlatinumMedalSvg />
          <span className={`patch-type ${skillLevel}`}>
            {i18nTranslated.platinumLabel}
          </span>
        </>
      );
    }
  }, [skillLevel]);
  return (
    <>
      <Profiletemplate>
        <div className="profile-detail">
          <img
            src={`${ProfilePath.PATH}${
              (currentUser as IUserContext).user.id
            }.png`}
            className="profile-img"
            onError={(event: React.SyntheticEvent<HTMLImageElement>) =>
              (event.currentTarget.src = dummyUser)
            }
          />
          <div className="dsp-profile-detail">
            <span className="">{i18nTranslated.welcomeLabel}</span>
            <span className="dsp-user-name">{name}</span>
            <span className="dsp-currentdate">{currentDate}</span>
          </div>
        </div>
        <div className="patch-detail">
          {skillLevel === null ? <></> : renderSkillLevel()}
        </div>
      </Profiletemplate>
      <div className="dsp-home-stats">
        <div className="enquiry-button-wrap">
          <div
            className="add-enquiry-btn"
            onClick={() =>
              navigate("/innerbase/home/addenquiry?title=add_enquiry")
            }
          >
            {i18nTranslated.add_enquiry} +
          </div>
        </div>
        <ListBoxtemplate
          accordionWrapClass="followup-accordion-wrap"
          hideIcon={followupsCount.value < 3}
        >
          <div className="dsp-accordion-head fCenter">
            <p className="heading-accordion">
              {i18nTranslated["followup_header"]}
            </p>
            <div className="dot-accordion"></div>
            <Link
              to="/innerbase/followup?title=followup"
              className="count-accordion"
            >
              {followupsCount.value}
            </Link>
          </div>
          <div className="dsp-accordion-content">
            <Followups noOfCards={5} />
          </div>
        </ListBoxtemplate>
        <ListBoxtemplate
          accordionWrapClass="plannedvisit-accordion-wrap"
          hideIcon={plannedVisitCount.value < 3}
        >
          <div className="dsp-accordion-head fCenter">
            <p className="heading-accordion">
              {i18nTranslated.plannedVisitHeader}
            </p>
            <div className="dot-accordion"></div>
            <Link
              to={{
                pathname:
                  AppUrlConstants.INNER_BASE +
                  AppUrlConstants.CHECK_IN +
                  AppUrlConstants.JCPS,
                search: new URLSearchParams({
                  [AppQueryParameters.COMMON.KEYS.TITLE]:
                    AppQueryParameters.CHECK_IN_JCP.VALUES.CHECK_IN_JCPS
                }).toString()
              }}
              className="count-accordion"
            >
              {plannedVisitCount.value}
            </Link>
          </div>
          <div className="dsp-accordion-content">
            <PlannedVisit noOfCards={5} setCount={setplannedVisitCount} />
          </div>
        </ListBoxtemplate>
        <OverdueHps/>
        <div className="explore-tractor-ml">
          <img className="model-tractor-img" src={tractorModelImg} />
          <div className="model-tractor-head">
            <span>{i18nTranslated.exploreMFModel}</span>
            <ArrowSvg />
          </div>
        </div>
      </div>
      <div className="checkin-btn">
        <Buttontemplate
          buttonWidth="130px"
          type="primary"
          callback={() =>
            navigate(
              `${
                AppUrlConstants.INNER_BASE +
                AppUrlConstants.CHECK_IN +
                AppUrlConstants.CATEGORIES
              }?title=check_in_category`
            )
          }
        >
          <ExitSvg />
          <span className="context-checkin">Check-in</span>
        </Buttontemplate>
      </div>
    </>
  );
};

export default DspHome;
