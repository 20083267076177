import { useState } from "react";

import useEffectAfterMount from "./useEffectAfterMount";
import JcpPlanApproval from "../services/JcpPlanApproval";

import { Model } from "../../Types/global";
import { UseJcPlanApprovalHook } from "../../Types/hooks";

export default function useJcpPlanApproval({
  filters,
  onSuccess,
  onFailure
}: UseJcPlanApprovalHook.Parameters) {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [data, setData] = useState<Model.JcPlanApproval | undefined>(undefined);
  const [error, setError] = useState<any>();

  useEffectAfterMount(() => {
    setIsLoading(true);
    JcpPlanApproval.getPagedJcPlanApprovals({
      page: 1,
      perPage: 1,
      filters
    })
      .then((result) => {
        if (onSuccess) {
          onSuccess.apply(null, [
            {
              data: result.data[0] || null
            }
          ]);
        }
        setData(result.data[0]);
        setIsLoading(false);
      })
      .catch((err) => {
        setError(err);

        if (onFailure) {
          onFailure.apply(null, [err]);
        }
      });
  }, [filters, onFailure]);

  return { data, isLoading, error };
}
