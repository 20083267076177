import styles from "./styles.module.css";

import axios from "axios";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";
import React, { useCallback, useState } from "react";
import useJcPlanningCalender from "../../../shared/hooks/useJcPlanningCalendar";
import { UseJcPlanningsHook } from "../../../Types/hooks";
import VillageColorCode from "../../../shared/enums/VillageColorCode";
import VillageTiv from "../../../shared/enums/VillageTiv";
import useJcpPlanApproval from "../../../shared/hooks/useJcpPlanApproval";
import ServerConnectorUtil from "../../../shared/utils/ServerConnectorUtil";
import useJcPlannings from "../../../shared/hooks/useJcPlannings";
import useJcPlanningCalenderHandler from "../../../shared/hooks/useJcPlanningCalendarHandler";
import useCustomEventListener from "../../../shared/hooks/useCustomEventListener";
import CustomEventNameConstants from "../../../shared/constants/CustomEventNameConstants";
import AppUrlConstants from "../../../shared/constants/AppUrlConstants";
import DateTimeProcessor from "../../../shared/processors/DateTimeProcessor";
import DateFormat from "../../../shared/enums/DateFormat";
import AppQueryParameters from "../../../shared/enums/AppQueryParameters";
import ToastUtil from "../../../shared/utils/ToastUtil";
import CustomEventEmiterUtil from "../../../shared/utils/CustomEventEmitterUtil";
import Loadertemplate from "../../../Components/templates/Loadertemplate";
import JcPlanningCalendar from "../../../shared/components/jc-planning-calender";
import JcPlanApprovalStatus from "../../../shared/enums/JcPlanApprovalStatus";
import JcPlanningScheduledVillageViewModal from "../../../shared/components/jc-planning-scheduled-village-view-modal";
import Button from "../../../shared/components/Button";
import NoteMessage from "../../../shared/components/note-message";
import { useFormContext } from "../../../shared/hooks/useFormContext";
import { CreateJcPlanningForm } from "../../types";
import { useCreateJcPlanningContext } from "../../hooks/useCreateJcPlanningContext";
import View from "../../enums/View";

type ColorCodeSummary = {
  colorCode: string;
  hTiv: number;
  lTiv: number;
};

const JcPlanningSummary: React.FC = () => {
  const navigate = useNavigate();
  const { setMultipleValues } = useFormContext<CreateJcPlanningForm>();
  const { goToView } = useCreateJcPlanningContext();
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [totalSelectecdVillages, setTotalSelectedVillages] =
    useState<number>(0);
  const [totalNumberOfVisits, setTotalNumberOfVisits] = useState<number>(0);
  const [totalUniqueVillages, setTotalUniqueVillages] = useState<number>(0);
  const [colorCodeSummaries, setColorCodeSummaries] = useState<
    Array<ColorCodeSummary>
  >([]);

  const {
    endDate,
    startDate,
    onMonthChange,
    dateColorCodeMap,
    jcPlanningFilters,
    setExcludedDates,
    setDateColorCodeMap,
    jcPlanApprovalFilters
  } = useJcPlanningCalender();

  const onJcPlanningsLoaded = useCallback(
    ({ data: jcPlannings }: UseJcPlanningsHook.OnSuccessCbParameters) => {
      setTotalSelectedVillages(
        jcPlannings.reduce((prev, _curr) => prev + 1, 0)
      );

      setTotalUniqueVillages(
        new Set(jcPlannings.map((item) => item.village.id)).size
      );

      setTotalNumberOfVisits(
        new Set(jcPlannings.map((item) => item.date)).size
      );

      const colorCodeSummaries: Array<ColorCodeSummary> = [];

      for (const colorCode of Object.values(VillageColorCode)) {
        const totalHtiv = jcPlannings.filter(
          (obj) =>
            obj.village.color_code === colorCode &&
            obj.village.tiv === VillageTiv.HIGH
        ).length;

        const totalLtiv = jcPlannings.filter(
          (obj) =>
            obj.village.color_code === colorCode &&
            obj.village.tiv === VillageTiv.LOW
        ).length;

        colorCodeSummaries.push({
          colorCode,
          hTiv: totalHtiv,
          lTiv: totalLtiv
        });
      }
      setColorCodeSummaries(colorCodeSummaries);
    },
    []
  );

  const { data: jcPlanApproval, isLoading: isLoadingJcPlanApproval } =
    useJcpPlanApproval({
      filters: jcPlanApprovalFilters,
      onFailure: ServerConnectorUtil.handleServerError
    });

  const {
    data: jcPlannings,
    isLoading: isLoadingJcPlannings,
    deleteJcPlanning
  } = useJcPlannings({
    page: 1,
    perPage: 200,
    filters: jcPlanningFilters,
    onSuccess: onJcPlanningsLoaded,
    onFailure: ServerConnectorUtil.handleServerError
  });

  useJcPlanningCalenderHandler({
    endDate,
    startDate,
    jcPlannings,
    jcPlanApproval,
    setExcludedDates,
    setDateColorCodeMap
  });

  useCustomEventListener(
    CustomEventNameConstants.JC_PLANNING.DELETE_RECORD,
    deleteJcPlanning
  );

  const isLoading = isLoadingJcPlanApproval || isLoadingJcPlannings;

  const onDateSelect = useCallback(
    (date: Date | null) => {
      let isCreationAllowed = false;

      if (date === null) {
        ToastUtil.makeFailureToast("Unable to read the selected date.");
        return;
      }

      const formatedDate = DateTimeProcessor.format(date.getTime());
      const hasJcPlannings = dateColorCodeMap[formatedDate];

      if (
        DateTimeProcessor.isDateAfter(date.getTime(), Date.now()) &&
        (!jcPlanApproval ||
          jcPlanApproval.status === JcPlanApprovalStatus.IN_DRAFT)
      ) {
        isCreationAllowed = true;
      }

      if (isCreationAllowed) {
        setMultipleValues({
          date: date.getTime(),
          planType: null,
          anchorVillage: null,
          nonAnchorVillages: null
        });

        if (hasJcPlannings) {
          CustomEventEmiterUtil.emit(
            CustomEventNameConstants.JC_PLANNING.OPEN_VIEW_MODAL,
            jcPlannings.filter((obj) => obj.date === formatedDate),
            isCreationAllowed
          );
        } else {
          goToView(View.FORM_VIEW);
        }
      } else {
        if (hasJcPlannings) {
          CustomEventEmiterUtil.emit(
            CustomEventNameConstants.JC_PLANNING.OPEN_VIEW_MODAL,
            jcPlannings.filter((obj) => obj.date === formatedDate),
            isCreationAllowed
          );
        } else {
          if (!DateTimeProcessor.isDateAfter(date.getTime(), Date.now())) {
            ToastUtil.makeFailureToast(
              "You're not allowed to create a jcp as the date is before current date."
            );
          }

          if (
            jcPlanApproval &&
            jcPlanApproval.status !== JcPlanApprovalStatus.IN_DRAFT
          ) {
            ToastUtil.makeFailureToast(
              "You're not allowed to create a jcp as the current month status is " +
                jcPlanApproval.status.toLowerCase()
            );
          }
        }
      }
    },
    [goToView, jcPlannings, jcPlanApproval, setMultipleValues, dateColorCodeMap]
  );

  const showColorCodeSummaryJcPlannings = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      const element = event.currentTarget as HTMLElement;

      const tiv = element.getAttribute("data-tiv");
      const colorCode = element.getAttribute("data-color-code");

      CustomEventEmiterUtil.emit(
        CustomEventNameConstants.JC_PLANNING.OPEN_VIEW_MODAL,
        jcPlannings.filter(
          (obj) =>
            obj.village.color_code === colorCode && obj.village.tiv === tiv
        ),
        false
      );
    },
    [jcPlannings]
  );

  const showAllJcPlannings = useCallback(() => {
    CustomEventEmiterUtil.emit(
      CustomEventNameConstants.JC_PLANNING.OPEN_VIEW_MODAL,
      jcPlannings,
      false
    );
  }, [jcPlannings]);

  const showUniqueJcPlannings = useCallback(() => {
    const requiredJcPlannings: Array<string> = Object.values(
      jcPlannings.reduce(
        (prev, curr) => ({
          ...prev,
          [curr.village.id]: curr.id
        }),
        {}
      )
    );

    CustomEventEmiterUtil.emit(
      CustomEventNameConstants.JC_PLANNING.OPEN_VIEW_MODAL,
      jcPlannings.filter((item) => requiredJcPlannings.includes(item.id)),
      false
    );
  }, [jcPlannings]);

  const sendApproval = async () => {
    setIsProcessing(true);
    try {
      await axios.patch(
        `/api/v1/jc-plan-approval/${jcPlanApproval?.id}/submit-for-approval`
      );
      navigate("/success/approval");
    } catch (error) {
      ServerConnectorUtil.handleServerError(error);
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <>
      <div className={styles["container"]}>
        {isLoading ? (
          <div className="loader-wrapper">
            <Loadertemplate />
          </div>
        ) : (
          <>
            <div className={styles["villages-count-container"]}>
              <div
                className={classNames(
                  styles["card"],
                  styles["card--village-count"]
                )}
                onClick={showAllJcPlannings}
              >
                <div className={classNames(styles["card__header"])}>
                  <h6 className={styles["title"]}>Total Selected Villages</h6>
                </div>
                <div className={styles["card__body"]}>
                  <p className={classNames(styles["count"], styles["link"])}>
                    {totalSelectecdVillages}
                  </p>
                </div>
              </div>
              <div
                className={classNames(
                  styles["card"],
                  styles["card--village-count"]
                )}
                onClick={showUniqueJcPlannings}
              >
                <div className={classNames(styles["card__header"])}>
                  <h6 className={styles["title"]}>Unique Villages</h6>
                </div>
                <div className={styles["card__body"]}>
                  <p className={classNames(styles["count"], styles["link"])}>
                    {totalUniqueVillages}
                  </p>
                </div>
              </div>
            </div>
            <div className={classNames(styles["card"])}>
              <div className={classNames(styles["card__header"])}>
                <h6 className={styles["title"]}>No. of days of visits</h6>
              </div>
              <div className={styles["card__body"]}>
                <p className={styles["count"]}>{totalNumberOfVisits}</p>
              </div>
            </div>
            <JcPlanningCalendar
              openToDate={startDate}
              dateColorCodeMap={dateColorCodeMap}
              onDateSelect={onDateSelect}
              onMonthChange={onMonthChange}
              jcPlanApprovalStatus={
                jcPlanApproval?.status || JcPlanApprovalStatus.IN_DRAFT
              }
            />
            <div className={styles["hr"]} />
            <h6 className={styles["bryg-summary-title"]}>BRYG Summary</h6>
            {colorCodeSummaries.map((item) => (
              <div key={item.colorCode} className={classNames(styles["card"])}>
                <div className={classNames(styles["card__header"])}>
                  <h6
                    className={classNames(
                      styles["title"],
                      styles[item.colorCode.toLowerCase()]
                    )}
                  >
                    {item.colorCode}
                  </h6>
                </div>
                <div
                  className={classNames(
                    styles["card__body"],
                    styles["bryg-container"]
                  )}
                >
                  <div
                    className={styles["tiv-container"]}
                    data-tiv={VillageTiv.HIGH}
                    data-color-code={item.colorCode}
                    onClick={showColorCodeSummaryJcPlannings}
                  >
                    <p className={styles["title"]}>HTIV</p>
                    <p className={classNames(styles["count"], styles["link"])}>
                      {item.hTiv}
                    </p>
                  </div>
                  <div
                    className={styles["tiv-container"]}
                    data-tiv={VillageTiv.LOW}
                    data-color-code={item.colorCode}
                    onClick={showColorCodeSummaryJcPlannings}
                  >
                    <p className={styles["title"]}>LTIV</p>
                    <p className={classNames(styles["count"], styles["link"])}>
                      {item.lTiv}
                    </p>
                  </div>
                </div>
              </div>
            ))}
            <JcPlanningScheduledVillageViewModal goToCreateView={() => goToView(View.FORM_VIEW)}/>
            <NoteMessage
              message={
                jcPlanApproval
                  ? jcPlanApproval.status !== JcPlanApprovalStatus.IN_DRAFT
                    ? "You're not allowed to create a jcp as month status is " +
                      jcPlanApproval.status.toLowerCase()
                    : "Create remaining before submitting for approval."
                  : "Create remaining before submitting for approval."
              }
            />
            <Button
              label="Send for approval"
              variant="primary"
              isLoading={isProcessing}
              disabled={
                jcPlannings.length === 0 ||
                !jcPlanApproval ||
                jcPlanApproval.status !== JcPlanApprovalStatus.IN_DRAFT
              }
              fullWidth
              onClick={sendApproval}
            />
          </>
        )}
      </div>
    </>
  );
};

export default JcPlanningSummary;
