export default class AppUrlConstants {
  public static readonly MENU = "/menu";
  public static readonly JCPS = "/jcps";
  public static readonly JCP_ID = "/:jcpId";
  public static readonly ALL_PATH = "*";
  public static readonly EMPTY_PATH = "";
  public static readonly SUCCESS = "/success";
  public static readonly CHECK_IN = "/check-in";
  public static readonly INNER_BASE = "/innerbase";
  public static readonly CATEGORIES = "/categories";
  public static readonly TRACTOR_USER_DATABASES = "/tractoruser";
  public static readonly PROSPECT = "/prospect";
  public static readonly PROSPECTS = "/prospects";
  public static readonly TUDB = "tudb";
  public static readonly FOLLOWUP = "followup";
  public static readonly SUMMARY = "/summary";
  public static readonly EDIT = "/edit";

  public static readonly BASE = class {
    public static readonly BASE_NAME = "/base";
    public static readonly JCP = class {
      public static readonly BASE_NAME = "/base/jcp";
    };
  };

  public static readonly CREATE_JCP = class {
    public static readonly BASE_NAME =
      AppUrlConstants.INNER_BASE + "/create-jcp";
    public static readonly SELECT_DATE = this.BASE_NAME + "/select-date";
    public static readonly FORM = this.BASE_NAME + "/form";
    public static readonly SELECT_ANCHOR_VILLAGE =
      this.BASE_NAME + "/select-anchor-village";
    public static readonly SELECT_NON_ANCHOR_VILLAGES =
      this.BASE_NAME + "/select-non-anchor-villages";
  };
}
