import React, { useCallback, useState } from 'react'
import { ICalendarProps } from '../../../Types/global'
import useCalendar from '../../hooks/useCalendar';
import classNames from 'classnames';
import NavigateArrowSvg from '../../../icons/NavigateArrowSvg';
import style from './index.module.css';
import { UseCommonCalendarRange } from '../../../Types/hooks';

export const Calendar: React.FC<ICalendarProps> = ({DateRange, onDaySelect}) => {
    const {daysArray, month, year, nextMonthCallback, prevMonthCallback} = useCalendar({range: DateRange});
    const today = new Date();
    const [dateRange, setdateRange] = useState<{
        start:  UseCommonCalendarRange.tileDateObj | null,
        end: UseCommonCalendarRange.tileDateObj | null
    }>({
        start: {
          day: DateRange.start.getDate(),
          month: DateRange.start.getMonth(),
          year: DateRange.start.getFullYear()
        },
        end: {
          day: DateRange.end.getDate(),
          month: DateRange.end.getMonth(),
          year: DateRange.end.getFullYear()
        }
    })
    const handleDayChange = useCallback((dayObj: UseCommonCalendarRange.tileDateObj) => {
      if(!dateRange.start){
          setdateRange((prev)=> ({...prev, start: dayObj}))
      }else{
          if((dateRange.start.day <= dayObj.day && dateRange.start.month === dayObj.month && dateRange.start.year === dayObj.year) ||
              (dateRange.start.month < dayObj.month && dateRange.start.year === dayObj.year) ||
              (dateRange.start.month > dayObj.month && dateRange.start.year > dayObj.year)){
                  setdateRange((prev)=> ({...prev, end: dayObj}))
                  onDaySelect(new Date(dateRange.start.year, dateRange.start.month, dateRange.start.day), new Date(dayObj.year, dayObj.month, dayObj.day))
          }else{
              setdateRange((prev)=> ({...prev, start: dayObj}))
          }
      }
  }, [dateRange])
    const isToday = (day: number | null, month: number, year: number) => {
      return (
        day !== null &&
        day === today.getDate() &&
        month === today.getMonth() &&
        year === today.getFullYear()
      );
    };
    const isUpcoming = (day: number | null, month: number, year: number) =>{
        return (
            day !== null &&
            (
              (
                day > today.getDate() &&
                month === today.getMonth() &&
                year === today.getFullYear()
              ) ||
              (
                month > today.getMonth() &&
                year >= today.getFullYear()
              ) ||
                year > today.getFullYear()
            )
        );
    };
    const isSelect = (day: number, month: number, year: number) => {
        if(dateRange.start && dateRange.end){
            return (((dateRange.start.month === dateRange.end.month && dateRange.start.year === dateRange.end.year) && ((month === dateRange.start.month && day >= dateRange.start.day) && (month === dateRange.end.month && day <= dateRange.end.day))) || 
                    ((dateRange.start.year === dateRange.end.year && dateRange.start.month < dateRange.end.month) && ((month === dateRange.start.month && day >= dateRange.start.day) || (month === dateRange.end.month && day <= dateRange.end.day) || (month > dateRange.start.month && month < dateRange.end.month))))
        }
        return false;
    }
    return (
        <>
            <div className={style["calendar-navigator"]}>
                <div className={style["current-month-year"]}>
                    {`${month}, ${year}`}
                </div>
                <div className={style["current-navigation"]}>
                    <span className={style["previous-navigate"]} onClick={prevMonthCallback}>
                        <NavigateArrowSvg />
                    </span>
                    <span className={style["next-navigate"]} onClick={nextMonthCallback}>
                        <NavigateArrowSvg />
                    </span>
                </div>
            </div>
            <>
            <div className={style["days-header"]}>
            {['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map((day) => (
              <div key={day} className={style["day"]}>
                {day}
              </div>
            ))}
          </div>
          <div className={style["days-grid"]}>
            {daysArray.map((dayObj, index) => {
              const todayClass = isToday(
                dayObj.day,
                dayObj.month,
                dayObj.year
              )
                ? style['today-date']
                : '';
              const upcomingClass = isUpcoming(
                dayObj.day,
                dayObj.month,
                dayObj.year
              )
                ? style['upcoming-date']
                : '';
              const selectClass = isSelect(
                dayObj.day,
                dayObj.month,
                dayObj.year
              ) 
                ? style['select-date']
                : '';
              return (
                <div
                  key={index}
                  className={classNames(
                    style['date'],
                    todayClass,
                    upcomingClass,
                    selectClass
                  )}
                  onClick={() => handleDayChange(dayObj)}
                >
                  {dayObj.day !== null ? (
                    <div className={style["date-value"]}>
                      {dayObj.day}
                    </div>
                  ) : (
                    <div className={style["date-placeholder"]}></div>
                  )}
                </div>
              );
            })}
          </div>
            </>
        </>
    )
}
