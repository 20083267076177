import styles from "./styles.module.css";

import classNames from "classnames";
import { useCallback, useState } from "react";

import Button from "../Button";
import useModal from "../../hooks/useModal";
import EmptyRecords from "../empty-records";
import JcPlanningService from "../../services/JcPlanningService";
import ServerConnectorUtil from "../../utils/ServerConnectorUtil";
import CustomEventEmiterUtil from "../../utils/CustomEventEmitterUtil";
import useCustomEventListener from "../../hooks/useCustomEventListener";
import CustomEventNameConstants from "../../constants/CustomEventNameConstants";

import { IJCPVillageStats } from "../../../Types/global";

type JcPlanningScheduledVillageViewModalProps = {
  goToCreateView?: () => void;
};

const JcPlanningScheduledVillageViewModal: React.FC<
  JcPlanningScheduledVillageViewModalProps
> = ({ goToCreateView }) => {
  const { open, openModal, closeModal } = useModal({});

  const [jcPlannings, setJcPlannings] = useState<Array<IJCPVillageStats>>([]);
  const [isCreationAllowed, setIsCreationAllowed] = useState<boolean>(false);

  const [isProcessing, setIsProcessing] = useState<boolean>(false);

  const onOpenModalEvent = useCallback(
    (
      jcPlannings: Array<IJCPVillageStats>,
      isCreationAllowed: boolean = false
    ) => {
      setIsCreationAllowed(isCreationAllowed);
      setJcPlannings(jcPlannings);
      openModal();
    },
    [openModal]
  );

  const deleteJcPlanning = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      const element = event.target as HTMLElement;

      const id = element.getAttribute("data-id") as string;

      setIsProcessing(true);
      JcPlanningService.deleteJcPlanningById({ id })
        .then(() => {
          setJcPlannings((prev) => prev.filter((item) => item.id !== id));

          CustomEventEmiterUtil.emit(
            CustomEventNameConstants.JC_PLANNING.DELETE_RECORD,
            id
          );
        })
        .catch(ServerConnectorUtil.handleServerError)
        .finally(() => setIsProcessing(false));
    },
    []
  );

  useCustomEventListener(
    CustomEventNameConstants.JC_PLANNING.OPEN_VIEW_MODAL,
    onOpenModalEvent
  );

  return (
    <>
      {open && (
        <>
          <div className={styles["freeze-page-popup"]} />
          {isProcessing && (
            <>
              <div className={styles["page-popup-loader"]}></div>
              <div className={styles["page-popup-loader-img"]}></div>
            </>
          )}
          <div className={styles["full-page-popup"]}>
            <div className={styles["popup-wrapper"]}>
              <div className={styles["popup-wrap-header"]}>
                <span className={styles["popup-header"]}>Scheduled Village</span>
                <div className={styles["close-icon"]} onClick={closeModal}></div>
              </div>
              <div className={classNames(styles["popup-wrap-content"])}>
                <div
                  className={classNames(
                    styles["body"],
                    isCreationAllowed && styles["footer-enabled"]
                  )}
                >
                  {jcPlannings.length === 0 ? (
                    <EmptyRecords
                      size="sm"
                      message="No jcp available for selected date."
                    />
                  ) : (
                    jcPlannings.map((item) => (
                      <div
                        key={item.id}
                        className={`visited-village-detail fCenter ${styles["jSpaceBetween"]}`}
                      >
                        <div className="visit-village">
                          <b>{item.village.name}</b>
                          <div className="visit-village-specification">
                            <span className="mvv_tiv">
                              Village:{" "}
                              <b className={`${item.village.color_code}__Font`}>
                                {item.village.color_code}
                              </b>
                            </span>
                            {item.village.tiv === "High" ? "HTIV" : "LTIV"}
                          </div>
                        </div>
                        {item.status === "In Draft" && (
                          <div
                            className={styles["delete-icon"]}
                            data-id={item.id}
                            onClick={deleteJcPlanning}
                          />
                        )}
                      </div>
                    ))
                  )}
                </div>
                {isCreationAllowed && (
                  <div className={styles["footer"]}>
                    <Button
                      label="Create"
                      variant="primary"
                      fullWidth
                      onClick={goToCreateView}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default JcPlanningScheduledVillageViewModal;
