import { Framework, IFollowupProspect, IPageResponse } from "../../Types/global";
import { FollowupServiceParameters } from "../../Types/parameters";
import api_request from "../constants/api_request";
import RequestMethod from "../enums/RequestMethod";
import RequestHandler from "../handlers/RequestHandler";
import ServerConnectorUtil from "../utils/ServerConnectorUtil";

export default class TaskService{
    public static async getFollowupPagedTask({
        page,
        perPage,
        signal,
        filter:{
            type,
            statuses,
            name,
            mobile_number
        }
    }: FollowupServiceParameters.FollowupPaged.Parameters): Promise<FollowupServiceParameters.FollowupPaged.ReturnType>{
        const reqHandler = new RequestHandler(api_request.TASK_DATA, RequestMethod.POST);
        reqHandler.addParam('page', page);
        reqHandler.addParam('perPage', perPage);
        let body: Record<string, unknown> = {}
        if(type){
            body['type'] = type;
        }
        if(statuses){
            body['statuses'] = statuses
        }
        if(name){
            reqHandler.addParam('name', name);
        }
        if(mobile_number){
            reqHandler.addParam('mobile_number', mobile_number);
        }
        if(signal){
            reqHandler.setAbortSignal(signal);
        }
        reqHandler.setBody(body);
        return reqHandler.execute()
        .then(response => response.data as FollowupServiceParameters.FollowupPaged.ReturnType)
    } 
}