import React, { useEffect, useState } from 'react'
import './index.css';
import { IMainTemplateProp } from '../../../Types/global';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import classNames from 'classnames';
import Loadertemplate from '../Loadertemplate';
import { HomeBlack } from '../../../icons/HomeBlack';
import { JCPSvg } from '../../../icons/JCPSvg';
import { EnquiriesSvg } from '../../../icons/EnquiriesSvg';
import { DashboardSvg } from '../../../icons/DashboardSvg';
import { DSPSvg } from '../../../icons/DSPSvg';
import { TafeLogoSvg } from '../../../icons/TafeLogoSvg';
import { ArrowPrimarySvg } from '../../../icons/ArrowPrimarySvg';
import { MenuSvg } from '../../../icons/MenuSvg';
import { NotificationSvg } from '../../../icons/NotificationSvg';

const i18nTranslated = {
    'homeMenu': 'Home',
    'jcpMenu': 'JCP',
    'enquiryMenu': 'Enquiries',
    'DashboardMenu': 'Dashboard',
    'dspMenu': 'DSP',
    'lead_title': 'Leads',
    'prospect_title': 'Prospects',
    'enquiries_title':'Enquiries',
    'hot_prospect_title': 'Hot Prospects',
    'overdue_hps_title': 'Overdue Hps',
    'add_enquiry': 'Add Enquiry',
    'add_tudb': 'Create a TUDB',
    'menu_title': 'Menu',
    'mpcgyarah-menu': 'MPC & Ek se Ek Gyarah',
    'check_in_category': 'Select Category',
    'check-in-jcps': 'JCP',
    'check-in-jcp':'Village Details',
    'village_details': 'Village details',
    'visit_details': 'Visit details',
    'leader_board': 'Leader board',
    'enquiry_master': 'Enquiry Master',
    'tractor_master': 'Tractor Master',
    'village_master': 'Village Master',
    'create_jcp': 'Create JCP',
    'select_anchor': 'Select Anchor',
    'select_village': 'Select Village',
    'summary': 'Summary',
    'followup': 'Follow up',
    'overdue': 'Overdues',
    'plannedvisit': 'Planned Visits',
    'prospect_details': 'Prospect Details',
    'tudb_details': 'TUDB Details',
    'followup_details': 'Followup Details',
    'prospect_edit': 'Prospect Edit',
    'tudb_edit': 'TUDB Edit',
    'deliveries': 'Deliveries',
    'enquiry_conversion_title': 'Enquiry conversion',
    'delivery_title': 'Deliveries',
    'sales_lost_title': 'Sales lost',
    'sales_dropped_title': 'Sales Dropped',
    'F1': 'Hot Prospects - Stages - F1',
    'F2': 'Hot Prospects - Stages - F2',
    'F3': 'Hot Prospects - Stages - F3',
    'F4': 'Hot Prospects - Stages - F4',
    'F5': 'Hot Prospects - Stages - F5',
    'hot_prospect_sog_title': 'Hot Prospects - Sogs - ',
    TopMfDissatisfiedModel_label: "Top MF Dissatisfied model",
    TopMfSatisfiedModel_label: "Top MF Satisfied model",
    TopCompDissatisfiedModel_label: "Top competitor Dissatisfied model",
    TopCompSatisfiedModel_label: "Top competitor Satisfied model"
}
const Maintemplate : React.FC<IMainTemplateProp> = ({children}) => {
    const [currentMenu, setcurrentMenu] = useState<string>('HOME');
    const [currentPage, setcurrentPage] = useState<string>('');
    const [contentClass, setcontentClass] = useState<string>('');
    const [loader, setloader] = useState<boolean>(false);
    const [title, settitle] = useState<string>();
    const navigate = useNavigate();
    const location = useLocation();
    const role = 'Dsp';
    const [queryParams] = useSearchParams();
    useEffect(()=>{
        let { pathname } = location;
        let pathArr: string[] = pathname.split('/');
        setcurrentPage(pathArr[1]);
        if(pathArr[1] === 'innerbase'){
            let title = queryParams.get('title');
            let sog = queryParams.get('sogs');
            settitle(`${i18nTranslated[title as keyof typeof i18nTranslated]}${sog ? sog : ''}`);
        }
        setcurrentMenu(pathArr[2]?.toUpperCase());
    }, [location]);
    const handleMenuClick = (menu: string)=>{
        setcurrentMenu(menu)
        switch(menu){
            case 'HOME':
                navigate('/base/home')
            break;
            case 'JCP':
                navigate('/base/jcp');
            break;
            case 'DSP':
                navigate('/base/dsp')
            break;
            case 'ENQUIRIES':
                navigate('/base/enquiries/enquiry')
            break;
            default:
                navigate('/base/dashboard')
        }
    }
    return (
        <div className='main-wrapper'>
            <div className='main-wrap-header'>
                {
                    currentPage === "base" ?
                        <>
                            <span style={{'cursor': 'pointer'}} onClick={()=> navigate('/innerbase/menu?title=menu_title')}>
                                <MenuSvg/>
                            </span>
                            <span className='tafe-header'>
                                <TafeLogoSvg/>
                            </span>
                            <NotificationSvg/>
                        </>
                    :
                        <>
                            <div className='left-side'>
                                <span className='tafe-back-icon' onClick={()=> navigate(-1)}>
                                    <ArrowPrimarySvg fill='#fff'/>
                                </span>
                                <span className='sub-header'>{title}</span>
                            </div>
                        </>
                }
            </div>
            <div className={classNames('main-wrap-content', contentClass && contentClass, {'withFooterContent': (currentPage === 'base')})}>
                <div className={classNames('content', {'loaderContent': loader})}>
                    {
                        loader ? 
                            <Loadertemplate></Loadertemplate>
                        :
                        <>
                            {children}
                        </>
                    }
                </div>
            </div>
            {
                (!loader && (currentPage === 'base'))
                &&
                <div className='main-wrap-footer'>
                    <div className={classNames('category', {'selected': (currentMenu === 'HOME')})} onClick={()=> handleMenuClick('HOME')}>
                        <div className='icon-category'>
                            <HomeBlack fill={(currentMenu === 'HOME') ? '#fff' : undefined}/>
                        </div>
                        <span className='category-title'>{i18nTranslated.homeMenu}</span>
                    </div>
                    {
                        (role === 'Dsp') ?
                            <div className={classNames('category', {'selected': (currentMenu === 'JCP')})} onClick={()=> handleMenuClick('JCP')}>
                                <div className='icon-category'>
                                    <JCPSvg fill={(currentMenu === 'JCP') ? '#fff' : undefined}/>
                                </div>
                                <span className='category-title'>{i18nTranslated.jcpMenu}</span>
                            </div>
                        :
                            <div className={classNames('category', {'selected': (currentMenu === 'DSP')})} onClick={()=> handleMenuClick('DSP')}>
                                <div className='icon-category'>
                                    <DSPSvg fill={(currentMenu === 'DSP') ? '#fff' : undefined}/>
                                </div>
                                <span className='category-title'>{i18nTranslated.dspMenu}</span>
                            </div>
                    }   
                    <div className={classNames('category', {'selected': (currentMenu === 'ENQUIRIES')})} onClick={()=> handleMenuClick('ENQUIRIES')}>
                        <div className='icon-category'>
                            <EnquiriesSvg fill={(currentMenu === 'ENQUIRIES') ? '#fff' : undefined}/>
                        </div>
                        <span className='category-title'>{i18nTranslated.enquiryMenu}</span>
                    </div>
                    <div className={classNames('category', {'selected': (currentMenu === 'DASHBOARD')})} onClick={()=> handleMenuClick('DASHBOARD')}>
                        <div className='icon-category'>
                            <DashboardSvg fill={(currentMenu === 'DASHBOARD') ? '#fff' : undefined}/>
                        </div>
                        <span className='category-title'>{i18nTranslated.DashboardMenu}</span>
                    </div>
                </div>
            }
        </div>
    )
}

export default Maintemplate