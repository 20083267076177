import { useCallback, useEffect, useRef } from "react";
import { UseDebounceHook } from "../../Types/hooks";

export default function useDebounce({
  fn,
  delay
}: UseDebounceHook.Parameters): UseDebounceHook.Result {
  const timeout = useRef<number | undefined>();

  useEffect(() => {
    return () => {
      if (timeout.current) {
        window.clearTimeout(timeout.current);
      }
    };
  }, []);

  const debouncedFunction = useCallback(
    (...params: Array<any>) => {
      if (timeout.current) {
        window.clearTimeout(timeout.current);
      }

      timeout.current = window.setTimeout(() => {
        fn.apply(null, [...params]);
      }, delay);
    },
    [delay, fn]
  );

  return debouncedFunction;
}
