import styles from "./styles.module.css";

import classNames from "classnames";

import EmptyRecordSvg from "../../../icons/EmptyRecordSvg";

type EmptyVillagesProps = {
  size?: "sm" | "lg";
  message: string;
};

const EmptyRecords: React.FC<EmptyVillagesProps> = ({
  size = "lg",
  message
}) => {
  return (
    <div className={classNames(styles["container"], styles[size])}>
      <EmptyRecordSvg className={styles["svg"]} />
      <h6 className={styles["title"]}>{message}</h6>
    </div>
  );
};

export default EmptyRecords;
