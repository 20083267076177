import { useEffect, useState } from "react";
import { UseCurrentLocationHook } from "../../Types/hooks";

export default function useCurrentLocation(): UseCurrentLocationHook.ReturnType {
  const [latitude, setLatitude] = useState<number | undefined>(undefined);
  const [longitude, setLongitude] = useState<number | undefined>(undefined);

  useEffect(() => {
    window.navigator.geolocation.getCurrentPosition(
      (postion) => {
        setLatitude(postion.coords.latitude);
        setLongitude(postion.coords.longitude);
      },
      (error) => {
        console.log(error)
      }
    );
  }, []);

  return {
    latitude,
    longitude
  };
}
