enum JcPlanningAdditionalFields {
  VILLAGE_STATS_TOTAL_VISITS_YEAR_TILL_DATE = 'village.stats.total_visits_year_till_date',
  VILLAGE_STATS_TOTAL_PROSPECTS = 'village.stats.total_prospects',
  VILLAGE_STATS_TOTAL_TUDB_MF_SATISFIED = 'village.stats.total_tudb_mf_satisfied',
  VILLAGE_STATS_TOTAL_TUDB_MF_UNSATISFIED = 'village.stats.total_tudb_mf_unsatisfied',
  VILLAGE_STATS_TOTAL_TUDB_COMPETITION_SATISFIED = 'village.stats.total_tudb_competition_satisfied',
  VILLAGE_STATS_TOTAL_TUDB_COMPETITION_UNSATISFIED = 'village.stats.total_tudb_competition_unsatisfied'
}

export default JcPlanningAdditionalFields;
