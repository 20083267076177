import React, { useEffect, useRef, useState } from 'react'
import calendar from '../../../shared/constants/calendar'
import Form from 'react-bootstrap/Form';
import '../../CSS/form.css'
import style from './index.module.css';
import BlackCrossSvg from '../../../icons/BlackCrossSvg';
import classNames from 'classnames';
import FilterSvg from '../../../icons/FilterSvg';
import { ICalendarRange, ISetStateProps } from '../../../Types/global';
import { Calendar } from '../../../shared/components/Calendar';
import dateHooks from '../../Hooks/dateHooks';
import DateFormat from '../../../shared/enums/DateFormat';

const i18nTranslated = {
    'customRange': 'Custom Range'
}
const DateFilter: React.FC<ISetStateProps> = ({setState}) => {
    const options = calendar.DATEFILTER;
    const dateOptionFilters = useRef<(HTMLInputElement | null)[]>([]);
    const [currentOption, setcurrentOption] = useState<string>(calendar.CURRENTMONTH);
    const [openFilter, setopenFilter] = useState<boolean>(false);
    const [showCalendarPopup, setshowCalendarPopup] = useState(false);
    const [filterDate, setfilterDate] = useState<ICalendarRange.IDateRange>({
        start: new Date(),
        end: new Date()
    });
   
    const dateOptionClick = (index: number) => {
        setcurrentOption(options[index]);
        setState(options[index]);
        setopenFilter(false);
    }
    const customDateClick = (start: Date, end: Date) => {
        setfilterDate({
            start: start,
            end: end
        });
        let formateStartDate: string = dateHooks.formatDate(start.toString(), 'YYYY-MM-DD');
        let formatEndDate: string = dateHooks.formatDate(end.toString(), 'YYYY-MM-DD');
        setcurrentOption(`
            ${dateHooks.formatDate(start.toString(), DateFormat.DISPLAY_DATE)} 
            to 
            ${dateHooks.formatDate(end.toString(), DateFormat.DISPLAY_DATE)}
        `);
        setState({
            start: formateStartDate,
            end: formatEndDate
        });
        setshowCalendarPopup(false);
        setopenFilter(false);
    }
    const removeOption = ()=>{
        setcurrentOption('');
        setfilterDate({
            start: new Date(),
            end: new Date()
        })
        setState('');
    }
    return (
        <div className={style['filter-wrap']}>
            <div className={style['filter-label']}>
                <div className={style['selected-filter']}>
                    {
                        currentOption !== ''
                        &&
                            <div className={style['selectedOption']} key={currentOption}>
                                <span className={style['optionValue']}>{currentOption}</span>
                                <span style={{'cursor':'pointer','marginTop': '2px'}} onClick={()=> removeOption()}>
                                    <BlackCrossSvg/>
                                </span>
                            </div>
                    }
                </div>
                <div className={classNames(style['filter-icon'], {[style.filterActive]: openFilter}, {[style.filterUsed]: currentOption !== ''})} onClick={() => setopenFilter(!openFilter)}>
                    <FilterSvg/>
                </div>
            </div>
            <div className={classNames(style['filter-box'],{'hide': !openFilter})}>
                {
                    options.map((item, index)=>(
                        <div className={style['date-option']} onClick={() => dateOptionClick(index)}>
                            <Form.Check
                                type='radio'
                                bsPrefix='custom-form-check'
                                label={item}
                                value={item}
                                name='dateOption'
                                ref={(element: HTMLInputElement | null)=> dateOptionFilters.current[index] = element}
                                checked={currentOption === item}
                            />
                        </div>
                    ))
                }
                <div className={style['date-option']} onClick={() => setshowCalendarPopup(true)}>
                    {i18nTranslated.customRange}
                </div>
            </div>
            {
                showCalendarPopup
                &&
                <>
                    <div className={style['calendar-popup']}>
                        <span className={style['popup-close']} onClick={() => setshowCalendarPopup(false)}>
                            <BlackCrossSvg/>
                        </span>
                        <div className={style['calendar-wrapper']}>
                            <Calendar DateRange={filterDate} onDaySelect={customDateClick}/>
                        </div>
                    </div>
                    <div className='freeze-page-popup' onClick={() => setshowCalendarPopup(false)}></div>
                </>
            }
        </div>
    )
}

export default DateFilter