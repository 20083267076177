import React from 'react'
import { IButtonTemplateProp } from '../../../Types/global';
import classNames from 'classnames';
import './index.css';

const Buttontemplate: React.FC<IButtonTemplateProp> = ({children, buttonWidth, type, callback, disabled, className}) => {
    return (
        <button disabled={disabled} style={{'width': buttonWidth}} className={classNames('button', {'primary-btn': (type === 'primary')}, {'secondary-btn': (type === 'secondary')}, className && className)} onClick={callback}>
            {children}
        </button>
    )
}
export default Buttontemplate;
