import React from 'react';
import { DoubleTickSvg } from '../../../../icons/DoubleTickSvg';
import Buttontemplate from '../../Buttontemplate';
import { EnquiriesSvg } from '../../../../icons/EnquiriesSvg';
import { useNavigate } from 'react-router-dom';
const i18nTranslated = {
    success: "Success",
    jcpSuccessDesc: "Your task has been sent for approval.",
    backToHome: "Back to Home",
}

const CreateJCP: React.FC = () => {
    const navigate = useNavigate();
    return (
        <>
            <DoubleTickSvg/>
            <h1 style={{marginTop: '30px'}}>{i18nTranslated.success}</h1>
            <p>{i18nTranslated.jcpSuccessDesc}</p>
            <Buttontemplate type='success' buttonWidth='100%' callback={()=> navigate('/base/home')}>
                <EnquiriesSvg fill='#257851'/>
                {i18nTranslated.backToHome}
            </Buttontemplate>
        </>
    )
}

export default CreateJCP