import { useCallback, useState } from 'react'
import { UseCalendar } from '../../Types/hooks';
import calendar from '../constants/calendar';

export default function useCalendar({
    range
}: UseCalendar.Parameters): UseCalendar.Result{
    const [activeDate, setActiveDate] = useState<Date>(range.start);
    const getDaysInMonth = (month: number, year: number) =>{
        return new Date(year, month, 0).getDate();
    }
    const totalDays = getDaysInMonth(
        activeDate.getMonth() + 1,
        activeDate.getFullYear()
    );

    const firstDayIndex = new Date(
        activeDate.getFullYear(),
        activeDate.getMonth(),
    1
    ).getDay();

    const previousMonthDaysToShow = Array.from(
        { length: firstDayIndex },
        (_, i) =>{
            let previousMonth = activeDate.getMonth() === 0 ? 11 :activeDate.getMonth() - 1;
            let previousYear = activeDate.getMonth() === 0 ? activeDate.getFullYear() - 1 : activeDate.getFullYear();
            return {
                day: getDaysInMonth(activeDate.getMonth(), activeDate.getFullYear()) - firstDayIndex + i + 1,
                month: previousMonth,
                year: previousYear
            }
        }
      );

    const nextMonthDaysToShow = Array.from(
        {
          length:
            (firstDayIndex + totalDays) % 7 === 0
              ? 0
              : 7 - ((firstDayIndex + totalDays) % 7)
        },
        (_, i) =>{
            let nextMonth = activeDate.getMonth() === 11 ? 0 :activeDate.getMonth() + 1;
            let nextYear = activeDate.getMonth() === 11 ? activeDate.getFullYear() + 1 : activeDate.getFullYear();
            return {
                day: i + 1,
                month: nextMonth,
                year: nextYear
            }
        }
      );
    
    const daysArray: {
        day: number,
        month: number
        year: number
      }[] = [
        ...previousMonthDaysToShow,
        ...Array.from({ length: totalDays }, (_, i) => {
            return {
                day: i + 1,
                month: activeDate.getMonth(),
                year: activeDate.getFullYear()
            }
        }),
        ...nextMonthDaysToShow
    ];
    const [month, setMonth] = useState<string>(
        calendar.MONTHS[activeDate.getMonth()]
    );
    const [year, setYear] = useState<number>(activeDate.getFullYear());
    const handlePrevMonth = useCallback(() => {
        const prevMonth = new Date(activeDate);
        prevMonth.setMonth(prevMonth.getMonth() - 1);
        setActiveDate(prevMonth);
        setMonth(calendar.MONTHS[prevMonth.getMonth()]);
        setYear(prevMonth.getFullYear());
      }, [activeDate]);
    
    const handleNextMonth = useCallback(() => {
        const nextMonth = new Date(activeDate);
        nextMonth.setMonth(nextMonth.getMonth() + 1);
        setActiveDate(nextMonth);
        setMonth(calendar.MONTHS[nextMonth.getMonth()]);
        setYear(nextMonth.getFullYear());
    }, [activeDate]);
   
    return {
        daysArray: daysArray,
        month: month,
        year: year,
        nextMonthCallback: handleNextMonth,
        prevMonthCallback: handlePrevMonth
    };
}
