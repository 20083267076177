import { useCallback } from "react";
import View from "./enums/View";
import useForm from "../shared/hooks/useForm";
import FormView from "./components/form-view";
import SelectDateView from "./components/select-date-view";
import Loadertemplate from "../Components/templates/Loadertemplate";
import { FormContext } from "../shared/hooks/useFormContext";
import useJcPlanningMeta from "../shared/hooks/useJcPlanningMeta";
import useViewNavigation from "./hooks/useViewNavigation";
import SelectAnchorVillageView from "./components/select-anchor-village-view";
import { CreateJcPlanningContext } from "./hooks/useCreateJcPlanningContext";
import SelectNonAnchorVillagesView from "./components/select-non-anchor-villages-view";
import './index.css';
import { CreateJcPlanningForm } from "./types";
import { useNavigate } from "react-router-dom";
import JcPlanningSummary from "./components/jc-planning-summary";

const initialValues: CreateJcPlanningForm = {
  date: null,
  planType: null,
  anchorVillage: null,
  nonAnchorVillages: null
};
const CreateJcPlanning: React.FC = () => {
  const navigate = useNavigate();
  const { view, goToView } = useViewNavigation();
  const { isLoading, planTypeOptions } = useJcPlanningMeta({});
  const formOperations = useForm<CreateJcPlanningForm>({
    initialValues
  });

  const cancelOperation = useCallback(() => {
    navigate("/base/jcp");
  }, [navigate]);

  return (
    <>
      {isLoading ? (
        <div className="loader-wrapper">
          <Loadertemplate />
        </div>
      ) : (
        <CreateJcPlanningContext.Provider
          value={{ goToView, cancelOperation, planTypeOptions }}
        >
          <FormContext.Provider value={formOperations}>
            {view === View.SUMMARY_VIEW ? (
              <JcPlanningSummary/>
            ) : view === View.FORM_VIEW ? (
              <FormView />
            ) : view === View.SELECT_ANCHOR_VILLAGE_VIEW ? (
              <SelectAnchorVillageView />
            ) : (
              <SelectNonAnchorVillagesView />
            )}
          </FormContext.Provider>
        </CreateJcPlanningContext.Provider>
      )}
    </>
  );
};

export default CreateJcPlanning;
