import React, { FormEvent, useCallback, useContext, useEffect, useRef, useState } from 'react';
import './index.css';
import '../../../CSS/form.css';
import Form from 'react-bootstrap/Form';
import Dropdowntemplate from '../../../templates/Dropdowntemplate';
import Buttontemplate from '../../../templates/Buttontemplate';
import axios from 'axios';
import { ILocation, IOptions, IUserContext } from '../../../../Types/global';
import SearchDropdowntemplate from '../../../templates/SearchDropdowntemplate';
import GeographyTemp from '../../../templates/GeographyTemp';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import Loadertemplate from '../../../templates/Loadertemplate';
import ServerConnectorUtil from '../../../../shared/utils/ServerConnectorUtil';
import ContactService from '../../../../shared/services/ContactService';
import { UserContext } from '../../../HOC/UserContext';

interface enquiryFields{
    id: string,
    name: string,
    options: IOptions[],
    type: string
}
interface IEnquiryData{
    sog: string | null,
    state: string | null,
    taluka: string | null,
    village: string | null,
    payment_mode: string | null,
    exchange: {
        has_exchange: string | null
    },
    contact:{
        name: string | null,
        mobile_number: string | null
    },
    enquiry_category: string | null,
    next_follow_up_in_days: string | null,
    interested_model_family: string | null,
    expected_date_of_delivery_in_days: string | null
}
const i18nTranslated = {
  'personalDetails-label': 'Personal Details',
  'tractorDetails-label': 'Tractor Details',
  'customerType-label': 'Customer type',
  'satisfiedTractor-label': 'Satisfied with tractor',
  'dissatisfaction-label': 'Reason for dissatisfaction',
  'tractor_brand_owned': 'Current brand',
  'tractor_model_owned': 'Current model',
  'yom-label': 'Year of Manufacture',
  'marketing-lead-brand': 'Market Leading Brand',
  'marketing-lead-model': 'Market Leading Model',
  'name-label': 'Name',
  'phone-label': 'Phone number',
  'state-label': 'State',
  'district-label': 'District',
  'taluka-label': 'Taluka',
  'village-label': 'Village',
  'save-button': 'Save',
  'name-placeholder': 'Enter the Name',
  'phone-placeholder': 'Enter the phone number',
  'state-placeholder': 'Add State',
  'district-placeholder': 'Add District',
  'taluka-placeholder': 'Add Taluka',
  'village-placeholder': 'Add Village',
  'yesCheckbox': 'Yes',
  'noCheckbox': 'No',
  'sourceOfUser-label': 'Source of user',
  'no_of_tractors_owned': 'No of Tractors Owned',
  'hp_segment': 'HP Segment',
  'no_of_tractors_village': 'Totel number of tractors in your village',
  'nameEmptyError': "Name can't be empty field",
  'mobileEmptyError': "Mobile number can't be empty field",
  'invaildMobileSyntax': "Enter the valid mobile number",
  'emptyFields': "Field can't be empty",
  'invalid_edd': "Enter a valid EDD",
  'invalid_followup': "Enter a valid Followups",
  'model-family-label': 'Model Family',
  'edd-label': "EDD",
  'stage-label': "Enquiry stage",
  'sog-label': "Source of Generation",
  'category-label': "Enquiry Category",
  'payment-label': "Mode of payment",
  'interested-exchange-label': "Interested in Exchange",
  'remark-label': "Remarks"
}
const regex = {
  phoneRegex: /^\d{10}$/
}
const EnquiryForm: React.FC = () => {
    const [showLoading, setshowLoading] = useState<boolean>(true);
    const [btnLoading, setbtnLoading] = useState<boolean>(false);
    const navigate = useNavigate();
    const currentUser = useContext(UserContext) as IUserContext;
    const [enquiryData, setenquiryData] = useState<IEnquiryData>({
        sog:null,
        state:null,
        taluka:null,
        village:null,
        payment_mode:null,
        exchange: {
            has_exchange:'No',
        },
        contact:{
            name:null,
            mobile_number:null
        },
        enquiry_category:null,
        next_follow_up_in_days:null,
        interested_model_family:null,
        expected_date_of_delivery_in_days:null
    })
    const [errors, seterrors] = useState({
        name: '',
        mobile_number: '',
        state: '',
        taluka: '',
        village: '',
        sog: '',
        brand:'',
        model:'',
        yom:'',
        has_exchange:'',
        payment_mode:'',
        enquiry_category:'',
        next_follow_up_in_days:'',
        interested_model_family:'',
        expected_date_of_delivery_in_days:''
    })
    const geographyEle = useRef<{ getSelectedLocation: () => ILocation; setErrorMsg: (key: string, error: string) => void}>(null);

    const [sogArray, setsogArray] = useState<IOptions[]>([]);
    const [stageArray, setstageArray] = useState<IOptions[]>([]);
    const [IMFArray, setIMFArray] = useState<IOptions[]>([]);
    const [ECArray, setECArray] = useState<IOptions[]>([]);
    const [paymentArray, setpaymentArray] = useState<IOptions[]>([]);
    const [contactName, setContactName] = useState<string>('');

    useEffect(()=>{
      const fetchData = async() => {
        try{
          const metaData = await axios.get('/api/v1/prospect/meta');
          if(metaData.data && metaData.data.data){
            let fieldData = metaData.data.data;
            fieldData.forEach((item:enquiryFields)=>{
              switch(item.name){
                case 'sog':
                  setsogArray(item.options);
                break;
                // case 'field_activity_type':
                //   setfieldActivityArray(item.options);
                // break;
                // case 'stage':
                //   setstageArray(item.options);
                // break;
                case 'interested_model_family':
                  setIMFArray(item.options);
                break;
                case 'enquiry_category':
                  setECArray(item.options);
                  setenquiryData((prev)=> ({...prev, enquiry_category: item.options[0].value}))
                break;
                case 'payment_mode':
                  setpaymentArray(item.options);
                  setenquiryData((prev)=> ({...prev, payment_mode: item.options[1].value}))
                break;
              }
            })
          }
        }catch(error){
            if (error instanceof Error) {
                ServerConnectorUtil.handleServerError(error);
            }
        }finally{
          setshowLoading(false);
        }
      }
      fetchData();
    }, [])
    const handleInputForm = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
      let { name, value} = event.target;
      if(name !== undefined && value !== undefined){
          setenquiryData((prev) => (
              {
                  ...prev,
                  [name]: value ?? null
              }
          ))
          seterrors((prev)=> ({...prev, [name]: ''}));
      }
  }, [enquiryData])
  const removeError = useCallback((key: string)=> {
      seterrors((prev)=> ({...prev, [key]: ''}));
  }, [errors]);
  const validateForm = useCallback(() => {
        let geographyElement = geographyEle.current;
        let geography = geographyElement?.getSelectedLocation();
        let setGeographyError = geographyElement?.setErrorMsg;
        if(enquiryData.contact.mobile_number === '' || enquiryData.contact.mobile_number === null){
            seterrors((prev)=> ({...prev, mobile_number: i18nTranslated.mobileEmptyError}));
            return false;
        }else if(enquiryData.contact.mobile_number !== null && !regex.phoneRegex.test(enquiryData.contact.mobile_number)){
            seterrors((prev)=> ({...prev, mobile_number: i18nTranslated.invaildMobileSyntax}));
            return false;
        }
        if(enquiryData.contact.name === '' || enquiryData.contact.name === null){
            seterrors((prevs) => ({...prevs, name: i18nTranslated.nameEmptyError}));
            return false;
        }

        if(geography !== undefined){
            if(geography.taluka === '' || geography.taluka === null){
                if(setGeographyError)
                    setGeographyError('taluka', i18nTranslated.emptyFields)
                return false;
            }
            if(geography.village === 'undefined' || geography.village === '' || geography.village === null){
                if(setGeographyError)
                    setGeographyError('village', i18nTranslated.emptyFields)
                return false;
            }
        }
        if(enquiryData.enquiry_category === '' || enquiryData.enquiry_category === null){
            seterrors((prev)=> ({...prev, enquiry_category: i18nTranslated.emptyFields}));
            return false;
        }
        if(enquiryData.sog === '' || enquiryData.sog === null){
            seterrors((prev)=> ({...prev, sog: i18nTranslated.emptyFields}));
            return false;
        }
        if(enquiryData.interested_model_family === '' || enquiryData.interested_model_family === null){
          seterrors((prev)=> ({...prev, interested_model_family: i18nTranslated.emptyFields}));
          return false;
        }
        if(enquiryData.next_follow_up_in_days === '' || enquiryData.next_follow_up_in_days === null){
            seterrors((prev)=> ({...prev, next_follow_up_in_days: i18nTranslated.emptyFields}));
            return false;
        }else if((enquiryData.expected_date_of_delivery_in_days !== null && parseInt(enquiryData.next_follow_up_in_days) > parseInt(enquiryData.expected_date_of_delivery_in_days)) || parseInt(enquiryData.next_follow_up_in_days) < 0){
            seterrors((prev)=> ({...prev, next_follow_up_in_days: i18nTranslated.invalid_followup}));
            return false;
        }
        if(enquiryData.expected_date_of_delivery_in_days === '' || enquiryData.expected_date_of_delivery_in_days === null){
            seterrors((prev)=> ({...prev, expected_date_of_delivery_in_days: i18nTranslated.emptyFields}));
            return false;
        }else if(parseInt(enquiryData.expected_date_of_delivery_in_days) > 100){
            seterrors((prev)=> ({...prev, expected_date_of_delivery_in_days: i18nTranslated.invalid_edd}))
            return false;
        }
        // if(enquiryData.stage === '' || enquiryData.stage === null){
        //     seterrors((prev)=> ({...prev, stage: i18nTranslated.emptyFields}));
        //     return false;
        // }
        if(enquiryData.payment_mode === '' || enquiryData.payment_mode === null){
            seterrors((prev)=> ({...prev, payment_mode: i18nTranslated.emptyFields}));
            return false;
        }
       
        return true;
    }, [enquiryData]);
    const handleAddEnquiry = async(event: FormEvent<HTMLFormElement>) => {
      try{
        event.preventDefault();
        if(validateForm()){
            setbtnLoading(true);
            let geographyElement = geographyEle.current;
            let geography: ILocation = geographyElement?.getSelectedLocation() as ILocation;
            enquiryData.taluka = geography.taluka
            enquiryData.village = geography.village
            await axios.post('/api/v1/prospect', enquiryData);
            navigate('/success/enquiry');
        }
      }catch(error){
        if (error instanceof Error) {
            ServerConnectorUtil.handleServerError(error);
        }
      }finally{
        setbtnLoading(false);
      }
    }
    const onChangeMobileNumber = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        let mobileNumber = event.target.value;

        setenquiryData((prev)=> ({...prev, contact: {...prev?.contact, mobile_number: mobileNumber}}));
        if(mobileNumber.length == 10) {
            ContactService.getPagedContacts({
                page:1,
                perPage:1,
                filters:{
                    mobileNumber
                }
            }).then(pagedResut=>{
                if(pagedResut?.data?.length) {
                    setContactName(pagedResut.data[0].name);
                    setenquiryData((prev) => (
                        {...prev, contact:{...prev.contact, name: pagedResut.data[0].name}}
                    ))
                }else{
                    setContactName('');
                }
            })
        }
    },[])
    const noExchange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        if(event.target.checked){
            setenquiryData((prev)=> ({...prev, exchange: {
              has_exchange: 'No'
            }}))
        }
    }, [])

    return (
      <>
        {
            showLoading ? 
                <div className='loader-wrapper'>
                  <Loadertemplate/>
                </div>
            :
                <Form onSubmit={handleAddEnquiry}>
                    <div className='custom-form'>
                        <Form.Group className='custom-form-group'>
                            <Form.Label className='custom-form-label'>Phone number<span className='required-star'>*</span></Form.Label>
                            <Form.Control autoComplete="off" size='lg' className='custom-form-input' placeholder='Phone number' name='phone' value={(enquiryData.contact.mobile_number !== null ? enquiryData.contact.mobile_number : '')} onChange={onChangeMobileNumber} onClick={()=> removeError('mobile_number')}></Form.Control>
                            <div className={classNames('empty-msg', {'visible': (errors.mobile_number !== '')})}>{errors.mobile_number}</div>
                        </Form.Group>
                        <Form.Group className='custom-form-group'>
                            <Form.Label className='custom-form-label'>Name<span className='required-star'>*</span></Form.Label>
                            <Form.Control autoComplete="off" size='lg' className='custom-form-input' name='name' value={(enquiryData.contact.name !== null ? enquiryData.contact.name : '')} onChange={(event: React.ChangeEvent<HTMLInputElement>) => setenquiryData((prev)=> ({...prev, contact: {...prev?.contact, name: event.target.value}}))} placeholder='Name' onClick={()=> removeError('name')}></Form.Control>
                            <div className={classNames('empty-msg', {'visible': (errors.name !== '')})}>{errors.name}</div>
                        </Form.Group>
                        {
                            contactName.length ? (
                            <Form.Group className='custom-form-group' style={{width: '100%'}}>
                            <Form.Label className='custom-form-label'>Contact Name</Form.Label>
                            <Form.Control disabled={true} size='lg' className='custom-form-input' name='name' value={contactName || ''}></Form.Control>
                            </Form.Group>) : <></>
                        }
                        <GeographyTemp ref={geographyEle}/>
                        <Form.Group className='custom-form-group'>
                            <Form.Label className='custom-form-label'>Enquiry Category<span className='required-star'>*</span></Form.Label>
                            <Form.Control as='div' size='lg' className='custom-form-div' onClick={()=> removeError('enquiry_category')}>
                                <SearchDropdowntemplate
                                options={ECArray}
                                isSearchable={true}
                                placeholder='Select Category'
                                defaultValue={{label: enquiryData.enquiry_category as string, value: enquiryData.enquiry_category as string}}
                                onSelect={(option)=> {setenquiryData((prev)=> ({...prev, enquiry_category: option.value}));}}
                                ></SearchDropdowntemplate>
                            </Form.Control>
                            <div className={classNames('empty-msg', {'visible': (errors.enquiry_category !== '')})}>{errors.enquiry_category}</div>
                        </Form.Group>
                        <Form.Group className='custom-form-group'>
                            <Form.Label className='custom-form-label'>Source of Generation<span className='required-star'>*</span></Form.Label>
                            <Form.Control as='div' size='lg' className='custom-form-div' onClick={()=> removeError('sog')}>
                                <SearchDropdowntemplate
                                options={sogArray}
                                isSearchable={true}
                                placeholder='Select the SOG'
                                onSelect={(option)=> {setenquiryData((prev)=> ({...prev, sog: option.value}));}}
                                ></SearchDropdowntemplate>
                            </Form.Control>
                            <div className={classNames('empty-msg', {'visible': (errors.sog !== '')})}>{errors.sog}</div>
                        </Form.Group>
                        <Form.Group className='custom-form-group'>
                            <Form.Label className='custom-form-label'>Model Family<span className='required-star'>*</span></Form.Label>
                            <Form.Control as='div' size='lg' className='custom-form-div' onClick={()=> removeError('interested_model_family')}>
                                <SearchDropdowntemplate
                                options={IMFArray}
                                isSearchable={true}
                                placeholder='Model Family'
                                onSelect={(option)=> {setenquiryData((prev)=> ({...prev, interested_model_family: option.value}));}}
                                ></SearchDropdowntemplate>
                            </Form.Control>
                            <div className={classNames('empty-msg', {'visible': (errors.interested_model_family !== '')})}>{errors.interested_model_family}</div>
                        </Form.Group>
                        <Form.Group className='custom-form-group'>
                            <Form.Label className='custom-form-label'>Follow up date<span className='required-star'>*</span></Form.Label>
                            <Form.Control type='number'  size='lg' placeholder='No of Days' className='custom-form-input' name='next_follow_up_in_days' value={enquiryData.next_follow_up_in_days !== null ? enquiryData.next_follow_up_in_days : ''} onChange={handleInputForm} onClick={()=> removeError('next_follow_up_in_days')}></Form.Control>
                            <div className={classNames('empty-msg', {'visible': (errors.next_follow_up_in_days !== '')})}>{errors.next_follow_up_in_days}</div>
                        </Form.Group>
                        <Form.Group className='custom-form-group'>
                            <Form.Label className='custom-form-label'>EDD<span className='required-star'>*</span></Form.Label>
                            <Form.Control type='number' size='lg' className='custom-form-input' placeholder='No of Days' name='expected_date_of_delivery_in_days' value={enquiryData.expected_date_of_delivery_in_days !== null ? enquiryData.expected_date_of_delivery_in_days : ''} onChange={handleInputForm} onClick={()=> removeError('expected_date_of_delivery_in_days')}></Form.Control>
                            <div className={classNames('empty-msg', {'visible': (errors.expected_date_of_delivery_in_days !== '')})}>{errors.expected_date_of_delivery_in_days}</div>
                        </Form.Group>
                        <Form.Group className='custom-form-group'>
                            <Form.Label className='custom-form-label'>Interested in Exchange?<span className='required-star'>*</span></Form.Label>
                            <div className='exchange-tractor-permission'>
                                <Form.Check
                                inline
                                label="Yes"
                                name="yesOrNo"
                                type="radio"
                                checked={enquiryData.exchange.has_exchange === 'Yes'}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>)=> {setenquiryData((prev)=> ({...prev, exchange: {...prev?.exchange, has_exchange: (event.target.checked) ? 'Yes' : 'No'}}));}}
                                />
                                <Form.Check
                                inline
                                label="No"
                                name="yesOrNo"
                                type="radio"
                                checked={enquiryData.exchange.has_exchange === 'No'}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>)=> {setenquiryData((prev)=> ({...prev, exchange: {...prev?.exchange, has_exchange: (event.target.checked) ? 'No' : 'Yes'}}));; noExchange(event)}}

                                />
                            </div>
                        </Form.Group>
                        <Form.Group className='custom-form-group'>
                            <Form.Label className='custom-form-label'>Mode of payment<span className='required-star'>*</span></Form.Label>
                            <Form.Control as='div' size='lg' className='custom-form-div dropdown-form-div'>
                                <Dropdowntemplate 
                                    defaultValue={enquiryData.payment_mode as string}
                                    dropdownMenu={paymentArray}
                                    onSelect={(value)=> {setenquiryData((prev)=> ({...prev, payment_mode: value}));}}
                                ></Dropdowntemplate>
                            </Form.Control>
                        </Form.Group>
                        <Form.Group className='custom-form-group'>
                            <Form.Label className='custom-form-label'>Dealer Name</Form.Label>
                            <Form.Control disabled={true} size='lg' className='custom-form-input' name='name' value={currentUser.user.dealer_name || ''}></Form.Control>
                        </Form.Group>
                        <Form.Group className='custom-form-group'>
                            <Form.Label className='custom-form-label'>Dealer Code</Form.Label>
                            <Form.Control disabled={true} size='lg' className='custom-form-input' name='name' value={currentUser.user.dealer_code || ''}></Form.Control>
                        </Form.Group>
                    </div>
                    <div className='custom-form-btn'>
                        <Buttontemplate type='primary' buttonWidth='100%'>
                            {
                                btnLoading ? 
                                <div className='button-loader'>
                                    <Loadertemplate type="button" />
                                </div>
                                :
                                "Add enquiry"
                            }
                        </Buttontemplate>
                    </div>
                </Form>
        }
      </>
    )
}

export default EnquiryForm;