import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

import { Toaster } from "react-hot-toast";
import { Navigate, Route, Routes } from "react-router-dom";

import Components from "./Components";
import CreateJcPlanning from "./create-jc-planning";
import CreateJcPlanningForm from "./CreateJcPlanningForm";
import CreateJcPlanningSelectAnchorVillage from "./CreateJcPlanningSelectAnchorVillage";
import CreateJcPlanningSelectDate from "./CreateJcPlanningSelectDate";
import CreateJcPlanningSelectNonAnchorVillagesWrapper from "./CreateJcPlanningSelectNonAnchorVillages";
import JcPlanningSummary from "./jc-planning-summary";
import CheckInCategories from "./check-in-categories";
import CheckInJcp from "./check-in-jcp";
import CheckInJcps from "./check-in-jcps";
import SuccessJcpCheckIn from "./shared/components/SuccessJcpCheckIn";
import AppUrlConstants from "./shared/constants/AppUrlConstants";
import SuccessApproval from "./shared/components/SuccessJcpCheckIn";
import MPCTab from "./MPCTab";
import MDCTab from "./MDCTab";

function App() {
  return (
    <>
      <Toaster
        position="top-center"
        toastOptions={{
          duration: 3000,
        }}
      />
      <Routes>
        <Route path="" element={<Components.OnLoadRender />}>
          <Route path="/login" element={<Components.Login />}></Route>
          <Route path="/base" element={<Components.MainLayout />}>
            <Route path="home" element={<Components.DspHome />}></Route>
            <Route path="jcp" element={<Components.DspJcp />}></Route>
            <Route path="enquiries" element={<Components.Enquiries />}>
              <Route path="enquiry" element={<Components.EnquiryTab />}></Route>
              <Route
                path="tractoruser"
                element={<Components.TUDBTab />}
              ></Route>
            </Route>
            <Route path="dashboard" element={<Components.Dashboard />}></Route>
            <Route path="" element={<Navigate to="home" replace />} />
          </Route>
          <Route path="/innerbase" element={<Components.MainLayout />}>
            <Route
              path=":parentPage/addenquiry"
              element={<Components.EnquiryForm />}
            ></Route>
            <Route path="addtudb" element={<Components.TUDBForm />}></Route>
            <Route path="menu" element={<Components.Menu />}></Route>
            <Route path="prospects" element={<Components.Prospects />}></Route>
            <Route path="followup" element={<Components.Tasks />}></Route>
            <Route path="overdue" element={<Components.OverdueList />}></Route>
            <Route
              path="plannedvisit"
              element={<Components.PlannedVisitList />}
            ></Route>
            <Route path="prospect/:id" element={<Components.Prospect />}></Route>
            <Route path="prospect/:id/edit" element={<Components.Prospect />}></Route>
            <Route path="tudb/:id" element={<Components.TUDB />}></Route>
            <Route path="tudb/:id/edit" element={<Components.TUDB />}></Route>
            <Route
              path={
                AppUrlConstants.INNER_BASE +
                AppUrlConstants.CHECK_IN +
                AppUrlConstants.CATEGORIES
              }
              element={<CheckInCategories />}
            />

            <Route
              path={
                AppUrlConstants.INNER_BASE +
                AppUrlConstants.JCPS +
                AppUrlConstants.SUMMARY
              }
              element={<JcPlanningSummary />}
            />

            <Route
              path={
                AppUrlConstants.INNER_BASE +
                AppUrlConstants.CHECK_IN +
                AppUrlConstants.JCPS
              }
              element={<CheckInJcps />}
            />

            <Route
              path={
                AppUrlConstants.INNER_BASE +
                AppUrlConstants.CHECK_IN +
                AppUrlConstants.JCPS +
                AppUrlConstants.JCP_ID
              }
              element={<CheckInJcp />}
            />
            <Route
              path={
                AppUrlConstants.INNER_BASE +
                AppUrlConstants.CHECK_IN +
                AppUrlConstants.ALL_PATH
              }
              element={
                <Navigate
                  to={
                    AppUrlConstants.INNER_BASE +
                    AppUrlConstants.CHECK_IN +
                    AppUrlConstants.CATEGORIES
                  }
                  replace
                />
              }
            />
            <Route
              path={
                AppUrlConstants.INNER_BASE +
                AppUrlConstants.CHECK_IN +
                AppUrlConstants.ALL_PATH
              }
              element={
                <Navigate
                  to={
                    AppUrlConstants.INNER_BASE +
                    AppUrlConstants.CHECK_IN +
                    AppUrlConstants.CATEGORIES
                  }
                  replace
                />
              }
            />
            <Route
              path="village"
              element={<Components.VillageDetails />}
            ></Route>
            <Route path="visit" element={<Components.VisitDetails />}></Route>
            <Route path="menu/mpcmdc" element={<Components.MPCMDCTabs />}>
              <Route path="mpc" element={<MPCTab />}></Route>
              <Route path="mdc" element={<MDCTab />}></Route>
            </Route>
            <Route
              path={
                AppUrlConstants.INNER_BASE +
                AppUrlConstants.MENU +
                AppUrlConstants.TRACTOR_USER_DATABASES
              }
              element={<Components.TractorMaster />}
            ></Route>
            <Route
              path="menu/villages"
              element={<Components.VillageMaster />}
            ></Route>
            <Route
              path="leaderboard"
              element={<Components.Leaderboard />}
            ></Route>
            <Route
              path={AppUrlConstants.CREATE_JCP.BASE_NAME}
              element={<CreateJcPlanning />}
            >
              {/* <Route
                path={AppUrlConstants.CREATE_JCP.SELECT_DATE}
                element={<CreateJcPlanningSelectDate />}
              />
              <Route
                path={AppUrlConstants.CREATE_JCP.FORM}
                element={<CreateJcPlanningForm />}
              />
              <Route
                path={AppUrlConstants.CREATE_JCP.SELECT_ANCHOR_VILLAGE}
                element={<CreateJcPlanningSelectAnchorVillage />}
              />
              <Route
                path={AppUrlConstants.CREATE_JCP.SELECT_NON_ANCHOR_VILLAGES}
                element={<CreateJcPlanningSelectNonAnchorVillagesWrapper />}
              /> */}
            </Route>
          </Route>
          <Route path="/success" element={<Components.Successtemplate />}>
            <Route path="enquiry" element={<Components.EnquirySuccess />} /> 
            <Route path="tudb" element={<Components.TudbSuccess />}></Route>
            <Route path="jcpcreate" element={<Components.CreateJCP />}></Route>
            <Route
              path={AppUrlConstants.SUCCESS + AppUrlConstants.CHECK_IN}
              element={<SuccessJcpCheckIn />}
            />
            <Route
              path="approval"
              element={<SuccessApproval />}
            />
          </Route>
          <Route path="" element={<Navigate to="/base" replace />} />
          <Route path="*" element={<Navigate to="/base" replace />} />
        </Route>
      </Routes>
    </>
  );
}

export default App;
