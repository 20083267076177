import React, { useCallback, useState } from 'react';
import CallContactTemp from '../Components/templates/CallContactTemp';
import WhatsappContactTemp from '../Components/templates/WhatsappContactTemp';
import RecordCollection from '../shared/components/RecordCollection';
import usePagedContacts from '../shared/hooks/usePagedContacts';
import ServerConnectorUtil from '../shared/utils/ServerConnectorUtil';
import { IOptions } from '../Types/global';
import { UsePagedContactsHook } from '../Types/hooks';
import './index.css';
import useScroll from '../shared/hooks/useScroll';

const allDropDownOptions = [
  { value: 'name', label: 'Name' },
  { value: 'mobileNumber', label: 'Mobile Number' }
];

const MDCTab: React.FC = () => {
  const [value, setValue] = useState('');
  const [searchKey, setSearchKey] = useState<string>('name');
  const [searchLabel, setSearchLabel] = useState<string>(
    allDropDownOptions[0].label.toLowerCase()
  );
  const [initialFilters] = useState({
    type: 'MDC'
  });

  const {
    data,
    isLoading,
    setFilters,
    updateFilterKey,
    isLoadingMore,
    loadMoreRecords
  } = usePagedContacts({
    page: 1,
    perPage: 200,
    filters: initialFilters,
    onFailure: ServerConnectorUtil.handleServerError
  });

  const onScrollEventListener = useScroll(loadMoreRecords);

  const onNameChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      setValue(value);
      updateFilterKey(searchKey as keyof UsePagedContactsHook.Filters, value);
    },
    [searchKey, updateFilterKey]
  );

  const onDropDownChange = useCallback((value: IOptions) => {
    setSearchKey(value.value);
    setValue('');
    setSearchLabel(value.label.toLowerCase());
    setFilters(initialFilters);
  }, [initialFilters, setFilters]);
  return (
    <>
      <RecordCollection>
        <RecordCollection.SearchBox
          value={value}
          onChange={onNameChange}
          placeholder={'Search by ' + searchLabel}
          initialDropDownValue={allDropDownOptions[0]}
          dropDownOptions={allDropDownOptions}
          onDropDownChange={onDropDownChange}
        />
        <RecordCollection.List onScroll={onScrollEventListener}>
          {isLoading ? (
            <RecordCollection.ListLoader />
          ) : data.length === 0 ? (
            <RecordCollection.ListEmpty />
          ) : (
            data.map((item) => (
              <RecordCollection.ListItemWrapper key={item.id}>
                <RecordCollection.ListItem>
                  <RecordCollection.ListItemTitle title={item.name} />
                  <RecordCollection.ListItemKeyValue
                    label="Village"
                    value={item.village || '--'}
                  />
                  <RecordCollection.ListItemKeyValue
                    label="Influencer Type"
                    value={item.influencer_type || '--'}
                  />
                  <RecordCollection.ListItemKeyValue
                    label="Mobile Number"
                    value={`+91 ${item.mobile_number}`}
                  />
                </RecordCollection.ListItem>
                <RecordCollection.ListAction>
                  <CallContactTemp
                    className="prospect-contact"
                    phoneNumber={item.mobile_number}
                  />
                  <WhatsappContactTemp phoneNumber={item.mobile_number} />
                </RecordCollection.ListAction>
              </RecordCollection.ListItemWrapper>
            ))
          )}
          {(!isLoading && isLoadingMore) && <RecordCollection.LoadMoreLoader />}
        </RecordCollection.List>
      </RecordCollection>
    </>
  );
};

export default MDCTab;
