import { Model } from "../../Types/global";

export default class JcPlanningUtil {
  public static getDateColorCodeMap(
    data: Array<Model.JcPlanning>
  ): Record<string, Array<string>> {
    return data.reduce((prev, curr) => {
      if (!prev[curr.date]) {
        prev[curr.date] = [curr.village.color_code];
      } else {
        prev[curr.date].push(curr.village.color_code);
      }

      return prev;
    }, {} as Record<string, Array<string>>);
  }
}
