import React, { useCallback, useEffect, useRef, useState } from 'react'
import { IPageResponse, IProspect } from '../../../../Types/global';
import DatalistServices from '../../../../shared/services/DatalistServices';
import api_request from '../../../../shared/constants/api_request';
import RequestMethod from '../../../../shared/enums/RequestMethod';
import ServerConnectorUtil from '../../../../shared/utils/ServerConnectorUtil';
import dateHooks from '../../../Hooks/dateHooks';
import ListOfDetailsTemp from '../../../templates/ListOfDetailsTemp';
import Prospecttemplate from '../../../templates/Prospecttemplate';
import { ArrowPrimarySvg } from '../../../../icons/ArrowPrimarySvg';
import CallContactTemp from '../../../templates/CallContactTemp';
import WhatsappContactTemp from '../../../templates/WhatsappContactTemp';
import AppUrlConstants from '../../../../shared/constants/AppUrlConstants';
import { Link } from 'react-router-dom';

const OverdueList: React.FC = () => {
    const [overdueHps, setoverdueHps] = useState<IProspect[]>([]);
    const [showLazyLoader, setshowLazyLoader] = useState<boolean>(false);
    const [moreResponse, setmoreResponse] = useState<boolean>(false);
    const [searchContent, setsearchContent] = useState<string>('');
    let page = 1;
    const pagniationAbortRef = useRef<AbortController | null>(null);
    const [showEmptyScreen, setshowEmptyScreen] = useState<boolean>(false);
    useEffect(()=> {
        setshowLazyLoader(true);
        let onOverdueAbort = new AbortController();
        getOverdues(onOverdueAbort.signal);
        return()=>{
            onOverdueAbort.abort();
            pagniationAbortRef.current?.abort();
        }
    }, [])
    const getOverdues = useCallback(async(signal: AbortSignal, pagination: boolean = false, value?: string | undefined)=> {
        try{
            page = moreResponse ? page + 1 : page;
            let payLoad: {
                enquiry_classification: string
                contact_name?: string
            } = {
                enquiry_classification: "Overdue Hot Prospect"
            }
            if(value !== undefined){
                payLoad.contact_name = value;
            }
            let responseData = await DatalistServices.getAllListData({
                url: api_request.PROSPECT_DATA,
                method: RequestMethod.POST,
                params: {
                    page: page,
                    perPage: 50
                },
                signal: signal,
                body: payLoad
            })
            let {data, page_info} = responseData as IPageResponse<IProspect>;
            setmoreResponse(page_info.hasMore);
            let overdueData: IProspect[] = data.map((item: IProspect)=> {
                if(item.contact !== null){
                    item.contact.name = item.contact.name !== null ? item.contact.name : '--';
                    item.contact.mobile_number = item.contact.mobile_number !== null ? item.contact.mobile_number : '--';
                    item.contact.village = item.contact.village !== null ? item.contact.village : '--';
                }
                item.color_code = item.color_code !== null ? item.color_code : '--';
                item.edd = dateHooks.formatDate(item.edd, 'DD MMM YYYY');
                return item;
            })
            setoverdueHps(overdueData);
            setshowEmptyScreen(overdueData.length ===  0);
            setshowLazyLoader(false);
        }catch(error: any){
            ServerConnectorUtil.handleServerError(error);
        }
    }, []);
    const overduePagination = useCallback(async(setShowLoader: React.Dispatch<React.SetStateAction<boolean>>) => {
        let controllar = new AbortController();
        pagniationAbortRef.current = controllar;
        await getOverdues(pagniationAbortRef.current.signal, true, searchContent !== '' ? searchContent : undefined);
        setShowLoader(false);
    }, [])
    const overdueAutocomplete = useCallback(async(value: string, signal: AbortSignal)=> {
        setsearchContent(value);
        await getOverdues(signal, false, value !== '' ? value : undefined)
    }, [])
    const onRenderCards = (): JSX.Element => {
        return <div className=''>
            {
                overdueHps.map((item)=> (
                    <Link to={`${AppUrlConstants.INNER_BASE}${AppUrlConstants.PROSPECT}/${item.id}?title=prospect_details`}>
                        <Prospecttemplate color_code={item.color_code}>
                            <div className='overdueHP-detail'>
                                <div className='overdueHP-name fCenter'>
                                    <span className='name'>{item.contact.name}</span>
                                    <span className='followupNavigate'>
                                        <ArrowPrimarySvg/>
                                    </span>
                                </div>
                                <div className='overdueHP-edd-detail'>EDD - {item.edd}</div>
                                <div className='overdueHP-location'>{item.contact.village}</div>
                                <div className='overdueHP-truckModel'>Model interested - {item.interested_model_family}</div>
                            </div>
                            <div className='overdueHP-social-contact fCenter fJustifyFD'>
                                <CallContactTemp className='prospect-contact' phoneNumber={item.contact.mobile_number}/>
                                <WhatsappContactTemp phoneNumber={item.contact.mobile_number}/>
                            </div>
                        </Prospecttemplate>
                    </Link>
                ))
            }
        </div>
    }
    return (
        <ListOfDetailsTemp
            suggestionsRender={onRenderCards}
            fetchApiRequest={overdueAutocomplete}
            paginationApiRequest={moreResponse ? overduePagination : null}
            placeholder='Search'
            showLoader={showLazyLoader}
            showEmptyScreen={showEmptyScreen}
            searchable={true}
        />
    )
}

export default OverdueList