import {
  Framework,
  IFields,
  ITUDBStat,
  ITudbUserData,
  Model
} from '../../Types/global';
import { TractorUserDatabaseServiceParameters } from '../../Types/parameters';
import api_request from '../constants/api_request';
import RequestMethod from '../enums/RequestMethod';
import RequestHandler from '../handlers/RequestHandler';

export default class TractorUserDatabaseService {
  public static getPagedTractorUserDatabase({
    page,
    perPage,
    filters,
    signal
  }: TractorUserDatabaseServiceParameters.GetPagedTractorUserDatabases.Parameters): TractorUserDatabaseServiceParameters.GetPagedTractorUserDatabases.ReturnType {
    const handler = new RequestHandler(
      '/api/v1/tractor-user-databases',
      RequestMethod.GET
    );

    handler.addParam('page', page);
    handler.addParam('perPage', perPage);

    if (filters.name) {
      handler.addParam('name', filters.name);
    }
    if (filters.type) {
      handler.addParam('type', filters.type);
    }
    if (filters.village_name) {
      handler.addParam('village_name', filters.village_name);
    }
    if (filters.mobile_number) {
      handler.addParam('mobile_number', filters.mobile_number);
    }
    if (filters.tractor_model) {
      handler.addParam('tractor_model', filters.tractor_model);
    }
    if(filters.village_color_code) {
      handler.addParam("village_color_code", filters.village_color_code)
    }
    if(filters.is_satisfied_with_his_tractor) {
      handler.addParam("is_satisfied_with_his_tractor", filters.is_satisfied_with_his_tractor)
    }
    if(filters.created_time_start_value){
      handler.addParam("created_time_start_value", filters.created_time_start_value);
    }
    if(filters.created_time_end_value){
      handler.addParam("created_time_end_value", filters.created_time_end_value)
    }
    if (signal) {
      handler.setAbortSignal(signal);
    }

    return handler
      .execute()
      .then(
        (result) =>
          result.data as TractorUserDatabaseServiceParameters.GetPagedTractorUserDatabases.ReturnType
      );
  }
  public static getTudbStat = ({
    url,
    method,
    params,
    body,
    signal
  }: {
    url: string;
    method: RequestMethod;
    params?: any;
    body?: unknown;
    signal: AbortSignal;
  }): Promise<Framework.IResult<ITUDBStat>> => {
    let reqHandler = new RequestHandler(url, method);
    if (params) {
      for (const key in params) {
        if (Object.prototype.hasOwnProperty.call(params, key)) {
          reqHandler.addParam(key, params[key]);
        }
      }
    }
    if (body) {
      reqHandler.setBody(body);
    }
    reqHandler.setAbortSignal(signal);
    return reqHandler
      .execute()
      .then((response) => response.data as Framework.IResult<ITUDBStat>);
  };
  public static getTudbDetails = ({
    url,
    method,
    signal
  }: {
    url: string;
    method: RequestMethod;
    signal: AbortSignal;
  }): Promise<Framework.IResult<ITudbUserData>> => {
    let reqHandler = new RequestHandler(url, method);
    reqHandler.setAbortSignal(signal);
    return reqHandler
      .execute()
      .then((response) => response.data as Framework.IResult<ITudbUserData>);
  };
  public static getTudbMeta = ({
    url,
    method,
    signal
  }: {
    url: string;
    method: RequestMethod;
    signal: AbortSignal;
  }) => {
    let reqHandler = new RequestHandler(url, method);
    reqHandler.setAbortSignal(signal);
    return reqHandler
      .execute()
      .then((response) => response.data as Framework.IResult<IFields[]>);
  };
  public static updateTUDBData = ({
    id,
    body
  }: {
    id: string;
    body: {
      type: string;
      source: string;
      village: string;
      taluka: string;
      tractor: {
        brand: string;
        model: string;
        hp_segment: string;
        purchased_year: string;
      };
      no_of_tractors_owned: string;
      total_no_of_tractors_in_the_village: string;
      is_satisfied_with_his_tractor: string;
      reason_of_dissatisfaction: string;
    };
  }): Promise<Framework.IResult<ITudbUserData>> => {
    let reqHandler = new RequestHandler(
      api_request.TUDB_DETAILS + id,
      RequestMethod.PUT
    );
    reqHandler.setBody(body);
    return reqHandler
      .execute()
      .then((response) => response.data as Framework.IResult<ITudbUserData>);
  };
}
