import axios from "axios";
import ServerConnectorUtil from "../../shared/utils/ServerConnectorUtil";

interface IVillage{
    "id": string | null,
    "name": string | null,
    "type": string | null,
    "tiv": string | null,
    "state": string | null,
    "taluka": string | null,
    "district": string | null,
    "latitude": string | null,
    "longitude": string | null,
    "color_code": string | null
}
const getTaluka = async()=>{
    try{
        let talukas = await axios.get(`/api/v1/geography/taluks`);
        let talukaData = talukas.data.data.map((item: string)=>{
            return {value: item, label: item}
        })
        return talukaData;
    }catch(error){
        if (error instanceof Error) {
            ServerConnectorUtil.handleServerError(error);
        }
    }
}
const getVillage = async(taluka: string)=> {
    try{
        let villages = await axios.get(`/api/v1/geography/villages?taluka=${taluka}&page=1&perPage=2000`);
        let villageData = villages.data.data.map((item: IVillage)=> {
            return {
                id: item.id,
                name: item.name
            }
        })
        return villageData;
    }catch(error){
        if (error instanceof Error) {
            ServerConnectorUtil.handleServerError(error);
        }
    }
}
const getGeography = {
    getTaluka,
    getVillage
}

export default getGeography;