import styles from "./styles.module.css";

type CheckboxProps = {
  name?: string;
  value?: string;
  checked?: boolean;
  disabled?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

const Checkbox: React.FC<CheckboxProps> = ({
  name,
  value,
  checked,
  disabled,
  onChange
}) => {
  return (
    <input
      type="checkbox"
      name={name}
      value={value}
      className={styles["input"]}
      checked={checked}
      disabled={disabled}
      onChange={onChange}
    />
  );
};

export default Checkbox;
