import JcPlanningUtil from "../utils/JcPlanningUtil";
import useEffectAfterMount from "./useEffectAfterMount";
import DateTimeProcessor from "../processors/DateTimeProcessor";
import JcPlanApprovalStatus from "../enums/JcPlanApprovalStatus";

import { UserJcPlanningCalendarHandlerHook } from "../../Types/hooks";

export default function useJcPlanningCalenderHandler({
  endDate,
  startDate,
  jcPlannings,
  jcPlanApproval,
  setExcludedDates,
  setDateColorCodeMap
}: UserJcPlanningCalendarHandlerHook.Parameters) {
  useEffectAfterMount(() => {
    const allDates = DateTimeProcessor.getDatesBetweenRange(startDate, endDate);
    if (
      jcPlanApproval &&
      jcPlanApproval.status !== JcPlanApprovalStatus.IN_DRAFT
    ) {
      setExcludedDates(allDates);
    } else {
      const currentDate = Date.now();
      const excludedDates = allDates.filter(
        (obj) => !DateTimeProcessor.isDateAfter(obj.getTime(), currentDate)
      );
      setExcludedDates(excludedDates);
    }
  }, [endDate, startDate, jcPlanApproval]);

  useEffectAfterMount(() => {
    setDateColorCodeMap(JcPlanningUtil.getDateColorCodeMap(jcPlannings));
  }, [jcPlannings]);

  return null;
}
