import { useEffect, useState } from "react";

import JcPlanningService from "../services/JcPlanningService";

import { IJCPVillageStats } from "../../Types/global";
import { UseJcPlanningByIdHook } from "../../Types/hooks";

export default function useJcPlanningById({
  id,
  onFailure,
  additionalFields
}: UseJcPlanningByIdHook.Parameters): UseJcPlanningByIdHook.Result {
  const [data, setData] = useState<IJCPVillageStats | undefined>();
  const [error, setError] = useState<any>();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    setIsLoading(true);
    JcPlanningService.getJcPlanningById({
      id,
      additionalFields
    })
      .then((data) => {
        setData(data);
        setIsLoading(false);
      })
      .catch((err) => {
        setError(err);
        if (onFailure) {
          onFailure.apply(null, [err]);
        }
      });
  }, [id, onFailure, additionalFields]);

  return {
    data,
    error,
    isLoading
  };
}
