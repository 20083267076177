import toast from 'react-hot-toast';
import { useCallback } from 'react';

interface InfoToastProps {
  message: string;
  id: string;
}

const InfoToast: React.FC<InfoToastProps> = ({ message, id }) => {
  const dismissToast = useCallback(() => {
    toast.remove(id);
  }, [id]);

  return (
    <div className="toast info">
      <div className="toast-icon-block">
        <div className="toast-icon"></div>
      </div>
      <p>{message}</p>
      <div className="toast-close-icon" onClick={dismissToast}>
        ×
      </div>
    </div>
  );
};

export default InfoToast;
