import "./styles.css";
import headerStyles from "./header.module.css";
import "react-datepicker/dist/react-datepicker.css";

import classNames from "classnames";
import React, { useCallback } from "react";
import DatePicker, { ReactDatePickerCustomHeaderProps } from "react-datepicker";

import DateFormat from "../../enums/DateFormat";
import DateTimeProcessor from "../../processors/DateTimeProcessor";

type JcPlanningCalendarProps = {
  maxDate?: Date;
  openToDate: Date;
  excludedDates?: Array<Date>;
  jcPlanApprovalStatus: string;
  onDateSelect: (date: Date | null) => void;
  onMonthChange: (month: string, year: string) => void;
  dateColorCodeMap: Record<string, Array<string>>;
};

const CustomHeader: React.FC<
  ReactDatePickerCustomHeaderProps &
    Pick<JcPlanningCalendarProps, "jcPlanApprovalStatus">
> = ({
  date,
  increaseMonth,
  decreaseMonth,
  jcPlanApprovalStatus,
  nextMonthButtonDisabled
}) => {
  return (
    <div className={headerStyles["main-container"]}>
      <div className={headerStyles["info-container"]}>
        <h6 className={headerStyles["month-year-label"]}>
          {DateTimeProcessor.format(date.getTime(), DateFormat.MONTH_YEAR)}
        </h6>
        <p
          className={classNames(
            headerStyles["badge"],
            headerStyles[
              jcPlanApprovalStatus.toLowerCase().replaceAll(" ", "-")
            ]
          )}
        >
          {jcPlanApprovalStatus}
        </p>
      </div>
      <div className={headerStyles["actions-container"]}>
        <button className={headerStyles["action-button"]}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            className={headerStyles["icon"]}
            onClick={decreaseMonth}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M15.75 19.5 8.25 12l7.5-7.5"
            />
          </svg>
        </button>
        <button
          className={headerStyles["action-button"]}
          disabled={nextMonthButtonDisabled}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            className={headerStyles["icon"]}
            onClick={increaseMonth}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="m8.25 4.5 7.5 7.5-7.5 7.5"
            />
          </svg>
        </button>
      </div>
    </div>
  );
};

const JcPlanningCalendar: React.FC<JcPlanningCalendarProps> = ({
  maxDate,
  openToDate,
  onDateSelect,
  onMonthChange,
  excludedDates,
  dateColorCodeMap,
  jcPlanApprovalStatus
}) => {
  const renderDayContents = useCallback(
    (day: number, date: Date): React.ReactNode => {
      const formatedDate = DateTimeProcessor.format(date.getTime());
      const colorCodes = Array.from(
        new Set(dateColorCodeMap[formatedDate] || [])
      );
      return (
        <div className="custom-day-container">
          <p className="day">{day}</p>
          {colorCodes.map((color) => (
            <div
              key={formatedDate + color}
              className={classNames("hr", color)}
            />
          ))}
        </div>
      );
    },
    [dateColorCodeMap]
  );

  const renderCustomHeader = useCallback(
    (props: ReactDatePickerCustomHeaderProps): React.JSX.Element => {
      return (
        <CustomHeader {...props} jcPlanApprovalStatus={jcPlanApprovalStatus} />
      );
    },
    [jcPlanApprovalStatus]
  );

  const __onMonthChange = useCallback(
    (date: Date) => {
      onMonthChange(
        DateTimeProcessor.format(date?.getTime(), DateFormat.MONTH),
        DateTimeProcessor.format(date.getTime(), DateFormat.YEAR)
      );
    },
    [onMonthChange]
  );
  return (
    <div className={"jc-planning-calendar"}>
      <DatePicker
        inline
        selected={null}
        maxDate={maxDate}
        openToDate={openToDate}
        onSelect={onDateSelect}
        useWeekdaysShort
        excludeDates={excludedDates}
        onMonthChange={__onMonthChange}
        renderCustomHeader={renderCustomHeader}
        renderDayContents={renderDayContents}
        disabledKeyboardNavigation
      />
    </div>
  );
};

export default JcPlanningCalendar;
