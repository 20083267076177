import React, { useCallback, useEffect, useState } from 'react';
import { IOptions } from '../../../Types/global';
import Select, { SingleValue } from 'react-select';
import classNames from 'classnames';
import './index.css';

interface ISearchDropdownProps{
    name?:string
    options: IOptions[],
    defaultValue?: IOptions | null,
    placeholder?: string,
    isSearchable?: boolean,
    isDisabled?: boolean,
    isClearable?: boolean,
    isLoading?: boolean,
    menuPosition?:'fixed'|'absolute'
    classes?: string,
    onSelect?: {
        (option: IOptions): void
    },
    onDropdownChange?:(name:string,value:IOptions|null)=>void
}

const SearchDropdowntemplate: React.FC<ISearchDropdownProps> = ({name,options, defaultValue, placeholder, isSearchable=false, isDisabled=false, isClearable=false, isLoading=false,menuPosition, classes, onSelect,onDropdownChange}) => {
    const [selectedOption, setselectedOption] = useState<IOptions | null>(null);
    useEffect(()=>{
        if(defaultValue !== undefined) {
            setselectedOption(defaultValue)
        }
    }, [defaultValue])
    const handleChange = useCallback(((option: SingleValue<IOptions>) => {
        if(option){
            setselectedOption(option);
            if(onSelect)
                onSelect(option);
        }
        if(name && onDropdownChange){
            onDropdownChange(name,option)
        }
    }),[name,onSelect,onDropdownChange])
    return (
        <Select
            options={options}
            menuPosition={menuPosition||'absolute'}
            menuPlacement='auto'
            className={classNames('defaultSelectButton', classes && classes)}
            isClearable={isClearable}
            isDisabled={isDisabled}
            placeholder={placeholder}
            isSearchable={isSearchable}
            isLoading={isLoading}
            classNamePrefix='default-select'
            value={selectedOption}
            onChange={handleChange}
        />
    )
}

export default SearchDropdowntemplate;