import React, { useCallback, useContext, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import RecordCollection from "../../../shared/components/RecordCollection";
import AppUrlConstants from "../../../shared/constants/AppUrlConstants";
import usePagedProspects from "../../../shared/hooks/usePagedProspects";
import { IOptions, IUserContext } from "../../../Types/global";
import { UsePagedProspectsHook } from "../../../Types/hooks";
import CallContactTemp from "../../templates/CallContactTemp";
import WhatsappContactTemp from "../../templates/WhatsappContactTemp";
import "./index.css";
import useScroll from "../../../shared/hooks/useScroll";
import dateHooks from "../../Hooks/dateHooks";
import DateFormat from "../../../shared/enums/DateFormat";
import useMeta from "../../../shared/hooks/useMeta";
import EnquiryStages from "../../../shared/enums/EnquiryStages";
import { UserContext } from "../../HOC/UserContext";

const i18nTranslated = {
  prospectId: "Prospect ID",
  prospectListLabel: "Prospects List",
  typeLabel: "Type",
  statusLabel: "Status",
  modelInterestLabel: "Model Interested",
  eddLabel: "EDD",
  contactNameSearch: "Search by contact name",
  stageLabel: "Stage",
  talukaLabel: "Taluka"
};

const allDropDownOptions = [
  { value: "contactName", label: "Name" },
  { value: "contactMobileNumber", label: "Mobile Number" },
  { value: "stages", label: "Stage" },
  { value: "prospectId", label: "Prospect ID" }
];

const stages = [
  EnquiryStages.F1_STAGE,
  EnquiryStages.F2_STAGE,
  EnquiryStages.F3_STAGE,
  EnquiryStages.F4_STAGE,
  EnquiryStages.F5_STAGE,
  EnquiryStages.DELIVERY_STAGE,
  EnquiryStages.SALES_LOST_STAGE,
  EnquiryStages.SALES_DROPPED_STAGE
];

const Prospects: React.FC = () => {
  const [searchParams] = useSearchParams();
  const currentUser = useContext(UserContext) as IUserContext;

  const [value, setValue] = useState("");
  const [searchKey, setSearchKey] = useState<string>("contactName");
  const [searchLabel, setSearchLabel] = useState<string>(
    allDropDownOptions[0].label.toLowerCase()
  );
  const [initialFilters] = useState<UsePagedProspectsHook.Filters>({
    enquiryClassification:
      searchParams.get("enquiry_classification") || undefined,
    stages: searchParams.get("stages")
      ? (searchParams.get("stages") as string).split(",")
      : [],
    sogs: searchParams.get("sogs")
      ? (searchParams.get("sogs") as string).split(",")
      : [],
    eddEndValue: searchParams.get("edd_end_value") || undefined,
    eddStartValue: searchParams.get("edd_start_value") || undefined,
    createdTimeStartValue:
      searchParams.get("created_time_start_value") || undefined,
    createdTimeEndValue:
      searchParams.get("created_time_end_value") || undefined,
    dateOfDeliveryEndValue:
      searchParams.get("date_of_delivery_end_value") || undefined,
    dateOfDeliveryStartValue:
      searchParams.get("date_of_delivery_start_value") || undefined
  });

  const {
    data,
    isLoading,
    setFilters,
    updateFilterKey,
    isLoadingMore,
    loadMoreRecords
  } = usePagedProspects({
    page: 1,
    dspUserId: currentUser.user.id,
    perPage: 200,
    filters: initialFilters
  });

  const onScrollEventListener = useScroll(loadMoreRecords);

  const onNameChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      let { value } = event.target;
      setValue(value);
      if (searchKey === "stages") {
        updateFilterKey(
          searchKey as keyof UsePagedProspectsHook.Filters,
          stages
            .filter((stage) =>
              stage.toLowerCase().includes(value.toLowerCase())
            )
            .map((stage) => stage)
        );
      } else {
        updateFilterKey(
          searchKey as keyof UsePagedProspectsHook.Filters,
          value
        );
      }
    },
    [searchKey, updateFilterKey]
  );

  const onDropDownChange = useCallback(
    (value: IOptions) => {
      setSearchKey(value.value);
      setValue("");
      setSearchLabel(value.label.toLowerCase());
      setFilters(initialFilters);
    },
    [initialFilters, setFilters]
  );

  return (
    <RecordCollection>
      <RecordCollection.SearchBox
        value={value}
        onChange={onNameChange}
        placeholder={"Search by " + searchLabel}
        initialDropDownValue={allDropDownOptions[0]}
        dropDownOptions={allDropDownOptions}
        onDropDownChange={onDropDownChange}
      />
      <RecordCollection.List onScroll={onScrollEventListener}>
        {isLoading ? (
          <RecordCollection.ListLoader />
        ) : data.length === 0 ? (
          <RecordCollection.ListEmpty />
        ) : (
          data.map((item) => (
            <RecordCollection.ListItemWrapper key={item.id}>
              <Link
                to={`${AppUrlConstants.INNER_BASE}${AppUrlConstants.PROSPECT}/${item.id}?title=prospect_details`}
                className="listDetailLink"
              >
                <RecordCollection.ListItemLink />
                <RecordCollection.ListItem>
                  <div className="dF">
                    <RecordCollection.ListItemColorCode
                      color_code={item.contact.color_code}
                    />
                    <RecordCollection.ListItemTitle title={item.contact.name} />
                  </div>
                  <RecordCollection.ListItemKeyValue
                    label={i18nTranslated.prospectId}
                    value={item.prospect_id || ""}
                  />
                  <RecordCollection.ListItemKeyValue
                    label={i18nTranslated.typeLabel}
                    value={item.enquiry_classification}
                  />
                  <RecordCollection.ListItemKeyValue
                    label={i18nTranslated.statusLabel}
                    value={item.enquiry_status}
                  />
                  <RecordCollection.ListItemKeyValue
                    label={i18nTranslated.stageLabel}
                    value={item.stage as string}
                  />
                  <RecordCollection.ListItemKeyValue
                    label={i18nTranslated.modelInterestLabel}
                    value={item.interested_model_family}
                  />
                  <RecordCollection.ListItemKeyValue
                    label={i18nTranslated.talukaLabel}
                    value={item.contact.taluka as string}
                  />
                  <RecordCollection.ListItemKeyValue
                    label={i18nTranslated.eddLabel}
                    value={
                      item.edd
                        ? dateHooks.formatDate(
                            item.edd,
                            DateFormat.DISPLAY_DATE
                          )
                        : "--"
                    }
                  />
                  <RecordCollection.ListAction>
                    <CallContactTemp
                      className="prospect-contact"
                      phoneNumber={item.contact.mobile_number}
                    />
                    <WhatsappContactTemp
                      phoneNumber={item.contact.mobile_number}
                    />
                  </RecordCollection.ListAction>
                </RecordCollection.ListItem>
              </Link>
            </RecordCollection.ListItemWrapper>
          ))
        )}
        {!isLoading && isLoadingMore && <RecordCollection.LoadMoreLoader />}
      </RecordCollection.List>
    </RecordCollection>
  );
};

export default Prospects;
