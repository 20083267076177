import React, { useContext } from "react";

import View from "../enums/View";

import { CommonTypes } from "../../Types/global";

type CreateJcPlanningContextParams = {
  goToView: (view: View) => void;
  cancelOperation: () => void;
  planTypeOptions: Array<CommonTypes.FieldOption>;
};

export const CreateJcPlanningContext = React.createContext<
  CreateJcPlanningContextParams | undefined
>(undefined);

export function useCreateJcPlanningContext(): CreateJcPlanningContextParams {
  const params = useContext(CreateJcPlanningContext);

  if (params === undefined) {
    throw new Error("Undefined create jc planning context.");
  }

  return params;
}
