import React, { useEffect, useRef, useState } from 'react';
import './index.css';
import '../../CSS/form.css';
import Form from 'react-bootstrap/Form';
import { IAutoCompleteSuggest } from '../../../Types/global';
import InputGroup from 'react-bootstrap/InputGroup';
import { ArrowSvg } from '../../../icons/ArrowSvg';
import Loadertemplate from '../Loadertemplate';
import { SearchSvg } from '../../../icons/SearchSvg';
import EmptyTemp from '../EmptyTemp';
import classNames from 'classnames';
import ServerConnectorUtil from '../../../shared/utils/ServerConnectorUtil';

const ListOfDetailsTemp : React.FC<IAutoCompleteSuggest> = ({fetchApiRequest, onClickValue, suggestionsRender, placeholder, paginationApiRequest, showLoader, showEmptyScreen, searchable=false}) => {
    const [value, setvalue] = useState<string>('');
    const [showLazyLoader, setshowLazyLoader] = useState<boolean>(false);
    const [initMount, setinitMount] = useState<boolean>(true);
    const detailBody = useRef<HTMLDivElement>(null);
    const paginationRef = useRef<boolean>(false);
    const autoCompleteRef = useRef<AbortController | null>(null);
    useEffect(()=> {
        if(initMount){
            setinitMount(false);
            return;
        }
        autoCompleteRef.current = new AbortController();
            fetchApiRequest(value, autoCompleteRef.current.signal)
        return () => {
            if(autoCompleteRef.current)
                autoCompleteRef.current.abort();
        }
    }, [value])
    const onPagination = async() => {
        try{
            if(paginationApiRequest !== null && paginationApiRequest !== undefined && !paginationRef.current){
                setshowLazyLoader(true);
                if(detailBody && detailBody.current){
                    let element = detailBody.current;
                    let scrollingTop = Math.round(element.scrollTop + element.offsetHeight);
                    let scrollingHeight = element.scrollHeight;
                    if(scrollingTop === scrollingHeight){
                        paginationApiRequest(setshowLazyLoader);
                        paginationRef.current = true;
                    }
                }
            }
        }catch(error){
            if (error instanceof Error) {
                ServerConnectorUtil.handleServerError(error);
            }
        }
    }

    return(
        <div className='List-of-details-wrap'>
            {
                searchable
                &&
                <div className='search-details-header'>
                    <InputGroup bsPrefix='default-search'>
                        <Form.Control size='lg' placeholder={placeholder} className='custom-form-input' value={value} onChange={(event: React.ChangeEvent<HTMLInputElement>)=> setvalue(event.target.value)}></Form.Control>
                        <InputGroup.Text><SearchSvg/></InputGroup.Text>
                    </InputGroup>
                </div>
            }
            <div  className={classNames('details-body',{'searchable-details-body': searchable},{'empty-details-body': showEmptyScreen})} ref={detailBody} onScroll={onPagination}>
                {
                    showLoader ?
                        <div className='loader-wrapper'>
                            <Loadertemplate></Loadertemplate>
                        </div>
                    :
                        <>
                            {
                                showEmptyScreen ?
                                    <div className='accordion-empty'>
                                        <EmptyTemp/>
                                    </div>
                                :
                                    <>
                                        {
                                            suggestionsRender()
                                        }
                                        {
                                            showLazyLoader && <div className='pagination-loader'><Loadertemplate></Loadertemplate></div>
                                        }
                                    </> 
                            }
                        </>
                }
            </div>
        </div>
    )
}
export default ListOfDetailsTemp;