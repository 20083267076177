import styles from "./styles.module.css";

import axios from "axios";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";
import React, { useCallback, useState } from "react";

import Button from "../shared/components/Button";
import ToastUtil from "../shared/utils/ToastUtil";
import VillageTiv from "../shared/enums/VillageTiv";
import useJcPlannings from "../shared/hooks/useJcPlannings";
import Loadertemplate from "../Components/templates/Loadertemplate";
import VillageColorCode from "../shared/enums/VillageColorCode";
import DateTimeProcessor from "../shared/processors/DateTimeProcessor";
import useJcpPlanApproval from "../shared/hooks/useJcpPlanApproval";
import JcPlanningCalendar from "../shared/components/jc-planning-calender";
import ServerConnectorUtil from "../shared/utils/ServerConnectorUtil";
import JcPlanApprovalStatus from "../shared/enums/JcPlanApprovalStatus";
import CustomEventEmiterUtil from "../shared/utils/CustomEventEmitterUtil";
import useJcPlanningCalender from "../shared/hooks/useJcPlanningCalendar";
import useCustomEventListener from "../shared/hooks/useCustomEventListener";
import CustomEventNameConstants from "../shared/constants/CustomEventNameConstants";
import useJcPlanningCalenderHandler from "../shared/hooks/useJcPlanningCalendarHandler";
import JcPlanningScheduledVillageViewModal from "../shared/components/jc-planning-scheduled-village-view-modal";

import { UseJcPlanningsHook } from "../Types/hooks";
import NoteMessage from "../shared/components/note-message";
import AppUrlConstants from "../shared/constants/AppUrlConstants";
import AppQueryParameters from "../shared/enums/AppQueryParameters";
import DateFormat from "../shared/enums/DateFormat";

type ColorCodeSummary = {
  colorCode: string;
  hTiv: number;
  lTiv: number;
};

const JcPlanningSummary: React.FC = () => {
  const navigate = useNavigate();

  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [totalSelectecdVillages, setTotalSelectedVillages] =
    useState<number>(0);
  const [totalNumberOfVisits, setTotalNumberOfVisits] = useState<number>(0);
  const [totalUniqueVillages, setTotalUniqueVillages] = useState<number>(0);
  const [colorCodeSummaries, setColorCodeSummaries] = useState<
    Array<ColorCodeSummary>
  >([]);

  const {
    endDate,
    startDate,
    onMonthChange,
    dateColorCodeMap,
    jcPlanningFilters,
    setExcludedDates,
    setDateColorCodeMap,
    jcPlanApprovalFilters
  } = useJcPlanningCalender();

  const onJcPlanningsLoaded = useCallback(
    ({ data: jcPlannings }: UseJcPlanningsHook.OnSuccessCbParameters) => {
      setTotalSelectedVillages(
        jcPlannings.reduce((prev, _curr) => prev + 1, 0)
      );

      setTotalUniqueVillages(
        new Set(jcPlannings.map((item) => item.village.id)).size
      );

      setTotalNumberOfVisits(
        new Set(jcPlannings.map((item) => item.date)).size
      );

      const colorCodeSummaries: Array<ColorCodeSummary> = [];

      for (const colorCode of Object.values(VillageColorCode)) {
        const totalHtiv = jcPlannings.filter(
          (obj) =>
            obj.village.color_code === colorCode &&
            obj.village.tiv === VillageTiv.HIGH
        ).length;

        const totalLtiv = jcPlannings.filter(
          (obj) =>
            obj.village.color_code === colorCode &&
            obj.village.tiv === VillageTiv.LOW
        ).length;

        colorCodeSummaries.push({
          colorCode,
          hTiv: totalHtiv,
          lTiv: totalLtiv
        });
      }
      setColorCodeSummaries(colorCodeSummaries);
    },
    []
  );

  const { data: jcPlanApproval, isLoading: isLoadingJcPlanApproval } =
    useJcpPlanApproval({
      filters: jcPlanApprovalFilters,
      onFailure: ServerConnectorUtil.handleServerError
    });

  const {
    data: jcPlannings,
    isLoading: isLoadingJcPlannings,
    deleteJcPlanning
  } = useJcPlannings({
    page: 1,
    perPage: 200,
    filters: jcPlanningFilters,
    onSuccess: onJcPlanningsLoaded,
    onFailure: ServerConnectorUtil.handleServerError
  });

  useJcPlanningCalenderHandler({
    endDate,
    startDate,
    jcPlannings,
    jcPlanApproval,
    setExcludedDates,
    setDateColorCodeMap
  });

  useCustomEventListener(
    CustomEventNameConstants.JC_PLANNING.DELETE_RECORD,
    deleteJcPlanning
  );

  const isLoading = isLoadingJcPlanApproval || isLoadingJcPlannings;

  const createJcPlanning = useCallback(() => {
    navigate({
      pathname: AppUrlConstants.CREATE_JCP.SELECT_DATE,
      search: new URLSearchParams({
        month: DateTimeProcessor.format(startDate.getTime(), DateFormat.MONTH),
        year: DateTimeProcessor.format(endDate.getTime(), DateFormat.YEAR),
        [AppQueryParameters.COMMON.KEYS.TITLE]: "create_jcp"
      }).toString()
    });
  }, [endDate, navigate, startDate]);

  const onDateSelect = useCallback(
    (date: Date | null) => {
      if (date === null) {
        ToastUtil.makeFailureToast("Unable to read the selected date.");
        return;
      }

      const formatedDate = DateTimeProcessor.format(date.getTime());
      const hasJcPlannings = dateColorCodeMap[formatedDate];

      if (!hasJcPlannings) {
        ToastUtil.makeFailureToast("No jcp available for the selected date.");
        return;
      }

      CustomEventEmiterUtil.emit(
        CustomEventNameConstants.JC_PLANNING.OPEN_VIEW_MODAL,
        jcPlannings.filter((obj) => obj.date === formatedDate),
        false
      );
    },
    [jcPlannings, dateColorCodeMap]
  );

  const showColorCodeSummaryJcPlannings = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      const element = event.currentTarget as HTMLElement;

      const tiv = element.getAttribute("data-tiv");
      const colorCode = element.getAttribute("data-color-code");

      CustomEventEmiterUtil.emit(
        CustomEventNameConstants.JC_PLANNING.OPEN_VIEW_MODAL,
        jcPlannings.filter(
          (obj) =>
            obj.village.color_code === colorCode && obj.village.tiv === tiv
        ),
        false
      );
    },
    [jcPlannings]
  );

  const showAllJcPlannings = useCallback(() => {
    CustomEventEmiterUtil.emit(
      CustomEventNameConstants.JC_PLANNING.OPEN_VIEW_MODAL,
      jcPlannings,
      false
    );
  }, [jcPlannings]);

  const showUniqueJcPlannings = useCallback(() => {
    const requiredJcPlannings: Array<string> = Object.values(
      jcPlannings.reduce(
        (prev, curr) => ({
          ...prev,
          [curr.village.id]: curr.id
        }),
        {}
      )
    );

    CustomEventEmiterUtil.emit(
      CustomEventNameConstants.JC_PLANNING.OPEN_VIEW_MODAL,
      jcPlannings.filter((item) => requiredJcPlannings.includes(item.id)),
      false
    );
  }, [jcPlannings]);

  const sendApproval = async () => {
    setIsProcessing(true);
    try {
      await axios.patch(
        `/api/v1/jc-plan-approval/${jcPlanApproval?.id}/submit-for-approval`
      );
      navigate("/success/approval");
    } catch (error) {
      ServerConnectorUtil.handleServerError(error);
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <>
      <div className={styles["container"]}>
        {isLoading ? (
          <div className="loader-wrapper">
            <Loadertemplate />
          </div>
        ) : (
          <>
            <div className={styles["villages-count-container"]}>
              <div
                className={classNames(
                  styles["card"],
                  styles["card--village-count"]
                )}
                onClick={showAllJcPlannings}
              >
                <div className={classNames(styles["card__header"])}>
                  <h6 className={styles["title"]}>Total Selected Villages</h6>
                </div>
                <div className={styles["card__body"]}>
                  <p className={classNames(styles["count"], styles["link"])}>
                    {totalSelectecdVillages}
                  </p>
                </div>
              </div>
              <div
                className={classNames(
                  styles["card"],
                  styles["card--village-count"]
                )}
                onClick={showUniqueJcPlannings}
              >
                <div className={classNames(styles["card__header"])}>
                  <h6 className={styles["title"]}>Unique Villages</h6>
                </div>
                <div className={styles["card__body"]}>
                  <p className={classNames(styles["count"], styles["link"])}>
                    {totalUniqueVillages}
                  </p>
                </div>
              </div>
            </div>
            <div className={classNames(styles["card"])}>
              <div className={classNames(styles["card__header"])}>
                <h6 className={styles["title"]}>No. of days of visits</h6>
              </div>
              <div className={styles["card__body"]}>
                <p className={styles["count"]}>{totalNumberOfVisits}</p>
              </div>
            </div>
            <JcPlanningCalendar
              openToDate={startDate}
              dateColorCodeMap={dateColorCodeMap}
              onDateSelect={onDateSelect}
              onMonthChange={onMonthChange}
              jcPlanApprovalStatus={
                jcPlanApproval?.status || JcPlanApprovalStatus.IN_DRAFT
              }
            />
            <div className={styles["hr"]} />
            <h6 className={styles["bryg-summary-title"]}>BRYG Summary</h6>
            {colorCodeSummaries.map((item) => (
              <div key={item.colorCode} className={classNames(styles["card"])}>
                <div className={classNames(styles["card__header"])}>
                  <h6
                    className={classNames(
                      styles["title"],
                      styles[item.colorCode.toLowerCase()]
                    )}
                  >
                    {item.colorCode}
                  </h6>
                </div>
                <div
                  className={classNames(
                    styles["card__body"],
                    styles["bryg-container"]
                  )}
                >
                  <div
                    className={styles["tiv-container"]}
                    data-tiv={VillageTiv.HIGH}
                    data-color-code={item.colorCode}
                    onClick={showColorCodeSummaryJcPlannings}
                  >
                    <p className={styles["title"]}>HTIV</p>
                    <p className={classNames(styles["count"], styles["link"])}>
                      {item.hTiv}
                    </p>
                  </div>
                  <div
                    className={styles["tiv-container"]}
                    data-tiv={VillageTiv.LOW}
                    data-color-code={item.colorCode}
                    onClick={showColorCodeSummaryJcPlannings}
                  >
                    <p className={styles["title"]}>LTIV</p>
                    <p className={classNames(styles["count"], styles["link"])}>
                      {item.lTiv}
                    </p>
                  </div>
                </div>
              </div>
            ))}
            <JcPlanningScheduledVillageViewModal />
            <Button
              label="Create another visit"
              variant="secondary"
              fullWidth
              onClick={createJcPlanning}
              disabled={
                !(
                  (!jcPlanApproval ||
                    jcPlanApproval.status === JcPlanApprovalStatus.IN_DRAFT) &&
                  DateTimeProcessor.isDateAfter(endDate.getTime(), Date.now())
                )
              }
            />
            <NoteMessage
              message={
                jcPlanApproval
                  ? jcPlanApproval.status !== JcPlanApprovalStatus.IN_DRAFT
                    ? "You're not allowed to create a jcp as month status is " +
                      jcPlanApproval.status.toLowerCase()
                    : "Create remaining before submitting for approval."
                  : "Create remaining before submitting for approval."
              }
            />
            <Button
              label="Send for approval"
              variant="primary"
              isLoading={isProcessing}
              disabled={
                jcPlannings.length === 0 ||
                !jcPlanApproval ||
                jcPlanApproval.status !== JcPlanApprovalStatus.IN_DRAFT
              }
              fullWidth
              onClick={sendApproval}
            />
          </>
        )}
      </div>
    </>
  );
};

export default JcPlanningSummary;
