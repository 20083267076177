import moment from "moment";

import DateFormat from "../enums/DateFormat";
import DateOperationResultType from "../enums/DateOperationResultType";
import { IDateStrRangeObj } from "../../Types/global";

export default class DateTimeProcessor {
  private static getResult(
    date: Date,
    resultType: DateOperationResultType
  ): Date | number {
    if (resultType === DateOperationResultType.MILLIS) {
      return date.getTime();
    } else {
      return date;
    }
  }
  public static format(
    date: number,
    format: DateFormat = DateFormat.ISO_DATE
  ): string {
    return moment(date).format(format);
  }

  public static addMonths(
    date: Date | number,
    months: number,
    resultType: DateOperationResultType = DateOperationResultType.MILLIS
  ): Date | number {
    const result = moment(date).add(months, "months").toDate();
    return this.getResult(result, resultType);
  }

  public static addDays(
    date: Date | number,
    days: number,
    resultType: DateOperationResultType = DateOperationResultType.MILLIS
  ): Date | number {
    const result = moment(date).add(days, "days").toDate();
    return this.getResult(result, resultType);
  }

  public static startOfDay(
    date: Date | number,
    resultType: DateOperationResultType = DateOperationResultType.MILLIS
  ): Date | number {
    const result = moment(date).startOf("day").toDate();
    return this.getResult(result, resultType);
  }

  public static endOfDay(
    date: Date | number,
    resultType: DateOperationResultType = DateOperationResultType.MILLIS
  ): Date | number {
    const result = moment(date).endOf("day").toDate();
    return this.getResult(result, resultType);
  }

  public static getStartOFMonthByDate(
    date: Date | number,
    resultType: DateOperationResultType = DateOperationResultType.MILLIS
  ): Date | number {
    const result = moment(date).startOf("month").toDate();

    return this.getResult(result, resultType);
  }
  public static getEndOFMonthByDate(
    date: Date | number,
    resultType: DateOperationResultType = DateOperationResultType.MILLIS
  ): Date | number {
    const result = moment(date).endOf("month").toDate();

    return this.getResult(result, resultType);
  }

  public static getStartOfByMonthAndYear(
    month: string,
    year: string,
    resultType: DateOperationResultType = DateOperationResultType.MILLIS
  ): Date | number {
    const result = moment(`${month} ${year}`, "MMMM YYYY")
      .startOf("month")
      .toDate();

    return this.getResult(result, resultType);
  }

  public static getEndOfByMonthAndYear(
    month: string,
    year: string,
    resultType: DateOperationResultType = DateOperationResultType.MILLIS
  ): Date | number {
    const result = moment(`${month} ${year}`, "MMMM YYYY")
      .endOf("month")
      .toDate();

    return this.getResult(result, resultType);
  }

  public static getDatesOfMonth(month: string, year: string): Array<Date> {
    const result: Array<Date> = [];
    const startDate = moment(`${month} ${year}`, "MMMM YYYY").startOf("month");
    const endDate = moment(`${month} ${year}`, "MMMM YYYY").endOf("month");

    while (!startDate.isAfter(endDate, "date")) {
      result.push(startDate.toDate());
      startDate.add(1, "day");
    }

    return result;
  }

  public static getDatesBetweenRange(start: Date, end: Date): Array<Date> {
    const result: Array<Date> = [];
    const startDate = moment(start);
    const endDate = moment(end);

    while (!startDate.isAfter(endDate, "date")) {
      result.push(startDate.toDate());
      startDate.add(1, "day");
    }

    return result;
  }

  public static isDateAfter(a: number, b: number): boolean {
    return moment(a).isAfter(moment(b), "date");
  }

  public static getSixWeekDays(): Array<number> {
    const days: Array<number> = [];

    for (let i = 3; i >= 0; i--) {
      days.push(moment().subtract(i, "days").toDate().getTime());
    }

    for (let i = 1; i <= 2; i++) {
      days.push(moment().add(i, "days").toDate().getTime());
    }

    return days;
  }

  public static isToday(date: number): boolean {
    return moment(date).isSame(moment(), "day");
  }

  public static parse(
    date: string,
    format: DateFormat = DateFormat.ISO_DATE
  ): number {
    return moment(date, format).toDate().getTime();
  }
  public static getFilterTime(jcpStatFilter: string | IDateStrRangeObj): {
    start_date: number;
    end_date: number;
  } {
    switch (jcpStatFilter) {
      case "This year":
        return {
          start_date: this.getResult(
            moment().startOf("year").toDate(),
            DateOperationResultType.MILLIS
          ) as number,
          end_date: this.getResult(
            moment().toDate(),
            DateOperationResultType.MILLIS
          ) as number
        };
      case "Today":
        return {
          start_date: this.getResult(
            moment().toDate(),
            DateOperationResultType.MILLIS
          ) as number,
          end_date: this.getResult(
            moment().toDate(),
            DateOperationResultType.MILLIS
          ) as number
        };
      case "Yesterday":
        return {
          start_date: this.getResult(
            moment().subtract(1, "day").toDate(),
            DateOperationResultType.MILLIS
          ) as number,
          end_date: this.getResult(
            moment().toDate(),
            DateOperationResultType.MILLIS
          ) as number
        };
      case "Last week":
        return {
          start_date: this.getResult(
            moment().subtract(1, "week").toDate(),
            DateOperationResultType.MILLIS
          ) as number,
          end_date: this.getResult(
            moment().toDate(),
            DateOperationResultType.MILLIS
          ) as number
        };
      case "Current month":
        return {
          start_date: this.getResult(
            moment().startOf("month").toDate(),
            DateOperationResultType.MILLIS
          ) as number,
          end_date: this.getResult(
            moment().toDate(),
            DateOperationResultType.MILLIS
          ) as number
        };
      case "Last month":
        return {
          start_date: this.getResult(
            moment().subtract(1, "month").toDate(),
            DateOperationResultType.MILLIS
          ) as number,
          end_date: this.getResult(
            moment().toDate(),
            DateOperationResultType.MILLIS
          ) as number
        };
      case "Last 3 months":
        return {
          start_date: this.getResult(
            moment().subtract(3, "month").toDate(),
            DateOperationResultType.MILLIS
          ) as number,
          end_date: this.getResult(
            moment().toDate(),
            DateOperationResultType.MILLIS
          ) as number
        };
      case "This week":
        return {
          start_date: this.getResult(
            moment().startOf("week").toDate(),
            DateOperationResultType.MILLIS
          ) as number,
          end_date: this.getResult(
            moment().toDate(),
            DateOperationResultType.MILLIS
          ) as number
        };
      default:
        return {
          start_date: this.getResult(
            moment((jcpStatFilter as IDateStrRangeObj).start).toDate(),
            DateOperationResultType.MILLIS
          ) as number,
          end_date: this.getResult(
            moment((jcpStatFilter as IDateStrRangeObj).end).toDate(),
            DateOperationResultType.MILLIS
          ) as number
        };
    }
  }
}
