import axios from 'axios';
import RequestMethod from '../enums/RequestMethod';

export default class RequestHandler {
	private url: string;
	private body: unknown;
	private headers: Record<string, string>;
	private params: Record<string, unknown>;
	private configurations: Record<string, unknown>;

	private method: RequestMethod;

	constructor(url: string, method: RequestMethod) {
		this.url = url;
		this.body = {};
		this.params = {};
		this.headers = {};
		this.configurations = {};

		this.method = method;
	}

	public setBody(body: unknown): void {
		this.body = body;
	}

	public addHeader(key: string, value: string): void {
		this.headers[key] = value;
	}

	public addParam(key: string, value: string | number | boolean): void {
		this.params[key] = value;
	}

	public setAbortSignal(signal: AbortSignal): void {
		this.configurations['signal'] = signal;
	}

	public addConfiguration(key: string, value: any): void {
		this.configurations[key] = value;
	}

	public async execute(): Promise<{
		data: unknown;
		status: number;
		headers: Record<string, string>;
	}> {
		return axios({
			url: this.url,
			data: this.body,
			params: this.params,
			method: this.method,
			headers: this.headers,
			...this.configurations
		}).then((response) => ({
			data: response.data,
			status: response.status,
			headers: response.headers as Record<string, string>
		}));
	}
}
