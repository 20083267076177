export type ListProp = {
  onScroll?: (event: React.UIEvent<HTMLDivElement>) => void;
} & React.PropsWithChildren;
const List: React.FC<ListProp> = ({ children, onScroll }) => {
  return (
    <div className="details-body" onScroll={onScroll}>
      {children}
    </div>
  );
};

export default List;
