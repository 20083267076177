import React, { useEffect, useState } from 'react'
import './index.css';
import Prospecttemplate from '../../templates/Prospecttemplate';
import { ArrowPrimarySvg } from '../../../icons/ArrowPrimarySvg';
import { Link } from 'react-router-dom';
import { ArrowSvg } from '../../../icons/ArrowSvg';
import RequestHandler from '../../../shared/handlers/RequestHandler';
import api_request from '../../../shared/constants/api_request';
import RequestMethod from '../../../shared/enums/RequestMethod';
import dateHooks from '../../Hooks/dateHooks';
import { ICount, IJCPVillageStats, INumberOfCards, IPageResponse } from '../../../Types/global';
import Loadertemplate from '../../templates/Loadertemplate';
import EmptyTemp from '../../templates/EmptyTemp';
import ServerConnectorUtil from '../../../shared/utils/ServerConnectorUtil';
import AppUrlConstants from '../../../shared/constants/AppUrlConstants';
import AppQueryParameters from '../../../shared/enums/AppQueryParameters';

const i18nTranslated = {
    tivHigh: 'HTIV',
    tivLow: 'LTIV',
    todayLabel: 'Today'
}
const PlannedVisit: React.FC<INumberOfCards & {setCount: React.Dispatch<React.SetStateAction<ICount>>}> = ({noOfCards, setCount}) => {
    const [plannedVisit, setplannedVisit] = useState<IJCPVillageStats[]>([]);
    const [showLazyLoading, setshowLazyLoading] = useState(true);
    useEffect(()=> {
        let plannedVisitAbort = new AbortController();
        const reqPlannedVisit = new RequestHandler(api_request.PLANNED_VISIT, RequestMethod.POST);
        reqPlannedVisit.setBody({
            "checked_in":"false"
        });
        reqPlannedVisit.addParam('page', 1);
        reqPlannedVisit.addParam('perPage', 200);
        reqPlannedVisit.addParam('additional_fields', 'village.stats.total_visits_year_till_date');
        reqPlannedVisit.addParam('date_start_value', dateHooks.formatDate('', 'YYYY-MM-DD'));
        reqPlannedVisit.addParam('date_end_value', dateHooks.formatDate('', 'YYYY-MM-DD'));
        reqPlannedVisit.addParam('statuses', 'Approved');
        reqPlannedVisit.execute().then(response => {
            let {data}: IPageResponse<IJCPVillageStats>= response.data as IPageResponse<IJCPVillageStats>;
            setCount({
                value: data.length
            })
            let plannedVisitData = data.map((item: IJCPVillageStats)=> {
                item.village.tiv = item.village.tiv === 'High' ? i18nTranslated.tivHigh : i18nTranslated.tivLow;
                return item;
            });
            if(noOfCards && noOfCards < plannedVisitData.length){
                plannedVisitData = plannedVisitData.slice(0, noOfCards);
            }
            setplannedVisit(plannedVisitData);
            setshowLazyLoading(false);
        }).catch(error=> {
            if (error instanceof Error) {
                ServerConnectorUtil.handleServerError(error);
            }
            setshowLazyLoading(false);
        })
        return ()=> {
            plannedVisitAbort.abort()
        }
    }, [])
    return (
        <>
            {
                showLazyLoading ?
                    <div className='loader-wrapper'>
                        <Loadertemplate/>
                    </div>
                :
                    plannedVisit.length === 0 ?
                        <div className='accordion-empty'>
                            <EmptyTemp/>
                        </div>
                    :
                    plannedVisit.map((item: IJCPVillageStats)=> (
                        <Link className='planned-visit-navigate' to={
                            {
                                pathname: AppUrlConstants.INNER_BASE +
                                AppUrlConstants.CHECK_IN +
                                AppUrlConstants.JCPS + '/' +
                                item.id,
                                search: new URLSearchParams({
                                    [AppQueryParameters.COMMON.KEYS.TITLE]: AppQueryParameters.CHECK_IN_JCP.VALUES.VILLAGE_DETAILS
                                }).toString()
                            }
                        } onClick={(event: React.MouseEvent<HTMLAnchorElement>)=> event.stopPropagation()}>
                            <Prospecttemplate color_code={item.village.color_code}>
                                <div className='jcp-detail'>
                                    <div className='jcp-location fCenter'>
                                        <span className='location'>{item.village.name}</span>
                                    </div>
                                    <div className='jcp-type fCenter'>
                                        {item.village.tiv}<span className='small-dot'></span>{item.plan_type}
                                    </div>
                                    <div className='jcp-edd-date'>{i18nTranslated.todayLabel}</div>
                                </div>
                            </Prospecttemplate>
                        </Link>
                    ))
            }
        </>
    )
}

export default PlannedVisit