import { useCallback, useState } from "react";
import { UseJcPlanningOverviewHook } from "../../Types/hooks";
import useEffectAfterMount from "./useEffectAfterMount";
import JcPlanningService from "../services/JcPlanningService";

export default function useJcPlanningOverview({
  onFailure,
  onSuccess,
  initialFilters
}: UseJcPlanningOverviewHook.Parameters): UseJcPlanningOverviewHook.ReturnType {
  const [data, setData] = useState<Record<string, number> | undefined>(
    undefined
  );
  const [error, setError] = useState<any>(undefined);
  const [filters, setFilters] =
    useState<UseJcPlanningOverviewHook.Filters>(initialFilters);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffectAfterMount(() => {
    if (isLoading) {
      JcPlanningService.getOverview({
        filters
      })
        .then((data) => {
          setData(data);
          if (onSuccess) {
            onSuccess(data);
          }
          setIsLoading(false);
        })
        .catch((err) => {
          setError(err);
          if (onFailure) {
            onFailure(err);
          }
        });
    }
  }, [onSuccess, onFailure, isLoading]);

  const updateFilter = useCallback(
    (
      key: keyof UseJcPlanningOverviewHook.Filters,
      value: string | boolean | Array<string>
    ) => {
      setFilters((prev) => ({
        ...prev,
        [key]: value
      }));
      setIsLoading(true);
    },
    []
  );

  const updateFilters = useCallback(
    (filters: UseJcPlanningOverviewHook.Filters) => {
      setFilters(filters);
      setIsLoading(true);
    },
    []
  );

  return {
    data,
    error,
    isLoading,
    updateFilter,
    updateFilters
  };
}
