import React from 'react';
import { IContactProps } from '../../../Types/global';
import WhatsappSvg from '../../../icons/WhatsappSvg';
import classNames from 'classnames';

const WhatsappContactTemp: React.FC<IContactProps> = ({phoneNumber, className}) => {
    const whatsappLink = `https://wa.me/${phoneNumber}`;
    return(
        <a href={whatsappLink} target='_blank' style={{'pointerEvents': 'auto'}} onClick={(event: React.MouseEvent<HTMLAnchorElement>)=> event.stopPropagation()}>
            <WhatsappSvg className={classNames(className && className)}/>
        </a>
    )
}

export default WhatsappContactTemp;