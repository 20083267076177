import React, { useCallback, useContext } from 'react'
import './index.css';
import '../../CSS/form.css';
import { UserContext } from '../../HOC/UserContext';
import { IUserContext } from '../../../Types/global';
import { Link, useNavigate } from 'react-router-dom';
import { UnnatiSvg } from '../../../icons/Menu/UnnatiSvg';
import { MpcMdcSvg } from '../../../icons/Menu/MpcMdcSvg';
import Signout from '../../templates/Signout';
import { EnquiryMaster } from '../../../icons/Menu/EnquiryMaster';
import Villagesmaster from '../../../icons/Menu/Villagesmaster';
import Tudbmaster from '../../../icons/Menu/Tudbmaster';

const Menu: React.FC = () => {
    const currentUser= useContext(UserContext);
    const role = (currentUser as IUserContext).user.role.name;
    const navigate = useNavigate();
    const translatedText = {
        'unnati-menu': 'Unnati',
        'mpcgyarah-menu': 'MPC & Ek se Ek Gyarah',
        'enquirymaster-menu': 'Enquiry Master',
        'tudbmaster-menu': 'TUDB Master',
        'villagemaster-menu': 'Village Master',
        'jcp-menu': 'JCP',
        'fieldActivity-menu': 'Field Activities',
        'filedActivityMaster-menu': 'Filed Activity Master',
        'stock-menu': 'Stock',
        'stockMaster-menu': 'Stock Master',
        'advance-menu': 'Advance',
        'advanceMaster-menu': 'Advance Master',
        'targetSetting-menu': 'Target Setting'
    }
    const renderingMenuList = useCallback(()=>{
        let menuList;
        switch(role){
            case 'Dsm':
            case 'Dealer':
            case 'Tetm':
                menuList = <>
                   <div className='menu-item'>
                        <UnnatiSvg/>
                        <span className='menu-text'>{translatedText['jcp-menu']}</span>
                    </div>
                    <div className='menu-item'>
                        <MpcMdcSvg/>
                        <span className='menu-text'>{translatedText['fieldActivity-menu']}</span>
                    </div>
                    <div className='menu-item'>
                        <UnnatiSvg/>
                        <span className='menu-text'>{translatedText['filedActivityMaster-menu']}</span>
                    </div>
                    <div className='menu-item'>
                        <MpcMdcSvg/>
                        <span className='menu-text'>{translatedText['stock-menu']}</span>
                    </div>
                    <div className='menu-item'>
                        <EnquiryMaster/>
                        <span className='menu-text'>{translatedText['stockMaster-menu']}</span>
                    </div>
                    <div className='menu-item'>
                        <Tudbmaster/>
                        <span className='menu-text'>{translatedText['advance-menu']}</span>
                    </div>
                    <div className='menu-item'>
                        <span className='villagemaster-icon'></span>
                        <span className='menu-text'>{translatedText['advanceMaster-menu']}</span>
                    </div>
                    <div className='menu-item'>
                        <span className='villagemaster-icon'></span>
                        <span className='menu-text'>{translatedText['targetSetting-menu']}</span>
                    </div>
                    {   
                        (role !== 'Tetm') &&
                            <Link to={'https://unnatirf.com/lendperfect/saml2/authenticate/60005203116'} target='_blank'>
                                <div className='menu-item'>
                                    <UnnatiSvg/>
                                    <span className='menu-text'>{translatedText['unnati-menu']}</span>
                                </div>
                            </Link>
                    }
                    <div className='menu-item'>
                        <EnquiryMaster/>
                        <span className='menu-text'>{translatedText['enquirymaster-menu']}</span>
                    </div>
                    <div className='menu-item'>
                        <Tudbmaster/>
                        <span className='menu-text'>{translatedText['tudbmaster-menu']}</span>
                    </div>
                    <div className='menu-item'>
                        <span className='villagemaster-icon'></span>
                        <span className='menu-text'>{translatedText['villagemaster-menu']}</span>
                    </div>

                </>
            break;
            default:
                menuList = <>
                    <Link to={'https://unnatirf.com/lendperfect/saml2/authenticate/60005203116'} target='_blank'>
                        <div className='menu-item'>
                            <div className='menu-icon'>
                                <UnnatiSvg/>
                            </div>
                            <span className='menu-text'>{translatedText['unnati-menu']}</span>
                        </div>
                    </Link>
                    <div className='menu-item' onClick={()=> navigate(`/innerbase/menu/mpcmdc/mpc?title=mpcgyarah-menu`)}>
                        <div className='menu-icon'>
                            <MpcMdcSvg/>
                        </div>
                        <span className='menu-text'>{translatedText['mpcgyarah-menu']}</span>
                    </div>
                    <div className='menu-item' onClick={() => navigate(`/innerbase/prospects?title=enquiry_master`)}>
                        <div className='menu-icon'>
                            <EnquiryMaster/>
                        </div>
                        <span className='menu-text'>{translatedText['enquirymaster-menu']}</span>
                    </div>
                    <div className='menu-item' onClick={() => navigate(`/innerbase/menu/tractoruser?title=tractor_master`)}>
                        <div className='menu-icon'>
                            <Tudbmaster/>
                        </div>
                        <span className='menu-text'>{translatedText['tudbmaster-menu']}</span>
                    </div>
                    <div className='menu-item' onClick={() => navigate(`/innerbase/menu/villages?title=village_master`)}>
                        <div className='menu-icon'>
                            <Villagesmaster/>
                        </div>
                        <span className='menu-text'>{translatedText['villagemaster-menu']}</span>
                    </div>

                </>
        }
        return menuList;
    }, []);
    return(
        <>
            <div className='menu-list'>
                {renderingMenuList()}
            </div>
            <Signout/>
        </>
    )
}
export default Menu;
