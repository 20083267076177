import React, { useState } from 'react';
import './index.css';
import Maintemplate from '../../templates/Maintemplate';
import Form from 'react-bootstrap/Form';
import SearchDropdowntemplate from '../../templates/SearchDropdowntemplate';
import { IOptions } from '../../../Types/global';
import Buttontemplate from '../../templates/Buttontemplate';

const VisitDetails: React.FC = () => {
    const i18nTranslated = {
        'visitDetails': 'Visit Details',
        'addRemarks': 'Add remarks',
        'checkIn': 'Check-in'
    }
    const [villageOptions, setvillageOptions] = useState<IOptions[]>([
        {
            'label': 'Kanchipuram',
            'value': 'Kanchipuram'
        },
        {
            'label': 'Chennai',
            'value': 'Chennai'
        }
    ])
    return (
        <Form className='visit-details'>
            <Form.Group className='custom-form-group visit-options'>
                <Form.Control as='div' size='lg' className='custom-form-div'>
                    <SearchDropdowntemplate
                        options={villageOptions}
                        placeholder="Add Village"
                        isSearchable={true}></SearchDropdowntemplate>
                </Form.Control>
            </Form.Group>
            <Form.Group className='custom-form-group visit-map'>
                <Form.Control as='div' className='custom-form-div visit-map'></Form.Control>
            </Form.Group>
            <Form.Group className='custom-form-group visit-textarea'>
                <Form.Control as='textarea' placeholder={i18nTranslated.addRemarks} className='custom-form-input custom-form-textarea'></Form.Control>
            </Form.Group>
            <Form.Group className='custom-form-group button-group'>
                <Buttontemplate type='primary' buttonWidth='100%' callback={()=> console.log('work')}>
                    {i18nTranslated.checkIn}
                </Buttontemplate>
            </Form.Group>
        </Form>
    )
}

export default VisitDetails;