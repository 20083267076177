import "./index.css";
import React, { useState } from "react";
import CallContactTemp from "../../../templates/CallContactTemp";
import WhatsappContactTemp from "../../../templates/WhatsappContactTemp";
import Loadertemplate from "../../../templates/Loadertemplate";
import EmptyTemp from "../../../templates/EmptyTemp";
import ServerConnectorUtil from "../../../../shared/utils/ServerConnectorUtil";
import usePagedContacts from "../../../../shared/hooks/usePagedContacts";

const MDCTab: React.FC = () => {
  const [filters] = useState({
    type: "MDC"
  });

  const { data, isLoading } = usePagedContacts({
    page: 1,
    perPage: 200,
    filters,
    onFailure: ServerConnectorUtil.handleServerError
  });
  return (
    <div className="listOfDetails">
      {isLoading ? (
        <div className="loader-wrapper">
          <Loadertemplate />
        </div>
      ) : data.length > 0 ? (
        data.map((item, index) => (
          <div className="prospect mdc_prospects" key={index}>
            <div className="mdc_prospect">
              <b>{item.name}</b>
              <p className="mdc-key">{item.village}</p>
              <p className="mdc-key">{item.influencer_type || "--"}</p>
            </div>
            <div className="prospects-contact">
              <CallContactTemp
                phoneNumber={item.mobile_number}
              ></CallContactTemp>
              <WhatsappContactTemp
                phoneNumber={item.mobile_number}
              ></WhatsappContactTemp>
            </div>
          </div>
        ))
      ) : (
        <div className="accordion-empty">
          <EmptyTemp />
        </div>
      )}
    </div>
  );
};

export default MDCTab;
