import React from "react";

export type ListItemTitleProps = {
  title: string;
};

const ListItemTitle: React.FC<ListItemTitleProps> = ({ title }) => {
  return <div className="list-title">
    <h6 className="title">{title}</h6>
  </div>;
};


export default ListItemTitle;