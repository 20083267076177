import { useCallback, useEffect, useRef, useState } from "react";
import { UseFollowupList } from "../../Types/hooks";
import { IFollowupProspect } from "../../Types/global";
import TaskService from "../services/TasksService";
import useDebounce from "./useDebounce";
import dateHooks from "../../Components/Hooks/dateHooks";

function useFollowup({
    page,
    perPage,
    filters
}: UseFollowupList.Parameters) {
    const [followups, setfollowups] = useState<IFollowupProspect[]>([]);
    const [error, setError] = useState<any>(null);
    const [__page, setPage] = useState<number>(page);
    const [hasMore, setHasMore] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isLoadingMore, setIsLoadingMore] = useState<boolean>(false);
    const [initiateRequest, setInitiateRequest] = useState<boolean>(false);

    const [__filters, __setFilters] = useState<UseFollowupList.Filters>(filters);
    const controller = useRef<AbortController | undefined>();

    const __triggerRequest = useCallback(() => {
        setInitiateRequest(true);
      }, []);
    
    const debouncedTriggerRequest = useDebounce({
        fn: __triggerRequest,
        delay: 800
    });

    useEffect(() => {
        setInitiateRequest(true);
    }, []);

    useEffect(() => {
        if(initiateRequest){
            if (controller.current) controller.current.abort();
            controller.current = new AbortController();
            TaskService.getFollowupPagedTask({
                page: __page,
                perPage: perPage,
                filter: __filters,
                signal: controller.current.signal
            })
            .then((response) => {
                let {data, page_info} = response;
                setfollowups((prev)=> ([...prev, ...data]))
                setHasMore(page_info.hasMore);
                setError(null);
                setIsLoading(false);
                setIsLoadingMore(false);
            })
            .catch((error) => {
                setError(error)
            })
            .finally(() => setInitiateRequest(false))
        }
    }, [initiateRequest, __page, perPage, __filters]);

    const updateFilterKey = useCallback(
        (key: keyof UseFollowupList.Filters, value: string) => {
          __setFilters((prev) => ({
            ...prev,
            [key]: value
          }));
          setPage(1);
          setfollowups([]);
          setIsLoading(true);
          debouncedTriggerRequest();
        },
        [debouncedTriggerRequest]
      );
    
      const setFilters = useCallback(
        (filters: UseFollowupList.Filters) => {
          __setFilters(filters);
          setPage(1);
          setfollowups([]);
          setIsLoading(true);
          debouncedTriggerRequest();
        },
        [debouncedTriggerRequest]
      );

      const loadMoreRecords = useCallback(() => {
        if (hasMore && !isLoadingMore) {
          setPage((c) => c + 1);
          setIsLoadingMore(true);
          setInitiateRequest(true);
        }
      }, [hasMore, isLoadingMore]);
    
    return{
        followups,
        error,
        hasMore,
        isLoading,
        isLoadingMore,
        updateFilterKey,
        loadMoreRecords,
        setFilters
    }
}

export default useFollowup