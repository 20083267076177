import Loadertemplate from "../../../Components/templates/Loadertemplate";

const RecordListLoader: React.FunctionComponent = () => {
  return (
    <div className="pagination-wrapper">
      <div className="pagination-loader">
        <Loadertemplate></Loadertemplate>
      </div>
    </div>
  );
};

export default RecordListLoader;
