import React from 'react';
import './index.css';

interface LoadertemplateProps {
  type?: 'button';
}

const Loadertemplate: React.FC<LoadertemplateProps> = ({ type }) => {
  return (
    <>
      {type === 'button' ? (
        <div className="buttonloader"></div>
      ) : (
        <div className="loader"></div>
      )}
    </>
  );
};

export default Loadertemplate;
