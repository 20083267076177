import { useState } from "react";

import useEffectAfterMount from "./useEffectAfterMount";
import GeographyService from "../services/GeographyServices";
import distanceCalc from "../../Components/Hooks/distanceCalc";

import { IVillage, Model } from "../../Types/global";
import { UseNonAnchorVillagesHook } from "../../Types/hooks";

export default function useNonAnchorVillages({
  latitude,
  longitude,
  onFailure,
  onSuccess,
  additionalFields
}: UseNonAnchorVillagesHook.Parameters): UseNonAnchorVillagesHook.Result {
  const [data, setData] = useState<IVillage[]>([]);
  const [error, setError] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffectAfterMount(() => {
    setIsLoading(true);
    GeographyService.getPagedNonAnchorVillages({
      page: 1,
      latitude,
      longitude,
      perPage: 2000,
      additionalFields
    })
      .then((result) => {
        const villages = result.data
          .map((item) => {
            item.distance = distanceCalc.calculateDistance(
              parseFloat(latitude),
              parseFloat(longitude),
              parseFloat(item.latitude as string),
              parseFloat(item.longitude as string)
            );
            return item;
          })
          .sort(
            (a, b) =>
              a.stats?.total_jcp_visits_year_till_date ||
              0 - (b.stats?.total_jcp_visits_year_till_date || 0)
          );
        setData(villages);
        if (onSuccess) {
          onSuccess.apply(null, [villages as Array<Model.Village>]);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setError(err);
        if (onFailure) {
          onFailure.apply(null, [err]);
        }
      });
  }, [latitude, longitude, onSuccess, onFailure, additionalFields]);

  return {
    data,
    error,
    isLoading
  };
}
