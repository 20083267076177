import React from 'react'
import { IProfileTemplateProp } from '../../../Types/global';

const Profiletemplate : React.FC<IProfileTemplateProp> = ({children}) => {
    return (
        <div className='profile-wrap'>{children}</div>
      )
}


export default Profiletemplate;
