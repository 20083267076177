import React from 'react';
import { IContactProps } from '../../../Types/global';
import CallblackSvg from '../../../icons/CallblackSvg';

const CallContactTemp: React.FC<IContactProps> = ({phoneNumber}) => {
    const telLink = `tel:${phoneNumber}`;
    return(
        <a href={telLink} style={{'pointerEvents': 'auto'}} onClick={(event: React.MouseEvent<HTMLAnchorElement>)=> event.stopPropagation()}>
            <CallblackSvg className='prospect-contact'/>
        </a>
    )
}

export default CallContactTemp;