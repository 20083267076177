import { ICount } from "../../Types/global";
import RequestMethod from "../../shared/enums/RequestMethod";
import { useEffect, useState } from "react";
import HomepageServices from "../../shared/services/HomepageServices";
import api_request from "../../shared/constants/api_request";
import ServerConnectorUtil from "../../shared/utils/ServerConnectorUtil";

const useHome = () => {
    const [followupsCount, setfollowupsCount] = useState<ICount>({
        value: 0
    });
    const [plannedVisitCount, setplannedVisitCount] = useState<ICount>({
        value: 0
    });
    const [overdueCount, setoverdueCount] = useState<ICount>({
        value: 0
    });
    useEffect(()=> {
        let followupAbort = new AbortController();
        let overdueAbort = new AbortController();
        HomepageServices.getCountServices({
            url: api_request.TASK_COUNT,
            method: RequestMethod.POST,
            body: {
                    "type":"Follow-Up",
                    "statuses":["Not Started"]
            },
            signal: followupAbort.signal
        }).then((response)=>  setfollowupsCount(response.data as ICount))
        .catch(error => {
            ServerConnectorUtil.handleServerError(error);
        })
        HomepageServices.getCountServices({
            url: api_request.PROSPECT_COUNT,
            method: RequestMethod.POST,
            body: {
                enquiry_classification:"Overdue Hot Prospect"
            },
            signal: overdueAbort.signal
        }).then((response)=> setoverdueCount(response.data as ICount))
        .catch(error => {
            ServerConnectorUtil.handleServerError(error);
        })
        return () => {
            followupAbort.abort();
            overdueAbort.abort();
        }
    }, [])
    return{
        followupsCount,
        plannedVisitCount,
        overdueCount,
        setplannedVisitCount
    }
    
}

export default useHome;