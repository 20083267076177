export type ListItemKeyValueProps = {
  label: string;
  value: string;
};

const ListItemKeyValue: React.FC<ListItemKeyValueProps> = ({ label, value }) => {
  return (
    <div className="key-value-container">
      <p className="key">{label}:</p>
      <p className="value">{value}</p>
    </div>
  );
};

export default ListItemKeyValue;
