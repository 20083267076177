import "./index.css";

import React, { useCallback, useRef, useState } from "react";

import Buttontemplate from "../Components/templates/Buttontemplate";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Gridtemplate from "../Components/templates/Gridtemplate";
import { ArrowSvg } from "../icons/ArrowSvg";
import { Link, useNavigate, useParams } from "react-router-dom";
import useJcPlanningById from "../shared/hooks/useJcPlanningById";
import JcPlanningAdditionalFields from "../shared/enums/JcPlanningAdditionalFields";
import Loadertemplate from "../Components/templates/Loadertemplate";
import EmptyRecordSvg from "../icons/EmptyRecordSvg";
import useCurrentLocation from "../shared/hooks/useCurrentLocation";
import distanceCalc from "../Components/Hooks/distanceCalc";
import JcPlanningService from "../shared/services/JcPlanningService";
import AppUrlConstants from "../shared/constants/AppUrlConstants";
import ServerConnectorUtil from "../shared/utils/ServerConnectorUtil";
import AppQueryParameters from "../shared/enums/AppQueryParameters";
import TractorUserDatabaseCustomerType from "../shared/enums/TractorUserDatabaseCustomerType";
import TractorUserDatabaseSatisfactionType from "../shared/enums/TractorUserDatabaseSatisfactionType";
import EmptyTemp from "../Components/templates/EmptyTemp";

const i18nTranslated = {
  villageDetails: "Village details",
  btnProceedTo: "Proceed To",
  checkIn: "Check-in",
  totalProspects: "Total Prospects",
  mfSatisfied: "MF Satisfied",
  mfDissatisfied: "MF Dissatisfied",
  mpcCoordinators: "MPC Co-ordinators",
  compSatisfied: "Competitor Satisfied",
  compDissatisfied: "Competitor Dissatisfied",
  recommendation: "Recommendations"
};

const CheckInJcp: React.FC = () => {
  const navigate = useNavigate();
  const { jcpId } = useParams() as Record<string, string>;
  const [additionalFields] = useState<Array<string>>([
    JcPlanningAdditionalFields.VILLAGE_STATS_TOTAL_PROSPECTS,
    JcPlanningAdditionalFields.VILLAGE_STATS_TOTAL_TUDB_MF_SATISFIED,
    JcPlanningAdditionalFields.VILLAGE_STATS_TOTAL_TUDB_MF_UNSATISFIED,
    JcPlanningAdditionalFields.VILLAGE_STATS_TOTAL_TUDB_COMPETITION_SATISFIED,
    JcPlanningAdditionalFields.VILLAGE_STATS_TOTAL_TUDB_COMPETITION_UNSATISFIED
  ]);

  const [isProcessing, setIsProcessing] = useState<boolean>(false);

  const { data, isLoading } = useJcPlanningById({
    id: jcpId,
    additionalFields
  });

  const { latitude, longitude } = useCurrentLocation();

  const checkInJcp = useCallback(() => {
    if (latitude === undefined || longitude === undefined) {
      console.log("Unable to access the current location.");
      let error = {
        message: "Unable to access the current location."
      };
      ServerConnectorUtil.handleServerError(error);
      return;
    }
    const distance = distanceCalc.calculateDistance(
      latitude,
      longitude,
      parseFloat(data!.village.latitude),
      parseFloat(data!.village.longitude)
    );

    if (!distance || distance > 6) {
      let error = {
        message: 'Not allowed to check-in'
      };
      ServerConnectorUtil.handleServerError(error);
      return;
    }

    setIsProcessing(true);
    JcPlanningService.checkInJcp({ id: jcpId, latitude, longitude })
      .then(() => {
        setIsProcessing(false);
        navigate(AppUrlConstants.SUCCESS + AppUrlConstants.CHECK_IN);
      })
      .catch(ServerConnectorUtil.handleServerError)
      .finally(() => {
        setIsProcessing(false);
      });
  }, [data, jcpId, latitude, longitude, navigate]);

  return (
    <>
      {isLoading ? (
        <div className="loader-wrapper">
          <Loadertemplate />
        </div>
      ) : data === undefined ? (
        <EmptyTemp/>
      ) : (
        <div className="village-details">
          <div className="village-details-header">
            <span className={`village-bryg edd-type ${data.village.color_code}`}></span>
            <div className="village-detail">
              <h1 className="village fFamily">{data.village.name}</h1>
              <span className="village-desc">
                <span className="village-taluka">{data.village.taluka}</span>
                <span className="grey-dot"></span>
                <span className="tiv-type">{data.village.tiv}</span>
              </span>
            </div>
          </div>
          <div className="village-details-body">
            <Container>
              <Row>
                <Col>
                  <Gridtemplate
                    gridHead={
                      <>
                        <span className="title">
                          {i18nTranslated.totalProspects}
                        </span>
                        <Link
                          to={{
                            pathname:
                              AppUrlConstants.INNER_BASE +
                              AppUrlConstants.MENU +
                              AppUrlConstants.TRACTOR_USER_DATABASES,
                            search: new URLSearchParams({
                              [AppQueryParameters.COMMON.KEYS.TITLE]:
                                AppQueryParameters.TRACTOR_USER_DATABASE.VALUES
                                  .RECORDS_VIEW_TITLE,
                              [AppQueryParameters.TRACTOR_USER_DATABASE.KEYS
                                .VILLAGE_COLOR_CODE]: data.village.color_code
                            }).toString()
                          }}
                        >
                          <ArrowSvg />
                        </Link>
                      </>
                    }
                    gridContent={
                      <span className="value">
                        {data.village.stats.total_prospects || 0}
                      </span>
                    }
                  ></Gridtemplate>
                </Col>
                <Col>
                  <Gridtemplate
                    gridHead={
                      <>
                        <span className="title">
                          {i18nTranslated.mfSatisfied}
                        </span>
                        <Link
                          to={{
                            pathname:
                              AppUrlConstants.INNER_BASE +
                              AppUrlConstants.MENU +
                              AppUrlConstants.TRACTOR_USER_DATABASES,
                            search: new URLSearchParams({
                              [AppQueryParameters.COMMON.KEYS.TITLE]:
                                AppQueryParameters.TRACTOR_USER_DATABASE.VALUES
                                  .RECORDS_VIEW_TITLE,
                              [AppQueryParameters.TRACTOR_USER_DATABASE.KEYS
                                .TYPE]:
                                TractorUserDatabaseCustomerType.MF_CUSTOMER,
                              [AppQueryParameters.TRACTOR_USER_DATABASE.KEYS
                                .VILLAGE_COLOR_CODE]: data.village.color_code,
                              [AppQueryParameters.TRACTOR_USER_DATABASE.KEYS
                                .IS_SATISFIED_WITH_HIS_TRACTOR]:
                                TractorUserDatabaseSatisfactionType.YES
                            }).toString()
                          }}
                        >
                          <ArrowSvg />
                        </Link>
                      </>
                    }
                    gridContent={
                      <span className="value">
                        {data.village.stats.total_tudb_mf_satisfied || 0}
                      </span>
                    }
                  ></Gridtemplate>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Gridtemplate
                    gridHead={
                      <>
                        <span className="title">
                          {i18nTranslated.mfDissatisfied}
                        </span>
                        <Link
                          to={{
                            pathname:
                              AppUrlConstants.INNER_BASE +
                              AppUrlConstants.MENU +
                              AppUrlConstants.TRACTOR_USER_DATABASES,
                            search: new URLSearchParams({
                              [AppQueryParameters.COMMON.KEYS.TITLE]:
                                AppQueryParameters.TRACTOR_USER_DATABASE.VALUES
                                  .RECORDS_VIEW_TITLE,
                              [AppQueryParameters.TRACTOR_USER_DATABASE.KEYS
                                .TYPE]:
                                TractorUserDatabaseCustomerType.MF_CUSTOMER,
                              [AppQueryParameters.TRACTOR_USER_DATABASE.KEYS
                                .VILLAGE_COLOR_CODE]: data.village.color_code,
                              [AppQueryParameters.TRACTOR_USER_DATABASE.KEYS
                                .IS_SATISFIED_WITH_HIS_TRACTOR]:
                                TractorUserDatabaseSatisfactionType.NO
                            }).toString()
                          }}
                        >
                          <ArrowSvg />
                        </Link>
                      </>
                    }
                    gridContent={
                      <span className="value">
                        {data.village.stats.total_tudb_mf_unsatisfied || 0}
                      </span>
                    }
                  ></Gridtemplate>
                </Col>
                <Col>
                  <Gridtemplate
                    gridHead={
                      <>
                        <span className="title">
                          {i18nTranslated.mpcCoordinators}
                        </span>
                        <Link
                          to={{
                            pathname:
                              AppUrlConstants.INNER_BASE +
                              AppUrlConstants.MENU +
                              AppUrlConstants.TRACTOR_USER_DATABASES,
                            search: new URLSearchParams({
                              [AppQueryParameters.COMMON.KEYS.TITLE]:
                                AppQueryParameters.TRACTOR_USER_DATABASE.VALUES
                                  .RECORDS_VIEW_TITLE,
                              [AppQueryParameters.TRACTOR_USER_DATABASE.KEYS
                                .VILLAGE_COLOR_CODE]: data.village.color_code
                            }).toString()
                          }}
                        >
                          <ArrowSvg />
                        </Link>
                      </>
                    }
                    gridContent={<span className="value">0</span>}
                  ></Gridtemplate>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Gridtemplate
                    gridHead={
                      <>
                        <span className="title">
                          {i18nTranslated.compSatisfied}
                        </span>
                        <Link
                          to={{
                            pathname:
                              AppUrlConstants.INNER_BASE +
                              AppUrlConstants.MENU +
                              AppUrlConstants.TRACTOR_USER_DATABASES,
                            search: new URLSearchParams({
                              [AppQueryParameters.COMMON.KEYS.TITLE]:
                                AppQueryParameters.TRACTOR_USER_DATABASE.VALUES
                                  .RECORDS_VIEW_TITLE,
                              [AppQueryParameters.TRACTOR_USER_DATABASE.KEYS
                                .TYPE]:
                                TractorUserDatabaseCustomerType.COMPETITION_CUSTOMER,
                              [AppQueryParameters.TRACTOR_USER_DATABASE.KEYS
                                .VILLAGE_COLOR_CODE]: data.village.color_code,
                              [AppQueryParameters.TRACTOR_USER_DATABASE.KEYS
                                .IS_SATISFIED_WITH_HIS_TRACTOR]:
                                TractorUserDatabaseSatisfactionType.YES
                            }).toString()
                          }}
                        >
                          <ArrowSvg />
                        </Link>
                      </>
                    }
                    gridContent={
                      <span className="value">
                        {data.village.stats.total_tudb_competition_satisfied ||
                          0}
                      </span>
                    }
                  ></Gridtemplate>
                </Col>
                <Col>
                  <Gridtemplate
                    gridHead={
                      <>
                        <span className="title">
                          {i18nTranslated.compDissatisfied}
                        </span>
                        <Link
                          to={{
                            pathname:
                              AppUrlConstants.INNER_BASE +
                              AppUrlConstants.MENU +
                              AppUrlConstants.TRACTOR_USER_DATABASES,
                            search: new URLSearchParams({
                              [AppQueryParameters.COMMON.KEYS.TITLE]:
                                AppQueryParameters.TRACTOR_USER_DATABASE.VALUES
                                  .RECORDS_VIEW_TITLE,
                              [AppQueryParameters.TRACTOR_USER_DATABASE.KEYS
                                .TYPE]:
                                TractorUserDatabaseCustomerType.COMPETITION_CUSTOMER,
                              [AppQueryParameters.TRACTOR_USER_DATABASE.KEYS
                                .VILLAGE_COLOR_CODE]: data.village.color_code,
                              [AppQueryParameters.TRACTOR_USER_DATABASE.KEYS
                                .IS_SATISFIED_WITH_HIS_TRACTOR]:
                                TractorUserDatabaseSatisfactionType.NO
                            }).toString()
                          }}
                        >
                          <ArrowSvg />
                        </Link>
                      </>
                    }
                    gridContent={
                      <span className="value">
                        {data.village.stats.total_tudb_competition_unatisfied ||
                          0}
                      </span>
                    }
                  ></Gridtemplate>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Gridtemplate
                    gridHead={
                      <>
                        <span className="title">
                          {i18nTranslated.recommendation}
                        </span>
                        <Link
                          to={{
                            pathname:
                              AppUrlConstants.INNER_BASE +
                              AppUrlConstants.MENU +
                              AppUrlConstants.TRACTOR_USER_DATABASES,
                            search: new URLSearchParams({
                              [AppQueryParameters.COMMON.KEYS.TITLE]:
                                AppQueryParameters.TRACTOR_USER_DATABASE.VALUES
                                  .RECORDS_VIEW_TITLE,
                              [AppQueryParameters.TRACTOR_USER_DATABASE.KEYS
                                .VILLAGE_COLOR_CODE]: data.village.color_code
                            }).toString()
                          }}
                        >
                          <ArrowSvg />
                        </Link>
                      </>
                    }
                    gridContent={<span className="value">0</span>}
                  ></Gridtemplate>
                </Col>
              </Row>
            </Container>
          </div>
          <div className="village-details-footer">
            <Buttontemplate
              type="primary"
              buttonWidth="100%"
              callback={checkInJcp}
            >
              {isProcessing ? (
                <div className="button-loader">
                  <Loadertemplate type="button" />
                </div>
              ) : (
                `${i18nTranslated.btnProceedTo} ${i18nTranslated.checkIn}`
              )}
            </Buttontemplate>
          </div>
        </div>
      )}
    </>
  );
};

export default CheckInJcp;
