import React, { useCallback, useState } from 'react'
import useFollowup from '../../../shared/hooks/useFollowup'
import RecordCollection from '../../../shared/components/RecordCollection';
import { UseFollowupList } from '../../../Types/hooks';
import { IOptions } from '../../../Types/global';
import CallContactTemp from '../../templates/CallContactTemp';
import WhatsappContactTemp from '../../templates/WhatsappContactTemp';
import AppUrlConstants from '../../../shared/constants/AppUrlConstants';
import AppQueryParameters from '../../../shared/enums/AppQueryParameters';
import useScroll from '../../../shared/hooks/useScroll';
import dateHooks from '../../Hooks/dateHooks';
import DateFormat from '../../../shared/enums/DateFormat';
import { Link } from 'react-router-dom';

const i18nTranslated = {
    modelInterestLabel: 'Model Interested',
    eddLabel: 'EDD',
    contactLabel: 'Contact',
    typeLabel: 'Type',
    statusLabel: 'Status',
}
const allDropDownOptions = [
    { value: 'name', label: 'Name' }
];
const Tasks: React.FC = () => {
    const [initFilter] = useState({
        type: "Follow-Up",
        statuses: ["Not Started"]
    })

    const {followups, isLoading, updateFilterKey, setFilters, isLoadingMore, loadMoreRecords} = useFollowup({
        page: 1,
        perPage: 50,
        filters: initFilter
    })
    const onScrollEventListener = useScroll(loadMoreRecords);
    const [value, setValue] = useState('');
    const [searchKey, setSearchKey] = useState<string>('name');
    const [searchLabel, setSearchLabel] = useState<string>(
        allDropDownOptions[0].label.toLowerCase()
      );
    const onNameChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        setValue(value);
        updateFilterKey(searchKey as keyof UseFollowupList.Filters, value);
    },[searchKey, updateFilterKey]);
    
    const onDropDownChange = useCallback((value: IOptions) => {
        setSearchKey(value.value);
        setValue('');
        setFilters(initFilter);
        setSearchLabel(value.label.toLowerCase());
    }, [initFilter, setFilters]);
    return (
        <RecordCollection>
            <RecordCollection.SearchBox
                value={value}
                onChange={onNameChange}
                placeholder={'Search by ' + searchLabel}
                initialDropDownValue={allDropDownOptions[0]}
                dropDownOptions={allDropDownOptions}
                onDropDownChange={onDropDownChange}
            />
            <RecordCollection.List onScroll={onScrollEventListener}>
                {
                    isLoading ?
                        (<RecordCollection.ListLoader/>)
                    : followups?.length === 0 ?
                        (<RecordCollection.ListEmpty/>)
                    :   followups && followups.map((item)=>(
                            <RecordCollection.ListItemWrapper>
                                <Link to={`${AppUrlConstants.INNER_BASE}${AppUrlConstants.PROSPECT}/${item.prospect.id}?${AppQueryParameters.COMMON.KEYS.TITLE}=${AppQueryParameters.PROSPECT.VALUES.PROSPECT_DETAILS}`} className='listDetailLink'>
                                    <RecordCollection.ListItemLink/>
                                    <RecordCollection.ListItem>
                                        <div className='dF'>
                                            <RecordCollection.ListItemColorCode color_code={item.prospect.contact?.color_code ? item.prospect.contact?.color_code : undefined}/>
                                            <RecordCollection.ListItemTitle title={item.prospect.contact?.name}/>
                                        </div>
                                        <RecordCollection.ListItemKeyValue 
                                            label={i18nTranslated.typeLabel}
                                            value={item.prospect.enquiry_classification ? item.prospect.enquiry_classification : '--'}
                                        />
                                        <RecordCollection.ListItemKeyValue 
                                            label={i18nTranslated.eddLabel}
                                            value={item.prospect.edd ? dateHooks.formatDate(item.prospect.edd, DateFormat.DISPLAY_DATE) : '--'}
                                        />
                                        <RecordCollection.ListItemKeyValue 
                                            label={'Follow up Date'}
                                            value={item.due_date ? dateHooks.formatDate(item.due_date, DateFormat.DISPLAY_DATE) : '--'}
                                        />
                                        <RecordCollection.ListItemKeyValue 
                                            label={i18nTranslated.modelInterestLabel}
                                            value={item.prospect.interested_model_family ? item.prospect.interested_model_family : '--'}
                                        />
                                        <RecordCollection.ListItemKeyValue 
                                            label={i18nTranslated.statusLabel}
                                            value={item.prospect.enquiry_status ? item.prospect.enquiry_status : '--'}
                                        />
                                        <RecordCollection.ListAction>
                                            <CallContactTemp
                                                className="prospect-contact"
                                                phoneNumber={item.prospect.contact?.mobile_number}
                                            />
                                            <WhatsappContactTemp
                                                phoneNumber={item.prospect.contact?.mobile_number}
                                            />
                                        </RecordCollection.ListAction>
                                    </RecordCollection.ListItem>
                                </Link>
                            </RecordCollection.ListItemWrapper>
                    ))
                }
                {(!isLoading && isLoadingMore) && <RecordCollection.LoadMoreLoader />}
            </RecordCollection.List>
        </RecordCollection>
    )
}

export default Tasks