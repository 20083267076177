import RequestHandler from '../handlers/RequestHandler';
import RequestMethod from '../enums/RequestMethod';
import { Framework, ICount, IPageResponse } from '../../Types/global';

export default class HomepageServices{
    public static getCountServices = ({url, method, params, body, signal}: {url: string, method: RequestMethod, params?: any, body?: unknown, signal: AbortSignal}): Promise<Framework.IResult<ICount>> => {
        let reqHandler = new RequestHandler(url, method);
        if(params){
            for (const key in params) {
                if (Object.prototype.hasOwnProperty.call(params, key)) {
                    reqHandler.addParam(key, params[key])
                }
            }
        }
        if(body){
            reqHandler.setBody(body);
        }
        reqHandler.setAbortSignal(signal);
        return reqHandler.execute().then(response => response.data as Framework.IResult<ICount>);
    }
}