import React, { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import ProspectServices from '../services/ProspectServices';
import api_request from '../constants/api_request';
import RequestMethod from '../enums/RequestMethod';
import ServerConnectorUtil from '../utils/ServerConnectorUtil';
import { IProspect } from '../../Types/global';
import { UsePagedIdProspect } from '../../Types/hooks';

export default function useIdPagedProspect({onSuccess, onFailure}: UsePagedIdProspect.Parameters){
    let {id} = useParams();
    const [isLoading, setisLoading] = useState(true);
    useEffect(()=> {
        let prospectDetailAbort = new AbortController();
        ProspectServices.getProspectDetails({
            url: api_request.PROSPECT_DETAILS+id,
            method: RequestMethod.GET,
            signal: prospectDetailAbort.signal
        })
        .then(response =>{
            onSuccess(response.data);
            setisLoading(false);
        })
        .catch(error => onFailure(error));
        return()=> {
            prospectDetailAbort.abort();
        }
    }, [onSuccess, onFailure])
    return {
        isLoading
    }
}
