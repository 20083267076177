import classNames from 'classnames';
import React, { ReactNode, useEffect, useRef, useState } from 'react';
import './index.css';
import NavigateArrowSvg from '../../../icons/NavigateArrowSvg';

interface IOnlyContentProps{
    children: ReactNode,
    accordionWrapClass?: string,
    accordionContentClass?: string,
    hideIcon?: boolean
}
const ListBoxtemplate : React.FC<IOnlyContentProps> = ({ children, accordionWrapClass, accordionContentClass, hideIcon }) => {
    const [showAccrodion, setshowAccrodion] = useState<boolean>(false);
    const contentDom = useRef<HTMLDivElement>(null);
    const wrapDom = useRef<HTMLDivElement>(null);
    const [collapseHeight, setcollapseHeight] = useState({});
    const openAccordion = (event: React.MouseEvent<HTMLDivElement>): void=>{
        let element = contentDom.current;
        let wrapEle = wrapDom.current;
        if(element && wrapEle){
            let wrapScrollHeight= wrapEle.scrollHeight;
            if(wrapScrollHeight >= 250){
                setshowAccrodion(!showAccrodion);
                let extendHeight = element.offsetHeight+30;
                if(!showAccrodion){
                    setcollapseHeight({'maxHeight': `${extendHeight}px`});
                }else{
                    setcollapseHeight({});
                }
            }
        }
    }
    return(
        <div style={collapseHeight} className={classNames('accordion-wrapper oFHidden', accordionWrapClass && accordionWrapClass, {'disableOpen': hideIcon})} ref={wrapDom}  onClick={openAccordion}>
            <div className={classNames('accordion-content', accordionContentClass && accordionContentClass)} ref={contentDom}>
                {children}
            </div>
            <span className={classNames('open-button',{'rotate-arrow': showAccrodion}, {'hide': hideIcon})}>
                <NavigateArrowSvg/>
            </span>
        </div>
    )
}
export default ListBoxtemplate;