import CustomEventEmiterUtil from "../utils/CustomEventEmitterUtil";
import useEffectAfterMount from "./useEffectAfterMount";

export default function useCustomEventListener(
  eventName: string,
  listener: (...params: Array<any>) => void
) {
  useEffectAfterMount(() => {
    const subscription = CustomEventEmiterUtil.addListener(eventName, listener);

    return () => {
      subscription.remove();
    };
  }, []);
}
