import React from 'react'
import Maintemplate from '../../templates/Maintemplate';
import { Outlet } from 'react-router-dom';

const MainLayout:React.FC = () => {
  return (
    <Maintemplate>
        <Outlet/>
    </Maintemplate>
  )
}

export default MainLayout;