import React, { useCallback, useContext, useRef, useState } from "react";
import "./index.css";
import { IDateStrRangeObj, IUserContext } from "../../../Types/global";
import Accordiontemplate from "../../templates/Accordiontemplate";
import Buttontemplate from "../../templates/Buttontemplate";
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Loadertemplate from "../../templates/Loadertemplate";
import DateFilter from "../../templates/Date-Filter";
import ServerConnectorUtil from "../../../shared/utils/ServerConnectorUtil";
import AppUrlConstants from "../../../shared/constants/AppUrlConstants";
import AppQueryParameters from "../../../shared/enums/AppQueryParameters";
import EmptyTemp from "../../templates/EmptyTemp";
import JcPlanningStatus from "../../../shared/enums/JcPlanningStatus";
import usePagedJcPlannings from "../../../shared/hooks/useJcPlannings";
import moment from "moment";
import { UseJcPlanningsHook } from "../../../Types/hooks";
import useCurrentLocation from "../../../shared/hooks/useCurrentLocation";
import distanceCalc from "../../Hooks/distanceCalc";
import useEffectAfterMount from "../../../shared/hooks/useEffectAfterMount";
import dateHooks from "../../Hooks/dateHooks";
import DateFormat from "../../../shared/enums/DateFormat";
import axios from "axios";
import AppConstants from "../../../shared/constants/AppContants";
import { UserContext } from "../../HOC/UserContext";
import calendar from "../../../shared/constants/calendar";
import ProspectStage from "../../../shared/enums/ProspectStage";
import DateTimeProcessor from "../../../shared/processors/DateTimeProcessor";

const i18nTranslated = {
  visit_summary: "Village visits summary",
  visit_schedule: "Scheduled Visits",
  create_jcp: "Create JCP",
  planned_label: "Planned",
  visited_label: "Visited TD",
  tractorusersadd_label: "Tractor Users Added",
  enquiriesgenerate_label: "Enquiries Generated",
  hpsgenerate_label: "HPs Generated",
  deliveries_label: "Deliveries"
};

type UserStatistics = {
  total_deliveries: number;
  total_delivery_target: number;
  total_enquires: number;
  total_hot_prospects: number;
  total_jc_plannings_planned: number;
  total_jc_plannings_planned_till_date: number;
  total_jc_plannings_visited_till_date: number;
  total_tractor_user_database_added: number;
};

const additionalFields = ["village.stats.total_visits_year_till_date"];
const DspJcp: React.FC = () => {
  const navigate = useNavigate();
  const currentUser = useContext(UserContext) as IUserContext;
  const userStatisticsController = useRef<AbortController | null>(null);
  const [jcPlanningDateFilter, setJcPlanningDateFilter] = useState<
    string | IDateStrRangeObj
  >(calendar.CURRENTMONTH);

  const [userStatistics, setUserStatistcis] = useState<UserStatistics>({
    total_deliveries: 0,
    total_delivery_target: 0,
    total_enquires: 0,
    total_hot_prospects: 0,
    total_jc_plannings_planned: 0,
    total_jc_plannings_planned_till_date: 0,
    total_jc_plannings_visited_till_date: 0,
    total_tractor_user_database_added: 0
  });
  const [jcPlanningFilters, setJcPlanningFilters] =
    useState<UseJcPlanningsHook.Filters>(() => {
      const { start_date, end_date } =
        dateHooks.formatFilterValueWithDateFormat(
          calendar.CURRENTMONTH,
          DateFormat.ISO_DATE
        );
      return {
        checkedIn: false,
        dateStartValue: moment(start_date, DateFormat.ISO_DATE)
          .toDate()
          .getTime(),
        dateEndValue: moment(end_date, DateFormat.ISO_DATE).toDate().getTime(),
        statuses: [JcPlanningStatus.APPROVED]
      };
    });
  const [isLoadingUserStatistics, setIsLoadingUserStatistics] =
    useState<boolean>(true);

  const {
    latitude: currentLocationLatitude,
    longitude: currentLocationLongitude
  } = useCurrentLocation();
  const calculateDistance = useCallback(
    (
      latitude1: number,
      longitude1: number,
      latitude2: string,
      longitude2: string
    ) => {
      if (!latitude1 || !longitude1) {
        return "NA";
      } else {
        const distance = distanceCalc.calculateDistance(
          latitude1,
          longitude1,
          parseFloat(latitude2),
          parseFloat(longitude2)
        );
        if (distance > 50) {
          return "50+ KM";
        } else {
          return distance.toFixed(2) + " KM";
        }
      }
    },
    []
  );

  const { data: jcPlannings, isLoading: isJcPlanningLoading } =
    usePagedJcPlannings({
      page: 1,
      perPage: 200,
      additionalFields,
      filters: jcPlanningFilters,
      onFailure: ServerConnectorUtil.handleServerError
    });

  useEffectAfterMount(() => {
    setIsLoadingUserStatistics(true);
    let endDateTime: string = "";
    let startDateTime: string = "";

    if (jcPlanningDateFilter) {
      const { start_date, end_date } =
        dateHooks.formatFilterValueWithDateFormat(
          jcPlanningDateFilter,
          DateFormat.ISO_DATE_WITH_TIME
        );

      endDateTime = end_date;
      startDateTime = start_date;
      setJcPlanningFilters((prev) => ({
        ...prev,
        dateStartValue: moment(start_date, DateFormat.ISO_DATE_WITH_TIME)
          .toDate()
          .getTime(),
        dateEndValue: moment(end_date, DateFormat.ISO_DATE_WITH_TIME)
          .toDate()
          .getTime()
      }));
    } else {
      setJcPlanningFilters((prev) => ({
        ...prev,
        dateEndValue: undefined,
        dateStartValue: undefined
      }));
    }

    if (userStatisticsController.current) {
      userStatisticsController.current.abort();
    }
    userStatisticsController.current = new AbortController();
    axios
      .get(
        `https://tafe-akilavan-50022289662.catalystappsail.in/api/v1/dsp-users/${currentUser.user.id}/statistics`,
        {
          params: {
            ...(startDateTime ? { start_date_time: startDateTime } : {}),
            ...(endDateTime ? { end_date_time: endDateTime } : {}),
            fields:
              "stats.total_enquiries,stats.total_deliveries,stats.total_hot_prospects,stats.total_tractor_user_database_added,stats.total_jc_plannings_planned,stats.total_jc_plannings_planned_till_date,stats.total_jc_plannings_visited_till_date"
          },
          signal: userStatisticsController.current.signal,
          headers: {
            "app-secret-key": AppConstants.APP_SECRET_KEY
          }
        }
      )
      .then((response) => {
        setUserStatistcis(response.data.data);
        setIsLoadingUserStatistics(false);
      })
      .catch(ServerConnectorUtil.handleServerError);
  }, [jcPlanningDateFilter]);

  const goToEnquriesMasterList = useCallback(
    (
      event: React.MouseEvent<
        HTMLDivElement | HTMLSpanElement | HTMLTableRowElement
      >
    ) => {
      const element = event.currentTarget as HTMLElement;
      const title = element.getAttribute("data-title") as string;
      const stages = element.getAttribute("data-stages");
      const eddEndValue = element.getAttribute("data-edd-end-value");
      const eddStartValue = element.getAttribute("data-edd-start-value");
      const createdTimeEndValue = element.getAttribute(
        "data-created-time-end-value"
      );
      const createdTimeStartValue = element.getAttribute(
        "data-created-time-start-value"
      );
      const dateOfDeliveryEndValue = element.getAttribute(
        "data-date-of-delivery-end-value"
      );
      const dateOfDeliveryStartValue = element.getAttribute(
        "data-date-of-delivery-start-value"
      );

      const searchParams = new URLSearchParams({
        title,
        ...(stages ? { stages } : {})
      });

      if (eddEndValue) {
        searchParams.set("edd_end_value", eddEndValue);
      }

      if (eddStartValue) {
        searchParams.set("edd_start_value", eddStartValue);
      }

      if (createdTimeEndValue) {
        searchParams.set("created_time_end_value", createdTimeEndValue);
      }
      if (createdTimeStartValue) {
        searchParams.set("created_time_start_value", createdTimeStartValue);
      }
      if (dateOfDeliveryEndValue) {
        searchParams.set("date_of_delivery_end_value", dateOfDeliveryEndValue);
      }
      if (dateOfDeliveryStartValue) {
        searchParams.set(
          "date_of_delivery_start_value",
          dateOfDeliveryStartValue
        );
      }

      navigate({
        pathname: `/innerbase/prospects`,
        search: searchParams.toString()
      });
    },
    [navigate]
  );

  const getDataAttributesForDeliveryDate = useCallback(() => {
    if (!jcPlanningDateFilter) {
      return {};
    } else {
      const { start_date, end_date } =
        dateHooks.formatFilterValueWithDateFormat(
          jcPlanningDateFilter,
          DateFormat.ISO_DATE_WITH_TIME
        );

      return {
        "data-date-of-delivery-start-value": start_date,
        "data-date-of-delivery-end-value": end_date
      };
    }
  }, [jcPlanningDateFilter]);

  const getDataAttributesForCreatedTime = useCallback(() => {
    if (!jcPlanningDateFilter) {
      return {};
    } else {
      const { start_date, end_date } =
        dateHooks.formatFilterValueWithDateFormat(
          jcPlanningDateFilter,
          DateFormat.ISO_DATE_WITH_TIME
        );

      return {
        "data-created-time-start-value": start_date,
        "data-created-time-end-value": end_date
      };
    }
  }, [jcPlanningDateFilter]);

  const goToTractorMasterList = useCallback(() => {
    let startDate: string | undefined;
    let endDate: string | undefined;

    if (jcPlanningDateFilter) {
      const { start_date, end_date } =
        dateHooks.formatFilterValueWithDateFormat(
          jcPlanningDateFilter,
          DateFormat.ISO_DATE
        );

      startDate = start_date;
      endDate = end_date;
    }

    const searchParams = new URLSearchParams({
      title: "tractor_master"
    });

    if (startDate) {
      searchParams.append("start_date", startDate);
    }

    if (endDate) {
      searchParams.append("end_date", endDate);
    }

    navigate({
      pathname: "/innerbase/menu/tractoruser",
      search: searchParams.toString()
    });
  }, [jcPlanningDateFilter, navigate]);

  return (
    <>
      <div className="jcp-content">
        <DateFilter setState={setJcPlanningDateFilter} />
        {isLoadingUserStatistics || isJcPlanningLoading ? (
          <div className="loader-wrapper">
            <Loadertemplate />
          </div>
        ) : (
          <>
            <div className="visit-summary">
              <div className="summary-header">
                {i18nTranslated.visit_summary}
              </div>
              <div className="summary-wrap">
                <div className="summary-grid">
                  {
                    <Container className="village-visit-container">
                      <Row className="village-visit-row">
                        <Col xs={6} md={6} className="village-visit-col">
                          <span className="filter-name">Planned</span>
                          <span className="filter-value">
                            {userStatistics.total_jc_plannings_planned}
                          </span>
                        </Col>
                        <Col xs={6} md={6} className="village-visit-col">
                          <span className="filter-name">Planned TD</span>
                          <span className="filter-value">
                            {
                              userStatistics.total_jc_plannings_planned_till_date
                            }
                          </span>
                        </Col>
                        <Col xs={6} md={6} className="village-visit-col">
                          <span className="filter-name">Visited TD</span>
                          <span className="filter-value">
                            {
                              userStatistics.total_jc_plannings_visited_till_date
                            }
                          </span>
                        </Col>
                        <Col
                          xs={6}
                          md={6}
                          className="village-visit-col"
                          onClick={goToTractorMasterList}
                          {...getDataAttributesForCreatedTime()}
                        >
                          <span className="filter-name">
                            TractorUsers Added
                          </span>
                          <span
                            className="filter-value"
                            style={{ color: "var(--primary-stats)" }}
                          >
                            {userStatistics.total_tractor_user_database_added}
                          </span>
                        </Col>
                        <Col
                          xs={6}
                          md={6}
                          className="village-visit-col"
                          data-title="enquiries_title"
                          data-stages={[
                            ProspectStage.F1,
                            ProspectStage.F2,
                            ProspectStage.F3,
                            ProspectStage.F4,
                            ProspectStage.F5
                          ].join(",")}
                          data-edd-start-value={DateTimeProcessor.format(
                            DateTimeProcessor.startOfDay(
                              DateTimeProcessor.addDays(new Date(), 31)
                            ) as number
                          )}
                          data-edd-end-value={DateTimeProcessor.format(
                            DateTimeProcessor.startOfDay(
                              DateTimeProcessor.addDays(new Date(), 99)
                            ) as number
                          )}
                          onClick={goToEnquriesMasterList}
                          {...getDataAttributesForCreatedTime()}
                        >
                          <span className="filter-name">
                            Enquires Generated
                          </span>
                          <span
                            className="filter-value"
                            style={{ color: "var(--primary-stats)" }}
                          >
                            {userStatistics.total_enquires}
                          </span>
                        </Col>
                        <Col
                          xs={6}
                          md={6}
                          className="village-visit-col"
                          data-title="hot_prospect_title"
                          data-stages={[
                            ProspectStage.F1,
                            ProspectStage.F2,
                            ProspectStage.F3,
                            ProspectStage.F4,
                            ProspectStage.F5
                          ].join(",")}
                          data-edd-start-value={DateTimeProcessor.format(
                            DateTimeProcessor.startOfDay(Date.now()) as number
                          )}
                          data-edd-end-value={DateTimeProcessor.format(
                            DateTimeProcessor.startOfDay(
                              DateTimeProcessor.addDays(new Date(), 30)
                            ) as number
                          )}
                          {...getDataAttributesForCreatedTime()}
                          onClick={goToEnquriesMasterList}
                        >
                          <span className="filter-name">HPs Generated</span>
                          <span
                            className="filter-value"
                            style={{ color: "var(--primary-stats)" }}
                          >
                            {userStatistics.total_hot_prospects}
                          </span>
                        </Col>
                        <Col
                          xs={6}
                          md={6}
                          className="village-visit-col"
                          data-title="delivery_title"
                          data-stages={[
                            ProspectStage.R6,
                            ProspectStage.R7,
                            ProspectStage.R8,
                            ProspectStage.R9,
                            ProspectStage.R10,
                            ProspectStage.DELIVERY
                          ].join(",")}
                          {...getDataAttributesForDeliveryDate()}
                          onClick={goToEnquriesMasterList}
                        >
                          <span className="filter-name">Deliveries</span>
                          <span
                            className="filter-value"
                            style={{ color: "var(--primary-stats)" }}
                          >
                            {userStatistics.total_deliveries}
                          </span>
                        </Col>
                      </Row>
                    </Container>
                  }
                </div>
              </div>
            </div>
            <div className="visit-schedule">
              <div className="schedule-header">
                {i18nTranslated.visit_schedule}
              </div>
              <div className="schedule-accordion">
                {jcPlannings.length !== 0 ? (
                  <Accordiontemplate
                    accordionItems={jcPlannings.map((item, index) => ({
                      accordionHead: (
                        <div className="" key={`head_${index}`}>
                          <div className="village-type">
                            <p className="village">{item.village.name}</p>
                            <span className="type-village">
                              Village:{" "}
                              <p className={`${item.village.color_code}__Font`}>
                                {item.village.color_code}
                              </p>
                            </span>
                          </div>
                          <div className="date-tiv">
                            <p className="visit-date">
                              {moment(item.date).format("MMMM D")}
                            </p>
                            <div className="grey-dot"></div>
                            <span className="tiv-detail">
                              TIV: <p>{item.village.tiv}</p>
                            </span>
                          </div>
                        </div>
                      ),
                      accordionBody: (
                        <>
                          <div className="visit-ytd" key={`body_${index}`}>
                            <span className="visit-ytd-label">
                              Visits YTD:{" "}
                            </span>
                            <p className="visit-ytd-value">
                              {
                                item.village.stats
                                  .total_jcp_visits_year_till_date
                              }
                            </p>
                          </div>
                          <div className="visit-distance" key={index}>
                            <span className="distance-label">Distance: </span>
                            <p className="distance-value">
                              {calculateDistance(
                                currentLocationLatitude as number,
                                currentLocationLongitude as number,
                                item.village.latitude,
                                item.village.longitude
                              )}
                            </p>
                          </div>
                        </>
                      )
                    }))}
                    accordionHeadClass="schedule-visit-head"
                    accordionBodyClass="schedule-visit-body"
                    accordionWrapClass="schedule-visit-wrap"
                  />
                ) : (
                  <div style={{ padding: "20px" }}>
                    <EmptyTemp />
                  </div>
                )}
              </div>
            </div>
          </>
        )}
      </div>
      <div className="create-jcp">
        <Buttontemplate
          buttonWidth="150px"
          type="primary"
          callback={() =>
            navigate({
              pathname: AppUrlConstants.CREATE_JCP.BASE_NAME,
              search: new URLSearchParams({
                [AppQueryParameters.COMMON.KEYS.TITLE]: "create_jcp"
              }).toString()
            })
          }
        >
          +<span className="context-jcp">{i18nTranslated.create_jcp}</span>
        </Buttontemplate>
      </div>
    </>
  );
};

export default DspJcp;
