import styles from "./styles.module.css";

import { useCallback } from "react";
import classNames from "classnames";

import Radio from "../../../../../shared/components/Radio";
import distanceCalc from "../../../../../Components/Hooks/distanceCalc";
import { useFormContext } from "../../../../../shared/hooks/useFormContext";

import { Model } from "../../../../../Types/global";
import { CreateJcPlanningForm } from "../../../../types";

const AnchorVillage: React.FC<{
  village: Model.Village;
  currentLocationLatitude?: number;
  currentLocationLongitude?: number;
}> = ({ village, currentLocationLatitude, currentLocationLongitude }) => {
  const { values, setValue, setError } = useFormContext<CreateJcPlanningForm>();

  const calculateDistance = useCallback(
    (latitude: string, longitude: string) => {
      if (!currentLocationLatitude || !currentLocationLongitude) {
        return "NA";
      } else {
        const distance = distanceCalc.calculateDistance(
          currentLocationLatitude,
          currentLocationLongitude,
          parseFloat(latitude),
          parseFloat(longitude)
        );

        if (distance > 50) {
          return "50+ KM";
        } else {
          return distance.toFixed(2) + " KM";
        }
      }
    },
    [currentLocationLatitude, currentLocationLongitude]
  );

  const onRadioChange = useCallback(
    (_event: React.ChangeEvent<HTMLInputElement>) => {
      if (values.anchorVillage?.id !== village.id) {
        setValue("anchorVillage", village);
        setError("anchorVillage", "");
        setValue("nonAnchorVillages", null);
      }
    },
    [values.anchorVillage?.id, village, setValue, setError]
  );
  return (
    <div className={styles["list-item"]}>
      <Radio
        checked={values.anchorVillage?.id === village.id}
        onChange={onRadioChange}
      />
      <div className={styles["content-wrapper"]}>
        <h6 className={styles["title"]}>{village.name}</h6>
        <div className={styles["info-container"]}>
          <div className={styles["tiv-distance-container"]}>
            <p className={styles["text"]}>TIV : {village.tiv}</p>
            <p className={styles["text"]}>
              {calculateDistance(village.latitude, village.longitude)}
            </p>
          </div>
          <div className={styles["color-code-ytd-container"]}>
            <div
              className={classNames(
                styles["dot"],
                village.color_code
                  ? styles[village.color_code.toLowerCase()]
                  : styles["na"]
              )}
            />
            <p className={styles["text"]}>
              Visits YTD : {village.stats?.total_jcp_visits_year_till_date}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AnchorVillage;
