import React from 'react'
import { IIconProps } from '../Types/global'

export const DashboardSvg: React.FC<IIconProps> = ({className, fill}) => {
    fill = fill !== undefined ? fill : '#000'
    return (
        <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.33333 10.9833L0 10.0333L3.46667 4.4L5.3 6.6L7.91667 2.5L10.1833 5.71667C9.88333 5.73889 9.59444 5.78889 9.31667 5.86667C9.03889 5.94444 8.77778 6.06111 8.53333 6.21667L7.96667 5.46667L5.5 9.36667L3.68333 7.18333L1.33333 10.9833ZM13.8333 14.5L11.75 12.4C11.5278 12.5222 11.3 12.6167 11.0667 12.6833C10.8444 12.75 10.6 12.7833 10.3333 12.7833C9.52222 12.7833 8.82778 12.5 8.25 11.9333C7.68333 11.3556 7.4 10.6556 7.4 9.83333C7.4 9.02222 7.68333 8.32778 8.25 7.75C8.82778 7.17222 9.52222 6.88333 10.3333 6.88333C11.1556 6.88333 11.85 7.17222 12.4167 7.75C12.9944 8.32778 13.2833 9.02222 13.2833 9.83333C13.2833 10.1 13.2444 10.35 13.1667 10.5833C13.1 10.8167 13.0111 11.0389 12.9 11.25L15 13.3333L13.8333 14.5ZM10.3333 11.15C10.7 11.15 11.0111 11.0222 11.2667 10.7667C11.5222 10.5111 11.65 10.2 11.65 9.83333C11.65 9.47778 11.5222 9.17222 11.2667 8.91667C11.0111 8.65 10.7 8.51667 10.3333 8.51667C9.97778 8.51667 9.66667 8.65 9.4 8.91667C9.14445 9.17222 9.01667 9.47778 9.01667 9.83333C9.01667 10.2 9.14445 10.5111 9.4 10.7667C9.66667 11.0222 9.97778 11.15 10.3333 11.15ZM11.8 5.93333C11.5222 5.82222 11.2333 5.75 10.9333 5.71667C10.6333 5.68333 10.3333 5.67778 10.0333 5.7L13.6667 0.0333328L15 0.983333L11.8 5.93333Z" fill={fill}/>
        </svg>
    )
}
