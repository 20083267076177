import React from 'react'
import RecordCollection from '../../../../shared/components/RecordCollection';
import useFollowup from '../../../../shared/hooks/useFollowup';
import Loadertemplate from '../../../templates/Loadertemplate';
import './followup.css';

const i18nTranslated = {
    title_label: 'Title',
    name_label: 'Name',
    status_label: 'Status',
    category_label: 'Category',
    interest_model_label: 'Interested model',
    stage_label: 'Stage',
    sog_label: 'Source of generation',
    tiv_label: 'TIV',
    type: 'Type',
    village: 'Village',
    edd: 'EDD',
    due_date: 'Due date',
    followup_date: 'Followup date'
}
const Followup: React.FC = () => {
    // let {followup, isLoading} = useFollowup();
    return (
            // isLoading ?
                <div className='loader-wrapper'>
                    <Loadertemplate/>
                </div>
            // :
            //     <div className='followup-detail-wrapper'>
            //         <RecordCollection>
            //             <RecordCollection.ListItemKeyValue 
            //                 label={i18nTranslated.title_label}
            //                 value={followup?.prospect.contact.name ? followup.prospect.contact.name : '--'}
            //             />
            //             <RecordCollection.ListItemKeyValue 
            //                 label={i18nTranslated.status_label}
            //                 value={followup?.prospect.enquiry_status ? followup.prospect.enquiry_status : '--'}
            //             />
            //             <RecordCollection.ListItemKeyValue 
            //                 label={i18nTranslated.category_label}
            //                 value={followup?.prospect.enquiry_category ? followup.prospect.enquiry_category : '--'}
            //             />
            //             <RecordCollection.ListItemKeyValue 
            //                 label={i18nTranslated.interest_model_label}
            //                 value={followup?.prospect.interested_model_family ? followup.prospect.interested_model_family : '--'}
            //             />
            //             <RecordCollection.ListItemKeyValue 
            //                 label={i18nTranslated.stage_label}
            //                 value={followup?.prospect.stage ? followup.prospect.stage : '--'}
            //             />
            //             <RecordCollection.ListItemKeyValue 
            //                 label={i18nTranslated.sog_label}
            //                 value={followup?.prospect.sog ? followup.prospect.sog : '--'}
            //             />
            //             <RecordCollection.ListItemKeyValue 
            //                 label={i18nTranslated.tiv_label}
            //                 value={followup?.prospect.tiv ? followup.prospect.tiv : '--'}
            //             />
            //             <RecordCollection.ListItemKeyValue 
            //                 label={i18nTranslated.type}
            //                 value={followup?.prospect.enquiry_classification ? followup.prospect.enquiry_classification : '--'}
            //             />
            //             <RecordCollection.ListItemKeyValue 
            //                 label={i18nTranslated.village}
            //                 value={followup?.prospect.contact.village ? followup.prospect.contact.village : '--'}
            //             />
            //             <RecordCollection.ListItemKeyValue 
            //                 label={i18nTranslated.edd}
            //                 value={followup?.prospect.edd ? followup.prospect.edd : '--'}
            //             />
            //             <RecordCollection.ListItemKeyValue 
            //                 label={i18nTranslated.due_date}
            //                 value={followup?.due_date ? followup.due_date : '--'}
            //             />
            //         </RecordCollection>
            //     </div>
    )
}

export default Followup;