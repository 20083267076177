import React, { useState } from "react";
import CallContactTemp from "../../../templates/CallContactTemp";
import WhatsappContactTemp from "../../../templates/WhatsappContactTemp";
import FilterTemp from "../../../templates/FilterTemplate";
import Loadertemplate from "../../../templates/Loadertemplate";
import ServerConnectorUtil from "../../../../shared/utils/ServerConnectorUtil";
import usePagedContacts from "../../../../shared/hooks/usePagedContacts";
import ContactAdditionalFields from "../../../../shared/enums/ContactAdditionalFields";
import EmptyTemp from "../../../templates/EmptyTemp";

const i18nTranslated = {
  hotProspect: "HPs",
  prospects: "Prospects",
  deliveries: "Deliveries"
};
const MPCTab: React.FC = () => {
  const [filters] = useState({
    type: "MPC"
  });

  const [additionalFields] = useState<Array<string>>(
    Object.values(ContactAdditionalFields)
  );
  const { data, isLoading } = usePagedContacts({
    page: 1,
    perPage: 200,
    filters,
    additionalFields,
    onFailure: ServerConnectorUtil.handleServerError
  });
  return (
    <div style={{ height: "100%" }}>
      {isLoading ? (
        <div className="loader-wrapper">
          <Loadertemplate />
        </div>
      ) : data.length ? (
        <>
          {/* <FilterTemp custom={false} options={[]}></FilterTemp> */}
          <div className="listOfDetails">
            {data.map((item, index) => (
              <div className="prospect" key={index}>
                <div className="prospect-details">
                  <b>{item.name}</b>
                  <span className="small-dot"></span>
                  <span className="prospect-type">{item.influencer_type}</span>
                </div>
                <div className="prospect-village">{item.village}</div>
                <div className="prospects-stats">
                  <div className="hps-stats">
                    <span>
                      {i18nTranslated.hotProspect}:
                      <b>{item.stats?.total_hot_prospects}</b>
                    </span>
                  </div>
                  <div className="prospect-stats">
                    <span>
                      {i18nTranslated.prospects}:
                      <b>{item.stats?.total_prospects}</b>
                    </span>
                  </div>
                  <div className="deliveries-stats">
                    <span>
                      {i18nTranslated.deliveries}:
                      <b>{item.stats?.total_hot_prospects}</b>
                    </span>
                  </div>
                </div>
                <div
                  className="prospects-contact"
                  style={{ margin: "15px 0 5px" }}
                >
                  <CallContactTemp
                    phoneNumber={item.mobile_number}
                  ></CallContactTemp>
                  <WhatsappContactTemp
                    phoneNumber={item.mobile_number}
                  ></WhatsappContactTemp>
                </div>
              </div>
            ))}
          </div>
        </>
      ) : (
        <div className="accordion-empty">
          <EmptyTemp />
        </div>
      )}
    </div>
  );
};

export default MPCTab;
