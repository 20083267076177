import React, { useCallback, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';

import RecordCollection from '../../../shared/components/RecordCollection';
import AppUrlConstants from '../../../shared/constants/AppUrlConstants';
import AppQueryParameters from '../../../shared/enums/AppQueryParameters';
import usePagedTractorUserDatabases from '../../../shared/hooks/usePagedTractorUserDatabases';
import { IOptions } from '../../../Types/global';
import { UsePagedTractorUserDatabasesHook } from '../../../Types/hooks';
import CallContactTemp from '../../templates/CallContactTemp';
import WhatsappContactTemp from '../../templates/WhatsappContactTemp';
import useScroll from '../../../shared/hooks/useScroll';

const i18nTranslated = {
  typeLabel: 'Type',
  statusLabel: 'Status',
  contactLabel: 'Contact',
  contactNameSearch: 'Search by contact name',
  sourceLabel: 'Source',
  villageLabel: 'Village',
  modelLabel: 'Model',
  satisfiedLabel: 'Satisfied',
  talukaLabel: 'Taluka'
};

type Params = {
  type?: string;
  name?: string;
  village_name?: string;
  tractor_model?: string;
  mobile_number?: string;
};

const allDropDownOptions = [
  { value: 'name', label: 'Name' },
  { value: 'type', label: 'Type' },
  { value: 'village_name', label: 'Village' },
  { value: 'mobile_number', label: 'Mobile Number' },
  { value: 'tractor_model', label: 'Tractor Model' }
];

const TractorMaster: React.FC = () => {
  const [searchParams] = useSearchParams();

  const [value, setValue] = useState('');
  const [searchKey, setSearchKey] = useState<string>('name');
  const [searchLabel, setSearchLabel] = useState<string>(
    allDropDownOptions[0].label.toLowerCase()
  );
  const [initialFilters] = useState<UsePagedTractorUserDatabasesHook.Filters>({
    type:
      searchParams.get(AppQueryParameters.TRACTOR_USER_DATABASE.KEYS.TYPE) ||
      undefined,
    name:
      searchParams.get(AppQueryParameters.TRACTOR_USER_DATABASE.KEYS.NAME) ||
      undefined,
    village_name:
      searchParams.get(
        AppQueryParameters.TRACTOR_USER_DATABASE.KEYS.VILLAGE_NAME
      ) || undefined,
    tractor_model:
      searchParams.get(
        AppQueryParameters.TRACTOR_USER_DATABASE.KEYS.TRACTOR_MODEL
      ) || undefined,
    mobile_number:
      searchParams.get(
        AppQueryParameters.TRACTOR_USER_DATABASE.KEYS.MOBILE_NUMBER
      ) || undefined,
      village_color_code:
        searchParams.get(
          AppQueryParameters.TRACTOR_USER_DATABASE.KEYS.VILLAGE_COLOR_CODE
        ) || undefined,
      is_satisfied_with_his_tractor:
        searchParams.get(
          AppQueryParameters.TRACTOR_USER_DATABASE.KEYS.IS_SATISFIED_WITH_HIS_TRACTOR
        ) || undefined,
      created_time_start_value: searchParams.get(AppQueryParameters.COMMON.KEYS.START_DATE) || undefined,
      created_time_end_value: searchParams.get(AppQueryParameters.COMMON.KEYS.END_DATE) || undefined
  });
  console.log(initialFilters);
  const {
    data,
    isLoading,
    isLoadingMore,
    setFilters,
    updateFilterKey,
    loadMoreRecords
  } = usePagedTractorUserDatabases({
    page: 1,
    perPage: 200,
    filters: initialFilters
  });

  const onScrollEventListener = useScroll(loadMoreRecords);

  const onNameChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      setValue(value);
      updateFilterKey(
        searchKey as keyof UsePagedTractorUserDatabasesHook.Filters,
        value
      );
    },
    [searchKey, updateFilterKey]
  );

  const [dropDownOptions] = useState<IOptions[]>(() => {
    const keysToExclude = Object.keys(initialFilters).filter(
      (key): key is keyof Params => Boolean(initialFilters[key as keyof Params])
    ) as string[];
    const filteredOptions = allDropDownOptions.filter(
      (option) => !keysToExclude.includes(option.value)
    );
    return filteredOptions;
  });

  const onDropDownChange = useCallback((value: IOptions) => {
    setSearchKey(value.value);
    setValue('');
    setSearchLabel(value.label.toLowerCase());
    setFilters(initialFilters);
  }, [initialFilters, setFilters]);

  return (
    <RecordCollection>
      <RecordCollection.SearchBox
        value={value}
        onChange={onNameChange}
        placeholder={'Search by ' + searchLabel}
        initialDropDownValue={allDropDownOptions[0]}
        dropDownOptions={dropDownOptions}
        onDropDownChange={onDropDownChange}
      />
      <RecordCollection.List onScroll={onScrollEventListener}>
        {isLoading ? (
          <RecordCollection.ListLoader />
        ) : data.length === 0 ? (
          <RecordCollection.ListEmpty />
        ) : (
          data.map((item) => (
            <RecordCollection.ListItemWrapper key={item.id}>
              <Link to={`${AppUrlConstants.INNER_BASE}/${AppUrlConstants.TUDB}/${item.id}?title=tudb_details`} className='listDetailLink'>
                <RecordCollection.ListItemLink/>
                <RecordCollection.ListItem>
                  <RecordCollection.ListItemTitle title={item.name}/>
                  <RecordCollection.ListItemKeyValue
                    label={i18nTranslated.typeLabel}
                    value={item.type}
                  />
                  <RecordCollection.ListItemKeyValue
                    label={i18nTranslated.statusLabel}
                    value={item.status}
                  />
                  <RecordCollection.ListItemKeyValue
                    label={i18nTranslated.sourceLabel}
                    value={item.source}
                  />
                  <RecordCollection.ListItemKeyValue
                    label={i18nTranslated.villageLabel}
                    value={item.village}
                  />
                  <RecordCollection.ListItemKeyValue
                    label={i18nTranslated.modelLabel}
                    value={item.tractor.model}
                  />
                  <RecordCollection.ListItemKeyValue
                    label={i18nTranslated.satisfiedLabel}
                    value={item.is_satisfied_with_his_tractor}
                  />
                  <RecordCollection.ListItemKeyValue
                    label={i18nTranslated.talukaLabel}
                    value={item.taluka}
                  />
                  <RecordCollection.ListAction>
                    <CallContactTemp
                      className="prospect-contact"
                      phoneNumber={item.mobile_number}
                    />
                    <WhatsappContactTemp phoneNumber={item.mobile_number} />
                  </RecordCollection.ListAction>
                </RecordCollection.ListItem>
              </Link>
            </RecordCollection.ListItemWrapper>
          ))
        )}
       {(!isLoading && isLoadingMore) && <RecordCollection.LoadMoreLoader />}
      </RecordCollection.List>
    </RecordCollection>
  );
};

export default TractorMaster;
