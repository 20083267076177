import React from 'react'
import { IIconProps } from '../Types/global'

export const HomeBlack:React.FC<IIconProps> = ({className, fill}) => {
    fill = (fill !== undefined) ? fill : '#000';
    return (
        <svg width="11" height="12" viewBox="0 0 11 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.01667 10.2833H3.28333V6.18333H7.71667V10.2833H8.98333V4.65L5.5 2.03333L2.01667 4.65V10.2833ZM0.383333 11.9167V3.83333L5.5 -9.53674e-07L10.6167 3.83333V11.9167H6.21667V7.68333H4.78333V11.9167H0.383333Z" fill={fill}/>
        </svg>
    )
}
