import React, { useEffect, useState } from 'react'
import Loadertemplate from '../../templates/Loadertemplate';
import { IRatingUserData } from '../../../Types/global';
import './index.css';
import Ratingtemplate from '../../templates/Ratingtemplate';
import getDSP from '../../Hooks/getDSP';

const Leaderboard:React.FC = () => {
    const [leaderBoard, setleaderBoard] = useState<IRatingUserData[]>([]);
    const [showLazyLoad, setshowLazyLoad] = useState<boolean>(false);
    useEffect(()=> {
        const getDsp = async() => {
            try{
                setshowLazyLoad(true);
                let leaderItems: IRatingUserData[] = await getDSP();
                setleaderBoard(leaderItems);
            }catch(error){

            }finally{
                setshowLazyLoad(false);
            }
        }
        getDsp();
    }, [])
    return (
        <div className='leader-list'>
            {
                showLazyLoad ?
                    <div className='loader-wrapper'>
                        <Loadertemplate></Loadertemplate>
                    </div>
                :
                    <Ratingtemplate ratingData={leaderBoard}></Ratingtemplate>
            }
        </div>
    )
}

export default Leaderboard