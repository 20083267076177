import './index.css';

import React from 'react';

import List, { ListProp } from './List';
import ListEmpty from './ListEmpty';
import ListItem from './ListItem';
import ListItemAction from './ListItemAction';
import ListItemKeyValue, { ListItemKeyValueProps } from './ListItemKeyValue';
import ListItemKeyValuePairs, {
  ListItemKeyValuePairsProps
} from './ListItemKeyValuePairs';
import ListItemLink, { ListItemLinkProps } from './ListItemLink';
import ListItemTitle, { ListItemTitleProps } from './ListItemTitle';
import ListItemWrapper from './ListItemWrapper';
import RecordListLoader from './RecordListLoader';
import RecordSearchBox, { RecordSearchBoxProps } from './RecordSearchBox';
import LoadMoreLoader from './LoadMoreLoader';
import ListItemColorCode, { color_code } from './ListItemColorCode';

type RecordCollectionComposition = {
  List: React.FC<ListProp>;
  LoadMoreLoader: React.FC;
  ListItem: React.FC<React.PropsWithChildren>;
  ListEmpty: React.FC;
  SearchBox: React.FC<RecordSearchBoxProps>;
  ListLoader: React.FC;
  ListAction: React.FC<React.PropsWithChildren>;
  ListItemLink: React.FC<ListItemLinkProps>;
  ListItemTitle: React.FC<ListItemTitleProps>;
  ListItemWrapper: React.FC<React.PropsWithChildren>;
  ListItemKeyValue: React.FC<ListItemKeyValueProps>;
  ListItemKeyValuePairs: React.FC<ListItemKeyValuePairsProps>;
  ListItemColorCode: React.FC<color_code>
};

const RecordCollection: React.FunctionComponent<React.PropsWithChildren> &
  RecordCollectionComposition = ({ children }) => {
  return <div className="List-of-details-wrap">{children}</div>;
};

RecordCollection.List = List;
RecordCollection.LoadMoreLoader = LoadMoreLoader;
RecordCollection.ListItem = ListItem;
RecordCollection.ListEmpty = ListEmpty;
RecordCollection.SearchBox = RecordSearchBox;
RecordCollection.ListAction = ListItemAction;
RecordCollection.ListLoader = RecordListLoader;
RecordCollection.ListItemLink = ListItemLink;
RecordCollection.ListItemTitle = ListItemTitle;
RecordCollection.ListItemWrapper = ListItemWrapper;
RecordCollection.ListItemKeyValue = ListItemKeyValue;
RecordCollection.ListItemKeyValuePairs = ListItemKeyValuePairs;
RecordCollection.ListItemColorCode = ListItemColorCode

export default RecordCollection;
