import moment from 'moment';
import calendar from '../../shared/constants/calendar';
import { IDateStrRangeObj } from '../../Types/global';
import DateFormat from '../../shared/enums/DateFormat';
const calculateDaysBetween = (dateString: string): string => {
    const inputDate = new Date(dateString);
    const currentDate = new Date();
    const timeDifference = currentDate.getTime() - inputDate.getTime();
    return Math.floor(timeDifference / (1000 * 60 * 60 * 24)).toString();
}
const isDateInPastOrFuture = (dateString: string): string => {
    const inputDate = new Date(dateString);
    const currentDate = new Date();
    if (inputDate < currentDate) {
      return 'Pending';
    } else if (inputDate > currentDate) {
      return 'Upcoming';
    } else {
      return 'Today';
    }
}
const removeNagative = (daysDifference: string) => {
  if(parseInt(daysDifference) < 0){
      let negativeNumSplit = daysDifference.split('-');
      daysDifference = negativeNumSplit[1];
  }
  return daysDifference;
}
const formatDate = (dateString: string, type: string): string => {
    let formattedDate;
    if(dateString !== ''){
        formattedDate = moment(dateString).format(type);
    }else{
        formattedDate = moment().format(type);
    }
    return formattedDate;
};

const formatFilterValueWithDateFormat = (jcpStatFilter: string | IDateStrRangeObj,dateFormat:DateFormat = DateFormat.ISO_DATE): {start_date: string, end_date: string} => {
    let today = moment();
    let startDate: string = '', endDate: string = ''
    switch(jcpStatFilter){
        case 'This year':
            startDate = today.month() < 3
            ? moment(new Date(today.year() - 1 , 3, 1)).format(dateFormat)
            : moment(new Date(today.year() , 3, 1)).format(dateFormat);
            endDate = today.month() >= 3
            ? moment(new Date(today.year() + 1 , 3, 0, 23, 59, 59)).format(dateFormat)
            : moment(new Date(today.year() , 3, 0, 23, 59, 59)).format(dateFormat);
        break;
        case 'Today':
            startDate = today.startOf('day').format(dateFormat);
            endDate = today.endOf('day').format(dateFormat);
        break;
        case 'Yesterday':
            startDate = moment().subtract(1, 'day').startOf('day').format(dateFormat);
            endDate = moment().subtract(1, 'day').endOf('day').format(dateFormat)
        break;
        case 'Last week':
            startDate = moment().subtract(1, 'week').startOf('week').format(dateFormat);
            endDate = moment().subtract(1, 'week').endOf('week').format(dateFormat)
        break;
        case 'Current month':
            startDate = moment().startOf('month').startOf('day').format(dateFormat);
            endDate = moment().endOf('month').endOf('day').format(dateFormat);
        break;
        case 'Last month':
            startDate = moment().subtract(1, 'month').startOf("month").startOf('day').format(dateFormat);
            endDate = moment().subtract(1, 'month').endOf('month').endOf('day').format(dateFormat);
        break;
        case 'This week':
            startDate = moment().startOf('week').startOf('day').format(dateFormat);
        break;
        default:
            startDate = moment((jcpStatFilter as IDateStrRangeObj).start,DateFormat.ISO_DATE).startOf("day").format(dateFormat);
            endDate = moment((jcpStatFilter as IDateStrRangeObj).end,DateFormat.ISO_DATE).endOf("day").format(dateFormat)
    }
    return {
        start_date: startDate,
        end_date: endDate
    }
}
const dateHooks = {
    calculateDaysBetween,
    isDateInPastOrFuture,
    formatDate,
    removeNagative,
    formatFilterValueWithDateFormat
}
export default dateHooks;