import React, {
  FormEvent,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react';
import './index.css';
import '../../../CSS/form.css';
import Form from 'react-bootstrap/Form';
import Dropdowntemplate from '../../../templates/Dropdowntemplate';
import Buttontemplate from '../../../templates/Buttontemplate';
import axios from 'axios';
import {
  IEnquiries,
  ILocation,
  IOptions,
  IProspect,
  IUserContext,
} from '../../../../Types/global';
import SearchDropdowntemplate from '../../../templates/SearchDropdowntemplate';
import classNames from 'classnames';
import Loadertemplate from '../../../templates/Loadertemplate';
import ServerConnectorUtil from '../../../../shared/utils/ServerConnectorUtil';
import { UserContext } from '../../../HOC/UserContext';
import useIdPagedProspect from '../../../../shared/hooks/useIdPagedProspect';
import EnquiryStages from '../../../../shared/enums/EnquiryStages';
import useForm from '../../../Hooks/useForm';
import ProspectServices from '../../../../shared/services/ProspectServices';
import ToastUtil from '../../../../shared/utils/ToastUtil';
import { useNavigate } from 'react-router-dom';

interface enquiryFields {
  id: string;
  name: string;
  options: IOptions[];
  type: string;
}
const i18nTranslated = {
  'personalDetails-label': 'Personal Details',
  'tractorDetails-label': 'Tractor Details',
  'customerType-label': 'Customer type',
  'satisfiedTractor-label': 'Satisfied with tractor',
  'dissatisfaction-label': 'Reason for dissatisfaction',
  tractor_brand_owned: 'Current brand',
  tractor_model_owned: 'Current model',
  'yom-label': 'Year of Manufacture',
  'marketing-lead-brand': 'Market Leading Brand',
  'marketing-lead-model': 'Market Leading Model',
  'name-label': 'Name',
  'phone-label': 'Phone number',
  'state-label': 'State',
  'district-label': 'District',
  'taluka-label': 'Taluka',
  'village-label': 'Village',
  'save-button': 'Save',
  'name-placeholder': 'Enter the Name',
  'phone-placeholder': 'Enter the phone number',
  'state-placeholder': 'Add State',
  'district-placeholder': 'Add District',
  'taluka-placeholder': 'Add Taluka',
  'village-placeholder': 'Add Village',
  yesCheckbox: 'Yes',
  noCheckbox: 'No',
  'sourceOfUser-label': 'Source of user',
  no_of_tractors_owned: 'No of Tractors Owned',
  hp_segment: 'HP Segment',
  no_of_tractors_village: 'Totel number of tractors in your village',
  nameEmptyError: "Name can't be empty field",
  mobileEmptyError: "Mobile can't be empty field",
  invaildMobileSyntax: 'Enter the valid mobile number',
  emptyFields: "Field can't be empty",
  invalid_edd: 'Enter a valid EDD',
  invalid_followup: 'Enter a valid Followups',
  'model-family-label': 'Model Family',
  'edd-label': 'EDD',
  'stage-label': 'Enquiry stage',
  'sog-label': 'Source of Generation',
  'category-label': 'Enquiry Category',
  'payment-label': 'Mode of payment',
  'interested-exchange-label': 'Interested in Exchange',
  'remark-label': 'Remarks',
  'tractor_deal_value': 'Tractor Deal Value',
  'others_deal_value': 'Others Deal Value',
  'margin_amount': 'Margin Amount',
  'shining_tractor_image_upload': 'Shining Tractor image uploaded',
  'shining_tractor_checklist_updated': 'Shining Tractor Checklist updated',
  'sales_lost_reason': 'Sales Lost reason',
  'sales_lost_remark': 'Sales Lost Remarks',
  'sales_lost_competitor_brand': 'Competitor Make',
  'sales_lost_competitor_model': 'Competitor Model'
};
const regex = {
  phoneRegex: /^\d{10}$/
};
const EnquiryForm: React.FC = () => {
  const [btnLoading, setbtnLoading] = useState<boolean>(false);
  const currentUser = useContext(UserContext) as IUserContext;
  const [initFormValues] = useState<IEnquiries.EnquiryFormType>({
    id: null,
    sog: null,
    stage: null,
    taluka: null,
    village: null,
    color_code: null,
    tiv: null,
    payment_mode: 'Bank',
    bank_description: null,
    other_deal_values: null,
    tractor_deal_value: null,
    margin_amount: null,
    date_of_delivery: null,
    has_exchange: 'No',
    exchange_brand: null,
    exchange_model: null,
    exchange_yom: null,
    exchange_value: null,
    name: null,
    mobile_number: null,
    enquiry_category: null,
    next_follow_up_in_days: null,
    interested_model_family: null,
    expected_date_of_delivery_in_days: null,
    sales_lost_reason: null,
    sales_lost_remarks: null,
    sales_lost_competitor_model: null,
    sales_lost_competitor_brand: null,
    sales_dropped_reason: null,
    sales_dropped_remarks: null,
    shining_tractor_image_uploaded: null,
    shining_tractor_checklist_updated: null,
  })
  const {values, errors, setMultipleValues,setError, setValue, onInputChange} = useForm({initialValues: initFormValues})

  const [sogArray, setsogArray] = useState<IOptions[]>([]);
  const [stageArray, setstageArray] = useState<IOptions[]>([]);
  const [IMFArray, setIMFArray] = useState<IOptions[]>([]);
  const [ECArray, setECArray] = useState<IOptions[]>([]);
  const [exchangeBrand, setExchangeBrand] = useState<IOptions[]>([]);
  const [exchangeModel, setExchangeModel] = useState<IOptions[]>([]);
  const [yom, setYom] = useState<IOptions[]>([]);
  const [paymentArray, setpaymentArray] = useState<IOptions[]>([]);
  const [bankArray, setBankArray] = useState<IOptions[]>([]);
  const [salesLostReason, setSalesLostReason] = useState<IOptions[]>([]);
  const [salesDroppedReason, setSalesDroppedReason] = useState<IOptions[]>([]);
  const [competitiorMake, setCompetitiorMake] = useState<IOptions[]>([]);
  const [allCompetitiorModel, setAllCompetitiorModel] = useState<IOptions[]>([]);
  const [competitiorModel, setCompetitiorModel] = useState<IOptions[]>([]);
  const [checklistUpdated, setChecklistUpdated] = useState<IOptions[]>([]);
  const [imageUploaded, setImageUploaded] = useState<IOptions[]>([]);
  const [contactName, setContactName] = useState<string>('');

  const [isEditable, setisEditable] = useState<boolean>(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const metaData = await axios.get('/api/v1/prospect/meta');
        if (metaData.data && metaData.data.data) {
          let fieldData = metaData.data.data;
          fieldData.forEach((item: enquiryFields) => {
            switch (item.name) {
              case 'sog':
                setsogArray(item.options);
                break;
              case 'stage':
                setstageArray(item.options);
                break;
              case 'interested_model_family':
                setIMFArray(item.options);
                break;
              case 'enquiry_category':
                setECArray(item.options);
                break;
              case 'payment_mode':
                setpaymentArray(item.options);
                break;
              case 'exchange_brand':
                setExchangeBrand(item.options);
              break;
              case 'exchange_model':
                setExchangeModel(item.options)
              break;
              case 'exchange_yom':
                setYom(item.options);
              break;
              case 'bank_description':
                setBankArray(item.options);
              break;
              case 'sales_lost_reason':
                setSalesLostReason(item.options)
              break;
              case 'sales_dropped_reason':
                setSalesDroppedReason(item.options);
              break;
              case 'competitor_brand':
                setCompetitiorMake(item.options);
              break;
              case 'competitor_model':
                setAllCompetitiorModel(item.options)
              break;
              case 'shining_tractor_image_uploaded':
                setImageUploaded(item.options);
              break;
              case 'shining_tractor_checklist_updated':
                setChecklistUpdated(item.options);
              break;
            }
          });
        }
      } catch (error) {
        if (error instanceof Error) {
          ServerConnectorUtil.handleServerError(error);
        }
      }
    };
    fetchData();
  }, []);
  const validateForm = useCallback(() => {
    if(values.enquiry_category === null){
      setError('enquiry_category', i18nTranslated.emptyFields);
      return false;
    }
    if(values.stage?.value === EnquiryStages.F3_STAGE){
      if(!values.tractor_deal_value){
        setError('tractor_deal_value', i18nTranslated.emptyFields)
        return false;
      }
      if(!values.other_deal_values){
        setError('other_deal_values', i18nTranslated.emptyFields)
        return false;
      }
    }
    if(values.stage?.value === EnquiryStages.F5_STAGE){
      if(!values.margin_amount){
        setError('margin_amount', i18nTranslated.emptyFields);
        return false;
      }
      
    }
    // if(values.stage?.value === EnquiryStages.DELIVERY_STAGE){
    //   if(!values.margin_amount){
    //     setError('margin_amount', i18nTranslated.emptyFields);
    //     return false;
    //   }
    //   if(!values.tractor_deal_value){
    //     setError('tractor_deal_value', i18nTranslated.emptyFields)
    //     return false;
    //   }
    //   if(values.shining_tractor_image_uploaded === null){
    //     setError('shining_tractor_image_uploaded', i18nTranslated.emptyFields)
    //     return false;
    //   }
    //   if(values.shining_tractor_checklist_updated === null){
    //     setError('shining_tractor_checklist_updated', i18nTranslated.emptyFields)
    //     return false;
    //   }
    // }
    if(values.stage?.value === EnquiryStages.SALES_LOST_STAGE){
      if(values.sales_lost_reason === null){
        setError('sales_lost_reason', i18nTranslated.emptyFields);
        return false;
      }
      if(!values.sales_lost_remarks || values.sales_lost_remarks === ''){
        setError('sales_lost_remarks', i18nTranslated.emptyFields);
        return false;
      }
      if(values.sales_lost_competitor_brand === null){
        setError('sales_lost_competitor_brand', i18nTranslated.emptyFields);
        return false;
      }
      if(values.sales_lost_competitor_model === null){
        setError('sales_lost_competitor_model', i18nTranslated.emptyFields);
        return false;
      }
    }
    if(values.stage?.value === EnquiryStages.SALES_DROPPED_STAGE){
      if(values.sales_dropped_reason === null){
        setError('sales_dropped_reason', i18nTranslated.emptyFields);
        return false;
      }
      if(!values.sales_dropped_remarks || values.sales_dropped_remarks === ''){
        setError('sales_dropped_remarks', i18nTranslated.emptyFields);
        return false;
      }
    }
    if(values.interested_model_family === null){
      setError('interested_model_family', i18nTranslated.emptyFields);
      return false;
    }
    if(values.next_follow_up_in_days === null){
      setError('next_follow_up_in_days', i18nTranslated.emptyFields);
      return false;
    }else if(values.expected_date_of_delivery_in_days !== null && values.next_follow_up_in_days > values.expected_date_of_delivery_in_days){
      setError('next_follow_up_in_days', i18nTranslated.invalid_followup);
      return false;
    }
    if(values.expected_date_of_delivery_in_days === null){
      setError('expected_date_of_delivery_in_days', i18nTranslated.emptyFields);
      return false;
    }else if(values.expected_date_of_delivery_in_days > 100){
      setError('expected_date_of_delivery_in_days', i18nTranslated.invalid_edd);
      return false;
    } 
    if(values.has_exchange === "Yes"){
      if(values.exchange_brand === null){
        setError('exchange_brand', i18nTranslated.emptyFields);
        return false;
      }
      if(values.exchange_model === null){
        setError('exchange_model', i18nTranslated.emptyFields);
        return false;
      }
      if(values.exchange_yom === null){
        setError('exchange_yom', i18nTranslated.emptyFields);
        return false;
      }
      if(values.exchange_value === null){
        setError('exchange_value', i18nTranslated.emptyFields)
        return false;
      }
    }
    if(values.payment_mode === '' || values.payment_mode === null){
      setError('payment_mode', i18nTranslated.emptyFields)
      return false;
    }
    if(values.payment_mode === 'Bank' && values.bank_description === null ){
      setError('bank_description', i18nTranslated.emptyFields)
      return false;
    }
    return true;
  }, [values]);

  const noExchange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.checked) {
        setValue('has_exchange', 'No');
        setValue('exchange_brand', null);
        setValue('exchange_model', null);
        setValue('exchange_yom', null);
        setValue('exchange_value', null);
      }
    },
    []
  );
  // Edit code
  const [marginAmountIsNotGet, setMarginAmountIsNotGet] = useState(false);
  const [tractorDealValueIsNotGet, setTractorDealValueNotGet] = useState(false);
  const [disableBtn, setDisableBtn] = useState(false);
  const onSuccess = useCallback((prospect: IProspect) => {
    if (prospect) {
      if(prospect.stage === 'Delivery'){
        setDisableBtn(true);
      }
      setMultipleValues({
        id: prospect.id,
        sog: prospect.sog === null ? null : {
          label: prospect.sog,
          value: prospect.sog
        },
        color_code: prospect.color_code,
        tiv: prospect.tiv,
        stage: prospect.stage === null ? null : {
          label: prospect.stage as string,
          value: prospect.stage as string
        },
        taluka: prospect.contact.taluka,
        village: prospect.contact.village,
        has_exchange: prospect.has_exchange || 'No',
        exchange_brand: prospect.exchange_details.brand === null ? null : {
          label: prospect.exchange_details.brand as string,
          value: prospect.exchange_details.brand as string
        },
        exchange_model: prospect.exchange_details.model === null ? null : {
          label: prospect.exchange_details.model as string,
          value: prospect.exchange_details.model as string
        },
        exchange_yom: prospect.exchange_details.year_of_manufacture === null ? null :{
          label: prospect.exchange_details.year_of_manufacture as string,
          value: prospect.exchange_details.year_of_manufacture as string
        },
        exchange_value: prospect.exchange_details.value,
        name:prospect.contact?.name,
        mobile_number: prospect.contact?.mobile_number,
        enquiry_category: prospect.enquiry_category === null ? null : {
          label: prospect.enquiry_category as string,
          value: prospect.enquiry_category as string
        },
        next_follow_up_in_days: prospect.next_follow_up_in_days,
        interested_model_family: prospect.interested_model_family === null ? null : {
          label: prospect.interested_model_family as string,
          value: prospect.interested_model_family as string
        },
        expected_date_of_delivery_in_days: prospect.expected_date_of_delivery_in_days,
        margin_amount: prospect.margin_amount,
        date_of_delivery: prospect.date_of_delivery,
        bank_description: prospect.payment_details?.bank_description === null ? null : {
          label: prospect.payment_details?.bank_description as string,
          value: prospect.payment_details?.bank_description as string
        },
        payment_mode: prospect.payment_details.payment_mode,
        tractor_deal_value: prospect.payment_details.tractor_deal_value,
        other_deal_values: prospect.payment_details.others_deal_value,
        sales_lost_reason: prospect.sales_lost_details.reason === null ? null : {
          label: prospect.sales_lost_details.reason as string,
          value: prospect.sales_lost_details.reason as string
        },
        sales_lost_remarks: prospect.sales_lost_details.remarks,
        sales_lost_competitor_brand: prospect.sales_lost_details.competitor_brand === null ? null : {
          label: prospect.sales_lost_details.competitor_brand as string,
          value: prospect.sales_lost_details.competitor_brand as string
        },
        sales_lost_competitor_model: prospect.sales_lost_details.competitor_model === null ? null : {
          label: prospect.sales_lost_details.competitor_model as string,
          value: prospect.sales_lost_details.competitor_model as string
        },
        sales_dropped_reason: prospect.sales_dropped_details.reason === null ? null : {
          label: prospect.sales_dropped_details.reason as string,
          value: prospect.sales_dropped_details.reason as string
        },
        sales_dropped_remarks: prospect.sales_dropped_details.remarks,
        shining_tractor_checklist_updated: prospect.shining_tractor_details.checklist_updated === null ? null : {
          label: prospect.shining_tractor_details.checklist_updated as string,
          value: prospect.shining_tractor_details.checklist_updated as string
        },
        shining_tractor_image_uploaded: prospect.shining_tractor_details.image_uploaded === null ? null : {
          label: prospect.shining_tractor_details.image_uploaded as string,
          value: prospect.shining_tractor_details.image_uploaded as string
        }
      })
      setMarginAmountIsNotGet(!prospect.margin_amount);
      setTractorDealValueNotGet(!prospect.payment_details.tractor_deal_value)
    }
  }, [stageArray]);
  const onFailure = useCallback((error: Error) => {
    ServerConnectorUtil.handleServerError(error);
  }, [])
  const filterCompetitiorModel = (option: IOptions) => {
    console.log(allCompetitiorModel);
    setCompetitiorModel(allCompetitiorModel.filter((model) => model.parent_reference_value === option.value))
  }
  const onDropdownChange = useCallback((name:string,value:IOptions|string|null)=>{
    setValue(name,value)
    setError(name,'')
    if(name === 'sales_lost_competitor_brand'){
      filterCompetitiorModel(value as IOptions);
    }
  },[setValue,setError, allCompetitiorModel])
  const onCheckChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const {name, value} = event.target;
    if(value === 'No'){
      noExchange(event)
    }
    setValue(name, value);
    setError(name, '');
  }, [setValue, setError])
  const { isLoading } = useIdPagedProspect({onSuccess, onFailure});
  const navigate = useNavigate();
  const handleOnSubmit = useCallback((event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if(!isEditable) {
        setisEditable(true);
        return false;
    }
    if(values && validateForm()){
        setbtnLoading(true);
        ProspectServices.putProspectRecord({values: values}).then(response => {
            navigate('/success/enquiry');
            // Uncommand after the backend change
            // setisEditable(false);
            // ToastUtil.makeSuccessToast('Record updated successfully.')
        })
        .catch(error => ServerConnectorUtil.handleServerError(error))
        .finally(() => {
            setbtnLoading(false);
        })
    }
  }, [values, isEditable])
  const renderStagesMandatoryFields = useCallback(() => {
      switch(values.stage?.value){
        case EnquiryStages.F3_STAGE: 
          return <>
            <Form.Group className='custom-form-group'>
              <Form.Label className='custom-form-label'>
                {i18nTranslated.tractor_deal_value}<span className="required-star">*</span>
              </Form.Label>
              <Form.Control 
                size="lg"
                className="custom-form-input"
                placeholder="INR"
                name="tractor_deal_value"
                type='number'
                disabled={!isEditable}
                value={ values.tractor_deal_value !== null ? values.tractor_deal_value : ''}
                onChange={onInputChange}
              ></Form.Control>
              <div
                  className={classNames('empty-msg', {
                    visible: errors.tractor_deal_value !== ''
                  })}
                >
                  {errors.tractor_deal_value}
                </div>
            </Form.Group>
            <Form.Group className='custom-form-group'>
              <Form.Label className='custom-form-label'>
                {i18nTranslated.others_deal_value}<span className="required-star">*</span>
              </Form.Label>
              <Form.Control 
                size="lg"
                className="custom-form-input"
                placeholder="INR"
                name="other_deal_values"
                type='number'
                disabled={!isEditable}
                value={ values.other_deal_values !== null ? values.other_deal_values : ''}
                onChange={onInputChange}
              ></Form.Control>
              <div
                  className={classNames('empty-msg', {
                    visible: errors.other_deal_values !== ''
                  })}
                >
                  {errors.other_deal_values}
              </div>
            </Form.Group>
          </>
        case EnquiryStages.F5_STAGE:
          return <Form.Group className='custom-form-group'>
            <Form.Label className='custom-form-label'>{i18nTranslated.margin_amount}<span className="required-star">*</span></Form.Label>
            <Form.Control
              size="lg"
              className="custom-form-input"
              type='number'
              onChange={onInputChange}
              placeholder="INR"
              name='margin_amount'
              disabled={!isEditable}
              value={ values.margin_amount !== null ? values.margin_amount : ''}
            ></Form.Control>
            <div
                  className={classNames('empty-msg', {
                    visible: errors.margin_amount !== ''
                  })}
                >
                  {errors.margin_amount}
              </div>
          </Form.Group>
        // case EnquiryStages.DELIVERY_STAGE:
        //   return <>
        //     {
        //       (marginAmountIsNotGet)
        //       &&
        //       <Form.Group className='custom-form-group'>
        //         <Form.Label className='custom-form-label'>{i18nTranslated.margin_amount}<span className="required-star">*</span></Form.Label>
        //         <Form.Control
        //           size="lg"
        //           className="custom-form-input"
        //           type='number'
        //           onChange={onInputChange}
        //           disabled={!isEditable}
        //           placeholder="INR"
        //           name='margin_amount'
        //           value={ values.margin_amount !== null ? values.margin_amount : ''}
        //         ></Form.Control>
        //         <div
        //               className={classNames('empty-msg', {
        //                 visible: errors.margin_amount !== ''
        //               })}
        //             >
        //               {errors.margin_amount}
        //           </div>
        //       </Form.Group>
        //     }
        //     {
        //       (tractorDealValueIsNotGet)
        //       &&
        //       <Form.Group className='custom-form-group'>
        //         <Form.Label className='custom-form-label'>
        //           {i18nTranslated.tractor_deal_value}<span className="required-star">*</span>
        //         </Form.Label>
        //         <Form.Control 
        //           size="lg"
        //           className="custom-form-input"
        //           placeholder="INR"
        //           name="tractor_deal_value"
        //           disabled={!isEditable}
        //           type='number'
        //           value={ values.tractor_deal_value !== null ? values.tractor_deal_value : ''}
        //           onChange={onInputChange}
        //         ></Form.Control>
        //         <div
        //             className={classNames('empty-msg', {
        //               visible: errors.tractor_deal_value !== ''
        //             })}
        //           >
        //             {errors.tractor_deal_value}
        //           </div>
        //       </Form.Group>
        //     }
        //     <Form.Group className='custom-form-group'>
        //       <Form.Label className='custom-form-label'>{i18nTranslated.shining_tractor_image_upload}<span className="required-star">*</span></Form.Label>
        //       <Form.Control
        //         as='div'
        //         size='lg'
        //         className='custom-form-div'
        //       >
        //         <SearchDropdowntemplate
        //           options={imageUploaded}
        //           defaultValue={values.shining_tractor_image_uploaded}
        //           onDropdownChange={onDropdownChange}
        //           name='shining_tractor_image_uploaded'
        //           isDisabled={!isEditable}
        //         />
        //       </Form.Control>
        //       <div
        //           className={classNames('empty-msg', {
        //             visible: errors.shining_tractor_image_uploaded !== ''
        //           })}
        //         >
        //           {errors.shining_tractor_image_uploaded}
        //       </div>
        //     </Form.Group>
        //     <Form.Group className='custom-form-group'>
        //       <Form.Label className='custom-form-label'>{i18nTranslated.shining_tractor_checklist_updated}<span className="required-star">*</span></Form.Label>
        //       <Form.Control
        //         as='div'
        //         size='lg'
        //         className='custom-form-div'
        //       >
        //         <SearchDropdowntemplate
        //           options={checklistUpdated}
        //           defaultValue={values.shining_tractor_checklist_updated}
        //           onDropdownChange={onDropdownChange}
        //           name='shining_tractor_checklist_updated'
        //           isDisabled={!isEditable}
        //         />
        //       </Form.Control>
        //       <div
        //           className={classNames('empty-msg', {
        //             visible: errors.shining_tractor_checklist_updated !== ''
        //           })}
        //         >
        //           {errors.shining_tractor_checklist_updated}
        //       </div>
        //     </Form.Group>
        //   </>
        case EnquiryStages.SALES_LOST_STAGE:
          return <>
            <Form.Group className='custom-form-group'>
              <Form.Label className='custom-form-label'>{i18nTranslated.sales_lost_reason}<span className="required-star">*</span></Form.Label>
              <Form.Control
                as='div'
                size='lg'
                className='custom-form-div'
              >
                <SearchDropdowntemplate
                  options={salesLostReason}
                  defaultValue={values.sales_lost_reason}
                  onDropdownChange={onDropdownChange}
                  name='sales_lost_reason'
                  isDisabled={!isEditable}
                />
              </Form.Control>
              <div
                  className={classNames('empty-msg', {
                    visible: errors.sales_lost_reason !== ''
                  })}
                >
                  {errors.sales_lost_reason}
              </div>
            </Form.Group>
            <Form.Group className='custom-form-group'>
              <Form.Label className='custom-form-label'>{i18nTranslated.sales_lost_remark}<span className="required-star">*</span></Form.Label>
              <Form.Control
                size='lg'
                className='custom-form-input'
                name='sales_lost_remarks'
                disabled={!isEditable}
                value={values.sales_lost_remarks !== null ? values.sales_lost_remarks : ''}
                placeholder='Remarks'
                onChange={onInputChange}
              ></Form.Control>
              <div
                  className={classNames('empty-msg', {
                    visible: errors.sales_lost_remarks !== ''
                  })}
                >
                  {errors.sales_lost_remarks}
              </div>
            </Form.Group>
            <Form.Group className='custom-form-group'>
              <Form.Label className='custom-form-label'>{i18nTranslated.sales_lost_competitor_brand}<span className="required-star">*</span></Form.Label>
              <Form.Control
                as='div'
                size='lg'
                className='custom-form-div'
              >
                <SearchDropdowntemplate
                  options={competitiorMake}
                  defaultValue={values.sales_lost_competitor_brand}
                  onDropdownChange={onDropdownChange}
                  name='sales_lost_competitor_brand'
                  isDisabled={!isEditable}
                />
              </Form.Control>
              <div
                  className={classNames('empty-msg', {
                    visible: errors.sales_lost_competitor_brand !== ''
                  })}
                >
                  {errors.sales_lost_competitor_brand}
              </div>
            </Form.Group>
            <Form.Group className='custom-form-group'>
              <Form.Label className='custom-form-label'>{i18nTranslated.sales_lost_competitor_model}<span className="required-star">*</span></Form.Label>
              <Form.Control
                as='div'
                size='lg'
                className='custom-form-div'
              >
                <SearchDropdowntemplate
                  options={values.sales_lost_competitor_brand ? competitiorModel : []}
                  defaultValue={values.sales_lost_competitor_model}
                  onDropdownChange={onDropdownChange}
                  name='sales_lost_competitor_model'
                  isDisabled={!isEditable}
                />
              </Form.Control>
              <div
                  className={classNames('empty-msg', {
                    visible: errors.sales_lost_competitor_model !== ''
                  })}
                >
                  {errors.sales_lost_competitor_model}
              </div>
            </Form.Group>
          </>
        case EnquiryStages.SALES_DROPPED_STAGE:
          return <>
            <Form.Group className='custom-form-group'>
              <Form.Label className='custom-form-label'>Dropped Reason<span className="required-star">*</span></Form.Label>
              <Form.Control
              className='custom-form-div'
              as='div'
              size='lg'>
                <SearchDropdowntemplate
                  options={salesDroppedReason}
                  defaultValue={values.sales_dropped_reason}
                  onDropdownChange={onDropdownChange}
                  name='sales_dropped_reason'
                  isDisabled={!isEditable}
                />
              </Form.Control>
              <div
                  className={classNames('empty-msg', {
                    visible: errors.sales_dropped_reason !== ''
                  })}
                >
                  {errors.sales_dropped_reason}
              </div>
            </Form.Group>
            <Form.Group className='custom-form-group'>
              <Form.Label className='custom-form-label'>Dropped Remarks<span className="required-star">*</span></Form.Label>
              <Form.Control 
                size="lg"
                className="custom-form-input"
                placeholder="Remark"
                name="sales_dropped_remarks"
                disabled={!isEditable}
                value={ values.sales_dropped_remarks !== null ? values.sales_dropped_remarks : ''}
                onChange={onInputChange}
              ></Form.Control>
              <div
                  className={classNames('empty-msg', {
                    visible: errors.sales_dropped_remarks !== ''
                  })}
                >
                  {errors.sales_dropped_remarks}
              </div>
            </Form.Group>
          </>
        // case EnquiryStages.R20_RETAIL_STAGE:
        //   return <></>
      }
  }, [values, errors, isEditable, competitiorModel])

  return (
    <>
      {isLoading ? (
        <div className="loader-wrapper">
          <Loadertemplate />
        </div>
      ) : (
        <Form onSubmit={handleOnSubmit}>
          <div className="custom-form">
            <Form.Group className="custom-form-group">
              <Form.Label className="custom-form-label">
                Phone number<span className="required-star">*</span>
              </Form.Label>
              <Form.Control
                size="lg"
                className="custom-form-input"
                placeholder="Phone number"
                name="phone"
                disabled={true}
                value={
                  values.mobile_number !== null
                    ? values.mobile_number
                    : ''
                }
              ></Form.Control>
              <div
                className={classNames('empty-msg', {
                  visible: errors.mobile_number !== ''
                })}
              >
                {errors.mobile_number}
              </div>
            </Form.Group>
            <Form.Group className="custom-form-group">
              <Form.Label className="custom-form-label">
                Name<span className="required-star">*</span>
              </Form.Label>
              <Form.Control
                size="lg"
                className="custom-form-input"
                name="name"
                value={
                  values.name !== null
                    ? values.name
                    : ''
                }
                disabled={true}
                placeholder="Name"
              ></Form.Control>
              <div
                className={classNames('empty-msg', {
                  visible: errors.name !== ''
                })}
              >
                {errors.name}
              </div>
            </Form.Group>
            {contactName.length ? (
              <Form.Group
                className="custom-form-group"
                style={{ width: '100%' }}
              >
                <Form.Label className="custom-form-label">
                  Contact Name
                </Form.Label>
                <Form.Control
                  disabled={true}
                  size="lg"
                  className="custom-form-input"
                  name="name"
                  value={contactName || ''}
                ></Form.Control>
              </Form.Group>
            ) : (
              <></>
            )}
            <Form.Group className="custom-form-group">
              <Form.Label className="custom-form-label">Taluka</Form.Label>
              <Form.Control
                disabled={true}
                size="lg"
                className="custom-form-input"
                name="name"
                value={values.taluka || ''}
              ></Form.Control>
            </Form.Group>
            <Form.Group className="custom-form-group">
              <Form.Label className="custom-form-label">Village</Form.Label>
              <Form.Control
                disabled={true}
                size="lg"
                className="custom-form-input"
                name="name"
                value={values.village || ''}
              ></Form.Control>
            </Form.Group>
            <Form.Group className="custom-form-group">
              <Form.Label className="custom-form-label">TAFE BRYG</Form.Label>
              <Form.Control
                disabled={true}
                size="lg"
                className="custom-form-input"
                name="name"
                value={values.color_code || ''}
              ></Form.Control>
            </Form.Group>
            <Form.Group className="custom-form-group">
              <Form.Label className="custom-form-label">TAFE TIV</Form.Label>
              <Form.Control
                disabled={true}
                size="lg"
                className="custom-form-input"
                name="name"
                value={values.tiv || ''}
              ></Form.Control>
            </Form.Group>
            <Form.Group className="custom-form-group">
              <Form.Label className="custom-form-label">
                Enquiry Category<span className="required-star">*</span>
              </Form.Label>
              <Form.Control
                as="div"
                size="lg"
                className="custom-form-div"
              >
                <SearchDropdowntemplate
                  options={ECArray}
                  name='enquiry_category'
                  isSearchable={true}
                  isDisabled={!isEditable}
                  defaultValue={values.enquiry_category}
                  placeholder="Select Category"
                  onDropdownChange={onDropdownChange}
                ></SearchDropdowntemplate>
              </Form.Control>
              <div
                className={classNames('empty-msg', {
                  visible: errors.enquiry_category !== ''
                })}
              >
                {errors.enquiry_category}
              </div>
            </Form.Group>
            <Form.Group className="custom-form-group">
              <Form.Label className="custom-form-label">
                Source of Generation<span className="required-star">*</span>
              </Form.Label>
              <Form.Control
                as="div"
                size="lg"
                className="custom-form-div"
              >
                <SearchDropdowntemplate
                  options={sogArray}
                  isDisabled={true}
                  placeholder="Select the SOG"
                  defaultValue={values.sog}
                ></SearchDropdowntemplate>
              </Form.Control>
              <div
                className={classNames('empty-msg', {
                  visible: errors.sog !== ''
                })}
              >
                {errors.sog}
              </div>
            </Form.Group>
            <Form.Group className="custom-form-group">
              <Form.Label className="custom-form-label">
                Enquiry stage<span className="required-star">*</span>
              </Form.Label>
              <Form.Control
                as="div"
                size="lg"
                className="custom-form-div"
              >
                <SearchDropdowntemplate
                  options={stageArray}
                  isSearchable={true}
                  isDisabled={!isEditable}
                  placeholder="Enquiry stage"
                  name='stage'
                  defaultValue={values.stage}
                  onDropdownChange={onDropdownChange}
                ></SearchDropdowntemplate>
              </Form.Control>
              <div
                className={classNames('empty-msg', {
                  visible: errors.stage !== ''
                })}
              >
                {errors.stage}
              </div>
            </Form.Group>
            {
              renderStagesMandatoryFields()
            }
            <Form.Group className="custom-form-group">
              <Form.Label className="custom-form-label">
                Model Family<span className="required-star">*</span>
              </Form.Label>
              <Form.Control
                as="div"
                size="lg"
                className="custom-form-div"
              >
                <SearchDropdowntemplate
                  options={IMFArray}
                  isSearchable={true}
                  placeholder="Modal Family"
                  name='interested_model_family'
                  defaultValue={values.interested_model_family}
                  isDisabled={!isEditable}
                  onDropdownChange={onDropdownChange}
                ></SearchDropdowntemplate>
              </Form.Control>
              <div
                className={classNames('empty-msg', {
                  visible: errors.interested_model_family !== ''
                })}
              >
                {errors.interested_model_family}
              </div>
            </Form.Group>
            <Form.Group className="custom-form-group">
              <Form.Label className="custom-form-label">
                Follow up date<span className="required-star">*</span>
              </Form.Label>
              <Form.Control
                size="lg"
                placeholder="No of Days"
                className="custom-form-input"
                name="next_follow_up_in_days"
                disabled={!isEditable}
                value={
                  values.next_follow_up_in_days !== null
                    ? values.next_follow_up_in_days
                    : ''
                }
                onChange={onInputChange}
              ></Form.Control>
              <div
                className={classNames('empty-msg', {
                  visible: errors.next_follow_up_in_days !== ''
                })}
              >
                {errors.next_follow_up_in_days}
              </div>
            </Form.Group>
            <Form.Group className="custom-form-group">
              <Form.Label className="custom-form-label">
                EDD<span className="required-star">*</span>
              </Form.Label>
              <Form.Control
                size="lg"
                className="custom-form-input"
                placeholder="No of Days"
                disabled={!isEditable}
                name="expected_date_of_delivery_in_days"
                value={
                  values.expected_date_of_delivery_in_days !== null
                    ? values.expected_date_of_delivery_in_days
                    : ''
                }
                onChange={onInputChange}
              ></Form.Control>
              <div
                className={classNames('empty-msg', {
                  visible: errors.expected_date_of_delivery_in_days !== ''
                })}
              >
                {errors.expected_date_of_delivery_in_days}
              </div>
            </Form.Group>
            <Form.Group className="custom-form-group">
              <Form.Label className="custom-form-label">
                Interested in Exchange?<span className="required-star">*</span>
              </Form.Label>
              <div className="exchange-tractor-permission">
                <Form.Check
                  inline
                  label="Yes"
                  value="Yes"
                  name="has_exchange"
                  type="radio"
                  disabled={!isEditable}
                  checked={values.has_exchange === 'Yes'}
                  onChange={onCheckChange}
                />
                <Form.Check
                  inline
                  label="No"
                  value="No"
                  name="has_exchange"
                  type="radio"
                  disabled={!isEditable}
                  checked={values.has_exchange === 'No'}
                  onChange={onCheckChange}
                />
              </div>
            </Form.Group>
            {
              values.has_exchange === 'Yes'
              &&
                <>
                  <Form.Group className="custom-form-group">
                    <Form.Label className='custom-form-label'>
                      Exchange Make<span className="required-star">*</span>
                    </Form.Label>
                    <Form.Control
                      as="div"
                      size="lg"
                      className="custom-form-div"
                    >
                      <SearchDropdowntemplate
                        options={exchangeBrand}
                        defaultValue={values.exchange_brand}
                        name='exchange_brand'
                        placeholder="Exchange brand"
                        isDisabled={!isEditable}
                        onDropdownChange={onDropdownChange}
                      ></SearchDropdowntemplate>

                    </Form.Control>
                    <div
                      className={classNames('empty-msg', {
                        visible: errors.exchange_brand !== ''
                      })}
                    >
                      {errors.exchange_brand}
                    </div>
                  </Form.Group>
                  <Form.Group className="custom-form-group">
                    <Form.Label className='custom-form-label'>
                      Exchange model<span className="required-star">*</span>
                    </Form.Label>
                    <Form.Control
                      as="div"
                      size="lg"
                      className="custom-form-div"
                    >
                      <SearchDropdowntemplate
                        options={exchangeModel}
                        placeholder="Exchange model"
                        defaultValue={values.exchange_model}
                        isDisabled={!isEditable}
                        name='exchange_model'
                        onDropdownChange={onDropdownChange}
                      ></SearchDropdowntemplate>
                    </Form.Control>
                    <div
                      className={classNames('empty-msg', {
                        visible: errors.exchange_model !== ''
                      })}
                    >
                      {errors.exchange_model}
                    </div>
                  </Form.Group>
                  <Form.Group className='custom-form-group'>
                    <Form.Label className='custom-form-label'>
                      YOM<span className="required-star">*</span>
                    </Form.Label>
                    <Form.Control
                      size='lg'
                      as='div'
                      className='custom-form-div'
                    >
                      <SearchDropdowntemplate
                        options={yom}
                        placeholder="YOM"
                        isDisabled={!isEditable}
                        defaultValue={values.exchange_yom}
                        name='exchange_yom'
                        onDropdownChange={onDropdownChange}
                      ></SearchDropdowntemplate>
                    </Form.Control>
                    <div
                      className={classNames('empty-msg', {
                        visible: errors.exchange_yom !== ''
                      })}
                      >
                        {errors.exchange_yom}
                    </div>
                  </Form.Group>
                  <Form.Group className="custom-form-group">
                    <Form.Label className='custom-form-label'>
                      Margin Money 2<span className="required-star">*</span>
                    </Form.Label>
                    <Form.Control
                      size="lg"
                      type='number'
                      className="custom-form-input"
                      disabled={!isEditable}
                      name="exchange_value"
                      placeholder='Margin Money'
                      value={values.exchange_value !== null ? values.exchange_value : ''}
                      onChange={onInputChange}
                    ></Form.Control>
                    <div
                      className={classNames('empty-msg', {
                        visible: errors.exchange_value !== ''
                      })}
                    >
                        {errors.exchange_value}
                    </div>
                  </Form.Group>
                </>
            }
            <Form.Group className="custom-form-group">
              <Form.Label className="custom-form-label">
                Mode of payment<span className="required-star">*</span>
              </Form.Label>
              <Form.Control
                as="div"
                size="lg"
                className="custom-form-div dropdown-form-div"
              >
                <Dropdowntemplate
                  defaultValue={values.payment_mode as string}
                  disabled={!isEditable}
                  name='payment_mode'
                  dropdownMenu={paymentArray}
                  onDropdownChange={onDropdownChange}
                ></Dropdowntemplate>
              </Form.Control>
            </Form.Group>
            {
              values.payment_mode === 'Bank'
              &&
              <Form.Group className='custom-form-group'>
                <Form.Label className='custom-form-label'>
                  Bank Description<span className="required-star">*</span>
                </Form.Label>
                <Form.Control
                  as='div'
                  size='lg'
                  className='custom-form-div'
                >
                  <SearchDropdowntemplate
                    options={bankArray}
                    placeholder="Select Bank"
                    name='bank_description'
                    defaultValue={values.bank_description}
                    isDisabled={!isEditable}
                    onDropdownChange={onDropdownChange}
                  />
                </Form.Control>
                <div
                      className={classNames('empty-msg', {
                        visible: errors.bank_description !== ''
                      })}
                  >
                      {errors.bank_description}
                  </div>
              </Form.Group>
            }
            <Form.Group className="custom-form-group">
              <Form.Label className="custom-form-label">Dealer Name</Form.Label>
              <Form.Control
                disabled={true}
                size="lg"
                className="custom-form-input"
                name="name"
                value={currentUser.user.dealer_name || ''}
              ></Form.Control>
            </Form.Group>
            <Form.Group className="custom-form-group">
              <Form.Label className="custom-form-label">Dealer Code</Form.Label>
              <Form.Control
                disabled={true}
                size="lg"
                className="custom-form-input"
                name="name"
                value={currentUser.user.dealer_code || ''}
              ></Form.Control>
            </Form.Group>
          </div>
          <div className="custom-form-btn">
            <Buttontemplate type="primary" buttonWidth="100%" disabled={disableBtn}>
              {btnLoading ? (
                <div className="button-loader">
                  <Loadertemplate type="button" />
                </div>
              ) : (
                isEditable ? 'Update Enquiry' :
                'Edit Equiry'
              )}
            </Buttontemplate>
          </div>
        </Form>
      )}
    </>
  );
};

export default EnquiryForm;
