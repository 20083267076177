import {
  Framework,
  IEnquiries,
  IEnquiriesStat,
  IOptions,
  IProspect
} from "../../Types/global";
import { ProspectServiceParameters } from "../../Types/parameters";
import { ProspectServicesParameters } from "../../Types/parameters";
import api_request from "../constants/api_request";
import AppConstants from "../constants/AppContants";
import RequestMethod from "../enums/RequestMethod";
import RequestHandler from "../handlers/RequestHandler";

type EnquirySummary = {
  total_leads: number;
  total_prospects: number;
  total_sales_lost: number;
  hot_prospect_sogs: Array<{
    key: string;
    value: number;
  }>;
  total_deliveries: number;
  hot_prospect_stages: Array<{
    key: string;
    value: number;
  }>;
  total_sales_dropped: number;
  total_hot_prospects: number;
  total_overdue_hot_prospects: number;
};

export default class ProspectServices {
  public static getEnquiryStat = ({
    url,
    method,
    params,
    body,
    signal
  }: {
    url: string;
    method: RequestMethod;
    params?: any;
    body?: unknown;
    signal: AbortSignal;
  }): Promise<Framework.IResult<IEnquiriesStat>> => {
    let reqHandler = new RequestHandler(url, method);
    if (params) {
      for (const key in params) {
        if (Object.prototype.hasOwnProperty.call(params, key)) {
          reqHandler.addParam(key, params[key]);
        }
      }
    }
    if (body) {
      reqHandler.setBody(body);
    }
    reqHandler.setAbortSignal(signal);
    return reqHandler
      .execute()
      .then((response) => response.data as Framework.IResult<IEnquiriesStat>);
  };
  public static getProspectDetails = ({
    url,
    method,
    signal
  }: {
    url: string;
    method: RequestMethod;
    signal: AbortSignal;
  }): Promise<Framework.IResult<IProspect>> => {
    let reqHandler = new RequestHandler(url, method);
    reqHandler.setAbortSignal(signal);
    return reqHandler
      .execute()
      .then((response) => response.data as Framework.IResult<IProspect>);
  };

  public static async getPagedProspects({
    page,
    perPage,
    dspUserId,
    filters: {
      sogs,
      stages,
      prospectId,
      contactName,
      eddEndValue,
      eddStartValue,
      contactMobileNumber,
      createdTimeEndValue,
      enquiryClassification,
      createdTimeStartValue,
      dateOfDeliveryEndValue,
      dateOfDeliveryStartValue
    },
    additionalFields = [],
    signal
  }: ProspectServiceParameters.GetPagedProspect.Parameters): Promise<ProspectServiceParameters.GetPagedProspect.ReturnType> {
    const handler = new RequestHandler(
      `https://tafe-akilavan-50022289662.catalystappsail.in/api/v1/dsp-users/${dspUserId}/prospects`,
      RequestMethod.GET
    );
    handler.addParam("page", page);
    handler.addParam("per_page", perPage);

    if (sogs && sogs.length) {
      handler.addParam("sogs", sogs.join(","));
    }

    if (stages && stages.length) {
      handler.addParam("stages", stages.join(","));
    }

    if (prospectId) {
      handler.addParam("prospect_id", prospectId);
    }

    if (contactName) {
      handler.addParam("contact_name", contactName);
    }

    if (eddEndValue) {
      handler.addParam("edd_end_value", eddEndValue);
    }

    if (eddStartValue) {
      handler.addParam("edd_start_value", eddStartValue);
    }

    if (contactMobileNumber) {
      handler.addParam("contact_mobile_number", contactMobileNumber);
    }

    if (enquiryClassification) {
      handler.addParam("enquiry_classification", enquiryClassification);
    }

    if (dateOfDeliveryEndValue) {
      handler.addParam("date_of_delivery_end_value", dateOfDeliveryEndValue);
    }

    if (dateOfDeliveryStartValue) {
      handler.addParam(
        "date_of_delivery_start_value",
        dateOfDeliveryStartValue
      );
    }

    if (createdTimeStartValue) {
      handler.addParam("created_time_start_value", createdTimeStartValue);
    }
    if (createdTimeEndValue) {
      handler.addParam("created_time_end_value", createdTimeEndValue);
    }

    if (additionalFields.length) {
      handler.addParam("additional_fields", additionalFields.join(","));
    }

    if (signal) {
      handler.setAbortSignal(signal);
    }

    handler.addHeader("app-secret-key", AppConstants.APP_SECRET_KEY);

    return handler
      .execute()
      .then(
        (result) =>
          result.data as unknown as ProspectServiceParameters.GetPagedProspect.ReturnType
      );
  }
  public static getProspectMeta = ({
    url,
    method,
    signal
  }: {
    url: string;
    method: RequestMethod;
    signal: AbortSignal;
  }): Promise<ProspectServicesParameters.ReturnType> => {
    let reqHandler = new RequestHandler(url, method);
    reqHandler.setAbortSignal(signal);
    return reqHandler
      .execute()
      .then(
        (response) => response.data as ProspectServicesParameters.ReturnType
      );
  };
  public static putProspectRecord = ({
    values
  }: ProspectServiceParameters.PutProspectRecord.Parameters): Promise<
    Framework.IResult<IProspect>
  > => {
    let json: Record<string, unknown> = {};
    let reqHandler = new RequestHandler(
      api_request.PROSPECT_DETAILS + values.id,
      RequestMethod.PUT
    );
    if (values.stage) {
      json["stage"] = values.stage.value;
    }
    if (values.sog) {
      json["sog"] = values.sog.value;
    }
    if (values.taluka) {
      json["taluka"] = values.taluka;
    }
    if (values.village) {
      json["village"] = values.village;
    }
    if (values.color_code) {
      json["color_code"] = values.color_code;
    }
    if (values.tiv) {
      json["tiv"] = values.tiv;
    }
    if (values.margin_amount) {
      json["margin_amount"] = values.margin_amount;
    }
    if (values.date_of_delivery) {
      json["date_of_delivery"] = values.date_of_delivery;
    }
    if (values.name) {
      json["name"] = values.name;
    }
    if (values.mobile_number) {
      json["mobile_number"] = values.mobile_number;
    }
    if (values.enquiry_category) {
      json["enquiry_category"] = values.enquiry_category.value;
    }
    if (values.next_follow_up_in_days) {
      json["next_follow_up_in_days"] = values.next_follow_up_in_days;
    }
    if (values.interested_model_family) {
      json["interested_model_family"] = values.interested_model_family.value;
    }
    if (values.expected_date_of_delivery_in_days) {
      json["expected_date_of_delivery_in_days"] =
        values.expected_date_of_delivery_in_days;
    }
    json["sales_lost_details"] = {};
    if (values.sales_lost_reason) {
      (json.sales_lost_details as Record<string, unknown>).reason =
        values.sales_lost_reason.value;
    }
    if (values.sales_lost_remarks) {
      (json.sales_lost_details as Record<string, unknown>).remarks =
        values.sales_lost_remarks;
    }
    if (values.sales_lost_competitor_brand) {
      (json.sales_lost_details as Record<string, unknown>).brand =
        values.sales_lost_competitor_brand.value;
    }
    if (values.sales_lost_competitor_model) {
      (json.sales_lost_details as Record<string, unknown>).model =
        values.sales_lost_competitor_model.value;
    }
    json["sales_dropped_details"] = {};
    if (values.sales_dropped_reason) {
      (json.sales_dropped_details as Record<string, unknown>).reason =
        values.sales_dropped_reason.value;
    }
    if (values.sales_dropped_remarks) {
      (json.sales_dropped_details as Record<string, unknown>).remarks =
        values.sales_dropped_remarks;
    }
    json["exchange_details"] = {};
    if (values.has_exchange) {
      (json.exchange_details as Record<string, unknown>).has_exchange =
        values.has_exchange;
      json["has_exchange"] = values.has_exchange; // Remove this code after backend change
    }
    if (values.exchange_brand) {
      (json.exchange_details as Record<string, unknown>).brand =
        values.exchange_brand.value;
    }
    if (values.exchange_model) {
      (json.exchange_details as Record<string, unknown>).model =
        values.exchange_model.value;
    }
    if (values.exchange_value) {
      (json.exchange_details as Record<string, unknown>).value =
        values.exchange_value;
    }
    if (values.exchange_yom) {
      (json.exchange_details as Record<string, unknown>).year_of_manufacture =
        values.exchange_yom.value;
    }
    json["payment_details"] = {};
    if (values.bank_description) {
      (json.payment_details as Record<string, unknown>).bank_description =
        values.bank_description.value;
    }
    if (values.tractor_deal_value) {
      (json.payment_details as Record<string, unknown>).tractor_deal_value =
        values.tractor_deal_value;
    }
    if (values.other_deal_values) {
      (json.payment_details as Record<string, unknown>).others_deal_value =
        values.other_deal_values;
    }
    if (values.payment_mode) {
      (json.payment_details as Record<string, unknown>).payment_mode =
        values.payment_mode;
      json.payment_mode = values.payment_mode; // Remove this code after backend change
    }
    json["shining_tractor_details"] = {};
    if (values.shining_tractor_checklist_updated) {
      (
        json.shining_tractor_details as Record<string, unknown>
      ).checklist_updated = values.shining_tractor_checklist_updated.value;
    }
    if (values.shining_tractor_image_uploaded) {
      (json.shining_tractor_details as Record<string, unknown>).image_uploaded =
        values.shining_tractor_image_uploaded.value;
    }
    reqHandler.setBody(json);
    return reqHandler
      .execute()
      .then((response) => response.data as Framework.IResult<IProspect>);
  };
}
