import React, { useCallback, useContext, useEffect, useState } from "react";
import "./index.css";
import Loadertemplate from "../../templates/Loadertemplate";
import axios from "axios";
import Prospecttemplate from "../../templates/Prospecttemplate";
import CallContactTemp from "../../templates/CallContactTemp";
import WhatsappContactTemp from "../../templates/WhatsappContactTemp";
import { ArrowPrimarySvg } from "../../../icons/ArrowPrimarySvg";
import dateHooks from "../../Hooks/dateHooks";
import prospectHooks from "../../Hooks/useHome";
import {
  INumberOfCards,
  IOverdueProspect,
  IUserContext
} from "../../../Types/global";
import api_request from "../../../shared/constants/api_request";
import EmptyTemp from "../../templates/EmptyTemp";
import ServerConnectorUtil from "../../../shared/utils/ServerConnectorUtil";
import { Link } from "react-router-dom";
import AppUrlConstants from "../../../shared/constants/AppUrlConstants";
import AppQueryParameters from "../../../shared/enums/AppQueryParameters";
import DateFormat from "../../../shared/enums/DateFormat";
import ListBoxtemplate from "../../templates/ListBoxtemplate";
import ProspectStage from "../../../shared/enums/ProspectStage";
import DateTimeProcessor from "../../../shared/processors/DateTimeProcessor";
import usePagedProspects from "../../../shared/hooks/usePagedProspects";
import { UserContext } from "../../HOC/UserContext";

const i18nTranslated = {
  overdueHeader: "Overdue HP"
};
const OverdueHps: React.FC<INumberOfCards> = () => {
  const currentUser = useContext(UserContext) as IUserContext;

  const [overdueHopProspectsFilters] = useState({
    stages: [
      ProspectStage.F1,
      ProspectStage.F2,
      ProspectStage.F3,
      ProspectStage.F4,
      ProspectStage.F5
    ],
    eddEndValue: DateTimeProcessor.format(
      DateTimeProcessor.startOfDay(Date.now()) as number
    )
  });

  const { data, isLoading, totalRecords } = usePagedProspects({
    page: 1,
    perPage: 200,
    dspUserId: currentUser.user.id,
    filters: overdueHopProspectsFilters
  });

  return (
    <ListBoxtemplate
      accordionWrapClass="overdue-accordion-wrap"
      hideIcon={totalRecords < 3}
    >
      <div className="dsp-accordion-head fCenter">
        <p className="heading-accordion">{i18nTranslated.overdueHeader}</p>
        <div className="dot-accordion"></div>
        <Link
          to={{
            pathname: AppUrlConstants.INNER_BASE + AppUrlConstants.PROSPECTS,
            search: new URLSearchParams({
              stages: [
                ProspectStage.F1,
                ProspectStage.F2,
                ProspectStage.F3,
                ProspectStage.F4,
                ProspectStage.F5
              ].join(","),
              edd_end_value: DateTimeProcessor.format(
                DateTimeProcessor.startOfDay(Date.now()) as number
              ),
              [AppQueryParameters.COMMON.KEYS.TITLE]:
                AppQueryParameters.PROSPECT.VALUES.OVERDUE_HP_TITLE
            }).toString()
          }}
          className="count-accordion"
        >
          {totalRecords}
        </Link>
      </div>
      <div className="dsp-accordion-content">
        {isLoading ? (
          <div className="loader-wrapper">
            <Loadertemplate />
          </div>
        ) : data.length === 0 ? (
          <div className="accordion-empty">
            <EmptyTemp />
          </div>
        ) : (
          data.map((item) => (
            <Link
                    to={{
                      pathname:
                        AppUrlConstants.INNER_BASE +
                        AppUrlConstants.PROSPECT +
                        "/" +
                        item.id,
                      search: new URLSearchParams({
                        [AppQueryParameters.COMMON.KEYS.TITLE]:
                          AppQueryParameters.PROSPECT.VALUES.PROSPECT_DETAILS
                      }).toString()
                    }}
                  >
                      <Prospecttemplate color_code={item.color_code}>
                        <div className="overdueHP-detail">
                          <div className="overdueHP-name fCenter">
                            <span className="name">{item.name}</span>
                            <span className="followupNavigate">
                              <ArrowPrimarySvg />
                            </span>
                          </div>
                          <div className="overdueHP-edd-detail">
                            EDD -{" "}
                            {dateHooks.formatDate(
                              item.edd as string,
                              DateFormat.DISPLAY_DATE
                            )}
                          </div>
                          <div className="overdueHP-location">{item.contact.village}</div>
                          <div className="overdueHP-truckModel">
                            Model interested - {item.interested_model_family}
                          </div>
                        </div>
                        <div className="overdueHP-social-contact fCenter fJustifyFD">
                          <CallContactTemp
                            className="prospect-contact"
                            phoneNumber={item.contact.mobile_number}
                          />
                          <WhatsappContactTemp phoneNumber={item.contact.mobile_number} />
                        </div>
                      </Prospecttemplate>
                  </Link>
          ))
        )}
      </div>
    </ListBoxtemplate>
  );
};

export default OverdueHps;
