import React from 'react'
import { IIconProps } from '../Types/global'

const NavigateArrowSvg: React.FC<IIconProps> = ({fill}) => {
  fill = (fill !== undefined) ? fill : '#E94460';
  return (
    <svg width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12.6695 6.39969L6.50027 0.230469L0.331055 6.39969L1.22719 7.29582L6.50027 2.02275L11.7734 7.29582L12.6695 6.39969Z" fill={fill}/>
    </svg>
  )
}

export default NavigateArrowSvg