import { useCallback, useState } from "react";

import useEffectAfterMount from "./useEffectAfterMount";
import { IOptions } from "../../Types/global";
import { UseHorizontalCalendarHook } from "../../Types/hooks";
import CalendarMonth from "../enums/CalendarMonth";

export default function useHorizontalCalendar({
  fromYear
}: UseHorizontalCalendarHook.Parameters) {
  const [initialDate] = useState(new Date());
  const [yearOptions] = useState<Array<IOptions>>(
    Array.from(
      { length: initialDate.getFullYear() - fromYear + 1 },
      (_, index) => ({
        label: (fromYear + index).toString(),
        value: (fromYear + index).toString()
      })
    )
  );
  const [monthOptions] = useState<Array<IOptions>>(
    Array.from({ length: 12 }, (_, index) => ({
      label: new Date(0, index).toLocaleString("default", { month: "long" }),
      value: new Date(0, index).toLocaleString("default", { month: "long" })
    }))
  );

  const [dates, setDates] = useState<Date[]>([]);
  const [currentYear, setCurrentYear] = useState<number>(
    initialDate.getFullYear()
  );
  const [currentMonth, setCurrentMonth] = useState<number>(
    initialDate.getMonth()
  );
  const [currentDate, setCurrentDate] = useState<Date>(initialDate);

  useEffectAfterMount(() => {
    const totalDays = new Date(currentYear, currentMonth + 1, 0).getDate();
    setDates(
      Array.from(
        { length: totalDays },
        (_, index) => new Date(currentYear, currentMonth, index + 1)
      )
    );
  }, [currentYear, currentMonth]);

  const changeYear = useCallback((year: number) => {
    setCurrentYear(year);
    setCurrentMonth(0);
    setCurrentDate(new Date(year, 0, 1));
  }, []);

  const changeMonth = useCallback(
    (month: number) => {
      setCurrentMonth(month);
      setCurrentDate(new Date(currentYear, month, 1));
    },
    [currentYear]
  );

  return {
    dates,
    yearOptions,
    monthOptions,
    currentYear,
    currentMonth,
    currentDate,
    changeYear,
    changeMonth,
    changeDate: setCurrentDate,
    formattedCurrentYear: currentYear.toString(),
    formattedCurrentMonth:
      CalendarMonth.getAllMonths()[currentMonth].mappedValue
  };
}
