import React from 'react';
import './index.css';
import { Outlet } from 'react-router-dom';

const Successtemplate : React.FC= () => {
    return (
        <div className='success-container'>
            <Outlet/>
        </div>
    )
}
export default Successtemplate;
