import styles from "./styles.module.css";

import { Model } from "../../../../../Types/global";
import classNames from "classnames";
import Checkbox from "../../../../../shared/components/Checkbox";
import React, { useCallback } from "react";

const NonAnchorVillage: React.FC<{
  village: Model.Village;
  checked: boolean;
  disabled: boolean;
  addVillage: (village: Model.Village) => void;
  removeVillage: (village: Model.Village) => void;
}> = ({ checked, disabled, village, addVillage, removeVillage }) => {
  const onCheckboxChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { checked } = event.target;

      if (checked) {
        addVillage(village);
      } else {
        removeVillage(village);
      }
    },
    [village, addVillage, removeVillage]
  );
  return (
    <div className={styles["list-item"]}>
      <Checkbox
        checked={checked}
        onChange={onCheckboxChange}
        disabled={!checked && disabled}
      />
      <div className={styles["content-wrapper"]}>
        <h6 className={styles["title"]}>{village.name}</h6>
        <div className={styles["info-container"]}>
          <p className={styles["text"]}>
            {village.distance ? village.distance.toFixed(2) + " KM" : "NA"}
          </p>
          <div className={styles["color-code-stat-container"]}>
            <div
              className={classNames(
                styles["dot"],
                village.color_code
                  ? styles[village.color_code.toLowerCase()]
                  : styles["na"]
              )}
            />
            <p className={styles["text"]}>
              Visits YTD : {village.stats?.total_jcp_visits_year_till_date}
            </p>
          </div>
          <p className={styles["text"]}>
            TIV : {village.tiv}
          </p>
        </div>
      </div>
    </div>
  );
};

export default NonAnchorVillage;
