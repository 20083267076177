export default class AppQueryParameters {
  public static readonly COMMON = class {
    public static readonly KEYS = class {
      public static readonly TITLE = 'title';
      public static readonly START_DATE = 'start_date';
      public static readonly END_DATE = 'end_date';
    };
  };
  public static readonly TRACTOR_USER_DATABASE = class {
    public static readonly KEYS = class {
      public static readonly NAME = 'name';
      public static readonly TYPE = 'type';
      public static readonly VILLAGE_NAME = 'village_name';
      public static readonly VILLAGE_COLOR_CODE = 'village_color_code';
      public static readonly TRACTOR_MODEL = 'tractor_model';
      public static readonly MOBILE_NUMBER = 'mobile_number';
      public static readonly IS_SATISFIED_WITH_HIS_TRACTOR =
        'is_satisfied_with_his_tractor';
    };

    public static readonly VALUES = class {
      public static readonly RECORDS_VIEW_TITLE = 'tractor_master';
      public static readonly MFDISSATISFIEDMODEL_VIEW_TITLE = 'TopMfDissatisfiedModel_label';
      public static readonly MFSATIFIEDMODEL_VIEW_TITLE = 'TopMfSatisfiedModel_label';
      public static readonly COMPDISSATISFIEDMODEL_VIEW_TITLE = 'TopCompDissatisfiedModel_label';
      public static readonly COMPSATISFIEDMODEL_VIEW_TITLE = 'TopCompSatisfiedModel_label';
      
    };
  };

  public static readonly PROSPECT = class {
      public static readonly PROSPECT_EDIT = "title=prospect_edit";
      public static readonly KEYS = class {
        public static readonly ENQUIRY_CLASSIFICATION = 'enquiry_classification';
        public static readonly STAGES = 'stages';
      }
      public static readonly VALUES = class {
        public static readonly OVERDUE_HP = 'Overdue Hot Prospect';
        public static readonly RECORDS_VIEW_TITLE = 'enquiry_master';
        public static readonly OVERDUE_HP_TITLE = 'overdue';
        public static readonly PROSPECT_DETAILS = "prospect_details";
        public static readonly DELIVERY_TITLE = "deliveries";
        public static readonly DELIVERY = 'Delivery';
      }
  }
  public static readonly CHECK_IN_JCP = class {
      public static readonly VALUES = class {
        public static readonly VILLAGE_DETAILS = 'village_details';
        public static readonly CHECK_IN_JCPS = 'check-in-jcps';
      }
  }
}
