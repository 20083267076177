export default class CalendarMonth {
  public static readonly JANUARY = new CalendarMonth(0, "January");
  public static readonly FEBRUARY = new CalendarMonth(1, "February");
  public static readonly MARCH = new CalendarMonth(2, "March");
  public static readonly APRIL = new CalendarMonth(3, "April");
  public static readonly MAY = new CalendarMonth(4, "May");
  public static readonly JUNE = new CalendarMonth(5, "June");
  public static readonly JULY = new CalendarMonth(6, "July");
  public static readonly AUGUST = new CalendarMonth(7, "August");
  public static readonly SEPTEMBER = new CalendarMonth(8, "September");
  public static readonly OCTOBER = new CalendarMonth(9, "October");
  public static readonly NOVEMBER = new CalendarMonth(10, "November");
  public static readonly DECEMBER = new CalendarMonth(11, "December");

  public readonly value: number;
  public readonly mappedValue: string;

  private constructor(value: number, mappedValue: string) {
    this.value = value;
    this.mappedValue = mappedValue;
  }

  public static getByValue(value: number): CalendarMonth | undefined {
    return this.getAllMonths().find((month) => month.value === value);
  }

  public static getByMappedValue(
    mappedValue: string
  ): CalendarMonth | undefined {
    return this.getAllMonths().find(
      (month) => month.mappedValue === mappedValue
    );
  }

  public static getAllMonths(): CalendarMonth[] {
    return Object.values(CalendarMonth).filter(
      (month) => month instanceof CalendarMonth
    );
  }
}
