import { Link } from 'react-router-dom';
import { ArrowSvg } from '../../../icons/ArrowSvg';

export type ListItemLinkProps = {
  to?: string 
};


const ListItemLink: React.FC<ListItemLinkProps> = ({ to }) => {
  return (
    <>
      {
        to ? 
          <Link className="link-arrow-position" to={to}>
            <ArrowSvg />
          </Link>
        :
          <ArrowSvg />
      }
    </>
  );
};

export default ListItemLink;
