import React, { ReactNode, useEffect, useRef, useState } from 'react'
import './index.css';
import classNames from 'classnames';
import { Accordion } from 'react-bootstrap';
import { IAccordionItem } from '../../../Types/global';
import NavigateArrowSvg from '../../../icons/NavigateArrowSvg';

interface IAccordionProps{
    accordionItems: IAccordionItem[],
    accordionWrapClass?: string,
    accordionHeadClass?: string,
    accordionBodyClass?: string
}
const Accordiontemplate : React.FC<IAccordionProps> = ({accordionItems, accordionWrapClass, accordionHeadClass, accordionBodyClass}) => {
    return(
        <Accordion alwaysOpen defaultActiveKey="0">
            {
                accordionItems.map((item,index)=>(
                    <Accordion.Item eventKey={`${index}`} className={classNames(accordionWrapClass && accordionWrapClass)} key={index} >
                        <Accordion.Header className={classNames('tafe-accordion-header',accordionHeadClass && accordionHeadClass)}>
                            {item.accordionHead}
                            <span className='accordion-navigate'>
                                <NavigateArrowSvg/>
                            </span>
                        </Accordion.Header>
                        <Accordion.Body className={classNames(accordionBodyClass && accordionBodyClass)}>{item.accordionBody}</Accordion.Body>
                    </Accordion.Item>
                ))
            }
        </Accordion>
    )
}

export default Accordiontemplate;
