import RequestMethod from "../enums/RequestMethod";
import RequestHandler from "../handlers/RequestHandler";
import { JcPlanApprovalServiceParameters } from "../../Types/parameters";

export default class JcpPlanApproval {
  public static async getPagedJcPlanApprovals({
    page,
    perPage,
    filters: { year, month, statuses }
  }: JcPlanApprovalServiceParameters.GetPagedJcPlanApprovals.Parameters): Promise<JcPlanApprovalServiceParameters.GetPagedJcPlanApprovals.ReturnType> {
    const handler = new RequestHandler(
      "/api/v1/jc-plan-approvals",
      RequestMethod.GET
    );

    handler.addParam("page", page);
    handler.addParam("perPage", perPage);

    if (year) {
      handler.addParam("year", year);
    }
    if (month) {
      handler.addParam("month", month);
    }
    if (statuses && statuses.length) {
      handler.addParam("statuses", statuses?.join(","));
    }

    return handler
      .execute()
      .then(
        (result) =>
          result.data as JcPlanApprovalServiceParameters.GetPagedJcPlanApprovals.ReturnType
      );
  }
}
