import {useEffect, useState } from "react";
import Dashboard from "./Pages/Dashboard";
import DspHome from "./Pages/DspHome";
import DspJcp from "./Pages/DspJcp";
import Enquiries from "./Pages/Enquiries";
import { IUser } from "../Types/global";
import { UserContext } from "./HOC/UserContext";
import {Outlet, useLocation, useNavigate } from "react-router-dom";
import Login from "./Login";
import EnquiryForm from "./Pages/Enquriy/EnquiryForm";
import Menu from "./Pages/Menu";
import TUDBForm from "./Pages/TUDBForm";
import Prospects from "./Pages/Prospects";
import CheckInJcps from "../check-in-jcps";
import CheckInJcp from "../check-in-jcp";
import VisitDetails from "./Pages/VisitDetails";
import Loadertemplate from "./templates/Loadertemplate";
import MainLayout from "./Pages/MainLayout";
import './index.css';
import EnquiryTab from "./Pages/Enquiries/EnquiryTab";
import TUDBTab from "./Pages/Enquiries/TUDBTab";
import Leaderboard from "./Pages/Leaderboard";
import MPCMDCTabs from "./Pages/MPCMDCTabs";
import MPCTab from "./Pages/MPCMDCTabs/MPCTab";
import MDCTab from "./Pages/MPCMDCTabs/MDCTab";
import TractorMaster from "./Pages/TractorMaster";
import axios from "axios";
import Successtemplate from "./templates/Successtemplate";
import TudbSuccess from "./templates/Successtemplate/Tudb";
import EnquirySuccess from "./templates/Successtemplate/Enquiry";
import VillageMaster from "./Pages/VillageMaster";
import CreateJCP from "./templates/Successtemplate/JCPCreate";
import OverdueList from "./Pages/List/OverdueList";
import PlannedVisitList from "./Pages/List/PlannedVisitList";
import Prospect from "./Pages/Details/Prospect";
import TUDB from "./Pages/Details/TUDB";
import Followup from "./Pages/Details/Followup";
import ServerConnectorUtil from "../shared/utils/ServerConnectorUtil";
import Tasks from "./Pages/Tasks";
import UserAdditionalFields from "../shared/enums/UserAdditionalFields";

interface IOnRenderProps{
    user: IUser | undefined,
    setuser: React.Dispatch<React.SetStateAction<IUser | undefined>>,
    showLoader: boolean
}
const OnLoadRender: React.FC = () => {
    const [loader, setloader] = useState<boolean>(true);
    const [user, setuser] = useState<IUser>();
    const [sessionExpired, setsessionExpired] = useState<boolean>(false);
    const navigate = useNavigate();
    const location = useLocation();
    useEffect(()=>{
        const getCurrentUser = async() => {
            try{
                let user = await axios.get('/api/v1/user/current-user',{
                    params:{
                        additional_fields:[UserAdditionalFields.SKILL_LEVEL,UserAdditionalFields.DEALER_NAME].join(",")
                    }
                });
                let { data } = user.data;
                // let currentUser = {
                //     "id": "185259000000166001",
                //     "role": {
                //         "id": "185259000001720984",
                //         "name": "Dsp"
                //     },
                //     "zuid": "60005214860",
                //     "email": "crmadmin@tafe.com",
                //     "last_name": "Admin",
                //     "first_name": "CRM",
                //     "dealer_code": "Z3772",
                //     "company_code": "TAFE"
                // }
                // // data.role['name'] = 'Dsp';
                setuser(data);
                if(location.pathname.includes('/login')){
                    navigate('/base');
                }
            }catch(error){
                if (error instanceof Error) {
                  console.log(error);
                }
                navigate('/login');
            }finally{
                setloader(false);
            }
        }
        getCurrentUser();
    }, [])
    return(
        <>
            {
                loader ?
                    <div className="init-loader-page">
                        <Loadertemplate></Loadertemplate>
                    </div>
                :
                (!sessionExpired) &&
                    <UserContext.Provider value={{user, setuser}}>
                        <Outlet/>
                    </UserContext.Provider>
            }
        </>
    )
}

const Components = {
    OnLoadRender,
    DspHome,
    DspJcp,
    Enquiries,
    Dashboard,
    Login,
    EnquiryForm,
    Menu,
    TUDBForm,
    Prospects,
    JCP: CheckInJcps,
    VillageDetails: CheckInJcp,
    VisitDetails,
    MPCMDCTabs,
    MainLayout,
    EnquiryTab,
    TUDBTab,
    Leaderboard,
    MPCTab,
    MDCTab,
    TractorMaster,
    VillageMaster,
    Successtemplate,
    EnquirySuccess,
    TudbSuccess,
    CreateJCP,
    Tasks,
    OverdueList,
    PlannedVisitList,
    Prospect,
    TUDB,
    Followup
}
export default Components;