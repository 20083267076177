import React, { useCallback, useEffect, useRef, useState } from 'react'
import ListOfDetailsTemp from '../../../templates/ListOfDetailsTemp'
import { IJCPVillageStats, IPageResponse } from '../../../../Types/global';
import DatalistServices from '../../../../shared/services/DatalistServices';
import api_request from '../../../../shared/constants/api_request';
import RequestMethod from '../../../../shared/enums/RequestMethod';
import ServerConnectorUtil from '../../../../shared/utils/ServerConnectorUtil';
import dateHooks from '../../../Hooks/dateHooks';
import Prospecttemplate from '../../../templates/Prospecttemplate';
import { ArrowPrimarySvg } from '../../../../icons/ArrowPrimarySvg';
import { Link } from 'react-router-dom';
import { ArrowSvg } from '../../../../icons/ArrowSvg';

const i18nTranslated = {
    tivHigh: 'HTIV',
    tivLow: 'LTIV',
    todayLabel: 'Today'
}
const PlannedVisitList: React.FC = () => {
    const [plannedVisit, setplannedVisit] = useState<IJCPVillageStats[]>([]);
    const [showLazyLoader, setshowLazyLoader] = useState<boolean>(false);
    const [moreResponse, setmoreResponse] = useState<boolean>(false);
    const [searchContent, setsearchContent] = useState<string>('');
    let page = 1;
    const pagniationAbortRef = useRef<AbortController | null>(null);
    const [showEmptyScreen, setshowEmptyScreen] = useState<boolean>(false);
    useEffect(()=> {
        setshowLazyLoader(true);
        let onPlannedVisitAbort = new AbortController();
        getPlannedVisit(onPlannedVisitAbort.signal);
        return()=>{
            onPlannedVisitAbort.abort();
            pagniationAbortRef.current?.abort();
        }
    }, [])
    const getPlannedVisit = useCallback(async(signal: AbortSignal, pagination: boolean = false, value?: string | undefined)=> {
        try{
            page = moreResponse ? page + 1 : page;
            let payLoad: {
                checked_in: string
                name?: string
            } = {
                checked_in:"false"
            }
            if(value !== undefined){
                payLoad.name = value;
            }
            let responseData = await DatalistServices.getAllListData({
                url: api_request.PLANNED_VISIT,
                method: RequestMethod.POST,
                params: {
                    page: page,
                    perPage: 200,
                    additional_fields: "village.stats.total_visits_year_till_date",
                    date_start_value: dateHooks.formatDate('', 'YYYY-MM-DD'),
                    date_end_value: dateHooks.formatDate('', 'YYYY-MM-DD'),
                    statuses: 'Approved'
                },
                signal: signal,
                body: payLoad
            })
            let {data, page_info} = responseData as IPageResponse<IJCPVillageStats>;
            setmoreResponse(page_info.hasMore);
            const plannedVisitData = data.map((item: IJCPVillageStats)=> {
                item.village.tiv = item.village.tiv === 'High' ? i18nTranslated.tivHigh : i18nTranslated.tivLow;
                return item;
            });
            setplannedVisit(plannedVisitData);
            setshowEmptyScreen(plannedVisitData.length === 0);
            setshowLazyLoader(false);
        }catch(error: any){
            ServerConnectorUtil.handleServerError(error);
        }
    }, []);
    const plannedVisitPagination = useCallback(async(setShowLoader: React.Dispatch<React.SetStateAction<boolean>>) => {
        let controllar = new AbortController();
        pagniationAbortRef.current = controllar;
        await getPlannedVisit(pagniationAbortRef.current.signal, true, searchContent !== '' ? searchContent : undefined);
        setShowLoader(false);
    }, [])
    const plannedVisitAutocomplete = useCallback(async(value: string, signal: AbortSignal)=> {
        setsearchContent(value);
        await getPlannedVisit(signal, false, value !== '' ? value : undefined)
    }, [])
    const onRenderCards = (): JSX.Element => {
        return <div className=''>
            {
                plannedVisit.map((item)=> (
                    <Prospecttemplate color_code={item.village.color_code}>
                        <div className='jcp-detail'>
                            <div className='jcp-location fCenter'>
                                <span className='location'>{item.village.name}</span>
                                <span className='followupNavigate'>
                                    <ArrowPrimarySvg/>
                                </span>
                            </div>
                            <div className='jcp-type fCenter'>
                                {item.village.tiv}<span className='small-dot'></span>{item.plan_type}
                            </div>
                            <div className='jcp-edd-date'>{i18nTranslated.todayLabel}</div>
                        </div>
                        <Link to="#" className='jcp-visit-navigate'><ArrowSvg/></Link>
                    </Prospecttemplate>
                ))
            }
        </div>
    }
    return (
        <ListOfDetailsTemp
            suggestionsRender={onRenderCards}
            fetchApiRequest={plannedVisitAutocomplete}
            paginationApiRequest={moreResponse ? plannedVisitPagination : null}
            placeholder='Search'
            showLoader={showLazyLoader}
            showEmptyScreen={showEmptyScreen}
        />
    )
}

export default PlannedVisitList