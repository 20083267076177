import "./index.css";
import styles from "./styles.module.css";

import classNames from "classnames";
import React, { useState, useCallback } from "react";
import { Link } from "react-router-dom";

import JcPlanningStatus from "../shared/enums/JcPlanningStatus";
import AppUrlConstants from "../shared/constants/AppUrlConstants";
import Loadertemplate from "../Components/templates/Loadertemplate";
import useJcPlannings from "../shared/hooks/useJcPlannings";
import Dropdowntemplate from "../Components/templates/Dropdowntemplate";
import "../Components/CSS/form.css";
import EmptyTemp from "../Components/templates/EmptyTemp";
import useHorizontalCalendar from "../shared/hooks/useHorizontalCalendar";
import CalendarMonth from "../shared/enums/CalendarMonth";
import useEffectAfterMount from "../shared/hooks/useEffectAfterMount";
import ServerConnectorUtil from "../shared/utils/ServerConnectorUtil";
import { UseJcPlanningOverviewHook } from "../Types/hooks";
import useJcPlanningOverview from "../shared/hooks/useJcPlanningOverview";
import DateTimeProcessor from "../shared/processors/DateTimeProcessor";
import DateFormat from "../shared/enums/DateFormat";

const i18nTranslated = {
  checkInJCP: "JCP",
  todayActivity: "Today's Activity",
  villageVisit: "Village visit",
  tiv: "TIV",
  bryg: "BRYG",
  pdov: "Planned Date of Visit",
  taluka: "Taluka"
};

const CheckInJcps: React.FC = () => {
  const {
    dates,
    yearOptions,
    monthOptions,
    currentDate,
    currentMonth,
    changeDate,
    changeMonth,
    changeYear,
    formattedCurrentYear
  } = useHorizontalCalendar({
    fromYear: 2017
  });

  const [isFirstTimeLoaded, setIsFirstTimeLoaded] = useState<boolean>(false);

  const [initialJcPlanningOverviewFilters] =
    useState<UseJcPlanningOverviewHook.Filters>({
      year: formattedCurrentYear,
      checkedIn: false,
      statuses: [JcPlanningStatus.APPROVED]
    });

  const [jcPlanningFilters, setJcPlanningFilters] = useState({
    checkedIn: false,
    dateStartValue: currentDate.getTime(),
    dateEndValue: currentDate.getTime(),
    statuses: [JcPlanningStatus.APPROVED]
  });

  const {
    data: jcPlanningOverview,
    isLoading: isJcPlanningOverviewLoading,
    updateFilter: updateJcPlaningOverviewFilter
  } = useJcPlanningOverview({
    initialFilters: initialJcPlanningOverviewFilters,
    onFailure: ServerConnectorUtil.handleServerError
  });

  const { data: jcPlannings, isLoading: isLoadingJcPlanning } = useJcPlannings({
    page: 1,
    perPage: 200,
    filters: jcPlanningFilters,
    onFailure: ServerConnectorUtil.handleServerError
  });

  const onDateChange = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      const element = event.currentTarget as HTMLElement;
      const millis = parseInt(element.getAttribute("data-millis") as string);
      changeDate(new Date(millis));
    },
    [changeDate]
  );

  const onMonthChange = useCallback(
    (value: string) => {
      const calendarMonth = CalendarMonth.getByMappedValue(value);
      changeMonth(calendarMonth!.value);
    },
    [changeMonth]
  );

  const onYearChange = useCallback(
    (year: string) => {
      changeYear(parseInt(year));
      updateJcPlaningOverviewFilter("year",year);
    },
    [changeYear, updateJcPlaningOverviewFilter]
  );

  useEffectAfterMount(() => {
    setIsFirstTimeLoaded(true);
  }, []);

  useEffectAfterMount(() => {
    if (isFirstTimeLoaded) {
      setJcPlanningFilters((prev) => ({
        ...prev,
        dateStartValue: currentDate.getTime(),
        dateEndValue: currentDate.getTime()
      }));
    }
  }, [currentDate]);

  return (
    <>
      {isJcPlanningOverviewLoading ? (
        <div className="loader-wrapper">
          <Loadertemplate />
        </div>
      ) : (
        <>
          <div className="checkin-jcp-header">Activity</div>
          <div
            style={{
              display: "flex",
              gap: "20px",
              width: "max-content",
              margin: "10px 15px 10px auto"
            }}
          >
            <Dropdowntemplate
              defaultValue={monthOptions[currentMonth].label}
              dropdownMenu={monthOptions}
              onSelect={onMonthChange}
            />
            <Dropdowntemplate
              defaultValue={formattedCurrentYear}
              dropdownMenu={yearOptions}
              onSelect={onYearChange}
            />
          </div>
          <div className={styles["check-in-calendar"]}>
            {dates.map((item) => (
              <div
                key={item.toISOString()}
                className={classNames(
                  styles["day"],
                  currentDate.toDateString() === item.toDateString() &&
                    styles["active"]
                )}
                data-millis={item.getTime()}
                onClick={onDateChange}
              >
                <p className={classNames(styles["value"])}>{item.getDate()}</p>
                {jcPlanningOverview &&
                  currentDate.toDateString() !== item.toDateString() &&
                  jcPlanningOverview[
                    DateTimeProcessor.format(
                      item.getTime(),
                      DateFormat.ISO_DATE
                    )
                  ] && <div className={styles["dot"]} />}
              </div>
            ))}
          </div>
          <div className="checkin-jcp-villages">
            {isLoadingJcPlanning ? (
              <div className="loader-wrapper">
                <Loadertemplate />
              </div>
            ) : jcPlannings.length === 0 ? (
              <EmptyTemp />
            ) : (
              jcPlannings.map((item) => (
                <Link
                  className="checkin-jcp-village"
                  key={item.id}
                  to={`${AppUrlConstants.INNER_BASE}${AppUrlConstants.CHECK_IN}${AppUrlConstants.JCPS}/${item.id}?title=check-in-jcp`}
                >
                  <div className="villages-list">
                    <div className="village-name">
                      {i18nTranslated.villageVisit} - {item.village.name}
                    </div>
                    <div className="village-tiv">
                      <span className="villages-list-label">
                        {i18nTranslated.tiv} -{" "}
                      </span>
                      {item.village.tiv}
                    </div>
                    <div className="village-bryg">
                      <span className="villages-list-label">
                        {i18nTranslated.bryg} -{" "}
                      </span>
                      {item.village.color_code}
                    </div>
                    <div className="village-pdov">
                      <span className="villages-list-label">
                        {i18nTranslated.pdov} -{" "}
                      </span>
                      {new Date(item.date).toLocaleDateString()}
                    </div>
                    <div className="village-taluka">
                      <span className="villages-list-label">
                        {i18nTranslated.taluka} -{" "}
                      </span>
                      {item.village.taluka}
                    </div>
                  </div>
                </Link>
              ))
            )}
          </div>
        </>
      )}
    </>
  );
};

export default CheckInJcps;
