import { set, cloneDeep } from "lodash";
import React, { useCallback, useState } from "react";

import { UseFormHook } from "../../Types/hooks";

export default function useForm<T = any>({
  initialValues
}: UseFormHook.Parameters<T>): UseFormHook.ReturnType<T> {
  const [errors, __setErrors] = useState<{ [x: string]: any }>({});
  const [values, setValues] = useState<T>(
    initialValues ? { ...initialValues } : ({} as T)
  );

  const onInputChange = useCallback(
    (
      event: React.ChangeEvent<
        HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
      >
    ) => {
      const { name, value } = event.target;
      if (event.target instanceof HTMLInputElement) {
        const { type, files } = event.target;

        if (type === "file") {
          if (files && files.length) {
            setValues((prev) => {
              const temp = cloneDeep(prev);

              return set<any>(temp, name, value);
            });
          } else {
            setValues((prev) => {
              const temp = cloneDeep(prev);

              return set<any>(temp, name, value);
            });
          }
        } else {
          setValues((prev) => {
            const temp = cloneDeep(prev);

            return set<any>(temp, name, value);
          });
        }
      } else if (event.target instanceof HTMLTextAreaElement) {
        setValues((prev) => {
          const temp = cloneDeep(prev);

          return set<any>(temp, name, value);
        });
      } else if (event.target instanceof HTMLSelectElement) {
        setValues((prev) => {
          const temp = cloneDeep(prev);

          return set<any>(temp, name, value);
        });
      }

      __setErrors((prev) => {
        const temp = cloneDeep(prev);

        return set<any>(temp, name, "");
      });
    },
    []
  );

  const setMultipleValues = useCallback((values: T) => {
    setValues(values);
  }, []);

  const setValue = useCallback((key: string, value: any) => {
    setValues((prev) => {
      const temp = cloneDeep(prev);
      return set<any>(temp, key, value);
    });
  }, []);

  const setError = useCallback((key: string, value: string) => {
    __setErrors((prev) => {
      const temp = cloneDeep(prev);

      return set(temp, key, value);
    });
  }, []);

  const setErrors = useCallback((errors: Record<string, any>) => {
    __setErrors(errors);
  }, []);

  const clearErrors = useCallback(() => {
    __setErrors({});
  }, []);
  return {
    values,
    errors,
    setValue,
    setError,
    setErrors,
    clearErrors,
    onInputChange,
    setMultipleValues
  };
}
