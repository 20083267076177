import JcPlanning from "../models/JcPlanning";
import RequestMethod from "../enums/RequestMethod";
import RequestHandler from "../handlers/RequestHandler";
import DateTimeProcessor from "../processors/DateTimeProcessor";

import { JcPlanningServiceParameters } from "../../Types/parameters";
import { CommonRequestResponse, IJCPVillageStats } from "../../Types/global";

export default class JcPlanningService {
  public static async getMeta(): Promise<JcPlanningServiceParameters.GetMeta.ReturnType> {
    const handler = new RequestHandler(
      "/api/v1/jc-planning/meta",
      RequestMethod.GET
    );

    return handler
      .execute()
      .then(
        (result) =>
          (result.data as CommonRequestResponse.MetaRequestResponse).data
      );
  }

  public static async getOverview({
    signal,
    filters: { year, month, statuses, checkedIn }
  }: JcPlanningServiceParameters.GetOverview.Parmeters): Promise<
    Record<string, number>
  > {
    const handler = new RequestHandler(
      "/api/v1/jc-plannings/overview",
      RequestMethod.GET
    );

    if (year) {
      handler.addParam("year", year);
    }
    if (month) {
      handler.addParam("month", month);
    }

    if (statuses && statuses.length) {
      handler.addParam("statuses", statuses.join(","));
    }

    if (checkedIn !== undefined) {
      handler.addParam("checked_in", checkedIn);
    }

    if (signal) {
      handler.setAbortSignal(signal);
    }

    return handler
      .execute()
      .then((result) => (result.data as { data: Record<string, number> }).data);
  }

  public static async getPagedJcPlannings({
    page,
    signal,
    perPage,
    filters: { statuses, checkedIn, dateEndValue, dateStartValue },
    additionalFields
  }: JcPlanningServiceParameters.GetPagedJcPlannings.Parameters): Promise<JcPlanningServiceParameters.GetPagedJcPlannings.ReturnType> {
    const handler = new RequestHandler(
      "/api/v1/jc-plannings",
      RequestMethod.POST
    );
    handler.addParam("page", page);
    handler.addParam("perPage", perPage);
    handler.setBody({});

    if (statuses && statuses.length) {
      handler.addParam("statuses", statuses.join(","));
    }
    if (checkedIn !== undefined) {
      handler.setBody({
        checked_in: checkedIn
      });
    }
    if (dateStartValue !== undefined) {
      handler.addParam(
        "date_start_value",
        DateTimeProcessor.format(dateStartValue)
      );
    }
    if (dateEndValue !== undefined) {
      handler.addParam(
        "date_end_value",
        DateTimeProcessor.format(dateEndValue)
      );
    }
    if (additionalFields && additionalFields.length) {
      handler.addParam("additional_fields", additionalFields.join(","));
    }
    return handler
      .execute()
      .then(
        (result) =>
          result.data as JcPlanningServiceParameters.GetPagedJcPlannings.ReturnType
      );
  }

  public static async getJcPlanningById({
    id,
    additionalFields
  }: JcPlanningServiceParameters.GetJcPlanningById.Parameters): Promise<JcPlanningServiceParameters.GetJcPlanningById.ReturnType> {
    const handler = new RequestHandler(
      "/api/v1/jc-planning/" + id,
      RequestMethod.GET
    );
    if (additionalFields && additionalFields.length) {
      handler.addParam("additional_fields", additionalFields.join(","));
    }
    return handler
      .execute()
      .then(
        (result) =>
          (result.data as CommonRequestResponse.RecordRequestResponse)
            .data as unknown as JcPlanningServiceParameters.GetJcPlanningById.ReturnType
      );
  }

  public static async createJcPlannings({
    data
  }: JcPlanningServiceParameters.CreateJcPlannings.Parameters): Promise<
    Array<IJCPVillageStats>
  > {
    const handler = new RequestHandler(
      "/api/v1/jc-plannings/create",
      RequestMethod.POST
    );

    handler.setBody({
      data: data.map((item) => ({
        date: DateTimeProcessor.format(item.date),
        plan_type: item.planType,
        village_id: item.villageId
      }))
    });

    return handler
      .execute()
      .then(
        (result) =>
          (result.data as CommonRequestResponse.PagedRecordRequestResponse)
            .data as unknown as Array<IJCPVillageStats>
      );
  }

  public static async checkInJcp({
    id,
    latitude,
    longitude
  }: JcPlanningServiceParameters.CheckInJcp.Parameters): Promise<JcPlanningServiceParameters.CheckInJcp.ReturnType> {
    const handler = new RequestHandler(
      "/api/v1/jc-planning/" + id + "/check-in",
      RequestMethod.PUT
    );

    handler.setBody({
      latitude,
      longitude
    });

    return handler
      .execute()
      .then(
        (result) =>
          (result.data as CommonRequestResponse.RecordRequestResponse)
            .data as unknown as JcPlanningServiceParameters.GetJcPlanningById.ReturnType
      );
  }

  public static async deleteJcPlanningById({
    id
  }: JcPlanningServiceParameters.DeleteJcPlanningById.Parameters): Promise<JcPlanningServiceParameters.DeleteJcPlanningById.ReturnType> {
    const handler = new RequestHandler(
      "/api/v1/jc-planning/" + id,
      RequestMethod.DELETE
    );

    return handler
      .execute()
      .then(
        (result) =>
          (result.data as CommonRequestResponse.RecordRequestResponse)
            .data as unknown as JcPlanningServiceParameters.DeleteJcPlanningById.ReturnType
      );
  }
}
