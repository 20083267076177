import React from 'react'
import { DoubleTickSvg } from '../../../../icons/DoubleTickSvg'
import Buttontemplate from '../../Buttontemplate'
import { JCPSvg } from '../../../../icons/JCPSvg'
import { useNavigate } from 'react-router-dom'

const i18nTranslated = {
    success: "Success",
    enquirySuccessDesc: "Your prospect has been added to the list",
    backToHome: "Back to Home",
    proceedToCheckIn: "Proceed to check-in"
}
const EnquirySuccess: React.FC = () => {
    const navigate = useNavigate();
    return (
        <>
            <DoubleTickSvg/>
            <h1 style={{marginTop: '30px'}}>{i18nTranslated.success}</h1>
            <p>{i18nTranslated.enquirySuccessDesc}</p>
            <Buttontemplate type='success' buttonWidth='100%' callback={()=> navigate('/base/home')}>
                <JCPSvg fill='#257851'/>
                {i18nTranslated.backToHome}
            </Buttontemplate>
        </>
    )
}

export default EnquirySuccess