import styles from "./styles.module.css";

import classNames from "classnames";

export type ViewBodyProps = {
  className?: string;
} & React.PropsWithChildren;

const ViewBody: React.FC<ViewBodyProps> = ({ children, className }) => {
  return (
    <div className={classNames(styles["body"], className)}>{children}</div>
  );
};

export default ViewBody;
