import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import './index.css';
import { IRatingTableProp, IUserContext } from '../../../Types/global';
import classNames from 'classnames';
import { TrophySvg } from '../../../icons/TrophySvg';
import { ArrowPrimarySvg } from '../../../icons/ArrowPrimarySvg';
import { UserContext } from '../../HOC/UserContext';
import dummyUser from '../../../images/dummy-user.png';
import ProfilePath from '../../../shared/enums/ProfilePath';

const i18nTranslated = {
    youLabel: 'You'
}
const Ratingtemplate: React.FC<IRatingTableProp> = ({ratingData}) => {
    const currentUser = useContext(UserContext);
    const user = (currentUser as IUserContext).user;
    const [rankCount] = useState<number>(ratingData.length);
    const [hover, setHover] = useState<boolean>(false);
    const activateHover = useCallback(() => {
        if(!hover){
            setHover(true);
        }
    }, [])
    return(
        <div className='container' onMouseMove={activateHover}>
            <table className='table rating-template-table'>
                <thead>
                    <tr className='row rate-head-row'>
                        <th className='col-3 rate-head'>Rank({rankCount})</th>
                        <th className='col-6 rate-head'>Name</th>
                        <th className='col-3 rate-head'>Deliveries</th>
                    </tr>
                </thead>
                <tbody className='rating-body'>
                    {
                        ratingData.map((item,index) => (
                            <tr className={classNames('row', 'rate-row',{'hover': hover}, {'currentuser': user.id === item.profileId})}>
                                <td className='col-2 rate-col'>
                                    <span className='line-trophy'>
                                        <TrophySvg/><span className='trophyIndex'>{index+1}</span>
                                    </span>
                                </td>
                                <td className='col-8 rate-col'>
                                    <span className='dsp-profile'>
                                        <img src={`${ProfilePath.PATH}${item.profileId}.png`} onError={(event: React.SyntheticEvent<HTMLImageElement>) => event.currentTarget.src = dummyUser}/>
                                    </span>
                                    <span className='dsp-name'>
                                        {
                                            user.id === item.profileId ?
                                                i18nTranslated.youLabel
                                            :
                                                item.name
                                        }
                                    </span>
                                </td>
                                <td className='col-2 rate-col'>
                                    <span className=''>{item.deliveries}</span>
                                    <span className={classNames('stats', {'up': (item.deliveryStat === 'up')}, {'down': (item.deliveryStat === 'down')})}>
                                        <ArrowPrimarySvg fill={item.deliveryStat === 'up' ? '#007851' : '#b51213'}/>
                                    </span>
                                </td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
        </div>
    )
}
export default Ratingtemplate;