import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import {
  IDateStrRangeObj,
  IReviewTractor,
  ITractorUsers,
  ITUDBStat
} from "../../../../Types/global";
import classNames from "classnames";
import Gridtemplate from "../../../templates/Gridtemplate";
import { Link, useNavigate } from "react-router-dom";
import Buttontemplate from "../../../templates/Buttontemplate";
import "./index.css";
import Loadertemplate from "../../../templates/Loadertemplate";
import { ArrowSvg } from "../../../../icons/ArrowSvg";
import AppUrlConstants from "../../../../shared/constants/AppUrlConstants";
import TractorUserDatabaseCustomerType from "../../../../shared/enums/TractorUserDatabaseCustomerType";
import AppQueryParameters from "../../../../shared/enums/AppQueryParameters";
import TractorUserDatabaseSatisfactionType from "../../../../shared/enums/TractorUserDatabaseSatisfactionType";
import ServerConnectorUtil from "../../../../shared/utils/ServerConnectorUtil";
import DateFilter from "../../../templates/Date-Filter";
import dateHooks from "../../../Hooks/dateHooks";
import TractorUserDatabaseService from "../../../../shared/services/TractorUserDatabaseService";
import api_request from "../../../../shared/constants/api_request";
import RequestMethod from "../../../../shared/enums/RequestMethod";
import calendar from "../../../../shared/constants/calendar";

const TUDBTab: React.FC = () => {
  const role = "dsp";
  const navigate = useNavigate();
  const i18nTranslated = {
    TractorUsers_label: "Tractor Users",
    VillageType_label: "Village Type",
    CompetitionUSatisfied_label: "Comp Unsatisfied",
    CompetitionSatisfied_label: "Comp Satisfied",
    MFUSatisfied_label: "MF Unsatisfied",
    MFSatisfied_label: "MF Satisfied",
    TopMfDissatisfiedModel_label: "Top MF Dissatisfied model",
    TopMfSatisfiedModel_label: "Top MF Satisfied model",
    TopCompDissatisfiedModel_label: "Top competitor Dissatisfied model",
    TopCompSatisfiedModel_label: "Top competitor Satisfied model",
    modelFamily_label: "Model family",
    customer_label: "Customer"
  };
  const [filterLoading, setfilterLoading] = useState(false);
  const [showLazyLoad, setshowLazyLoad] = useState<boolean>(false);
  const [totalTractorUserDatabases, settotalTractorUser] = useState<number>(0);
  const [tractorUsers, settractorUsers] = useState<ITractorUsers[]>([
    {
      type: "Red",
      compSatisfied: 0,
      compUnsatisfied: 0,
      mfSatisfied: 0,
      mfUnsatisfied: 0
    },
    {
      type: "Yellow",
      compSatisfied: 0,
      compUnsatisfied: 0,
      mfSatisfied: 0,
      mfUnsatisfied: 0
    },
    {
      type: "Green",
      compSatisfied: 0,
      compUnsatisfied: 0,
      mfSatisfied: 0,
      mfUnsatisfied: 0
    },
    {
      type: "Black",
      compSatisfied: 0,
      compUnsatisfied: 0,
      mfSatisfied: 0,
      mfUnsatisfied: 0
    }
  ]);
  const [TMFDSM, setTMFDSM] = useState<IReviewTractor>({
    modelFamily: "--",
    percentage: 0
  });
  const [TMFSM, setTMFSM] = useState<IReviewTractor>({
    modelFamily: "--",
    percentage: 0
  });
  const [TCDSM, setTCDSM] = useState<IReviewTractor>({
    modelFamily: "--",
    percentage: 0
  });
  const [TCSM, setTCSM] = useState<IReviewTractor>({
    modelFamily: "--",
    percentage: 0
  });
  const [start_date, setStart_date] = useState<string | null>(null);
  const [end_date, setEnd_date] = useState<string | null>(null);
  const getStats = (data: ITUDBStat) => {
      let { color_codes, tractor_models }: ITUDBStat = data;
      if (color_codes.length > 0) {
        let totalTractorUser: number = 0;
        color_codes.forEach((codes) => {
          totalTractorUser += codes.value;
          if (codes.customer_type === "MF Customer") {
            if (codes.is_customer_satisfied_with_his_tractor === "Yes") {
              settractorUsers((prev) =>
                prev.map((user) =>
                  user.type === codes.color_code
                    ? { ...user, mfSatisfied: codes.value }
                    : user
                )
              );
            } else {
              settractorUsers((prev) =>
                prev.map((user) =>
                  user.type === codes.color_code
                    ? { ...user, mfUnsatisfied: codes.value }
                    : user
                )
              );
            }
          } else if (codes.customer_type === "Competition Customer") {
            if (codes.is_customer_satisfied_with_his_tractor === "Yes") {
              settractorUsers((prev) =>
                prev.map((user) =>
                  user.type === codes.color_code
                    ? { ...user, compSatisfied: codes.value }
                    : user
                )
              );
            } else {
              settractorUsers((prev) =>
                prev.map((user) =>
                  user.type === codes.color_code
                    ? { ...user, compUnsatisfied: codes.value }
                    : user
                )
              );
            }
          }
        });
        settotalTractorUser(totalTractorUser);
      }
      let TMFSM: IReviewTractor  = {
        modelFamily: "--",
        percentage: 0
      }
      let TMFDSM: IReviewTractor = {
        modelFamily: "--",
        percentage: 0
      }; 
      let TCSM: IReviewTractor = {
        modelFamily: "--",
        percentage: 0
      }; 
      let TCDSM: IReviewTractor = {
        modelFamily: "--",
        percentage: 0
      };
      if (tractor_models.length > 0) {
        tractor_models.forEach((models) => {
          if (models.customer_type === "MF Customer") {
            if (models.is_customer_satisfied_with_his_tractor === "Yes") {
              TMFSM = {
                modelFamily: models.tractor_model,
                percentage: models.percentage
              };
            } else {
              TMFDSM = {
                modelFamily: models.tractor_model,
                percentage: models.percentage
              };
            }
          } else if (models.customer_type === "Competition Customer") {
            if (models.is_customer_satisfied_with_his_tractor === "Yes") {
              TCSM = {
                modelFamily: models.tractor_model,
                percentage: models.percentage
              };
            } else {
              TCDSM = {
                modelFamily: models.tractor_model,
                percentage: models.percentage
              };
            }
          }
        });
        setTMFSM(TMFSM);
        setTMFDSM(TMFDSM);
        setTCSM(TCSM);
        setTCDSM(TCDSM);
      }else{
        setTMFSM(TMFSM);
        setTMFDSM(TMFDSM);
        setTCSM(TCSM);
        setTCDSM(TCDSM);
      }
  }
  const [tudbCurrentFilter, settudbCurrentFilter] = useState<string | IDateStrRangeObj>(calendar.CURRENTMONTH);
  useEffect(()=> {
      let tudbFilterAbort = new AbortController();
      setfilterLoading(true);
      if(tudbCurrentFilter !== ''){
          let {start_date, end_date} = dateHooks.formatFilterValueWithDateFormat(tudbCurrentFilter);
          setStart_date(start_date);
          setEnd_date(end_date);
          TractorUserDatabaseService.getTudbStat({
              url: api_request.TUDB_STAT,
              method: RequestMethod.GET,
              signal: tudbFilterAbort.signal,
              params: {
                start_date: start_date,
                end_date: end_date
              }
          })
          .then(response =>{
            getStats(response.data)
            setfilterLoading(false);
        })
          .catch(error => ServerConnectorUtil.handleServerError(error))
          // .finally(() => {
          //     setfilterLoading(false);
          // })
      }else{
        setStart_date('');
        setEnd_date('');
        TractorUserDatabaseService.getTudbStat({
            url: api_request.TUDB_STAT,
            method: RequestMethod.GET,
            signal: tudbFilterAbort.signal
        })
        .then(response =>{
          getStats(response.data)
          setfilterLoading(false);
      })
        .catch(error => ServerConnectorUtil.handleServerError(error))
      
      }
      return () => {
        tudbFilterAbort.abort();
      }
  }, [tudbCurrentFilter])
  return (
    <>
          <div className="tudb-content">
            <Container>
              <Row>
                <Col>
                  <DateFilter setState={settudbCurrentFilter}/>
                </Col>
              </Row>
              {
                  filterLoading ?
                      <div className='loader-wrapper' style={{'height': 'calc(100vh - 380px)'}}>
                          <Loadertemplate/>
                      </div>
                  :
                      <>
                          <Row>
                            <Col>
                              <div className="tractor-user-stats">
                                <div className="tractor-user-header">
                                  <p className="tractor-user-head">
                                    {i18nTranslated.TractorUsers_label}
                                  </p>
                                  <span className="grey-dot"></span>
                                  <p className="tractor-user-count">
                                    {
                                      <Link
                                        to={{
                                          pathname:
                                            AppUrlConstants.INNER_BASE +
                                            AppUrlConstants.MENU +
                                            AppUrlConstants.TRACTOR_USER_DATABASES,
                                          search: new URLSearchParams({
                                            [AppQueryParameters.COMMON.KEYS.TITLE]: AppQueryParameters.TRACTOR_USER_DATABASE.VALUES.RECORDS_VIEW_TITLE,
                                            [AppQueryParameters.COMMON.KEYS.START_DATE]: start_date || '',
                                            [AppQueryParameters.COMMON.KEYS.END_DATE]: end_date || ''
                                          }).toString()
                                        }}
                                      >
                                        {totalTractorUserDatabases}
                                      </Link>
                                    }
                                  </p>
                                </div>
                                <div className="tractor-user-table">
                                  <table className="table">
                                    <thead>
                                      <tr>
                                        <th className="tractor-user-head">
                                          {i18nTranslated.VillageType_label}
                                        </th>
                                        <th className="tractor-user-head">
                                          {i18nTranslated.CompetitionUSatisfied_label}
                                        </th>
                                        <th className="tractor-user-head">
                                          {i18nTranslated.CompetitionSatisfied_label}
                                        </th>
                                        <th className="tractor-user-head">
                                          {i18nTranslated.MFUSatisfied_label}
                                        </th>
                                        <th className="tractor-user-head">
                                          {i18nTranslated.MFSatisfied_label}
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {tractorUsers.map((item, index) => (
                                        <tr key={index}>
                                          <td className="tractor-stat">
                                            <span
                                              className={classNames(
                                                `tudb-village-type ${item.type}`
                                              )}
                                            ></span>
                                          </td>
                                          <td className="tractor-stat">
                                            <Link
                                              to={{
                                                pathname:
                                                  AppUrlConstants.INNER_BASE +
                                                  AppUrlConstants.MENU +
                                                  AppUrlConstants.TRACTOR_USER_DATABASES,
                                                search: new URLSearchParams({
                                                  [AppQueryParameters.COMMON.KEYS.TITLE]:
                                                    AppQueryParameters.TRACTOR_USER_DATABASE
                                                      .VALUES.RECORDS_VIEW_TITLE,
                                                  [AppQueryParameters.TRACTOR_USER_DATABASE
                                                    .KEYS.TYPE]:
                                                    TractorUserDatabaseCustomerType.COMPETITION_CUSTOMER,
                                                  [AppQueryParameters.TRACTOR_USER_DATABASE
                                                    .KEYS.VILLAGE_COLOR_CODE]: item.type,
                                                  [AppQueryParameters.TRACTOR_USER_DATABASE
                                                    .KEYS.IS_SATISFIED_WITH_HIS_TRACTOR]:
                                                    TractorUserDatabaseSatisfactionType.NO,
                                                    [AppQueryParameters.COMMON.KEYS.START_DATE]: start_date || '',
                                                    [AppQueryParameters.COMMON.KEYS.END_DATE]: end_date || ''
                                                }).toString()
                                              }}
                                            >
                                              {item.compUnsatisfied}
                                            </Link>
                                          </td>
                                          <td className="tractor-stat">
                                            <Link
                                              to={{
                                                pathname:
                                                  AppUrlConstants.INNER_BASE +
                                                  AppUrlConstants.MENU +
                                                  AppUrlConstants.TRACTOR_USER_DATABASES,
                                                search: new URLSearchParams({
                                                  [AppQueryParameters.COMMON.KEYS.TITLE]:
                                                    AppQueryParameters.TRACTOR_USER_DATABASE
                                                      .VALUES.RECORDS_VIEW_TITLE,
                                                  [AppQueryParameters.TRACTOR_USER_DATABASE
                                                    .KEYS.TYPE]:
                                                    TractorUserDatabaseCustomerType.COMPETITION_CUSTOMER,
                                                  [AppQueryParameters.TRACTOR_USER_DATABASE
                                                    .KEYS.VILLAGE_COLOR_CODE]: item.type,
                                                  [AppQueryParameters.TRACTOR_USER_DATABASE
                                                    .KEYS.IS_SATISFIED_WITH_HIS_TRACTOR]:
                                                    TractorUserDatabaseSatisfactionType.YES,
                                                    [AppQueryParameters.COMMON.KEYS.START_DATE]: start_date || '',
                                                    [AppQueryParameters.COMMON.KEYS.END_DATE]: end_date || ''
                                                }).toString()
                                              }}
                                            >
                                              {item.compSatisfied}
                                            </Link>
                                          </td>
                                          <td className="tractor-stat">
                                            <Link
                                              to={{
                                                pathname:
                                                  AppUrlConstants.INNER_BASE +
                                                  AppUrlConstants.MENU +
                                                  AppUrlConstants.TRACTOR_USER_DATABASES,
                                                search: new URLSearchParams({
                                                  [AppQueryParameters.COMMON.KEYS.TITLE]:
                                                    AppQueryParameters.TRACTOR_USER_DATABASE
                                                      .VALUES.RECORDS_VIEW_TITLE,
                                                  [AppQueryParameters.TRACTOR_USER_DATABASE
                                                    .KEYS.TYPE]:
                                                    TractorUserDatabaseCustomerType.MF_CUSTOMER,
                                                  [AppQueryParameters.TRACTOR_USER_DATABASE
                                                    .KEYS.VILLAGE_COLOR_CODE]: item.type,
                                                  [AppQueryParameters.TRACTOR_USER_DATABASE
                                                    .KEYS.IS_SATISFIED_WITH_HIS_TRACTOR]:
                                                    TractorUserDatabaseSatisfactionType.NO,
                                                    [AppQueryParameters.COMMON.KEYS.START_DATE]: start_date || '',
                                                    [AppQueryParameters.COMMON.KEYS.END_DATE]: end_date || ''
                                                }).toString()
                                              }}
                                            >
                                              {item.mfUnsatisfied}
                                            </Link>
                                          </td>
                                          <td className="tractor-stat">
                                            <Link
                                              to={{
                                                pathname:
                                                  AppUrlConstants.INNER_BASE +
                                                  AppUrlConstants.MENU +
                                                  AppUrlConstants.TRACTOR_USER_DATABASES,
                                                search: new URLSearchParams({
                                                  [AppQueryParameters.COMMON.KEYS.TITLE]:
                                                    AppQueryParameters.TRACTOR_USER_DATABASE
                                                      .VALUES.RECORDS_VIEW_TITLE,
                                                  [AppQueryParameters.TRACTOR_USER_DATABASE
                                                    .KEYS.TYPE]:
                                                    TractorUserDatabaseCustomerType.MF_CUSTOMER,
                                                  [AppQueryParameters.TRACTOR_USER_DATABASE
                                                    .KEYS.VILLAGE_COLOR_CODE]: item.type,
                                                  [AppQueryParameters.TRACTOR_USER_DATABASE
                                                    .KEYS.IS_SATISFIED_WITH_HIS_TRACTOR]:
                                                    TractorUserDatabaseSatisfactionType.YES,
                                                    [AppQueryParameters.COMMON.KEYS.START_DATE]: start_date || '',
                                                    [AppQueryParameters.COMMON.KEYS.END_DATE]: end_date || ''
                                                }).toString()
                                              }}
                                            >
                                              {item.mfSatisfied}
                                            </Link>
                                          </td>
                                        </tr>
                                      ))}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <Gridtemplate
                                gridWrapperClassName="mf-box-grid"
                                gridHeaderClassName="mf-grid-title"
                                gridContentClassName="mf-grid-value"
                                gridHead={
                                  <>
                                    <h6 className="mf-title">
                                      {i18nTranslated.TopMfDissatisfiedModel_label}
                                    </h6>
                                    <Link
                                      to={{
                                        pathname:
                                          AppUrlConstants.INNER_BASE +
                                          AppUrlConstants.MENU +
                                          AppUrlConstants.TRACTOR_USER_DATABASES,
                                        search: new URLSearchParams({
                                          [AppQueryParameters.COMMON.KEYS.TITLE]:
                                            AppQueryParameters.TRACTOR_USER_DATABASE.VALUES
                                              .MFDISSATISFIEDMODEL_VIEW_TITLE,
                                          [AppQueryParameters.TRACTOR_USER_DATABASE.KEYS
                                            .TYPE]:
                                            TractorUserDatabaseCustomerType.MF_CUSTOMER,
                                          [AppQueryParameters.TRACTOR_USER_DATABASE.KEYS
                                            .IS_SATISFIED_WITH_HIS_TRACTOR]:
                                            TractorUserDatabaseSatisfactionType.NO,
                                          ...(TMFDSM?.modelFamily
                                            ? {
                                                [AppQueryParameters.TRACTOR_USER_DATABASE.KEYS
                                                  .TRACTOR_MODEL]: TMFDSM?.modelFamily
                                              }
                                            : {}),
                                            [AppQueryParameters.COMMON.KEYS.START_DATE]: start_date || '',
                                            [AppQueryParameters.COMMON.KEYS.END_DATE]: end_date || ''
                                        }).toString()
                                      }}
                                    >
                                      <ArrowSvg />
                                    </Link>
                                  </>
                                }
                                gridContent={
                                  <>
                                    <div className="mf-name">
                                      <span className="mf-name-title">
                                        {i18nTranslated.modelFamily_label}
                                      </span>
                                      <span className="mf-name-value">
                                        {TMFDSM?.modelFamily}
                                      </span>
                                    </div>
                                    <div className="mf-customer">
                                      <span className="mf-customer-title">
                                        {i18nTranslated.customer_label} %
                                      </span>
                                      <span className="mf-customer-value">
                                        {TMFDSM?.percentage}
                                      </span>
                                    </div>
                                  </>
                                }
                              ></Gridtemplate>
                            </Col>
                            <Col>
                              <Gridtemplate
                                gridWrapperClassName="mf-box-grid"
                                gridHeaderClassName="mf-grid-title"
                                gridContentClassName="mf-grid-value"
                                gridHead={
                                  <>
                                    <span className="mf-title">
                                      {i18nTranslated.TopMfSatisfiedModel_label}
                                    </span>
                                    <Link
                                      to={{
                                        pathname:
                                          AppUrlConstants.INNER_BASE +
                                          AppUrlConstants.MENU +
                                          AppUrlConstants.TRACTOR_USER_DATABASES,
                                        search: new URLSearchParams({
                                          [AppQueryParameters.COMMON.KEYS.TITLE]:
                                            AppQueryParameters.TRACTOR_USER_DATABASE.VALUES
                                              .MFSATIFIEDMODEL_VIEW_TITLE,
                                          [AppQueryParameters.TRACTOR_USER_DATABASE.KEYS
                                            .TYPE]:
                                            TractorUserDatabaseCustomerType.MF_CUSTOMER,
                                          [AppQueryParameters.TRACTOR_USER_DATABASE.KEYS
                                            .IS_SATISFIED_WITH_HIS_TRACTOR]:
                                            TractorUserDatabaseSatisfactionType.YES,
                                          ...(TMFSM?.modelFamily
                                            ? {
                                                [AppQueryParameters.TRACTOR_USER_DATABASE.KEYS
                                                  .TRACTOR_MODEL]: TMFSM?.modelFamily
                                              }
                                            : {}),
                                            [AppQueryParameters.COMMON.KEYS.START_DATE]: start_date || '',
                                            [AppQueryParameters.COMMON.KEYS.END_DATE]: end_date || ''
                                        }).toString()
                                      }}
                                    >
                                      <ArrowSvg />
                                    </Link>
                                  </>
                                }
                                gridContent={
                                  <>
                                    <div className="mf-name">
                                      <span className="mf-name-title">
                                        {i18nTranslated.modelFamily_label}
                                      </span>
                                      <span className="mf-name-value">
                                        {TMFSM?.modelFamily}
                                      </span>
                                    </div>
                                    <div className="mf-customer">
                                      <span className="mf-customer-title">
                                        {i18nTranslated.customer_label} %
                                      </span>
                                      <span className="mf-customer-value">
                                        {TMFSM?.percentage}
                                      </span>
                                    </div>
                                  </>
                                }
                              ></Gridtemplate>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <Gridtemplate
                                gridWrapperClassName="mf-box-grid"
                                gridHeaderClassName="mf-grid-title"
                                gridContentClassName="mf-grid-value"
                                gridHead={
                                  <>
                                    <span className="mf-title">
                                      {i18nTranslated.TopCompDissatisfiedModel_label}
                                    </span>
                                    <Link
                                      to={{
                                        pathname:
                                          AppUrlConstants.INNER_BASE +
                                          AppUrlConstants.MENU +
                                          AppUrlConstants.TRACTOR_USER_DATABASES,
                                        search: new URLSearchParams({
                                          [AppQueryParameters.COMMON.KEYS.TITLE]:
                                            AppQueryParameters.TRACTOR_USER_DATABASE.VALUES
                                              .COMPDISSATISFIEDMODEL_VIEW_TITLE,
                                          [AppQueryParameters.TRACTOR_USER_DATABASE.KEYS
                                            .TYPE]:
                                            TractorUserDatabaseCustomerType.COMPETITION_CUSTOMER,
                                          [AppQueryParameters.TRACTOR_USER_DATABASE.KEYS
                                            .IS_SATISFIED_WITH_HIS_TRACTOR]:
                                            TractorUserDatabaseSatisfactionType.NO,
                                          ...(TCDSM?.modelFamily
                                            ? {
                                                [AppQueryParameters.TRACTOR_USER_DATABASE.KEYS
                                                  .TRACTOR_MODEL]: TCDSM?.modelFamily
                                              }
                                            : {}),
                                            [AppQueryParameters.COMMON.KEYS.START_DATE]: start_date || '',
                                            [AppQueryParameters.COMMON.KEYS.END_DATE]: end_date || ''
                                        }).toString()
                                      }}
                                    >
                                      <ArrowSvg />
                                    </Link>
                                  </>
                                }
                                gridContent={
                                  <>
                                    <div className="mf-name">
                                      <span className="mf-name-title">
                                        {i18nTranslated.modelFamily_label}
                                      </span>
                                      <span className="mf-name-value">
                                        {TCDSM?.modelFamily}
                                      </span>
                                    </div>
                                    <div className="mf-customer">
                                      <span className="mf-customer-title">
                                        {i18nTranslated.customer_label} %
                                      </span>
                                      <span className="mf-customer-value">
                                        {TCDSM?.percentage}
                                      </span>
                                    </div>
                                  </>
                                }
                              ></Gridtemplate>
                            </Col>
                            <Col>
                              <Gridtemplate
                                gridWrapperClassName="mf-box-grid"
                                gridHeaderClassName="mf-grid-title"
                                gridContentClassName="mf-grid-value"
                                gridHead={
                                  <>
                                    <span className="mf-title">
                                      {i18nTranslated.TopCompSatisfiedModel_label}
                                    </span>
                                    <Link
                                      to={{
                                        pathname:
                                          AppUrlConstants.INNER_BASE +
                                          AppUrlConstants.MENU +
                                          AppUrlConstants.TRACTOR_USER_DATABASES,
                                        search: new URLSearchParams({
                                          [AppQueryParameters.COMMON.KEYS.TITLE]:
                                            AppQueryParameters.TRACTOR_USER_DATABASE.VALUES
                                              .COMPSATISFIEDMODEL_VIEW_TITLE,
                                          [AppQueryParameters.TRACTOR_USER_DATABASE.KEYS
                                            .TYPE]:
                                            TractorUserDatabaseCustomerType.COMPETITION_CUSTOMER,
                                          [AppQueryParameters.TRACTOR_USER_DATABASE.KEYS
                                            .IS_SATISFIED_WITH_HIS_TRACTOR]:
                                            TractorUserDatabaseSatisfactionType.YES,
                                          ...(TCSM?.modelFamily
                                            ? {
                                                [AppQueryParameters.TRACTOR_USER_DATABASE.KEYS
                                                  .TRACTOR_MODEL]: TCSM.modelFamily
                                              }
                                            : {}),
                                            [AppQueryParameters.COMMON.KEYS.START_DATE]: start_date || '',
                                            [AppQueryParameters.COMMON.KEYS.END_DATE]: end_date || ''
                                        }).toString()
                                      }}
                                    >
                                      <ArrowSvg />
                                    </Link>
                                  </>
                                }
                                gridContent={
                                  <>
                                    <div className="mf-name">
                                      <span className="mf-name-title">
                                        {i18nTranslated.modelFamily_label}
                                      </span>
                                      <span className="mf-name-value">
                                        {TCSM?.modelFamily}
                                      </span>
                                    </div>
                                    <div className="mf-customer">
                                      <span className="mf-customer-title">
                                        {i18nTranslated.modelFamily_label} %
                                      </span>
                                      <span className="mf-customer-value">
                                        {TCSM?.percentage}
                                      </span>
                                    </div>
                                  </>
                                }
                              ></Gridtemplate>
                            </Col>
                          </Row>
                      </>
              }
            </Container>
            {role === "dsp" && (
              <div className="create-tudb">
                <Buttontemplate
                  buttonWidth="170px"
                  type="primary"
                  callback={() => navigate("/innerbase/addtudb?title=add_tudb")}
                >
                  +<span className="context-tudb">Create a TUDB</span>
                </Buttontemplate>
              </div>
            )}
          </div>
    </>
  );
};

export default TUDBTab;
