import './index.css';
import React, { useEffect, useState } from 'react'
import Prospecttemplate from '../../templates/Prospecttemplate'
import { ArrowPrimarySvg } from '../../../icons/ArrowPrimarySvg'
import { IFollowupFormat, IFollowupProspect, INumberOfCards, IPageResponse } from '../../../Types/global'
import classNames from 'classnames'
import Loadertemplate from '../../templates/Loadertemplate'
import CallContactTemp from '../../templates/CallContactTemp'
import WhatsappContactTemp from '../../templates/WhatsappContactTemp';
import RequestHandler from '../../../shared/handlers/RequestHandler';
import RequestMethod from '../../../shared/enums/RequestMethod';
import dateHooks from '../../Hooks/dateHooks';
import ServerConnectorUtil from '../../../shared/utils/ServerConnectorUtil';
import api_request from '../../../shared/constants/api_request';
import EmptyTemp from '../../templates/EmptyTemp';
import { Link } from 'react-router-dom';
import AppUrlConstants from '../../../shared/constants/AppUrlConstants';
import AppQueryParameters from '../../../shared/enums/AppQueryParameters';
import DateTimeProcessor from '../../../shared/processors/DateTimeProcessor';
import DateFormat from '../../../shared/enums/DateFormat';

const Followups:React.FC<INumberOfCards> = ({noOfCards}) => {
    const [showOnloading, setshowOnloading] = useState<boolean>(true);
    const [followupFormate, setfollowupFormate] = useState<IFollowupFormat[]>([]);
    const [data, setData] = useState<Array<IFollowupProspect>>([]);
    useEffect(()=> {
        let followupAbort = new AbortController();
        let reqFollowupHandler = new RequestHandler(api_request.TASK_DATA, RequestMethod.POST)
        let followupBody = {
            "type":"Follow-Up",
            "statuses":["Not Started"]
        }
        reqFollowupHandler.setBody(followupBody);
        reqFollowupHandler.addParam('page', 1);
        reqFollowupHandler.addParam('perPage', 50);
        reqFollowupHandler.setAbortSignal(followupAbort.signal);
        reqFollowupHandler.execute().then((response)=> {
            let {data}: IPageResponse<IFollowupProspect> = response.data as IPageResponse<IFollowupProspect>;
            let sortFollowupsData: IFollowupFormat[] = []
            setData(data);
            data.forEach((item: IFollowupProspect)=> {
                let due_date: string, name: string='--', village: string='--', edd: string, color_code: string, due_date_type: string, mobile_number: string='--';
                due_date = item.due_date !== null ? item.due_date : '--';
                due_date_type = dateHooks.isDateInPastOrFuture(due_date);
                due_date = dateHooks.calculateDaysBetween(due_date);
                if(item.prospect.contact){
                    name = (item.prospect.contact.name !== null) ? item.prospect.contact.name : name;
                    village = (item.prospect.contact.village !== null) ? item.prospect.contact.village : village;
                    mobile_number = (item.prospect.contact.mobile_number !== null) ? item.prospect.contact.mobile_number : mobile_number;
                }
                edd = item.prospect.edd !== null ? DateTimeProcessor.format(new Date(item.prospect.edd).getTime(), DateFormat.DISPLAY_DATE) : 'NA';
                color_code = item.prospect.contact?.color_code !== null ? item.prospect.contact?.color_code : '--';
                let prospectId = item.prospect.id !== null ? item.prospect.id : '';
                let followupData: IFollowupFormat = {
                    name: name,
                    village: village,
                    due_date: due_date,
                    due_date_type: due_date_type,
                    edd: edd,
                    color_code: color_code,
                    mobile_number: mobile_number,
                    id: prospectId
                };
                sortFollowupsData.push(followupData);
            })
            sortFollowupsData = sortFollowupsData.sort((itemone,itemtwo)=> JSON.parse(itemtwo.due_date) - JSON.parse(itemone.due_date));
            if(noOfCards && noOfCards < sortFollowupsData.length){
                sortFollowupsData = sortFollowupsData.slice(0, noOfCards);
            }
            setfollowupFormate(sortFollowupsData);
            setshowOnloading(false);
        }).catch(error=> {
            ServerConnectorUtil.handleServerError(error);
        })
        return () => {
            followupAbort.abort()
        }
    }, [])
    return (
        <>
            {
                showOnloading ?
                    <div className='loader-wrapper'>
                        <Loadertemplate/>
                    </div>
                :
                    followupFormate.length === 0 ?
                        <div className='accordion-empty'>
                            <EmptyTemp/>
                        </div>
                    :
                        data.slice(0, noOfCards).map((item, index)=> (
                            <Link to={
                                {
                                    pathname: AppUrlConstants.INNER_BASE + AppUrlConstants.PROSPECT + '/' + item.prospect.id,
                                    search: new URLSearchParams({
                                        [AppQueryParameters.COMMON.KEYS.TITLE]: AppQueryParameters.PROSPECT.VALUES.PROSPECT_DETAILS
                                    }).toString()
                                }
                            }>
                                <Prospecttemplate color_code={item.prospect.contact.color_code} key={index}>
                                    <div className='prospect-detail'>
                                        <div className='prospect-name fCenter'>
                                            <span className='name'>{item.prospect.contact.name ? item.prospect.contact.name : 'NA'}</span>
                                            <span className='followupNavigate'>
                                                <ArrowPrimarySvg/>
                                            </span>
                                        </div>
                                        <div className='prospect-edd-detail fCenter'>
                                            EDD - {item.prospect.edd ? DateTimeProcessor.format(new Date(item.prospect.edd).getTime(), DateFormat.DISPLAY_DATE) : 'NA'}<span className='small-dot'></span><span className='prospect-location'>{item.prospect.contact.village ? item.prospect.contact.village : 'NA'}</span>
                                        </div>
                                        <div className={classNames('prospect-edd-date', {'pending-prospect': dateHooks.isDateInPastOrFuture(item.due_date as string) === 'Pending' }, {'upcoming-prospect': dateHooks.isDateInPastOrFuture(item.due_date as string) === 'Upcoming'})}>
                                            Follow up date - {item.due_date ? DateTimeProcessor.format(new Date(item.due_date).getTime(), DateFormat.DISPLAY_DATE) : 'NA'}
                                        </div>
                                    </div>
                                    <div className='prospect-social-contact fCenter fJustifyFD'>
                                        <CallContactTemp className='prospect-contact' phoneNumber={item.prospect.contact.mobile_number ? item.prospect.contact.mobile_number : 'NA'}/>
                                        <WhatsappContactTemp phoneNumber={item.prospect.contact.mobile_number ? item.prospect.contact.mobile_number : 'NA'}/>
                                    </div>
                                </Prospecttemplate>
                            </Link>
                        ))
            }
        </>
    )
}

export default Followups