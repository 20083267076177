import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from "react";
import { Col, Container, Row } from "react-bootstrap";
import { ArrowPrimarySvg } from "../../../../icons/ArrowPrimarySvg";
import NavigateArrowSvg from "../../../../icons/NavigateArrowSvg";
import Buttontemplate from "../../../templates/Buttontemplate";
import Gridtemplate from "../../../templates/Gridtemplate";
import {
  IDateStrRangeObj,
  IEnquiriesStat,
  IEnquiryClassification,
  IOptions,
  ISog,
  IStage,
  IStatsLabelValue,
  IUserContext
} from "../../../../Types/global";
import { useNavigate } from "react-router-dom";
import "./index.css";
import Loadertemplate from "../../../templates/Loadertemplate";
import ServerConnectorUtil from "../../../../shared/utils/ServerConnectorUtil";
import ProspectServices from "../../../../shared/services/ProspectServices";
import api_request from "../../../../shared/constants/api_request";
import RequestMethod from "../../../../shared/enums/RequestMethod";
import DateFilter from "../../../templates/Date-Filter";
import dateHooks from "../../../Hooks/dateHooks";
import EnquiryStages from "../../../../shared/enums/EnquiryStages";
import useEffectAfterMount from "../../../../shared/hooks/useEffectAfterMount";
import axios from "axios";
import DateFormat from "../../../../shared/enums/DateFormat";
import ProspectStage from "../../../../shared/enums/ProspectStage";
import DateTimeProcessor from "../../../../shared/processors/DateTimeProcessor";
import AppConstants from "../../../../shared/constants/AppContants";
import { UserContext } from "../../../HOC/UserContext";
import calendar from "../../../../shared/constants/calendar";
import ListBoxtemplate from "../../../templates/ListBoxtemplate";

type EnquirySummary = {
  total_leads: number;
  total_prospects: number;
  total_sales_lost: number;
  hot_prospect_sogs: Array<{
    key: string;
    value: number;
  }>;
  total_deliveries: number;
  hot_prospect_stages: Array<{
    key: string;
    value: number;
  }>;
  total_sales_dropped: number;
  total_hot_prospects: number;
  total_overdue_hot_prospects: number;
};
const i18nTranslated = {
  Lead: "Leads",
  Prospect: "Prospects",
  Hot_prospect: "Hot Prospects",
  Overdue_hot_prospect: "Overdue HPs",
  F1_label: "F1",
  F2_label: "F2",
  F3_label: "F3",
  F4_label: "F4",
  F5_label: "F5",
  Dsi_label: "DSI",
  FA_label: "Field Activity",
  WalkIn_label: "Walk-in",
  Others_label: "Others",
  EnquiryConversion_title: "Enquiry conversion",
  Deliveries_label: "Deliveries",
  SalesLost_label: "Sales lost",
  SalesDropped_label: "Sales Dropped"
};

const StageMap: Record<string, string> = {
  [ProspectStage.F1]: "F1",
  [ProspectStage.F2]: "F2",
  [ProspectStage.F3]: "F3",
  [ProspectStage.F4]: "F4",
  [ProspectStage.F5]: "F5"
};

const role = "dsp";
const EnquiryTab: React.FC = () => {
  const navigate = useNavigate();
  const currentUser = useContext(UserContext) as IUserContext;
  const controller = useRef<AbortController | null>(null);

  const [enquirySummary, setEnquirySummary] = useState<EnquirySummary>({
    total_leads: 0,
    total_prospects: 0,
    total_deliveries: 0,
    total_sales_lost: 0,
    total_sales_dropped: 0,
    total_hot_prospects: 0,
    total_overdue_hot_prospects: 0,
    hot_prospect_sogs: [],
    hot_prospect_stages: []
  });

  const [currentFilter, setcurrentFilter] = useState<string | IDateStrRangeObj>(
    calendar.CURRENTMONTH
  );
  const [showFilterLoading, setshowFilterLoading] = useState(true);
  useEffectAfterMount(() => {
    setshowFilterLoading(true);
    let endDateTime: string = "";
    let startDateTime: string = "";

    if (currentFilter) {
      const { start_date, end_date } =
        dateHooks.formatFilterValueWithDateFormat(
          currentFilter,
          DateFormat.ISO_DATE_WITH_TIME
        );

      endDateTime = end_date;
      startDateTime = start_date;
    }

    if (controller.current) {
      controller.current.abort();
    }

    controller.current = new AbortController();
    axios
      .get(
        `https://tafe-akilavan-50022289662.catalystappsail.in/api/v1/dsp-users/${currentUser.user.id}/prospects/summary`,
        {
          params: {
            ...(startDateTime ? { start_date_time: startDateTime } : {}),
            ...(endDateTime ? { end_date_time: endDateTime } : {})
          },
          signal: controller.current.signal,
          headers: {
            "app-secret-key": AppConstants.APP_SECRET_KEY
          }
        }
      )
      .then((response) => {
        setEnquirySummary(response.data.data);
        setshowFilterLoading(false);
      })
      .catch(ServerConnectorUtil.handleServerError);
  }, [currentFilter]);


  const goToEnquriesMasterList = useCallback(
    (
      event: React.MouseEvent<
        HTMLDivElement | HTMLSpanElement | HTMLTableRowElement
      >
    ) => {
      const element = event.currentTarget as HTMLElement;
      const sogs = element.getAttribute("data-sogs");
      const title = element.getAttribute("data-title") as string;
      const stages = element.getAttribute("data-stages");
      const eddEndValue = element.getAttribute("data-edd-end-value");
      const eddStartValue = element.getAttribute("data-edd-start-value");
      const createdTimeEndValue = element.getAttribute(
        "data-created-time-end-value"
      );
      const createdTimeStartValue = element.getAttribute(
        "data-created-time-start-value"
      );
      const dateOfDeliveryEndValue = element.getAttribute(
        "data-date-of-delivery-end-value"
      );
      const dateOfDeliveryStartValue = element.getAttribute(
        "data-date-of-delivery-start-value"
      );

      const searchParams = new URLSearchParams({
        title,
        ...(stages ? { stages } : {}),
        ...(sogs ? { sogs } : {})
      });

      if (eddEndValue) {
        searchParams.set("edd_end_value", eddEndValue);
      }

      if (eddStartValue) {
        searchParams.set("edd_start_value", eddStartValue);
      }

      if (createdTimeEndValue) {
        searchParams.set("created_time_end_value", createdTimeEndValue);
      }
      if (createdTimeStartValue) {
        searchParams.set("created_time_start_value", createdTimeStartValue);
      }
      if(dateOfDeliveryEndValue){
        searchParams.set("date_of_delivery_end_value",dateOfDeliveryEndValue)
      }
      if(dateOfDeliveryStartValue){
        searchParams.set("date_of_delivery_start_value",dateOfDeliveryStartValue)
      }

      navigate({
        pathname: `/innerbase/prospects`,
        search: searchParams.toString()
      });
    },
    [navigate]
  );

  const getDataAttributesForCreatedTime = useCallback(() => {
    if (!currentFilter) {
      return {};
    } else {
      const { start_date, end_date } =
        dateHooks.formatFilterValueWithDateFormat(
          currentFilter,
          DateFormat.ISO_DATE_WITH_TIME
        );

      return {
        "data-created-time-start-value": start_date,
        "data-created-time-end-value": end_date
      };
    }
  }, [currentFilter]);

  const getDataAttributesForDeliveryDate = useCallback(() => {
    if (!currentFilter) {
      return {};
    } else {
      const { start_date, end_date } =
        dateHooks.formatFilterValueWithDateFormat(
          currentFilter,
          DateFormat.ISO_DATE_WITH_TIME
        );

      return {
        "data-date-of-delivery-start-value": start_date,
        "data-date-of-delivery-end-value": end_date
      };
    }
  }, [currentFilter]);

  return (
    <div className="enquiry-content">
      <DateFilter setState={setcurrentFilter} />

      {showFilterLoading ? (
        <div
          className="loader-wrapper"
          style={{ height: "calc(100vh - 380px)" }}
        >
          <Loadertemplate></Loadertemplate>
        </div>
      ) : (
        <Container>
          <Row>
            <Col className="enquiry-conversion">
              <div className="enquiry-conversion-table">
                <div className="conversion-head">
                  <span className="title">
                    {i18nTranslated.EnquiryConversion_title}
                  </span>
                  <div
                    data-title="enquiry_conversion_title"
                    data-stages={[
                      ProspectStage.R6,
                      ProspectStage.R7,
                      ProspectStage.R8,
                      ProspectStage.R9,
                      ProspectStage.R10,
                      ProspectStage.SALES_LOST,
                      ProspectStage.DELIVERY,
                      ProspectStage.SALES_DROPPED
                    ].join(",")}
                    onClick={goToEnquriesMasterList}
                    {...getDataAttributesForDeliveryDate()}
                    {...getDataAttributesForCreatedTime()}
                  >
                    <ArrowPrimarySvg />
                  </div>
                </div>
                <div className="conversion-body">
                  <Container>
                    <Row>
                      <Col className="conversion-col">
                        <span className="conversion-label">
                          {i18nTranslated.Deliveries_label}
                        </span>
                        <span
                          className="conversion-value"
                          data-title="delivery_title"
                          data-stages={[
                            ProspectStage.R6,
                            ProspectStage.R7,
                            ProspectStage.R8,
                            ProspectStage.R9,
                            ProspectStage.R10,
                            ProspectStage.DELIVERY
                          ].join(",")}
                          {...getDataAttributesForDeliveryDate()}
                          onClick={goToEnquriesMasterList}
                        >
                          {enquirySummary.total_deliveries}
                        </span>
                      </Col>
                      <Col className="conversion-col">
                        <span className="conversion-label">
                          {i18nTranslated.SalesLost_label}
                        </span>
                        <span
                          className="conversion-value"
                          data-title="sales_lost_title"
                          data-stages={[ProspectStage.SALES_LOST].join(",")}
                          onClick={goToEnquriesMasterList}
                          {...getDataAttributesForCreatedTime()}
                        >
                          {enquirySummary.total_sales_lost}
                        </span>
                      </Col>
                      <Col className="conversion-col">
                        <span className="conversion-label">
                          {i18nTranslated.SalesDropped_label}
                        </span>
                        <span
                          className="conversion-value"
                          data-title="sales_dropped_title"
                          data-stages={[ProspectStage.SALES_DROPPED].join(",")}
                          onClick={goToEnquriesMasterList}
                          {...getDataAttributesForCreatedTime()}
                        >
                          {enquirySummary.total_sales_dropped}
                        </span>
                      </Col>
                    </Row>
                  </Container>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="enquiry-stat-grid">
            <Col>
              <div
                data-title="lead_title"
                data-stages={[
                  ProspectStage.F1,
                  ProspectStage.F2,
                  ProspectStage.F3,
                  ProspectStage.F4,
                  ProspectStage.F5
                ].join(",")}
                data-edd-start-value={DateTimeProcessor.format(
                  DateTimeProcessor.startOfDay(
                    DateTimeProcessor.addDays(new Date(), 61)
                  ) as number
                )}
                data-edd-end-value={DateTimeProcessor.format(
                  DateTimeProcessor.startOfDay(
                    DateTimeProcessor.addDays(new Date(), 99)
                  ) as number
                )}
                onClick={goToEnquriesMasterList}
              >
                <Gridtemplate
                  gridHead={
                    <>
                      <span className="title">{i18nTranslated.Lead}</span>
                      <ArrowPrimarySvg />
                    </>
                  }
                  gridContent={<>{enquirySummary.total_leads}</>}
                  gridWrapperClassName="box-grid"
                  gridContentClassName="grid-value"
                ></Gridtemplate>
              </div>
            </Col>
            <Col>
              <div
                data-title="prospect_title"
                data-stages={[
                  ProspectStage.F1,
                  ProspectStage.F2,
                  ProspectStage.F3,
                  ProspectStage.F4,
                  ProspectStage.F5
                ].join(",")}
                data-edd-start-value={DateTimeProcessor.format(
                  DateTimeProcessor.startOfDay(
                    DateTimeProcessor.addDays(new Date(), 31)
                  ) as number
                )}
                data-edd-end-value={DateTimeProcessor.format(
                  DateTimeProcessor.startOfDay(
                    DateTimeProcessor.addDays(new Date(), 60)
                  ) as number
                )}
                onClick={goToEnquriesMasterList}
              >
                <Gridtemplate
                  gridHead={
                    <>
                      <span className="title">{i18nTranslated.Prospect}</span>
                      <ArrowPrimarySvg />
                    </>
                  }
                  gridContent={<>{enquirySummary.total_prospects}</>}
                  gridWrapperClassName="box-grid"
                  gridContentClassName="grid-value"
                ></Gridtemplate>
              </div>
            </Col>
          </Row>
          <Row className="enquiry-stat-grid">
            <Col>
              <div
                data-title="hot_prospect_title"
                data-stages={[
                  ProspectStage.F1,
                  ProspectStage.F2,
                  ProspectStage.F3,
                  ProspectStage.F4,
                  ProspectStage.F5
                ].join(",")}
                data-edd-start-value={DateTimeProcessor.format(
                  DateTimeProcessor.startOfDay(Date.now()) as number
                )}
                data-edd-end-value={DateTimeProcessor.format(
                  DateTimeProcessor.startOfDay(
                    DateTimeProcessor.addDays(new Date(), 30)
                  ) as number
                )}
                onClick={goToEnquriesMasterList}
              >
                <Gridtemplate
                  gridHead={
                    <>
                      <span className="title">
                        {i18nTranslated.Hot_prospect}
                      </span>
                      <ArrowPrimarySvg />
                    </>
                  }
                  gridContent={<>{enquirySummary.total_hot_prospects}</>}
                  gridWrapperClassName="box-grid"
                  gridContentClassName="grid-value"
                ></Gridtemplate>
              </div>
            </Col>
            <Col>
              <div
                data-title="overdue_hps_title"
                data-stages={[
                  ProspectStage.F1,
                  ProspectStage.F2,
                  ProspectStage.F3,
                  ProspectStage.F4,
                  ProspectStage.F5
                ].join(",")}
                data-edd-end-value={DateTimeProcessor.format(
                  DateTimeProcessor.startOfDay(Date.now()) as number
                )}
                onClick={goToEnquriesMasterList}
              >
                <Gridtemplate
                  gridHead={
                    <>
                      <span className="title">
                        {i18nTranslated.Overdue_hot_prospect}
                      </span>
                      <ArrowPrimarySvg />
                    </>
                  }
                  gridContent={
                    <>{enquirySummary.total_overdue_hot_prospects}</>
                  }
                  gridWrapperClassName="box-grid"
                  gridContentClassName="grid-value overdue-hotProspect"
                ></Gridtemplate>
              </div>
            </Col>
          </Row>
          <Row>
            <Col className="hp-stages">
              <div className="title">Hot Prospects - Stages</div>
              <table className="table hp-stage-table">
                <thead>
                  <tr>
                    <th className="title-table">Stage</th>
                    <th className="title-table">HPs</th>
                  </tr>
                </thead>
                <tbody>
                  {enquirySummary.hot_prospect_stages.map((item, index) => (
                    <tr
                      key={index}
                      data-title={StageMap[item.key]}
                      data-stages={item.key}
                      data-edd-start-value={DateTimeProcessor.format(
                        DateTimeProcessor.startOfDay(Date.now()) as number
                      )}
                      data-edd-end-value={DateTimeProcessor.format(
                        DateTimeProcessor.startOfDay(
                          DateTimeProcessor.addDays(new Date(), 30)
                        ) as number
                      )}
                      onClick={goToEnquriesMasterList}
                    >
                      <td>{StageMap[item.key]}</td>
                      <td className="hps-count">
                        {item.value}
                        <span className="navigate-hpStages">
                          <NavigateArrowSvg />
                        </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </Col>
          </Row>
          <Row>
          <ListBoxtemplate accordionWrapClass='hp_sog_wrapper'>
                <div className='title'>Hot Prospects SoG</div>
                <table className='table hp-sog-table'>
                    <thead>
                        <tr>
                            <th className='title-table'>Type</th>
                            <th className='title-table'>HPs</th>
                        </tr>
                    </thead>
                    <tbody>
                      {enquirySummary.hot_prospect_sogs.map((item, index) => (
                        <tr
                          key={index}
                          data-title="hot_prospect_sog_title"
                          data-sogs={item.key}
                          data-edd-start-value={DateTimeProcessor.format(
                            DateTimeProcessor.startOfDay(Date.now()) as number
                          )}
                          data-edd-end-value={DateTimeProcessor.format(
                            DateTimeProcessor.startOfDay(
                              DateTimeProcessor.addDays(new Date(), 30)
                            ) as number
                          )}
                          onClick={goToEnquriesMasterList}
                        >
                          <td>{item.key}</td>
                          <td className="hps-count">
                            {item.value}
                            <span className="navigate-hpStages">
                              <NavigateArrowSvg />
                            </span>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                </table>
            </ListBoxtemplate>
          </Row>
        </Container>
      )}
      {role && (
        <div className="add-enquiry">
          <Buttontemplate
            buttonWidth="150px"
            type="primary"
            callback={() =>
              navigate("/innerbase/enquiry/addenquiry?title=add_enquiry")
            }
          >
            +<span className="context-enquiry">Add Enquiry</span>
          </Buttontemplate>
        </div>
      )}
    </div>
  );
};

export default EnquiryTab;
