import "./index.css";
import React from "react";
import { Link } from "react-router-dom";

import { ArrowSvg } from "../icons/ArrowSvg";
import { EnqGenSvg } from "../icons/CheckIn/EnqGenSvg";
import { FieldActivitySvg } from "../icons/CheckIn/FieldActivitySvg";
import { FICollectionSvg } from "../icons/CheckIn/FICollectionSvg";
import { OtherTaskSvg } from "../icons/CheckIn/OtherTaskSvg";
import { JcpCheckinSvg } from "../icons/CheckIn/JcpCheckinSvg";

import AppUrlConstants from "../shared/constants/AppUrlConstants";

type CheckInCategory = {
  name: string;
  path: string;
  describe: string;
  icon: JSX.Element;
};

const i18nTranslated = {
  checkInCategory: "Select Category",
  categoryHeader: "Select a Category to check-in",
  checkInJCP: "JCP",
  checkInJCPDescribe: "Check-in for your journey centre planning",
  checkInEnqGen: "Enq. Gen. & Follow-ups",
  checkInEnqGenDescribe: "Check-in for your enquriy generation",
  checkInFieldActivity: "Field Activity",
  checkInFieldActivityDescribe: "Check-in for your scheduled field activity",
  checkInFICollection: "FI & Collections",
  checkInFICollectionDescribe: "Check-in for your collections and payments",
  checkInOtherTasks: "Other Tasks",
  checkInOtherTasksDescribe: "Complete your other remaining tasks"
};

const checkInCategories: Array<CheckInCategory> = [
  {
    name: i18nTranslated.checkInJCP,
    path:
      AppUrlConstants.INNER_BASE +
      AppUrlConstants.CHECK_IN +
      AppUrlConstants.JCPS +
      "?title=check-in-jcps",
    describe: i18nTranslated.checkInJCPDescribe,
    icon: <JcpCheckinSvg />
  },
  // {
  //   name: i18nTranslated.checkInEnqGen,
  //   path:
  //     AppUrlConstants.INNER_BASE +
  //     AppUrlConstants.CHECK_IN +
  //     AppUrlConstants.JCPS +
  //     "?title=visit_details",
  //   describe: i18nTranslated.checkInEnqGenDescribe,
  //   icon: <EnqGenSvg />
  // },
  // {
  //   name: i18nTranslated.checkInFieldActivity,
  //   path:
  //     AppUrlConstants.INNER_BASE +
  //     AppUrlConstants.CHECK_IN +
  //     AppUrlConstants.JCPS +
  //     "?title=visit_details",
  //   describe: i18nTranslated.checkInFieldActivityDescribe,
  //   icon: <FieldActivitySvg />
  // },
  // {
  //   name: i18nTranslated.checkInFICollection,
  //   path:
  //     AppUrlConstants.INNER_BASE +
  //     AppUrlConstants.CHECK_IN +
  //     AppUrlConstants.JCPS +
  //     "?title=visit_details",
  //   describe: i18nTranslated.checkInFICollectionDescribe,
  //   icon: <FICollectionSvg />
  // },
  // {
  //   name: i18nTranslated.checkInOtherTasks,
  //   path:
  //     AppUrlConstants.INNER_BASE +
  //     AppUrlConstants.CHECK_IN +
  //     AppUrlConstants.JCPS +
  //     "?title=visit_details",
  //   describe: i18nTranslated.checkInOtherTasksDescribe,
  //   icon: <OtherTaskSvg />
  // }
];

const CheckInCategories: React.FC = () => {
  return (
    <>
      <div className="checkin-category-header">
        {i18nTranslated.categoryHeader}
      </div>
      <div className="checkin-category-options">
        {checkInCategories.map((item) => (
          <Link className="checkin-category" key={item.name} to={item.path}>
            <div className="checkin-category-icon">{item.icon}</div>
            <div className="checkin-category-content">
              <span className="checkin-category-name">{item.name}</span>
              <span className="checkin-category-describe">{item.describe}</span>
            </div>
            <div className="checkin-navigate">
              <ArrowSvg />
            </div>
          </Link>
        ))}
      </div>
    </>
  );
};
export default CheckInCategories;
