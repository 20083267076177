import React, { useCallback } from 'react'
import Tabtemplate from '../../templates/Tabtemplate';
import {ITabsData} from '../../../Types/global';
import { Outlet, useNavigate } from 'react-router-dom';
const i18nTranslated = {
  'tudbTab': 'TUDB',
  'enquiryTab': 'Enquiry'
}
const Enquiries: React.FC = () => {
    const navigate = useNavigate();
    const renderEnquiriesTabs = useCallback(()=>{
        const tabs: ITabsData[] = [
          {
            key: 'enquiry',
            title: i18nTranslated['enquiryTab']
          },
          {
            key: 'tractoruser',
            title: i18nTranslated['tudbTab']
          }
        ]
        return <Tabtemplate tabs = {tabs} onSelect={handleEnquirySelect}>
          <Outlet/>
        </Tabtemplate>
    }, []);
    const handleEnquirySelect = useCallback((key: string | null) => {
        navigate(`/base/enquiries/${key}`);
    }, [])
  return (
    renderEnquiriesTabs()
  )
}
export default Enquiries;