import axios from "axios";
import { IDSPLeaderBoard, IRatingUserData } from "../../Types/global";
import ServerConnectorUtil from "../../shared/utils/ServerConnectorUtil";

const getDSP = async():Promise<IRatingUserData[]> => {
    try{
        let dspList = await axios.get('/api/v1/user/my-dsp-peers?additional_fields=stats.total_prospects,stats.total_deliveries');
        let leader_list = dspList.data;
        let data: IDSPLeaderBoard[] = leader_list.data;
        let sortLeader: IDSPLeaderBoard[] = data.sort((currentLeader, nextLeader)=> {
            if(currentLeader.stats.total_deliveries !== undefined && nextLeader.stats.total_deliveries !== undefined)
                return nextLeader.stats.total_deliveries - currentLeader.stats.total_deliveries
            return 0;
        });
        let leaderItems: IRatingUserData[] = [];
        sortLeader.forEach((item)=> {
            let deliveries: number = 0;
            if(item.stats.total_deliveries !== undefined){
                deliveries = item.stats.total_deliveries;
            }
            let name;
            if(item.first_name === null && item.last_name === null){
                name = `-`
            }else if(item.first_name === null){
                name = item.last_name
            }else if(item.last_name === null){
                name = item.first_name
            }else{
                name = `${item.first_name} ${item.last_name}`
            }
            let newItem: IRatingUserData ={
                profileId: item.id,
                name: name,
                deliveryStat: 'up',
                deliveries: deliveries
            }
            leaderItems.push(newItem);
        })
        return leaderItems;
    }catch(error){
        if (error instanceof Error) {
            ServerConnectorUtil.handleServerError(error);
        }
        return [];
    }
}
export default getDSP;