import ToastUtil from './ToastUtil';
export default class ServerConnectorUtil {
  public static handleServerError(error: any): void {
    if ((error as any).code === 'ERR_CANCELED') {
      return;
    }
    console.log(error);
    ToastUtil.makeFailureToast(error?.response?.data?.message ? error?.response?.data?.message : error?.message);
  }
}
