import { IFollowupProspect, IJCPVillageStats, IPageResponse, IProspect, ITudbUserData, IVillage } from "../../Types/global";
import RequestMethod from "../enums/RequestMethod";
import RequestHandler from "../handlers/RequestHandler";

export default class DatalistServices{
    public static async getAllListData ({
        url, 
        method, 
        params, 
        signal,
        body
    }: {
        url: string, 
        method: RequestMethod, 
        params: any, 
        signal: AbortSignal,
        body?: unknown
    }) : Promise<IPageResponse<ITudbUserData | IProspect | IVillage | IFollowupProspect | IJCPVillageStats>>{
        const reqHandler = new RequestHandler(url, method);
        for (const key in params) {
            if (Object.prototype.hasOwnProperty.call(params, key)) {
                reqHandler.addParam(key, params[key])
            }
        }
        reqHandler.setAbortSignal(signal);
        if(body){
            reqHandler.setBody(body);
        }
        return reqHandler
        .execute()
        .then((response) => response.data as IPageResponse<ITudbUserData  | IProspect | IVillage | IFollowupProspect | IJCPVillageStats>);

    }
}