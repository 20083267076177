import { useNavigate } from 'react-router-dom';
import { DoubleTickSvg } from '../../../icons/DoubleTickSvg';
import Buttontemplate from '../../../Components/templates/Buttontemplate';
import { EnquiriesSvg } from '../../../icons/EnquiriesSvg';

const i18nTranslated = {
  success: 'Success',
  jcpSuccessDesc: 'JCP has been successfully submitted for approval.',
  backToHome: 'Back to Home'
};

const SuccessJcpCheckIn: React.FC = () => {
  const navigate = useNavigate();
  return (
    <>
      <DoubleTickSvg />
      <h1 style={{ color: '#fff', fontSize: '16px', marginTop: '30px' }}>
        {i18nTranslated.success}
      </h1>
      <p style={{ color: '#fff', fontSize: '14px', marginTop: '20px' }}>
        {i18nTranslated.jcpSuccessDesc}
      </p>
      <Buttontemplate
        type="success"
        buttonWidth="100%"
        callback={() => navigate('/base/home')}
      >
        <EnquiriesSvg fill="#257851" />
        {i18nTranslated.backToHome}
      </Buttontemplate>
    </>
  );
};

export default SuccessJcpCheckIn;
