import { Form, InputGroup } from 'react-bootstrap';

import { SearchSvg } from '../../../icons/SearchSvg';
import SearchDropdowntemplate from '../../../Components/templates/SearchDropdowntemplate';
import { IOptions } from '../../../Types/global';

export type RecordSearchBoxProps = {
  value: string;
  placeholder: string;
  dropDownOptions: IOptions[];
  initialDropDownValue?:IOptions
  onDropDownChange: (value: IOptions) => void;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

const RecordSearchBox: React.FC<RecordSearchBoxProps> = ({
  value,
  onChange,
  placeholder,
  dropDownOptions,
  onDropDownChange,
  initialDropDownValue,
}) => {
  return (
    <div className="search-details-header">
      <SearchDropdowntemplate
        options={dropDownOptions}
        onSelect={onDropDownChange}
        placeholder="Select"
        menuPosition='fixed'
        defaultValue={initialDropDownValue}
      />
      <InputGroup bsPrefix="default-search">
        <Form.Control
          size="lg"
          placeholder={placeholder}
          className="custom-form-input"
          value={value}
          onChange={onChange}
        ></Form.Control>
        <InputGroup.Text>
          <SearchSvg />
        </InputGroup.Text>
      </InputGroup>
    </div>
  );
};

export default RecordSearchBox;
