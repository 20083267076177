import { useCallback } from 'react';

type ScrollEvent = React.UIEvent<HTMLDivElement>;

const useScroll = (cb: () => void) => {
	const execute = useCallback(
		(event: ScrollEvent) => {
			const { scrollHeight, clientHeight, scrollTop } =
				event.target as HTMLElement;

			if (scrollHeight - clientHeight <= scrollTop * 1.2) {
				cb();
			}
		},
		[cb]
	);

	return execute;
};

export default useScroll;