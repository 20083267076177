import React from "react";
import styles from "./styles.module.css";

import ViewBody, { ViewBodyProps } from "./ViewBody";
import ViewFooter from "./ViewFooter";

type ViewLayoutComposition = {
  Body: React.FC<ViewBodyProps>;
  Footer: React.FC<React.PropsWithChildren>;
};

const ViewLayout: React.FC<React.PropsWithChildren> & ViewLayoutComposition = ({
  children
}) => {
  return <div className={styles["main-container"]}>{children}</div>;
};

ViewLayout.Body = ViewBody;
ViewLayout.Footer = ViewFooter;

export default ViewLayout;
