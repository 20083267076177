import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from "react";
import "./index.css";
import "../../templates/Ratingtemplate/index.css";
import Ratingtemplate from "../../templates/Ratingtemplate";
import {
  DashboardPage,
  DspUser,
  IAvgDeliveriesStat,
  IDateStrRangeObj,
  IDeliveriesStat,
  IRatingUserData,
  IUserContext
} from "../../../Types/global";
import Gridtemplate from "../../templates/Gridtemplate";
import { ArrowPrimarySvg } from "../../../icons/ArrowPrimarySvg";
import { Link, useNavigate } from "react-router-dom";
import Loadertemplate from "../../templates/Loadertemplate";
import getDSP from "../../Hooks/getDSP";
import ServerConnectorUtil from "../../../shared/utils/ServerConnectorUtil";
import useCurrentUserStat from "../../../shared/hooks/useCurrentUserStat";
import dateHooks from "../../Hooks/dateHooks";
import AppUrlConstants from "../../../shared/constants/AppUrlConstants";
import AppQueryParameters from "../../../shared/enums/AppQueryParameters";
import DateFilter from "../../templates/Date-Filter";
import DateTimeProcessor from "../../../shared/processors/DateTimeProcessor";
import calendar from "../../../shared/constants/calendar";
import useEffectAfterMount from "../../../shared/hooks/useEffectAfterMount";
import DateFormat from "../../../shared/enums/DateFormat";
import axios from "axios";
import AppConstants from "../../../shared/constants/AppContants";
import { UserContext } from "../../HOC/UserContext";
import moment from "moment";
import classNames from "classnames";
import { TrophySvg } from "../../../icons/TrophySvg";
import ProfilePath from "../../../shared/enums/ProfilePath";
import dummyUser from "../../../images/dummy-user.png";
import ProspectStage from "../../../shared/enums/ProspectStage";

interface IbillingTarget {
  billing: number;
  target: number;
}
interface IdeliveriesTarget {
  deliveries: number;
  target: number;
}
interface IcollectionTarget {
  collection: number;
  target: number;
}
const i18nTranslated = {
  youLabel: "You",
  "companyOs-title": "Company OS(INR)",
  "stockInDays-title": "Stock (in days)",
  "tpiScore-title": "TPI Score(quadrant)",
  "marketShareAndTarget-title": "Market Share/Target (%)",
  deliveriesStatLabel: "Deliveries",
  actualStatLabel: "Actual",
  targetStatLabel: "Target",
  dspAvgDeliveriesStatLabel: "Avg No. of Deliveries (per month)",
  dspIncentiveStatLabel: "My Incentive",
  dspRewardsStatLabel: "My Rewards",
  leaderBoard: "Leader board",
  BillingStatLabel: "Billings",
  RetailStatLabel: "Retail",
  RctStatLabel: "RCT (Days)",
  bankGuarantee: "Bank Guarantee(INR)",
  CollectionsLabel: "Collections"
};

const RatingTemplate: React.FC<{ dspUsers: Array<DspUser> }> = ({
  dspUsers
}) => {
  const currentUser = useContext(UserContext);
  const user = (currentUser as IUserContext).user;
  const [rankCount] = useState<number>(dspUsers.length);
  const [hover, setHover] = useState<boolean>(false);
  const activateHover = useCallback(() => {
    if (!hover) {
      setHover(true);
    }
  }, []);
  return (
    <div className="container" onMouseMove={activateHover}>
      <table className="table rating-template-table">
        <thead>
          <tr className="row rate-head-row">
            <th className="col-3 rate-head">Rank({rankCount})</th>
            <th className="col-6 rate-head">Name</th>
            <th className="col-3 rate-head">Deliveries</th>
          </tr>
        </thead>
        <tbody className="rating-body">
          {dspUsers.map((item, index) => (
            <tr
              className={classNames(
                "row",
                "rate-row",
                { hover: hover },
                { currentuser: user.id === item.id }
              )}
            >
              <td className="col-2 rate-col">
                <span className="line-trophy">
                  <TrophySvg />
                  <span className="trophyIndex">{index + 1}</span>
                </span>
              </td>
              <td className="col-8 rate-col">
                <span className="dsp-profile">
                  <img
                    src={`${ProfilePath.PATH}${item.id}.png`}
                    onError={(event: React.SyntheticEvent<HTMLImageElement>) =>
                      (event.currentTarget.src = dummyUser)
                    }
                  />
                </span>
                <span className="dsp-name">
                  {user.id === item.id
                    ? i18nTranslated.youLabel
                    : [item.first_name, item.last_name]
                        .filter((obj) => obj)
                        .join(" ")}
                </span>
              </td>
              <td className="col-2 rate-col">
                <span className="">
                  {item.analytics.total_deliveries_analytics.current_value}
                </span>
                <span
                  className={classNames(
                    "stats",
                    item.analytics.total_deliveries_analytics.variation > 0
                      ? "up"
                      : "down"
                  )}
                >
                  <ArrowPrimarySvg
                    fill={
                      item.analytics.total_deliveries_analytics.variation > 0
                        ? "#007851"
                        : "#b51213"
                    }
                  />
                </span>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const Dashboard: React.FC = () => {
  const [role, setrole] = useState("dsp");
  const navigate = useNavigate();
  const currentUser = useContext(UserContext) as IUserContext;
  const userPerformanceMetricsController = useRef<AbortController | null>(null);
  const [dateFilter, setDateFilter] = useState<string | IDateStrRangeObj>(
    calendar.CURRENTMONTH
  );
  const [userPerformanceMetrics, setUserPerformanceMetrics] = useState<{
    total_rewards: number;
    total_incentive: number;
    total_deliveries: number;
    total_deliveries_target: number;
    average_no_of_deliveries_per_month: number;
  }>({
    total_rewards: 0,
    total_incentive: 0,
    total_deliveries: 0,
    total_deliveries_target: 0,
    average_no_of_deliveries_per_month: 0
  });
  const [isLoadingUserPerformanceMetrics, setIsLoadingUserPerformanceMetrics] =
    useState<boolean>(true);
  const [isLoadingDspUsers, setIsLoadingDspUsers] = useState<boolean>(true);
  const [dspUsers, setDspUsers] = useState<Array<DspUser>>([]);

  const [onLoadCompleted, setOnLoadCompleted] = useState<boolean>(true);
  const [userStatFilter, setUserStatFilter] =
    useState<DashboardPage.UserStat.Parameters>({
      onFailure: ServerConnectorUtil.handleServerError
    });

  const [showDspLazyLoad, setshowDspLazyLoad] = useState<boolean>(false);
  const [dspUser, setdspUser] = useState<IRatingUserData[]>([]);
  const currentYear = new Date().getFullYear();
  const [start_date, setStart_date] = useState<string | null>(
    dateHooks.formatDate(new Date(currentYear, 0, 1).toString(), "YYYY-MM-DD")
  );
  const [end_date, setEnd_date] = useState<string | null>(
    dateHooks.formatDate(new Date().toString(), "YYYY-MM-DD")
  );

  useEffectAfterMount(() => {
    setIsLoadingUserPerformanceMetrics(true);
    let endDateTime: string = "";
    let startDateTime: string = "";

    if (dateFilter) {
      const { start_date, end_date } =
        dateHooks.formatFilterValueWithDateFormat(
          dateFilter,
          DateFormat.ISO_DATE_WITH_TIME
        );

      endDateTime = end_date;
      startDateTime = start_date;
    } else {
    }

    if (userPerformanceMetricsController.current) {
      userPerformanceMetricsController.current.abort();
    }
    userPerformanceMetricsController.current = new AbortController();
    axios
      .get(
        `https://tafe-akilavan-50022289662.catalystappsail.in/api/v1/dsp-users/${currentUser.user.id}/performance-metrics`,
        {
          params: {
            ...(startDateTime ? { start_date_time: startDateTime } : {}),
            ...(endDateTime ? { end_date_time: endDateTime } : {}),
            fields:
              "stats.total_enquiries,stats.total_deliveries,stats.total_hot_prospects,stats.total_tractor_user_database_added,stats.total_jc_plannings_planned,stats.total_jc_plannings_planned_till_date,stats.total_jc_plannings_visited_till_date"
          },
          signal: userPerformanceMetricsController.current.signal,
          headers: {
            "app-secret-key": AppConstants.APP_SECRET_KEY
          }
        }
      )
      .then((response) => {
        setUserPerformanceMetrics(response.data.data);
        setIsLoadingUserPerformanceMetrics(false);
      })
      .catch(ServerConnectorUtil.handleServerError);
  }, [dateFilter]);

  useEffectAfterMount(() => {
    setIsLoadingDspUsers(true);

    const currentStartDateTime = moment()
      .startOf("month")
      .startOf("day")
      .format(DateFormat.ISO_DATE_WITH_TIME);
    const currentEndDateTime = moment().endOf("month").endOf("day").format(DateFormat.ISO_DATE_WITH_TIME);
    const previousStartDateTime = moment()
      .startOf("month")
      .startOf("day")
      .subtract(1, "month")
      .format(DateFormat.ISO_DATE_WITH_TIME);
    const previousEndDateTime = moment(
      previousStartDateTime,
      DateFormat.ISO_DATE_WITH_TIME
    )
      .endOf("month")
      .endOf("day")
      .format(DateFormat.ISO_DATE_WITH_TIME);

    axios
      .get(
        `https://tafe-akilavan-50022289662.catalystappsail.in/api/v1/dsp-users-with-analytics/${currentUser.user.id}`,
        {
          params: {
            current_start_date_time: currentStartDateTime,
            current_end_date_time: currentEndDateTime,
            previous_start_date_time: previousStartDateTime,
            previous_end_date_time: previousEndDateTime
          },

          headers: {
            "app-secret-key": AppConstants.APP_SECRET_KEY
          }
        }
      )
      .then((response) => {
        setDspUsers(response.data.data);
        setIsLoadingDspUsers(false);
      })
      .catch(ServerConnectorUtil.handleServerError);
  }, []);

  const goToEnquiresMaster = useCallback(() => {
    let dateOfDeliveryStartValue: string | undefined;
    let dateOfDeliveryEndValue: string | undefined;

    if (dateFilter) {
      const { start_date, end_date } =
        dateHooks.formatFilterValueWithDateFormat(
          dateFilter,
          DateFormat.ISO_DATE_WITH_TIME
        );

      dateOfDeliveryStartValue = start_date;
      dateOfDeliveryEndValue = end_date;
    }

    const searchParams = new URLSearchParams({
      title: "delivery_title",
      stages: [
        ProspectStage.DELIVERY,
        ProspectStage.R6,
        ProspectStage.R7,
        ProspectStage.R8,
        ProspectStage.R9,
        ProspectStage.R10,
        ProspectStage.R10
      ].join(",")
    });

    if (dateOfDeliveryEndValue) {
      searchParams.set("date_of_delivery_end_value", dateOfDeliveryEndValue);
    }
    if (dateOfDeliveryStartValue) {
      searchParams.set(
        "date_of_delivery_start_value",
        dateOfDeliveryStartValue
      );
    }

    navigate({
      pathname: `/innerbase/prospects`,
      search: searchParams.toString()
    });
  }, [dateFilter, navigate]);

  const [deliveries, setdeliveries] = useState<IDeliveriesStat>({
    actual: 4,
    target: 8
  });
  const [avgDeliveries, setavgDeliveries] = useState<IAvgDeliveriesStat>({
    actual: 6
  });
  const [incentive, setincentive] = useState<string>("12,number");
  const [rewards, setrewards] = useState<string>("500");
  const [billTarget, setbillTarget] = useState<IbillingTarget>({
    billing: 24,
    target: 25
  });
  const [deliveryTarget, setdeliveryTarget] = useState<IdeliveriesTarget>({
    deliveries: 17,
    target: 20
  });
  const [collectionTarget, setcollectTarget] = useState<IcollectionTarget>({
    collection: 18,
    target: 20
  });
  const [retail, setretail] = useState<number>(18);
  const [rctDays, setrctDays] = useState<number>(45);

  // useEffect(() => {
  //   if (dateFilter !== "") {
  //     if (onLoadCompleted) {
  //       setOnLoadCompleted(false);
  //     }
  //     const { start_date, end_date } =
  //       DateTimeProcessor.getFilterTime(dateFilter);
  //     setStart_date(DateTimeProcessor.format(start_date));
  //     setEnd_date(DateTimeProcessor.format(end_date));
  //     setUserStatFilter((prev) => ({
  //       ...prev,
  //       dateStartValue: start_date,
  //       dateEndValue: end_date
  //     }));
  //   } else if (!onLoadCompleted) {
  //     const { dateStartValue, dateEndValue, ...updatedUserStat } =
  //       userStatFilter;
  //     setStart_date(null);
  //     setEnd_date(null);
  //     setUserStatFilter(updatedUserStat);
  //   }
  // }, [dateFilter]);

  return (
    <div className="dashboard-content">
      <div className="container">
        {role === "dsp" ? (
          <>
            <div className="row dashboard-upper-container">
              <DateFilter setState={setDateFilter} />
              {isLoadingUserPerformanceMetrics ? (
                <div className="loader-wrapper dashboard-loader">
                  <Loadertemplate />
                </div>
              ) : (
                <div className="col-12 grids-wrap">
                  <div className="row">
                    <div className="col-6" onClick={goToEnquiresMaster}>
                      <Gridtemplate
                        gridWrapperClassName="db-grid-box"
                        gridHead={
                          <>
                            <span className="db-title">
                              {i18nTranslated["deliveriesStatLabel"]}
                            </span>
                            <ArrowPrimarySvg></ArrowPrimarySvg>
                          </>
                        }
                        gridContent={
                          <>
                            <div className="db-content">
                              <div className="db-head">{`${i18nTranslated["actualStatLabel"]}/${i18nTranslated["targetStatLabel"]}`}</div>
                              <span style={{ color: "var(--primary-stats)" }}>
                                {userPerformanceMetrics.total_deliveries}
                              </span>
                              <span className="db-value">
                                /
                                {userPerformanceMetrics.total_deliveries_target}
                              </span>
                            </div>
                          </>
                        }
                      ></Gridtemplate>
                    </div>
                    <div className="col-6">
                      <Gridtemplate
                        gridWrapperClassName="db-grid-box"
                        gridHead={
                          <>
                            <span className="db-title">
                              {i18nTranslated["dspAvgDeliveriesStatLabel"]}
                            </span>
                            {/* <ArrowPrimarySvg></ArrowPrimarySvg> */}
                          </>
                        }
                        gridContent={
                          <>
                            <div className="db-content">
                              <div className="db-head">
                                {i18nTranslated["actualStatLabel"]}
                              </div>
                              <span style={{ color: "var(--primary-stats)" }}>
                                {
                                  userPerformanceMetrics.average_no_of_deliveries_per_month
                                }
                              </span>
                            </div>
                          </>
                        }
                      ></Gridtemplate>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6">
                      <Gridtemplate
                        gridWrapperClassName="db-grid-box"
                        gridHead={
                          <>
                            <span className="db-title">
                              {i18nTranslated["dspIncentiveStatLabel"]}
                            </span>
                            {/* <ArrowPrimarySvg></ArrowPrimarySvg> */}
                          </>
                        }
                        gridContent={
                          <>
                            <div className="db-content">
                              Rs. {userPerformanceMetrics.total_incentive}
                            </div>
                          </>
                        }
                      ></Gridtemplate>
                    </div>
                    <div className="col-6">
                      <Gridtemplate
                        gridWrapperClassName="db-grid-box"
                        gridHead={
                          <>
                            <span className="db-title">
                              {i18nTranslated["dspRewardsStatLabel"]}
                            </span>
                            {/* <ArrowPrimarySvg></ArrowPrimarySvg> */}
                          </>
                        }
                        gridContent={
                          <>
                            <div className="db-content">
                              {userPerformanceMetrics.total_rewards}
                            </div>
                          </>
                        }
                      ></Gridtemplate>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="row dashboard-lower-container">
              <div className="col-12 rating-board-header">
                <span className="rating-title">
                  {i18nTranslated["leaderBoard"]}
                </span>
              </div>
              {isLoadingDspUsers ? (
                <div className="loader-wrapper dashboard-dsp-loader">
                  <Loadertemplate></Loadertemplate>
                </div>
              ) : (
                <>
                  <div className="col-12 rating-table">
                    <RatingTemplate dspUsers={dspUsers} />
                  </div>
                </>
              )}
            </div>
          </>
        ) : (
          <>
            <div className="row other-stats-dashboard">
              <div className="row stats-filter"></div>
              <div className="row">
                <div className="col-6">
                  <Gridtemplate
                    gridWrapperClassName="db-grid-box"
                    gridHead={
                      <>
                        <span className="db-title">{`${i18nTranslated["BillingStatLabel"]}/${i18nTranslated["targetStatLabel"]}`}</span>
                        <ArrowPrimarySvg></ArrowPrimarySvg>
                      </>
                    }
                    gridContent={
                      <>
                        <div className="db-content">
                          <div className="db-value">
                            <span className="dashboard-value">
                              {billTarget.billing}
                            </span>
                            /{billTarget.target}
                          </div>
                        </div>
                      </>
                    }
                  ></Gridtemplate>
                </div>
                <div className="col-6">
                  <Gridtemplate
                    gridWrapperClassName="db-grid-box"
                    gridHead={
                      <>
                        <span className="db-title">{`${i18nTranslated["deliveriesStatLabel"]}/${i18nTranslated["targetStatLabel"]}`}</span>
                        <ArrowPrimarySvg></ArrowPrimarySvg>
                      </>
                    }
                    gridContent={
                      <>
                        <div className="db-content">
                          <div className="db-value">
                            <span className="dashboard-value">
                              {deliveryTarget.deliveries}
                            </span>
                            /{deliveryTarget.target}
                          </div>
                        </div>
                      </>
                    }
                  ></Gridtemplate>
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <Gridtemplate
                    gridWrapperClassName="db-grid-box"
                    gridHead={
                      <>
                        <span className="db-title">
                          {i18nTranslated["RetailStatLabel"]}
                        </span>
                        <ArrowPrimarySvg></ArrowPrimarySvg>
                      </>
                    }
                    gridContent={
                      <>
                        <div className="db-content">
                          <div className="db-value">
                            <span className="dashboard-value">{retail}</span>
                          </div>
                        </div>
                      </>
                    }
                  ></Gridtemplate>
                </div>
                {role === "tetm" ? (
                  <div className="col-6">
                    <Gridtemplate
                      gridWrapperClassName="db-grid-box"
                      gridHead={
                        <>
                          <span className="db-title">{`${i18nTranslated["CollectionsLabel"]}/${i18nTranslated["targetStatLabel"]}`}</span>
                          <ArrowPrimarySvg></ArrowPrimarySvg>
                        </>
                      }
                      gridContent={
                        <>
                          <div className="db-content">
                            <div className="db-value">
                              <span className="dashboard-value">
                                {collectionTarget.collection}
                              </span>
                              /{collectionTarget.target}
                            </div>
                          </div>
                        </>
                      }
                    ></Gridtemplate>
                  </div>
                ) : (
                  <div className="col-6">
                    <Gridtemplate
                      gridWrapperClassName="db-grid-box"
                      gridHead={
                        <>
                          <span className="db-title">
                            {i18nTranslated["RctStatLabel"]}
                          </span>
                          <ArrowPrimarySvg></ArrowPrimarySvg>
                        </>
                      }
                      gridContent={
                        <>
                          <div className="db-content">{rctDays}</div>
                        </>
                      }
                    ></Gridtemplate>
                  </div>
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-6">
                <Gridtemplate
                  gridWrapperClassName="db-grid-box"
                  gridHead={
                    <>
                      <span className="db-title">
                        Bank Guarantee<span>(INR)</span>
                      </span>
                    </>
                  }
                  gridContent={
                    <>
                      <div className="db-content">4,20,000</div>
                    </>
                  }
                ></Gridtemplate>
              </div>
              <div className="col-6">
                <Gridtemplate
                  gridWrapperClassName="db-grid-box"
                  gridHead={
                    <>
                      <span className="db-title">
                        {i18nTranslated["companyOs-title"]}
                      </span>
                    </>
                  }
                  gridContent={
                    <>
                      <div className="db-content">5,60,000</div>
                    </>
                  }
                ></Gridtemplate>
              </div>
            </div>
            <div className="row">
              <div className="col-6">
                <Gridtemplate
                  gridWrapperClassName="db-grid-box"
                  gridHead={
                    <>
                      <span className="db-title">
                        {i18nTranslated["stockInDays-title"]}
                      </span>
                    </>
                  }
                  gridContent={
                    <>
                      <div className="db-content">
                        14<span className="db-value">(47)</span>
                      </div>
                    </>
                  }
                ></Gridtemplate>
              </div>
              {role === "tetm" ? (
                <div className="col-6">
                  <Gridtemplate
                    gridWrapperClassName="db-grid-box"
                    gridHead={
                      <>
                        <span className="db-title">
                          {i18nTranslated["RctStatLabel"]}
                        </span>
                        <ArrowPrimarySvg></ArrowPrimarySvg>
                      </>
                    }
                    gridContent={
                      <>
                        <div className="db-content">{rctDays}</div>
                      </>
                    }
                  ></Gridtemplate>
                </div>
              ) : (
                <div className="col-6">
                  <Gridtemplate
                    gridWrapperClassName="db-grid-box"
                    gridHead={
                      <>
                        <span className="db-title">
                          {i18nTranslated["tpiScore-title"]}
                        </span>
                      </>
                    }
                    gridContent={
                      <>
                        <div className="db-content">
                          86%
                          <span className="db-value">(Q1)</span>
                        </div>
                      </>
                    }
                  ></Gridtemplate>
                </div>
              )}
            </div>
            <div className="row">
              <div className="col-12">
                <Gridtemplate
                  gridWrapperClassName="db-grid-box"
                  gridHead={
                    <>
                      <span className="db-title">
                        {i18nTranslated["marketShareAndTarget-title"]}
                      </span>
                    </>
                  }
                  gridContent={
                    <>
                      <div className="db-content">
                        12.30
                        <span className="db-value">/13.50</span>
                      </div>
                    </>
                  }
                ></Gridtemplate>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Dashboard;
