enum EnquiryStages{
    F1_STAGE = "F1 - Customer interested to buy tractor",
    F2_STAGE = "F2 - Customer considering our tractor",
    F3_STAGE = "F3 - Deal Closed",
    F4_STAGE = "F4 - Booking amount received",
    F5_STAGE = "F5 - Margin Money received / Old Tractor",
    DELIVERY_STAGE = "Delivery",
    SALES_LOST_STAGE = "Sales Lost",
    SALES_DROPPED_STAGE = "Sales Dropped"
}

export default EnquiryStages;