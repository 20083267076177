enum DateFormat {
  YEAR = "YYYY",
  MONTH = "MMMM",
  MONTH_YEAR = "MMMM, YYYY",
  ISO_DATE = "YYYY-MM-DD",
  DAY_OF_MONTH = "DD",
  DISPLAY_DATE = "DD-MM-YYYY",
  ISO_DATE_WITH_TIME = "YYYY-MM-DDTHH:mm:ss",
}

export default DateFormat;
