import React, { useCallback } from 'react';
import './index.css';
import Tabtemplate from '../../templates/Tabtemplate';
import { ITabsData } from '../../../Types/global';
import { Outlet, useNavigate } from 'react-router-dom';

const i18nTranslated = {
    'mpcTabTitle': 'MPC',
    'mdcTabTitle': 'MDC',
    'mpcgyarah-menu': 'MPC & Ek se Ek Gyarah',
}
const MPCMDCTabs: React.FC = () => {
    const navigate = useNavigate();
    const renderEnquiriesTabs = useCallback(()=>{
        const tabs: ITabsData[] = [
            {
                key: 'mpc',
                title: i18nTranslated['mpcTabTitle']
            },
            {
                key: 'mdc',
                title: i18nTranslated['mdcTabTitle']
            }
        ]
        return <Tabtemplate className='mpc-mdc-tab-content' tabs = {tabs} onSelect={handleOnSelect}>
          <Outlet/>
        </Tabtemplate>
    }, []);
    const handleOnSelect = useCallback((key: string | null) => {
        navigate(`/innerbase/menu/mpcmdc/${key}?title=mpcgyarah-menu`);
    }, [])
    return (
        <>
           {renderEnquiriesTabs()}
        </>
    )
}

export default MPCMDCTabs;