import styles from "./styles.module.css";

import { Form } from "react-bootstrap";
import classNames from "classnames";
import { useLocation, useNavigate } from "react-router-dom";
import { useCallback, useState } from "react";

import View from "../../enums/View";
import ViewLayout from "../view-layout";
import Button from "../../../shared/components/Button";
import DateFormat from "../../../shared/enums/DateFormat";
import JcPlanningService from "../../../shared/services/JcPlanningService";
import { useFormContext } from "../../../shared/hooks/useFormContext";
import DateTimeProcessor from "../../../shared/processors/DateTimeProcessor";
import ServerConnectorUtil from "../../../shared/utils/ServerConnectorUtil";
import { useCreateJcPlanningContext } from "../../hooks/useCreateJcPlanningContext";
import SearchDropdowntemplate from "../../../Components/templates/SearchDropdowntemplate";

import { CreateJcPlanningForm } from "../../types";
import { IOptions } from "../../../Types/global";


const FormView: React.FC = () => {
  const navigate = useNavigate();
  const {pathname} = useLocation();
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const { values, errors, setValue, setError, clearErrors } =
    useFormContext<CreateJcPlanningForm>();
  const { planTypeOptions, goToView, cancelOperation } =
    useCreateJcPlanningContext();

  const onPlanTypeChange = useCallback(
    (option: IOptions) => {
      setValue("planType", option);
    },
    [setValue]
  );

  const onAnchorVillageClick = useCallback(() => {
    goToView(View.SELECT_ANCHOR_VILLAGE_VIEW);
  }, [goToView]);

  const onNonAnchorVillageClick = useCallback(() => {
    if (!values.anchorVillage) {
      setError("anchorVillage", "Select anchor village.");
      return;
    }
    goToView(View.SELECT_NON_ANCHOR_VILLAGES_VIEW);
  }, [setError, goToView, values.anchorVillage]);

  const createJcPlanning = useCallback(() => {
    clearErrors();
    if (!values.planType) {
      setError("planType", "Plan type cannot be empty.");
      return;
    }
    if (!values.anchorVillage) {
      setError("anchorVillage", "Anchor village cannot be empty.");
      return;
    }
    if (!values.nonAnchorVillages || values.nonAnchorVillages.length === 0) {
      setError("nonAnchorVillages", "Non anchor villages cannot be empty.");
    }

    setIsProcessing(true);
    JcPlanningService.createJcPlannings({
      data: values
        .nonAnchorVillages!.map((item) => ({
          date: values.date as number,
          planType: values.planType!.value,
          villageId: item.id
        }))
        .concat([
          {
            date: values.date as number,
            planType: values.planType!.value,
            villageId: values.anchorVillage!.id
          }
        ])
    })
      .then(() => {
        goToView(View.SUMMARY_VIEW)
        navigate(
          {
            pathname: pathname,
            search: new URLSearchParams({
              title: "create_jcp",
              month: DateTimeProcessor.format(values.date!,DateFormat.MONTH),
              year: DateTimeProcessor.format(values.date!,DateFormat.YEAR)
            }).toString()
          }
        )
      })
      .catch(ServerConnectorUtil.handleServerError)
      .finally(() => setIsProcessing(false));
  }, [values, navigate, setError, clearErrors]);

  return (
    <ViewLayout>
      <ViewLayout.Body className={styles["container"]}>
        <Form.Group
          className={classNames("custom-form-group", styles["form-group"])}
        >
          <Form.Label className="custom-form-label">Plan Type</Form.Label>
          <Form.Control as="div" size="lg" className="custom-form-div">
            <SearchDropdowntemplate
              options={planTypeOptions}
              onSelect={onPlanTypeChange}
              defaultValue={values.planType}
              placeholder={"Select a plan type"}
            />
          </Form.Control>
          <p className={classNames("empty-msg visible")}>{errors.planType}</p>
        </Form.Group>
        <Form.Group
          className={classNames("custom-form-group", styles["form-group"])}
        >
          <Form.Label className="custom-form-label">Anchor Village</Form.Label>
          <Form.Control
            as="div"
            size="lg"
            className="custom-form-div"
            onClick={onAnchorVillageClick}
          >
            <div
              className={classNames(
                "anchor-box",
                values.anchorVillage ? "anchor-data" : "placeholder-class"
              )}
            >
              {values.anchorVillage?.name || "Select anchor village"}
            </div>
          </Form.Control>
          <p className={classNames("empty-msg visible")}>
            {errors.anchorVillage}
          </p>
        </Form.Group>
        <Form.Group
          className={classNames("custom-form-group", styles["form-group"])}
        >
          <Form.Label className="custom-form-label">
            Non Anchor Villages
          </Form.Label>
          <Form.Control
            as="div"
            size="lg"
            className="custom-form-div"
            onClick={onNonAnchorVillageClick}
          >
            <div
              className={classNames(
                "anchor-box",
                values.nonAnchorVillages ? "anchor-data" : "placeholder-class"
              )}
            >
              {values.nonAnchorVillages?.map((item) => item.name).join(" , ") ||
                "Select non anchor villages"}
            </div>
          </Form.Control>
          <p className={classNames("empty-msg visible")}>
            {errors.nonAnchorVillages}
          </p>
        </Form.Group>
      </ViewLayout.Body>
      <ViewLayout.Footer>
        <Button label="Cancel" variant="secondary" onClick={cancelOperation} />
        <Button
          label="Submit"
          variant="primary"
          isLoading={isProcessing}
          onClick={createJcPlanning}
          disabled={
            !values.planType ||
            !values.anchorVillage ||
            !values.nonAnchorVillages ||
            !values.nonAnchorVillages.length
          }
        />
      </ViewLayout.Footer>
    </ViewLayout>
  );
};

export default FormView;
