import React, {
  FormEvent,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react';
import './index.css';
import '../../../CSS/form.css';
import Form from 'react-bootstrap/Form';
import axios from 'axios';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import {
  IFields,
  ILocation,
  IOptions,
  ITudbFormData,
  IUserContext,
  IVillage
} from '../../../../Types/global';
import ServerConnectorUtil from '../../../../shared/utils/ServerConnectorUtil';
import ContactService from '../../../../shared/services/ContactService';
import Loadertemplate from '../../../templates/Loadertemplate';
import GeographyTemp from '../../../templates/GeographyTemp';
import SearchDropdowntemplate from '../../../templates/SearchDropdowntemplate';
import Buttontemplate from '../../../templates/Buttontemplate';
import useTUDBId from '../../../../shared/hooks/useTUDBId';
import useEditTUDB from '../../../../shared/hooks/useEditTUDB';
import TractorUserDatabaseService from '../../../../shared/services/TractorUserDatabaseService';
import getGeography from '../../../Hooks/getGeography';
import GeographyService from '../../../../shared/services/GeographyServices';
import ToastUtil from '../../../../shared/utils/ToastUtil';
import { UserContext } from '../../../HOC/UserContext';
// import ServerConnectorUtil from '../../../shared/utils/ServerConnectorUtil';
// import ContactService from '../../../shared/services/ContactService';
const i18nTranslated = {
  'personalDetails-label': 'Personal Details',
  'tractorDetails-label': 'Tractor Details',
  'customerType-label': 'Customer type',
  'satisfiedTractor-label': 'Satisfied with tractor',
  'dissatisfaction-label': 'Reason for dissatisfaction',
  tractor_brand_owned: 'Current brand',
  tractor_model_owned: 'Current model',
  'yom-label': 'Purchase Year',
  'name-label': 'Name',
  'phone-label': 'Phone number',
  'state-label': 'State',
  'district-label': 'District',
  'taluka-label': 'Taluka',
  'village-label': 'Village',
  'save-button': 'Save',
  'name-placeholder': 'Name',
  'phone-placeholder': 'Phone number',
  'state-placeholder': 'Add State',
  'district-placeholder': 'Add District',
  'taluka-placeholder': 'Add Taluka',
  'village-placeholder': 'Add Village',
  yesCheckbox: 'Yes',
  noCheckbox: 'No',
  'sourceOfUser-label': 'Source of user',
  no_of_tractors_owned: 'No of Tractors Owned',
  hp_segment: 'HP Segment',
  no_of_tractors_village: 'Totel number of tractors in your village',
  nameEmptyError: "Name can't be empty field",
  mobileEmptyError: "Mobile can't be empty field",
  invaildMobileSyntax: 'Enter the valid mobile number',
  emptyFields: "Field can't be empty"
};
const regex = {
  phoneRegex: /^\d{10}$/
};
const TUDB: React.FC = () => {
  const navigate = useNavigate();
  const [btnLoading, setbtnLoading] = useState<boolean>(false);
  const [tudbData, settudbData] = useState<ITudbFormData>({
    name: null,
    mobile_number: null,
    type: null,
    source: null,
    village: null,
    taluka: null,
    tractor: {
      brand: null,
      model: null,
      hp_segment: null,
      purchased_year: null
    },
    no_of_tractors_owned: null,
    is_satisfied_with_his_tractor: 'No',
    reason_of_dissatisfaction: null,
    total_no_of_tractors_in_the_village: null
  });

  const [showLazyLoad, setshowLazyLoad] = useState<boolean>(false);
  const [customerTypes, setcustomerTypes] = useState<IOptions[]>([]);
  const [purchasedYear, setpurchasedYear] = useState<IOptions[]>([]);
  const [sourceOfUser, setsourceOfUser] = useState<IOptions[]>([]);
  const [reasonDissatisfied, setreasonDissatisfied] = useState<IOptions[]>([]);
  const [tractorOwned, settractorOwned] = useState<IOptions[]>([]);
  const [hpSegment, sethpSegment] = useState<IOptions[]>([]);
  const [brandOwned, setbrandOwned] = useState<IOptions[]>([]);
  const [modelOwned, setmodelOwned] = useState<IOptions[]>([]);
  const [noTractorsVillage, setnoTractorsVillage] = useState<IOptions[]>([]);
  const [contactName, setContactName] = useState<string>('');
  const [isEditable, setisEditable] = useState<boolean>(false);

  const geographyEle = useRef<{
    getSelectedLocation: () => ILocation;
    setErrorMsg: (key: string, error: string) => void;
  }>(null);
  const [errors, seterrors] = useState({
    name: '',
    mobile_number: '',
    taluka: '',
    village: '',
    type: '',
    sourceOfUser: '',
    satisfiedWithTractor: '',
    reasonForDissatisfaction: '',
    tractorsOwned: '',
    tractorBrand: '',
    tractorModel: '',
    purchasedYear: '',
    hpSegment: '',
    tractorInVillage: ''
  });
  useEffect(() => {
    const getTudbMeta = async () => {
      try {
        setshowLazyLoad(true);
        let responseMeta = await axios.get(
          '/api/v1/tractor-user-database/meta'
        );
        let { data } = responseMeta.data;
        data.forEach((item: IFields) => {
          switch (item.name) {
            case 'customer_type':
              setcustomerTypes(item.options);
              break;
            case 'purchased_year':
              setpurchasedYear(item.options);
              break;
            case 'source_of_user':
              setsourceOfUser(item.options);
              break;
            case 'reason_of_dissatisfaction':
              setreasonDissatisfied(item.options);
              break;
            case 'no_of_tractors_owned':
              settractorOwned(item.options);
              break;
            case 'hp_segment':
              sethpSegment(item.options);
              break;
            case 'tractor_brand_owned':
              setbrandOwned(item.options);
              break;
            case 'tractor_model_owned':
              setmodelOwned(item.options);
              break;
            case 'total_number_of_tractors_in_your_village':
              setnoTractorsVillage(item.options);
              break;
          }
        });
      } catch (error) {
        if (error instanceof Error) {
          ServerConnectorUtil.handleServerError(error);
        }
      } finally {
        setshowLazyLoad(false);
      }
    };
    getTudbMeta();
  }, []);
  const handleTudbSubmit = async (event: FormEvent<HTMLFormElement>) => {
    try {
      event.preventDefault();
      if (validateForm()) {
        setbtnLoading(true);
        tudbData.reason_of_dissatisfaction === null &&
          delete tudbData.reason_of_dissatisfaction;
        let geographyElement = geographyEle.current;
        let geography: ILocation =
          geographyElement?.getSelectedLocation() as ILocation;
        tudbData.taluka = geography.taluka;
        tudbData.village = geography.village;
        await axios.post('/api/v1/tractor-user-database', tudbData);
        navigate('/success/tudb');
      }
    } catch (error: any) {
      let { data } = error.response;
    } finally {
      setbtnLoading(false);
    }
  };
  const validateForm = useCallback(() => {
    let geographyElement = geographyEle.current;
    let geography = geographyElement?.getSelectedLocation();
    let setGeographyError = geographyElement?.setErrorMsg;
    if (tudbData.name === '' || tudbData.name === null) {
      seterrors((prevs) => ({ ...prevs, name: i18nTranslated.nameEmptyError }));
      return false;
    }
    if (tudbData.mobile_number === '' || tudbData.mobile_number === null) {
      seterrors((prev) => ({
        ...prev,
        mobile_number: i18nTranslated.mobileEmptyError
      }));
      return false;
    } else if (
      tudbData.mobile_number !== null &&
      !regex.phoneRegex.test(tudbData.mobile_number)
    ) {
      seterrors((prev) => ({
        ...prev,
        mobile_number: i18nTranslated.invaildMobileSyntax
      }));
      return false;
    }
    if (geography !== undefined) {
      if (geography.taluka === '' || geography.taluka === null) {
        if (setGeographyError)
          setGeographyError('taluka', i18nTranslated.emptyFields);
        return false;
      }
      if (
        geography.village === 'undefined' ||
        geography.village === '' ||
        geography.village === null
      ) {
        if (setGeographyError)
          setGeographyError('village', i18nTranslated.emptyFields);
        return false;
      }
    }
    if (tudbData.type === '' || tudbData.type === null) {
      seterrors((prev) => ({ ...prev, type: i18nTranslated.emptyFields }));
      return false;
    }
    if (tudbData.source === '' || tudbData.source === null) {
      seterrors((prev) => ({
        ...prev,
        sourceOfUser: i18nTranslated.emptyFields
      }));
      return false;
    }
    if (
      tudbData.is_satisfied_with_his_tractor === 'No' &&
      (tudbData.reason_of_dissatisfaction === '' ||
        tudbData.reason_of_dissatisfaction === null)
    ) {
      seterrors((prev) => ({
        ...prev,
        reasonForDissatisfaction: i18nTranslated.emptyFields
      }));
      return false;
    }
    if (
      tudbData.no_of_tractors_owned === '' ||
      tudbData.no_of_tractors_owned === null
    ) {
      seterrors((prev) => ({
        ...prev,
        tractorsOwned: i18nTranslated.emptyFields
      }));
      return false;
    }
    if (tudbData.tractor.brand === '' || tudbData.tractor.brand === null) {
      seterrors((prev) => ({
        ...prev,
        tractorBrand: i18nTranslated.emptyFields
      }));
      return false;
    }
    if (tudbData.tractor.model === '' || tudbData.tractor.model === null) {
      seterrors((prev) => ({
        ...prev,
        tractorModel: i18nTranslated.emptyFields
      }));
      return false;
    }
    if (
      tudbData.tractor.purchased_year === '' ||
      tudbData.tractor.purchased_year === null
    ) {
      seterrors((prev) => ({
        ...prev,
        purchasedYear: i18nTranslated.emptyFields
      }));
      return false;
    }
    if (
      tudbData.tractor.hp_segment === '' ||
      tudbData.tractor.hp_segment === null
    ) {
      seterrors((prev) => ({ ...prev, hpSegment: i18nTranslated.emptyFields }));
      return false;
    }
    if (
      tudbData.total_no_of_tractors_in_the_village === '' ||
      tudbData.total_no_of_tractors_in_the_village === null
    ) {
      seterrors((prev) => ({
        ...prev,
        tractorInVillage: i18nTranslated.emptyFields
      }));
      return false;
    }
    return true;
  }, [tudbData]);
  const handleInputForm = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      let { name, value } = event.target;
      if (name !== undefined && value !== undefined) {
        settudbData((prev) => ({
          ...prev,
          [name]: value ?? null
        }));
      }
    },
    [tudbData]
  );
  const removeError = useCallback(
    (key: string) => {
      seterrors((prev) => ({ ...prev, [key]: '' }));
    },
    [errors]
  );
  const onChangeMobileNumber = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      let mobileNumber = event.target.value;

      settudbData((prev) => ({ ...prev, mobile_number: mobileNumber }));
      if (mobileNumber.length == 10) {
        ContactService.getPagedContacts({
          page: 1,
          perPage: 1,
          filters: {
            mobileNumber
          }
        }).then((pagedResut) => {
          if (pagedResut?.data?.length) {
            setContactName(pagedResut.data[0].name);
          }
        });
      }
    },
    []
  );
  const onCustomerSatisfiedCheck = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      settudbData((prev) => ({
        ...prev,
        reason_of_dissatisfaction: '',
        is_satisfied_with_his_tractor: event.target.checked ? 'Yes' : 'No'
      }));
      setselectedDissatisfactionReason(null);
    },
    []
  );
  const onCustomerNotSatisfiedCheck = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      settudbData((prev) => ({
        ...prev,
        reason_of_dissatisfaction: '',
        is_satisfied_with_his_tractor: event.target.checked ? 'No' : 'Yes'
      }));
      setselectedDissatisfactionReason(null);
    },
    []
  );

  // Edit code
  const currentUser = useContext(UserContext) as IUserContext;
  const [selectedTaluka, setselectedTaluka] = useState<IOptions | null>(null);
  const [talukasArray, settalukasArray] = useState<IOptions[]>([]);
  const [villagesOriginalArray, setVillagesOriginalArray] = useState<
    IVillage[]
  >([]);
  const [selectedVillage, setSelectedVillage] = useState<IOptions | null>(null);
  const [selectedVillageData, setSelectedVillageData] =
    useState<IVillage | null>(null);

  const [villageDropDownOptions, setVillageDropDownOptions] = useState<
    IOptions[]
  >([]);
  const [selectedType, setselectedType] = useState<IOptions | null>(null);
  const [selectedSource, setselectedSource] = useState<IOptions | null>(null);
  const [selectedDissatisfactionReason, setselectedDissatisfactionReason] =
    useState<IOptions | null>(null);
  const [selectedTractorOwned, setselectedTractorOwned] =
    useState<IOptions | null>(null);
  const [selectedBrand, setselectedBrand] = useState<IOptions | null>(null);
  const [selectedModel, setselectedModel] = useState<IOptions | null>(null);
  const [selectedHpSegment, setselectedHpSegment] = useState<IOptions | null>(
    null
  );
  const [selectedPurchasedYear, setselectedPurchasedYear] =
    useState<IOptions | null>(null);
  const [selectedTotalTractor, setselectedTotalTractor] =
    useState<IOptions | null>(null);
  const [villageDisabled, setvillageDisabled] = useState<boolean>(true);

  const { tudb, isLoading, id, editPage, settudb } = useTUDBId();
  const onTalukaSelect = useCallback(async (option: IOptions) => {
    setselectedTaluka(option);
    setSelectedVillage(null);
    setSelectedVillageData(null);
    setvillageDisabled(true);
    GeographyService.getPagedVillages({
      page: 1,
      perPage: 1000,
      filters: {
        taluk: option.value
      }
    }).then((pagedResut) => {
      setVillageDropDownOptions(
        pagedResut.data.map((item) => ({
          label: item.name,
          value: item.id
        }))
      );
      setVillagesOriginalArray(pagedResut.data);
      setvillageDisabled(false);
    });
  }, []);
  const onVillageSelect = useCallback(
    (option: IOptions) => {
      const villageData = villagesOriginalArray.find(
        (item) => item.id === option.value
      );
      if (villageData) {
        setSelectedVillage(option);
        setSelectedVillageData(villageData);
      }
    },
    [villagesOriginalArray]
  );
  useEffect(() => {
    if (tudb) {
      setselectedType({
        label: tudb.type as string,
        value: tudb.type as string
      });

      setselectedSource({
        label: tudb.source as string,
        value: tudb.source as string
      });

      setselectedDissatisfactionReason({
        label: tudb.reason_of_dissatisfaction as string,
        value: tudb.reason_of_dissatisfaction as string
      });

      setselectedTractorOwned({
        label: tudb.no_of_tractors_owned as string,
        value: tudb.no_of_tractors_owned as string
      });

      setselectedBrand({
        label: tudb.tractor.brand as string,
        value: tudb.tractor.brand as string
      });

      setselectedModel({
        label: tudb.tractor.model as string,
        value: tudb.tractor.model as string
      });

      setselectedHpSegment({
        label: tudb.tractor.hp_segment as string,
        value: tudb.tractor.hp_segment as string
      });

      setselectedPurchasedYear({
        label: tudb.tractor.purchased_year as string,
        value: tudb.tractor.purchased_year as string
      });

      setselectedTotalTractor({
        label: tudb.total_no_of_tractors_in_the_village as string,
        value: tudb.total_no_of_tractors_in_the_village as string
      });

      setselectedTaluka({
        label: tudb.taluka as string,
        value: tudb.taluka as string
      });
      // getGeography
      //   .getTaluka()
      //   .then((taluks) => {
      //     settalukasArray(taluks);
      //   })
      //   .catch(ServerConnectorUtil.handleServerError);

      // GeographyService.getPagedVillages({
      //   page: 1,
      //   perPage: 1000,
      //   filters: {
      //     taluk: tudb.taluka as string
      //   }
      // })
      //   .then((pagedResut) => {
      //     const villageData = pagedResut.data.find(
      //       (item) => item.name === tudb.village
      //     );
      //     setVillagesOriginalArray(pagedResut.data);
      //     if (villageData) {
      //       setSelectedVillage({
      //         label: villageData.name,
      //         value: villageData.id
      //       });
      //       setSelectedVillageData(villageData);
      //     }
      //   })
      //   .catch(ServerConnectorUtil.handleServerError);

      settudbData({
        name: tudb.name,
        mobile_number: tudb.mobile_number,
        type: tudb.type,
        source: tudb.source,
        village: tudb.village,
        taluka: tudb.taluka,
        tractor: {
          brand: tudb.tractor.brand,
          model: tudb.tractor.model,
          hp_segment: tudb.tractor.hp_segment,
          purchased_year: tudb.tractor.purchased_year
        },
        no_of_tractors_owned: tudb.no_of_tractors_owned,
        is_satisfied_with_his_tractor: tudb.is_satisfied_with_his_tractor,
        reason_of_dissatisfaction: tudb.reason_of_dissatisfaction,
        total_no_of_tractors_in_the_village:
          tudb.total_no_of_tractors_in_the_village
      });
    }
  }, [tudb]);
  const handleOnSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!isEditable) {
      setisEditable(true);
      return false;
    }

    if (tudb && validateForm()) {
      setbtnLoading(true);
      TractorUserDatabaseService.updateTUDBData({
        id: tudb.id as string,
        body: {
          type: tudbData.type as string,
          source: tudbData.source as string,
          village: tudbData.village as string,
          taluka: tudbData.taluka as string,
          tractor: {
            brand: tudbData.tractor.brand as string,
            model: tudbData.tractor.model as string,
            hp_segment: tudbData.tractor.hp_segment as string,
            purchased_year: tudbData.tractor.purchased_year as string
          },
          no_of_tractors_owned: tudbData.no_of_tractors_owned as string,
          total_no_of_tractors_in_the_village:
            tudbData.total_no_of_tractors_in_the_village as string,
          is_satisfied_with_his_tractor:
            tudbData.is_satisfied_with_his_tractor as string,
          reason_of_dissatisfaction:
            tudbData.reason_of_dissatisfaction as string
        }
      })
        .then((response) => {
          settudb(response.data);
          setisEditable(false);
          ToastUtil.makeSuccessToast('Record updated successfully.');
        })
        .catch((error) => ServerConnectorUtil.handleServerError(error))
        .finally(() => {
          setbtnLoading(false);
        });
    }
  };
  return (
    <>
      {isLoading ? (
        <div className="loader-wrapper">
          <Loadertemplate></Loadertemplate>
        </div>
      ) : (
        <Form onSubmit={handleOnSubmit}>
          <div className="custom-form">
            <Form.Group className="custom-form-group">
              <Form.Label className="custom-form-label">
                {i18nTranslated['phone-label']}
                <span className="required-star">*</span>
              </Form.Label>
              <Form.Control
                size="lg"
                disabled={true}
                className="custom-form-input"
                placeholder={i18nTranslated['phone-placeholder']}
                name="mobile_number"
                value={
                  tudbData.mobile_number !== null ? tudbData.mobile_number : ''
                }
                onChange={onChangeMobileNumber}
                onClick={() => removeError('mobile_number')}
              ></Form.Control>
              <div
                className={classNames('empty-msg', {
                  visible: errors.mobile_number !== ''
                })}
              >
                {errors.mobile_number}
              </div>
            </Form.Group>
            <Form.Group className="custom-form-group">
              <Form.Label className="custom-form-label">
                {i18nTranslated['name-label']}
                <span className="required-star">*</span>
              </Form.Label>
              <Form.Control
                size="lg"
                className="custom-form-input"
                name="name"
                disabled={true}
                value={tudbData.name !== null ? tudbData.name : ''}
                onChange={handleInputForm}
                placeholder={i18nTranslated['name-placeholder']}
                onClick={() => removeError('name')}
              ></Form.Control>
              <div
                className={classNames('empty-msg', {
                  visible: errors.name !== ''
                })}
              >
                {errors.name}
              </div>
            </Form.Group>
            {contactName.length ? (
              <Form.Group
                className="custom-form-group"
                style={{ width: '100%' }}
              >
                <Form.Label className="custom-form-label">
                  Contact Name
                </Form.Label>
                <Form.Control
                  disabled={true}
                  size="lg"
                  className="custom-form-input"
                  name="name"
                  value={contactName || ''}
                ></Form.Control>
              </Form.Group>
            ) : (
              <></>
            )}
            {
              !isEditable ? 
              <>
                <Form.Group className="custom-form-group">
                  <Form.Label className="custom-form-label">Taluka</Form.Label>
                  <Form.Control
                    disabled={true}
                    size="lg"
                    className="custom-form-input"
                    name="name"
                    value={tudb?.taluka || ''}
                  ></Form.Control>
                </Form.Group>
                <Form.Group className="custom-form-group">
                  <Form.Label className="custom-form-label">Village</Form.Label>
                  <Form.Control
                    disabled={true}
                    size="lg"
                    className="custom-form-input"
                    name="name"
                    value={tudb?.village || ''}
                  ></Form.Control>
                </Form.Group>
              </> 
              : 
              <></>
            }
            <Form.Group className="custom-form-group">
              <Form.Label className="custom-form-label">
                {i18nTranslated['customerType-label']}
                <span className="required-star">*</span>
              </Form.Label>
              <Form.Control
                as="div"
                size="lg"
                className="custom-form-div"
                onClick={() => removeError('type')}
              >
                <SearchDropdowntemplate
                  options={customerTypes}
                  classes=""
                  placeholder="Customer Type"
                  isDisabled={!isEditable}
                  defaultValue={selectedType}
                  isSearchable={true}
                  onSelect={(option) => {
                    settudbData((prev) => ({ ...prev, type: option.value }));
                  }}
                ></SearchDropdowntemplate>
              </Form.Control>
              <div
                className={classNames('empty-msg', {
                  visible: errors.type !== ''
                })}
              >
                {errors.type}
              </div>
            </Form.Group>
            <Form.Group className="custom-form-group">
              <Form.Label className="custom-form-label">
                {i18nTranslated['sourceOfUser-label']}
                <span className="required-star">*</span>
              </Form.Label>
              <Form.Control
                as="div"
                size="lg"
                className="custom-form-div"
                onClick={() => removeError('sourceOfUser')}
              >
                <SearchDropdowntemplate
                  options={sourceOfUser}
                  classes=""
                  placeholder="Source of user"
                  isDisabled={!isEditable}
                  defaultValue={selectedSource}
                  isSearchable={true}
                  onSelect={(option) => {
                    settudbData((prev) => ({ ...prev, source: option.value }));
                  }}
                ></SearchDropdowntemplate>
              </Form.Control>
              <div
                className={classNames('empty-msg', {
                  visible: errors.sourceOfUser !== ''
                })}
              >
                {errors.sourceOfUser}
              </div>
            </Form.Group>
            <Form.Group className="custom-form-group">
              <Form.Label className="custom-form-label">
                {i18nTranslated['satisfiedTractor-label']}
                <span className="required-star">*</span>
              </Form.Label>
              <div>
                <Form.Check
                  inline
                  bsPrefix="custom-form-check"
                  type="radio"
                  onChange={onCustomerSatisfiedCheck}
                  label={i18nTranslated['yesCheckbox']}
                  name="yesRadio"
                  disabled={!isEditable}
                  checked={tudbData.is_satisfied_with_his_tractor === 'Yes'}
                ></Form.Check>
                <Form.Check
                  inline
                  bsPrefix="custom-form-check"
                  type="radio"
                  onChange={onCustomerNotSatisfiedCheck}
                  label={i18nTranslated['noCheckbox']}
                  name="yesRadio"
                  disabled={!isEditable}
                  checked={tudbData.is_satisfied_with_his_tractor === 'No'}
                ></Form.Check>
              </div>
            </Form.Group>
            {tudbData.is_satisfied_with_his_tractor === 'No' && (
              <Form.Group className="custom-form-group">
                <Form.Label className="custom-form-label">
                  {i18nTranslated['dissatisfaction-label']}
                  <span className="required-star">*</span>
                </Form.Label>
                <Form.Control
                  as="div"
                  size="lg"
                  className="custom-form-div"
                  onClick={() => removeError('reasonForDissatisfaction')}
                >
                  <SearchDropdowntemplate
                    options={reasonDissatisfied}
                    defaultValue={selectedDissatisfactionReason}
                    classes=""
                    placeholder="Dissatisfaction reason"
                    isDisabled={!isEditable}
                    isSearchable={true}
                    onSelect={(option) => {
                      settudbData((prev) => ({
                        ...prev,
                        reason_of_dissatisfaction: option.value
                      }));
                    }}
                  ></SearchDropdowntemplate>
                </Form.Control>
                <div
                  className={classNames('empty-msg', {
                    visible: errors.reasonForDissatisfaction !== ''
                  })}
                >
                  {errors.reasonForDissatisfaction}
                </div>
              </Form.Group>
            )}
            <Form.Group className="custom-form-group">
              <Form.Label className="custom-form-label">
                {i18nTranslated['no_of_tractors_owned']}
                <span className="required-star">*</span>
              </Form.Label>
              <Form.Control
                as="div"
                size="lg"
                className="custom-form-div"
                onClick={() => removeError('tractorsOwned')}
              >
                <SearchDropdowntemplate
                  options={tractorOwned}
                  defaultValue={selectedTractorOwned}
                  classes=""
                  placeholder="Tractor owned"
                  isDisabled={!isEditable}
                  isSearchable={true}
                  onSelect={(option) => {
                    settudbData((prev) => ({
                      ...prev,
                      no_of_tractors_owned: option.value
                    }));
                  }}
                ></SearchDropdowntemplate>
              </Form.Control>
              <div
                className={classNames('empty-msg', {
                  visible: errors.tractorsOwned !== ''
                })}
              >
                {errors.tractorsOwned}
              </div>
            </Form.Group>
            <Form.Group className="custom-form-group">
              <Form.Label className="custom-form-label">
                {i18nTranslated['tractor_brand_owned']}
                <span className="required-star">*</span>
              </Form.Label>
              <Form.Control
                as="div"
                size="lg"
                className="custom-form-div"
                onClick={() => removeError('tractorBrand')}
              >
                <SearchDropdowntemplate
                  options={brandOwned}
                  defaultValue={selectedBrand}
                  classes=""
                  isDisabled={!isEditable}
                  placeholder="Tractor Brand"
                  isSearchable={true}
                  onSelect={(option) => {
                    settudbData((prev) => ({
                      ...prev,
                      tractor: { ...prev?.tractor, brand: option.value }
                    }));
                  }}
                ></SearchDropdowntemplate>
              </Form.Control>
              <div
                className={classNames('empty-msg', {
                  visible: errors.tractorBrand !== ''
                })}
              >
                {errors.tractorBrand}
              </div>
            </Form.Group>
            <Form.Group className="custom-form-group">
              <Form.Label className="custom-form-label">
                {i18nTranslated['tractor_model_owned']}
                <span className="required-star">*</span>
              </Form.Label>
              <Form.Control
                as="div"
                size="lg"
                className="custom-form-div"
                onClick={() => removeError('tractorModel')}
              >
                <SearchDropdowntemplate
                  options={modelOwned}
                  defaultValue={selectedModel}
                  classes=""
                  placeholder="Tractor model"
                  isDisabled={!isEditable}
                  isSearchable={true}
                  onSelect={(option) => {
                    settudbData((prev) => ({
                      ...prev,
                      tractor: { ...prev?.tractor, model: option.value }
                    }));
                  }}
                ></SearchDropdowntemplate>
              </Form.Control>
              <div
                className={classNames('empty-msg', {
                  visible: errors.tractorModel !== ''
                })}
              >
                {errors.tractorModel}
              </div>
            </Form.Group>
            <Form.Group className="custom-form-group">
              <Form.Label className="custom-form-label">
                {i18nTranslated['yom-label']}
                <span className="required-star">*</span>
              </Form.Label>
              <Form.Control
                as="div"
                size="lg"
                className="custom-form-div"
                onClick={() => removeError('purchasedYear')}
              >
                <SearchDropdowntemplate
                  options={purchasedYear}
                  defaultValue={selectedPurchasedYear}
                  classes=""
                  isSearchable={true}
                  placeholder="Purchased Year"
                  isDisabled={!isEditable}
                  onSelect={(option) => {
                    settudbData((prev) => ({
                      ...prev,
                      tractor: {
                        ...prev?.tractor,
                        purchased_year: option.value
                      }
                    }));
                  }}
                ></SearchDropdowntemplate>
              </Form.Control>
              <div
                className={classNames('empty-msg', {
                  visible: errors.purchasedYear !== ''
                })}
              >
                {errors.purchasedYear}
              </div>
            </Form.Group>
            <Form.Group className="custom-form-group">
              <Form.Label className="custom-form-label">
                {i18nTranslated['hp_segment']}
                <span className="required-star">*</span>
              </Form.Label>
              <Form.Control
                as="div"
                size="lg"
                className="custom-form-div"
                onClick={() => removeError('hpSegment')}
              >
                <SearchDropdowntemplate
                  options={hpSegment}
                  defaultValue={selectedHpSegment}
                  classes=""
                  placeholder="Hp segment"
                  isSearchable={true}
                  isDisabled={!isEditable}
                  onSelect={(option) => {
                    settudbData((prev) => ({
                      ...prev,
                      tractor: { ...prev?.tractor, hp_segment: option.value }
                    }));
                  }}
                ></SearchDropdowntemplate>
              </Form.Control>
              <div
                className={classNames('empty-msg', {
                  visible: errors.hpSegment !== ''
                })}
              >
                {errors.hpSegment}
              </div>
            </Form.Group>
            <Form.Group className="custom-form-group">
              <Form.Label className="custom-form-label">
                {i18nTranslated['no_of_tractors_village']}
                <span className="required-star">*</span>
              </Form.Label>
              <Form.Control
                as="div"
                size="lg"
                className="custom-form-div"
                onClick={() => removeError('tractorInVillage')}
              >
                <SearchDropdowntemplate
                  options={noTractorsVillage}
                  defaultValue={selectedTotalTractor}
                  classes=""
                  placeholder="No of Tractors Village"
                  isDisabled={!isEditable}
                  isSearchable={true}
                  onSelect={(option) => {
                    settudbData((prev) => ({
                      ...prev,
                      total_no_of_tractors_in_the_village: option.value
                    }));
                  }}
                ></SearchDropdowntemplate>
              </Form.Control>
              <div
                className={classNames('empty-msg', {
                  visible: errors.tractorInVillage !== ''
                })}
              >
                {errors.tractorInVillage}
              </div>
            </Form.Group>
            <Form.Group className="custom-form-group">
              <Form.Label className="custom-form-label">Dealer Name</Form.Label>
              <Form.Control
                disabled={true}
                size="lg"
                className="custom-form-input"
                name="name"
                value={currentUser.user.dealer_name || ''}
              ></Form.Control>
            </Form.Group>
            <Form.Group className="custom-form-group">
              <Form.Label className="custom-form-label">Dealer Code</Form.Label>
              <Form.Control
                disabled={true}
                size="lg"
                className="custom-form-input"
                name="name"
                value={currentUser.user.dealer_code || ''}
              ></Form.Control>
            </Form.Group>
          </div>
          <div className="custom-form-btn">
            <Buttontemplate type="primary" buttonWidth="100%">
              {btnLoading ? (
                <div className="button-loader">
                  <Loadertemplate type="button" />
                </div>
              ) : isEditable ? (
                'Update TUDB'
              ) : (
                'Edit TUDB'
              )}
            </Buttontemplate>
          </div>
        </Form>
      )}
    </>
  );
};
export default TUDB;
